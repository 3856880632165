import { Days } from '@/components/dashboard/availabilities/interfaces';
import { FilterTypes } from '@/components/search/filterDropdown/interfaces';
import { LocationState } from '@/components/search/search/interfaces';
import { ListingStatus, PropertyEnum } from '@proprioo/hokkaido';
import { Libraries } from '@react-google-maps/api';

import {
  ANCENIS_SAINT_GEREON,
  ANNECY,
  BEAUPREAU_EN_MAUGES,
  BELLEVIGNY,
  BELLEVILLE,
  CHAUMES_EN_RETZ,
  CHESNAY_ROCQUENCOURT,
  EPAGNY_METZ_TESSY,
  ESSARTS_EN_BOCAGE,
  EVRY_COURCOURONNES,
  INGRANDES_LE_FRESNE_SUR_LOIRE,
  LES_ABRETS_EN_DAUPHINE,
  LOIREAUXENCE,
  MACHECOUL_SAINT_MEME,
  MARENNES_HIERS_BROUAGE,
  MARSEILLE_2,
  MORET_LOING_ET_ORVANNE,
  OMBREE_D_ANJOU,
  OREE_D_ANJOU,
  PALADRU_VILLAGES,
  SEVREMOINE,
  THIZY_LES_BOURGS,
  VALLONS_DE_L_ERDRE,
  VILLEMAURY
} from './locationStates';

type DictionaryPlace = Record<string, LocationState>;

export const COOKIE_KEY = 'proprioo-dashboard_token';
export const X_REQUESTED_WITH = 'website';
export const X_REQUESTED_WITH_ONLINE_VALUATION = 'onlineValuation';
export const X_REQUESTED_WITH_BOOKING = 'estimation';
export const DEFAULT_PROPERTIES = [PropertyEnum.FLAT, PropertyEnum.HOUSE];
export const ALL_PROPERTIES = [
  ...DEFAULT_PROPERTIES,
  PropertyEnum.PARKING,
  PropertyEnum.BUILDING
];
export const DEFAULT_STATUS = ListingStatus.PUBLISHED;
export const DEFAULT_TIMEZONE = 'Europe/Paris';
export const DAY_FORMAT = 'EEEE d MMMM yyyy';
export const DAY_SHORT_FORMAT = 'EE';
export const MONTH_FORMAT = 'd MMMM';
export const DAY_HOURS_FORMAT = 'EEEE d MMMM yyyy HH:mm';
export const HOURS_FORMAT = 'HH:mm';
export const DISTRICT_POSTCODE_REGEX = /(^[a-z]+)\s[0-9]+/i;
export const OPERATOR_REGEX = /(\w*OR|AND|<=|>=|<|>\w*)+/g;
export const LANGUAGE = 'fr';
export const CURRENCY_FORMAT = {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};
export const LIBRARIES: Libraries = ['drawing', 'geometry', 'places'];
export const MAX_CITIES_TITLE = 3;
export const LIST_DAYS = [
  Days.MONDAY,
  Days.TUESDAY,
  Days.WEDNESDAY,
  Days.THURSDAY,
  Days.FRIDAY,
  Days.SATURDAY,
  Days.SUNDAY
];
export const WEB_SHARE_SITES = [
  'copy',
  'mail',
  'linkedin',
  'facebook',
  'twitter',
  'whatsapp'
];
export const AUTHORIZED_FILTER_FIELDS = [
  FilterTypes.TYPE,
  FilterTypes.PRICE,
  FilterTypes.SURFACE,
  FilterTypes.ROOMS,
  FilterTypes.BEDROOMS,
  FilterTypes.PAGE,
  FilterTypes.STATUS,
  FilterTypes.OTHER
];
export const DEFAULT_AGENT_PICTURE =
  'https://cdn.proprioo.fr/assets/images/defaultAgent.png';

export const LOCATION_STATE_FROM_PLACE_ID: DictionaryPlace = {
  ChIJw5c6BqrByRIRGwhUuj22otk: MARSEILLE_2, // Marseille 2 wording
  ChIJXczrp_7AyRIREMy2UKkZCBw: MARSEILLE_2, // Marseille 2 postal code
  ChIJIc8v3w995kcRfuFlZGHww1c: CHESNAY_ROCQUENCOURT, // Le Chesnay-Rocquencourt
  ChIJZ0DhTAF95kcRBf65J29J5mI: CHESNAY_ROCQUENCOURT, // Le Chesnay
  ChIJcd79BmV95kcR0DiMaMOCCwQ: CHESNAY_ROCQUENCOURT, // Rocquencourt
  ChIJyVEFHPqPi0cRujQFYoEWeEI: ANNECY, // Annecy
  ChIJb81CnMGFi0cREJq65CqrCAQ: ANNECY, // Pringy
  'ChIJC5L-T5aPi0cRwEQ_IzW5nZ8': ANNECY, // Annecy-le-Vieux
  ChIJ0ewzR0KFi0cR3rhcqumUaYw: ANNECY, // Cran-Gevrier
  ChIJp5rNtRCFi0cRkGMNPma0_VM: ANNECY, // Meythet
  'ChIJA9EIOrOai0cRXL5-7WoDX-Q': ANNECY, // Seynod
  ChIJLQcLOS0ei0cR1vGu8HFTxmo: PALADRU_VILLAGES, // Paladru
  'ChIJNY9kEwfgikcREHG-5CqrCAQ': PALADRU_VILLAGES, // Le Pin
  ChIJtRs9VNwfi0cRuHQ8AivYsmw: PALADRU_VILLAGES, // Villages du Lac de Paladru
  ChIJ_a3NinLe5UcRsEeLaMOCCwQ: EVRY_COURCOURONNES, // Courcouronnes
  ChIJc80Aqh7e5UcROTx5ohKaxi8: EVRY_COURCOURONNES, // Évry
  ChIJD4p2DBne5UcRmKoINUem8ZM: EVRY_COURCOURONNES, // Évry-Courcouronnes
  ChIJ1ZmG8k2Ei0cRIKC65CqrCAQ: EPAGNY_METZ_TESSY, // Épagny
  ChIJOSfF2qyFi0cRaSqnUkfhXXE: EPAGNY_METZ_TESSY, // Épagny Metz-Tessy
  ChIJbV_MECqgBUgRbRGBWcieXOA: CHAUMES_EN_RETZ, // Chaumes-en-Retz
  ChIJJX2gebWfBUgRoPMNHlI3DQQ: CHAUMES_EN_RETZ, // Arthon-en-Retz
  ChIJO1ROG7aYBUgRcPENHlI3DQQ: CHAUMES_EN_RETZ, // Chéméré
  ChIJy3B_TgwtBEgRb74eRmRo4fQ: BELLEVIGNY, // Bellevigny
  ChIJDcN71Q0tBEgRtBhox3nSRaI: BELLEVIGNY, // Belleville-sur-Vie
  ChIJMVnbVsEsBEgRsK0JHlI3DQQ: BELLEVIGNY, // Saligny
  ChIJkTwA40ADBkgRIm_c2ygjxhU: ANCENIS_SAINT_GEREON, // Ancenis-Saint-Géréon
  ChIJXahR4kADBkgRwPMNHlI3DQQ: ANCENIS_SAINT_GEREON, // Ancenis
  ChIJExxk8o4DBkgRAOoNHlI3DQQ: ANCENIS_SAINT_GEREON, // Saint-Géréon
  ChIJ5aaxx3l980cRqeiVPKnj7W4: BELLEVILLE, // Belleville-en-Beaujolais
  ChIJD9LJCV9980cRFMuUzThAONk: BELLEVILLE, // Belleville
  ChIJd9eZiHR980cRcBy75CqrCAQ: BELLEVILLE, // Saint-Jean-d'Ardières
  ChIJY6RHLdakBUgRLpixnR1Ayy4: MACHECOUL_SAINT_MEME, // Machecoul-Saint-Même
  'ChIJhaWydBilBUgRi-74Gq06yhk': MACHECOUL_SAINT_MEME, // Machecoul
  ChIJgSyIk7KkBUgR0OgNHlI3DQQ: MACHECOUL_SAINT_MEME, // Saint-Même-le-Tenu
  ChIJt8cVSDKvCEgRw57XraLXBoQ: VALLONS_DE_L_ERDRE, // Vallons-de-l'Erdre
  'ChIJv1skLa-uCEgRqkmVSGD0a1E': VALLONS_DE_L_ERDRE, // Bonneuvre
  ChIJ01NCm_2mCEgRfJPN5nY2Rpo: VALLONS_DE_L_ERDRE, // Maumusson
  ChIJ0dAZmSmvCEgR4OgNHlI3DQQ: VALLONS_DE_L_ERDRE, // Saint-Mars-la-Jaille
  ChIJH8GGL0yuCEgRdlju_m2qhcM: VALLONS_DE_L_ERDRE, // Saint-Sulpice-des-Landes
  ChIJQQmVBlCjCEgRcOYNHlI3DQQ: VALLONS_DE_L_ERDRE, // Vritz
  ChIJiW2iOMwfBkgROGQuRxiT4lo: LOIREAUXENCE, // Loireauxence
  ChIJL9BxKlCgCEgRQPMNHlI3DQQ: LOIREAUXENCE, // Belligné
  ChIJ3Z6awwEgBkgRRS6Q1XAdbmI: LOIREAUXENCE, // La Chapelle-Saint-Sauveur
  ChIJDVAUnP8dBkgREq7bpSko090: LOIREAUXENCE, // La Rouxière
  'ChIJgXTPI-AeBkgR6Bp0qQCQF2g': LOIREAUXENCE, // Varades
  ChIJcdnN3BiBBkgRibLgIKmw71Y: ESSARTS_EN_BOCAGE, // Essarts-en-Bocage
  ChIJq_RSi9uABkgRwLgJHlI3DQQ: ESSARTS_EN_BOCAGE, // Les Essarts
  ChIJrzxHRYYqBEgR4LsJHlI3DQQ: ESSARTS_EN_BOCAGE, // Boulogne
  ChIJFWVCyN6HBkgRqrp66ULIhuA: ESSARTS_EN_BOCAGE, // L'Oie
  ChIJLUIIA7CHBkgRQLEJHlI3DQQ: ESSARTS_EN_BOCAGE, // Sainte-Florence
  ChIJAxweFbcFBkgRTBFRdtOHdgA: OREE_D_ANJOU, // Orée d'Anjou
  'ChIJ-TBi5FkGBkgRYJgNHlI3DQQ': OREE_D_ANJOU, // Champtoceaux
  ChIJXdE37w4bBkgRJtDk4CpZU6Y: OREE_D_ANJOU, // Bouzillé
  ChIJof_RREYEBkgROYgpL5OzCDo: OREE_D_ANJOU, // Drain
  ChIJ2xPjr8cIBkgRGeslgvdTaAY: OREE_D_ANJOU, // Landemont
  ChIJ3Q8d0cYEBkgRlyp0qviqbe8: OREE_D_ANJOU, // Liré
  hIJMTx3VKUPBkgRYIwNHlI3DQQ: OREE_D_ANJOU, // Saint-Christophe-la-Couperie
  ChIJ3VyYt34FBkgRAIsNHlI3DQQ: OREE_D_ANJOU, // Saint-Laurent-des-Autels
  ChIJMzmoYBkGBkgRgIkNHlI3DQQ: OREE_D_ANJOU, // Saint-Sauveur-de-Landemont
  ChIJqSaC6nH9BUgRYr2FFHlbcKs: OREE_D_ANJOU, // La Varenne
  ChIJQam1iwpmAUgR0CDbAcPv2bk: MARENNES_HIERS_BROUAGE, // Marennes-Hiers-Brouage
  'ChIJXR-pA95oAUgRIJ3uYJLTBQQ': MARENNES_HIERS_BROUAGE, // Hiers-Brouage
  ChIJkYpMl99lAUgRQJvuYJLTBQQ: MARENNES_HIERS_BROUAGE, // Marennes
  'ChIJd-D4WjQZi0cRMjmMHbE-39M': LES_ABRETS_EN_DAUPHINE, // Les Abrets en Dauphiné
  'ChIJAS08jDUZi0cR0IK-5CqrCAQ': LES_ABRETS_EN_DAUPHINE, // Les Abrets
  ChIJoSCgI9cYi0cRKMcFNWES8Ok: LES_ABRETS_EN_DAUPHINE, // Fitilieu
  'ChIJObKZ9Ccci0cRMIG-5CqrCAQ': LES_ABRETS_EN_DAUPHINE, // La Bâtie-Divisin
  ChIJKZ1eFTmH5EcRYC2GO7HCR1M: VILLEMAURY, // Villemaury
  'ChIJa-y23wx95EcRibpZYhzoSHE': VILLEMAURY, // Civry
  ChIJNzSgf7yA5EcRoHw5BdfIDQQ: VILLEMAURY, // Lutz-En-Dunois
  ChIJBx16rliG5EcRcHg5BdfIDQQ: VILLEMAURY, // Ozoir-Le-Breuil
  'ChIJw3d3tA-H5EcRYHY5BdfIDQQ': VILLEMAURY, // Saint-Cloud-En-Dunois
  'ChIJE1m_ufC-CEgRDHZ6d4mBXes': OMBREE_D_ANJOU, // Ombrée d'Anjou
  'ChIJgwkucPO3CEgRH2YB4KN-XC0': OMBREE_D_ANJOU, // Pouancé
  'ChIJD-pzFli_CEgRMnJPT73NLpc': OMBREE_D_ANJOU, // La Chapelle-Hullin
  ChIJgReohpy4CEgR8uZne5YSbxs: OMBREE_D_ANJOU, // Chazé-Henry
  'ChIJTaWzWEa-CEgRQJYNHlI3DQQ': OMBREE_D_ANJOU, // Combrée
  ChIJyW8YIXi_CEgRghDs5cWbAsE: OMBREE_D_ANJOU, // Grugé-l'Hôpital
  ChIJEULonEm5CEgREI8NHlI3DQQ: OMBREE_D_ANJOU, // Noëllet
  ChIJf3QJDXe3CEgRkI0NHlI3DQQ: OMBREE_D_ANJOU, // La Prévière
  ChIJMYeH6Ye5CEgRs_7qcE8RoCQ: OMBREE_D_ANJOU, // Saint-Michel-et-Chanveaux
  ChIJrVu51yS8CEgRcIcNHlI3DQQ: OMBREE_D_ANJOU, // Le Tremblay
  'ChIJR7R-dtG4CEgRu76ovBHikNs': OMBREE_D_ANJOU, // Vergonnes
  ChIJV7LWT95z9EcRDxYNDHEHMCA: THIZY_LES_BOURGS, // Thizy-les-Bourgs
  ChIJJbK6S4Vz9EcRACi75CqrCAQ: THIZY_LES_BOURGS, // Bourg-de-Thizy
  'ChIJoX-CZtN29EcRACe75CqrCAQ': THIZY_LES_BOURGS, // La Chapelle-de-Mardore
  ChIJxwmdA5V29EcRhfloTJoYLN8: THIZY_LES_BOURGS, // Mardore
  ChIJeUvWEkFx9EcRqGBaC5TDXRA: THIZY_LES_BOURGS, // Marnand
  ChIJG5tC5d1z9EcRBeDYUO3n5d4: THIZY_LES_BOURGS, // Thizy
  ChIJ9YEOxPoVBkgRrDVYM1tnW8A: BEAUPREAU_EN_MAUGES, // Baupréau-en-Mauges - Beaupréau
  ChIJ3TNjQLU_BkgRTEBvqTlp5cQ: BEAUPREAU_EN_MAUGES, // Andrezé
  ChIJk5bisq8VBkgRMJgNHlI3DQQ: BEAUPREAU_EN_MAUGES, // La Chapelle-du-Genêt
  ChIJxwPvyUMSBkgRcJMNHlI3DQQ: BEAUPREAU_EN_MAUGES, // Gesté
  ChIJI8B2LW45BkgRm5sbNi29eI8: BEAUPREAU_EN_MAUGES, // Jallais
  ChIJ0703VdQ4BkgRKush6q5ZuOc: BEAUPREAU_EN_MAUGES, // La Jubaudière
  ChIJC8yhBoE8BkgRUJWjmlo_BkE: BEAUPREAU_EN_MAUGES, // Le Pin-en-Mauges
  ChIJjxb4O8A7BkgRAI4NHlI3DQQ: BEAUPREAU_EN_MAUGES, // La Poitevinière
  ChIJEezWeOAUBkgREJNlDj8CXuQ: BEAUPREAU_EN_MAUGES, // Saint-Philbert-en-Mauges
  ChIJfUaQXF4TBkgR1zZrutOQXE4: BEAUPREAU_EN_MAUGES, // Villedieu-la-Blouère
  'ChIJEw3UU-cgBkgRSTF8B5EKEgo': INGRANDES_LE_FRESNE_SUR_LOIRE, // Ingrandes-le-Fresne sur Loire
  'ChIJ-6e0W9wgBkgR8JINHlI3DQQ': INGRANDES_LE_FRESNE_SUR_LOIRE, // Ingrandes
  ChIJs2OT9wAhBkgRQPANHlI3DQQ: INGRANDES_LE_FRESNE_SUR_LOIRE, // Le Fresne-sur-Loire
  ChIJOXY2BOpqBkgR6Jy0GDEHsok: SEVREMOINE, // Sèvremoine
  ChIJWWoKPrhqBkgRmom3bEe8QXI: SEVREMOINE, // Saint-Macaire-en-Mauges
  ChIJsWZ6azppBkgR8JENHlI3DQQ: SEVREMOINE, // Le Longeron
  ChIJsYt0u5ptBkgRUJANHlI3DQQ: SEVREMOINE, // Montfaucon-Montigné
  ChIJz8jes6dsBkgRe7BWPOODpTs: SEVREMOINE, // La Renaudière
  ChIJ33X2BnlsBkgR0IwNHlI3DQQ: SEVREMOINE, // Roussay
  ChIJW_wZivZqBkgRwIwNHlI3DQQ: SEVREMOINE, // Saint-André-de-la-Marche
  ChIJUybrTgNzBkgRSSxy6NdPQoo: SEVREMOINE, // Saint-Crespin-sur-Moine
  ChIJlalb7U9tBkgRgIsNHlI3DQQ: SEVREMOINE, // Saint-Germain-sur-Moine
  ChIJqyAbiSANBkgRwIcNHlI3DQQ: SEVREMOINE, // Tillières
  ChIJo1fEJ0BuBkgRMfKfjYIFudo: SEVREMOINE, // Torfou
  'ChIJNa7piZZg70cRY6-tKm3icbs': MORET_LOING_ET_ORVANNE, // Moret-Loing-et-Orvanne
  ChIJ3xRVR39g70cRvccCoxks04k: MORET_LOING_ET_ORVANNE, // Moret-sur-Loing
  'ChIJB1BzhJtg70cRNs0xQSuAy-8': MORET_LOING_ET_ORVANNE, // Ecuelles
  ChIJcT8QLUhg70cRnVL7GNqfUUI: MORET_LOING_ET_ORVANNE, // Episy
  ChIJoW_wEk1n70cRYDhTjuWq_kM: MORET_LOING_ET_ORVANNE, // Montarlot
  'ChIJ-27tdcFf70cRIEiMaMOCCwQ': MORET_LOING_ET_ORVANNE // Veneux-les-Sablons
};
