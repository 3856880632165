import { LocationSearchType } from '@/components/common/locationRefinement/interfaces';
import { LocationState } from '@/components/search/search/interfaces';

export const MARSEILLE_2: LocationState = {
  id: 'FR-13202',
  name: 'Marseille 2ème',
  type: LocationSearchType.SUBMUNICIPALITY,
  coordinates: [],
  geom: [
    [
      43.30161, 5.37489, 43.30141, 5.37502, 43.30129, 5.37482, 43.29966,
      5.37433, 43.29947, 5.3733, 43.29929, 5.37313, 43.29939, 5.37161, 43.29626,
      5.37419, 43.29528, 5.37442, 43.29503, 5.37375, 43.2959, 5.37352, 43.29611,
      5.37268, 43.29561, 5.36759, 43.29466, 5.36298, 43.29459, 5.36292, 43.2947,
      5.36204, 43.29508, 5.36137, 43.29529, 5.36121, 43.29542, 5.36081,
      43.29774, 5.35883, 43.29807, 5.35862, 43.29813, 5.35875, 43.29798,
      5.35897, 43.29801, 5.35922, 43.29757, 5.35962, 43.29884, 5.36244, 43.2992,
      5.36213, 43.29938, 5.36249, 43.30097, 5.36116, 43.30147, 5.36132,
      43.30254, 5.3637, 43.30314, 5.36319, 43.30259, 5.36195, 43.30307, 5.36155,
      43.30432, 5.36432, 43.30451, 5.36417, 43.30484, 5.36427, 43.30516,
      5.36221, 43.30522, 5.36216, 43.30539, 5.36222, 43.30533, 5.36266,
      43.30909, 5.36378, 43.30911, 5.36337, 43.30948, 5.36348, 43.30942,
      5.36382, 43.30936, 5.36385, 43.31207, 5.36465, 43.31213, 5.36426,
      43.31238, 5.36406, 43.31127, 5.36149, 43.31244, 5.36052, 43.31342,
      5.36277, 43.31364, 5.36254, 43.31382, 5.36293, 43.31357, 5.36309,
      43.31417, 5.36445, 43.31435, 5.36437, 43.31446, 5.36463, 43.31523,
      5.36402, 43.31396, 5.36118, 43.31397, 5.36108, 43.31471, 5.36051,
      43.31529, 5.36179, 43.31627, 5.36097, 43.31546, 5.35916, 43.31582,
      5.35885, 43.31577, 5.35873, 43.31585, 5.35861, 43.31598, 5.3585, 43.31605,
      5.35866, 43.31655, 5.35823, 43.3177, 5.36078, 43.31801, 5.36053, 43.31837,
      5.36049, 43.3182, 5.35747, 43.31913, 5.3567, 43.3202, 5.3591, 43.32038,
      5.35896, 43.32057, 5.3594, 43.32154, 5.35865, 43.32195, 5.35959, 43.32191,
      5.3598, 43.32127, 5.36032, 43.32206, 5.36212, 43.32363, 5.36081, 43.32352,
      5.36055, 43.32425, 5.35994, 43.32359, 5.35846, 43.32301, 5.35891,
      43.32256, 5.35862, 43.32221, 5.35887, 43.32179, 5.35793, 43.32206,
      5.35771, 43.32097, 5.35515, 43.32177, 5.35453, 43.32297, 5.35725,
      43.32446, 5.35601, 43.32432, 5.3557, 43.32451, 5.35554, 43.32325, 5.35268,
      43.3236, 5.35239, 43.32351, 5.35219, 43.32379, 5.35197, 43.32388, 5.35216,
      43.32425, 5.35185, 43.32503, 5.35362, 43.32956, 5.34984, 43.32876,
      5.34804, 43.3297, 5.34727, 43.33109, 5.35045, 43.33187, 5.34982, 43.33205,
      5.34605, 43.33308, 5.34519, 43.33326, 5.34522, 43.33293, 5.3524, 43.33303,
      5.35261, 43.33479, 5.35114, 43.33496, 5.34752, 43.33465, 5.34745,
      43.33468, 5.34687, 43.335, 5.34686, 43.33519, 5.34272, 43.33632, 5.34179,
      43.3365, 5.3418, 43.33636, 5.34486, 43.33605, 5.35051, 43.33595, 5.35108,
      43.33655, 5.35217, 43.33646, 5.35334, 43.33593, 5.35424, 43.33537,
      5.35459, 43.33394, 5.35486, 43.33359, 5.35476, 43.33347, 5.35547,
      43.33277, 5.35542, 43.33191, 5.35571, 43.33107, 5.35681, 43.33092,
      5.35733, 43.33048, 5.35802, 43.33046, 5.35909, 43.33015, 5.35899,
      43.32933, 5.35904, 43.32865, 5.35921, 43.32728, 5.36038, 43.32506,
      5.36183, 43.32285, 5.36404, 43.32225, 5.36435, 43.32159, 5.36493,
      43.32099, 5.36483, 43.32025, 5.36526, 43.31928, 5.36617, 43.31651,
      5.36842, 43.31672, 5.36913, 43.31453, 5.37057, 43.31376, 5.368, 43.30233,
      5.37451, 43.30199, 5.3745, 43.30148, 5.374, 43.30147, 5.37441, 43.30161,
      5.37489
    ],
    [
      43.32329, 5.35137, 43.32318, 5.35146, 43.3231, 5.35128, 43.32275, 5.35157,
      43.3224, 5.35078, 43.31544, 5.35662, 43.31575, 5.35734, 43.31501, 5.35796,
      43.31488, 5.35767, 43.31513, 5.35746, 43.31493, 5.35699, 43.31483,
      5.35689, 43.31461, 5.35709, 43.31456, 5.35699, 43.31363, 5.35781,
      43.31321, 5.35803, 43.31324, 5.35813, 43.31308, 5.35827, 43.31304,
      5.35817, 43.3118, 5.35931, 43.31212, 5.36007, 43.31111, 5.36091, 43.31078,
      5.36016, 43.30934, 5.36144, 43.309, 5.36158, 43.30858, 5.36154, 43.30348,
      5.35952, 43.29986, 5.35844, 43.29923, 5.35833, 43.29898, 5.35785, 43.2992,
      5.35767, 43.29706, 5.35292, 43.29716, 5.35283, 43.29929, 5.35758,
      43.29958, 5.35787, 43.30076, 5.35823, 43.30107, 5.35821, 43.30189,
      5.35862, 43.3037, 5.35913, 43.30549, 5.35982, 43.30596, 5.35989, 43.30661,
      5.36028, 43.30847, 5.361, 43.3088, 5.3611, 43.30897, 5.36103, 43.30913,
      5.36081, 43.31011, 5.36006, 43.31486, 5.35602, 43.31534, 5.35572,
      43.32514, 5.34737, 43.3255, 5.34717, 43.32755, 5.34538, 43.32774, 5.34529,
      43.33109, 5.34243, 43.33149, 5.34217, 43.33464, 5.33943, 43.3353, 5.33844,
      43.33546, 5.33796, 43.33633, 5.3362, 43.33652, 5.33599, 43.33769, 5.33353,
      43.3385, 5.33264, 43.33853, 5.33269, 43.34168, 5.32876, 43.34211, 5.32813,
      43.34367, 5.32504, 43.34748, 5.31832, 43.3477, 5.31766, 43.34777, 5.31761,
      43.34785, 5.31771, 43.34755, 5.31844, 43.34388, 5.32501, 43.34399,
      5.32507, 43.34395, 5.32529, 43.34385, 5.32538, 43.3437, 5.32532, 43.34294,
      5.32664, 43.34216, 5.32825, 43.33781, 5.33387, 43.33526, 5.33895,
      43.33537, 5.33934, 43.33567, 5.33979, 43.33561, 5.34014, 43.33461,
      5.34139, 43.33485, 5.34105, 43.33478, 5.34091, 43.33453, 5.34111,
      43.33455, 5.34125, 43.33424, 5.34056, 43.32846, 5.34542, 43.32889,
      5.34639, 43.32828, 5.34693, 43.32788, 5.34604, 43.32298, 5.35017,
      43.32337, 5.35105, 43.32332, 5.35108, 43.32341, 5.35128, 43.32329, 5.35137
    ]
  ]
};

export const CHESNAY_ROCQUENCOURT: LocationState = {
  id: 'FR-78158',
  name: 'Le Chesnay-Rocquencourt',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      48.84308, 2.10301, 48.84237, 2.10514, 48.84189, 2.10526, 48.84163,
      2.10638, 48.8412, 2.1099, 48.84096, 2.10993, 48.84094, 2.11061, 48.84094,
      2.11075, 48.84041, 2.11087, 48.84083, 2.11191, 48.84044, 2.11427,
      48.84036, 2.11402, 48.8398, 2.1167, 48.83873, 2.11963, 48.83683, 2.11887,
      48.83635, 2.1207, 48.83611, 2.12056, 48.83517, 2.12611, 48.83513, 2.12879,
      48.83549, 2.13385, 48.83683, 2.14361, 48.8369, 2.14587, 48.83587, 2.14595,
      48.82849, 2.14848, 48.82816, 2.14699, 48.82735, 2.14543, 48.82698,
      2.14449, 48.82551, 2.14275, 48.82424, 2.14016, 48.82103, 2.13796,
      48.81902, 2.13785, 48.81872, 2.13716, 48.81842, 2.13686, 48.81398,
      2.13607, 48.81504, 2.12945, 48.81529, 2.12948, 48.8155, 2.12929, 48.8158,
      2.12945, 48.81589, 2.12911, 48.8156, 2.12896, 48.8155, 2.12849, 48.81825,
      2.12111, 48.82037, 2.1151, 48.82063, 2.11409, 48.82079, 2.11451, 48.82858,
      2.11261, 48.82687, 2.10729, 48.8258, 2.10545, 48.82448, 2.10455, 48.82339,
      2.10246, 48.82543, 2.09575, 48.82345, 2.09245, 48.82636, 2.08946,
      48.82653, 2.08918, 48.83551, 2.09638, 48.83565, 2.09645, 48.83624,
      2.09485, 48.8364, 2.0951, 48.83668, 2.09528, 48.83676, 2.09559, 48.8375,
      2.09584, 48.83782, 2.09607, 48.83861, 2.09684, 48.83862, 2.09841,
      48.83833, 2.10135, 48.84177, 2.10267, 48.84188, 2.10261, 48.84308, 2.10301
    ]
  ]
};

export const ANNECY: LocationState = {
  id: 'FR-74010',
  name: 'Annecy',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      45.89116, 6.08094, 45.89124, 6.08093, 45.89137, 6.0806, 45.89174, 6.08066,
      45.89223, 6.08052, 45.89263, 6.08059, 45.89279, 6.08049, 45.89289,
      6.08067, 45.89337, 6.08047, 45.89348, 6.08066, 45.89367, 6.08055,
      45.89371, 6.08099, 45.89389, 6.08139, 45.89386, 6.08172, 45.89398,
      6.08174, 45.89412, 6.082, 45.89435, 6.08206, 45.89456, 6.08279, 45.89484,
      6.08298, 45.8949, 6.08326, 45.89504, 6.08323, 45.89522, 6.08344, 45.89531,
      6.08331, 45.89544, 6.08353, 45.89548, 6.08336, 45.89567, 6.0832, 45.89579,
      6.08346, 45.89591, 6.08347, 45.89596, 6.08323, 45.89634, 6.08282, 45.8968,
      6.08301, 45.89677, 6.08325, 45.89685, 6.08372, 45.89721, 6.08362,
      45.89758, 6.08395, 45.89786, 6.08366, 45.89822, 6.08371, 45.89847,
      6.08387, 45.89851, 6.08436, 45.8987, 6.08435, 45.89894, 6.0845, 45.89926,
      6.08445, 45.89938, 6.08426, 45.89956, 6.08453, 45.90004, 6.08415, 45.9001,
      6.08434, 45.90105, 6.08445, 45.90143, 6.08413, 45.9018, 6.08315, 45.90239,
      6.08473, 45.90289, 6.08676, 45.90315, 6.08715, 45.90371, 6.08762,
      45.90424, 6.08773, 45.90496, 6.0877, 45.90535, 6.08749, 45.90605, 6.08688,
      45.90677, 6.0867, 45.90688, 6.08633, 45.90638, 6.08473, 45.90664, 6.08432,
      45.90682, 6.0842, 45.90805, 6.0852, 45.90809, 6.08609, 45.90819, 6.08658,
      45.90838, 6.08608, 45.90849, 6.08603, 45.90868, 6.08674, 45.90903,
      6.08684, 45.90935, 6.08713, 45.90966, 6.08654, 45.91027, 6.08657,
      45.91083, 6.08597, 45.91158, 6.0858, 45.91207, 6.08609, 45.91236, 6.08609,
      45.91244, 6.08561, 45.91236, 6.08539, 45.91246, 6.0851, 45.91242, 6.08493,
      45.9125, 6.08485, 45.91292, 6.08546, 45.91345, 6.08542, 45.9139, 6.0856,
      45.91416, 6.08495, 45.91433, 6.08416, 45.91478, 6.08438, 45.91496,
      6.08458, 45.915, 6.0848, 45.91515, 6.08501, 45.91543, 6.08457, 45.91544,
      6.08439, 45.91557, 6.08417, 45.91549, 6.08347, 45.9157, 6.08316, 45.91595,
      6.08314, 45.91605, 6.08372, 45.91611, 6.08375, 45.91623, 6.08354,
      45.91655, 6.08429, 45.91662, 6.08483, 45.91683, 6.08491, 45.91707,
      6.08518, 45.9176, 6.08517, 45.91772, 6.08439, 45.9187, 6.08433, 45.91878,
      6.08388, 45.91886, 6.08387, 45.91907, 6.08397, 45.91912, 6.0845, 45.91926,
      6.08474, 45.91923, 6.0849, 45.92006, 6.08468, 45.92022, 6.08513, 45.91999,
      6.08519, 45.92024, 6.08587, 45.92, 6.08594, 45.92016, 6.08634, 45.92028,
      6.08628, 45.92048, 6.0865, 45.92057, 6.08638, 45.92053, 6.08612, 45.92084,
      6.08584, 45.92112, 6.08618, 45.92126, 6.08619, 45.92138, 6.08646,
      45.92155, 6.08651, 45.9215, 6.08677, 45.92179, 6.08708, 45.92186, 6.08749,
      45.92179, 6.08767, 45.92192, 6.08799, 45.9219, 6.08821, 45.92229, 6.08802,
      45.92249, 6.08778, 45.92293, 6.08791, 45.92413, 6.08895, 45.92434,
      6.08968, 45.92445, 6.08986, 45.92498, 6.09011, 45.92526, 6.08978,
      45.92566, 6.08903, 45.92595, 6.08944, 45.92624, 6.08946, 45.9264, 6.08986,
      45.92678, 6.08974, 45.92702, 6.09091, 45.92865, 6.09305, 45.92855,
      6.09319, 45.92919, 6.09402, 45.92905, 6.09427, 45.92998, 6.09565, 45.9301,
      6.09582, 45.9308, 6.09567, 45.92803, 6.10044, 45.92789, 6.10038, 45.92713,
      6.10186, 45.92745, 6.10224, 45.92641, 6.10402, 45.92649, 6.10412,
      45.92471, 6.10753, 45.92435, 6.10776, 45.92145, 6.11404, 45.92223,
      6.11488, 45.9233, 6.11529, 45.92435, 6.11604, 45.9248, 6.11658, 45.92499,
      6.11697, 45.92595, 6.11834, 45.92655, 6.11875, 45.92789, 6.11932,
      45.93005, 6.12088, 45.93131, 6.12128, 45.93208, 6.12164, 45.93261,
      6.12214, 45.93318, 6.12314, 45.9341, 6.12261, 45.9348, 6.12307, 45.93478,
      6.12298, 45.93508, 6.12353, 45.93515, 6.12384, 45.93818, 6.12075,
      45.93825, 6.12079, 45.9386, 6.12053, 45.93948, 6.11969, 45.93971, 6.1198,
      45.94052, 6.11651, 45.94232, 6.11315, 45.94229, 6.11295, 45.94256,
      6.11242, 45.9428, 6.11247, 45.94303, 6.11221, 45.94306, 6.11206, 45.94292,
      6.11143, 45.94301, 6.11124, 45.94322, 6.11115, 45.94363, 6.1117, 45.94423,
      6.11182, 45.9444, 6.11158, 45.94455, 6.11155, 45.94556, 6.11226, 45.94587,
      6.1122, 45.94602, 6.11203, 45.94619, 6.11148, 45.9463, 6.11141, 45.94647,
      6.11147, 45.94697, 6.11043, 45.94657, 6.10975, 45.94683, 6.10868,
      45.94845, 6.10699, 45.94884, 6.10689, 45.94941, 6.10643, 45.94944,
      6.10629, 45.94934, 6.10603, 45.94938, 6.10582, 45.95011, 6.10457, 45.9512,
      6.104, 45.95147, 6.10377, 45.95211, 6.10365, 45.95209, 6.10264, 45.95233,
      6.10147, 45.95254, 6.101, 45.95369, 6.09983, 45.95587, 6.09998, 45.9588,
      6.09697, 45.95928, 6.09635, 45.95946, 6.09309, 45.95937, 6.09106, 45.9592,
      6.08931, 45.95965, 6.08888, 45.95987, 6.08852, 45.95999, 6.08809,
      45.96007, 6.08718, 45.96034, 6.08675, 45.96111, 6.07956, 45.9611, 6.07888,
      45.96173, 6.07863, 45.96304, 6.07783, 45.96402, 6.07809, 45.96478,
      6.07874, 45.96487, 6.07769, 45.96445, 6.07486, 45.96479, 6.07497, 45.9649,
      6.07515, 45.96493, 6.07547, 45.96552, 6.07523, 45.96618, 6.0752, 45.96647,
      6.07548, 45.96677, 6.07558, 45.96732, 6.07625, 45.96772, 6.07633,
      45.96784, 6.07657, 45.9683, 6.07668, 45.9686, 6.07717, 45.9688, 6.07697,
      45.96935, 6.07731, 45.96966, 6.07738, 45.96999, 6.07779, 45.97011,
      6.07859, 45.9705, 6.07913, 45.97082, 6.08, 45.97225, 6.08054, 45.97274,
      6.08065, 45.97329, 6.08043, 45.97349, 6.08057, 45.97362, 6.08094,
      45.97368, 6.08148, 45.97393, 6.08206, 45.97515, 6.08377, 45.97544,
      6.08503, 45.97613, 6.08626, 45.97654, 6.08783, 45.97671, 6.08817,
      45.97477, 6.09188, 45.97408, 6.09238, 45.97356, 6.09337, 45.97305,
      6.09399, 45.97304, 6.09423, 45.97339, 6.0951, 45.97339, 6.09569, 45.97351,
      6.09617, 45.97351, 6.09667, 45.97341, 6.09725, 45.97355, 6.09775,
      45.97363, 6.09958, 45.97345, 6.10014, 45.97317, 6.10049, 45.97308,
      6.10097, 45.97271, 6.1018, 45.9723, 6.10229, 45.97209, 6.10283, 45.97095,
      6.10403, 45.97073, 6.10403, 45.97021, 6.10377, 45.96981, 6.10389,
      45.96925, 6.10358, 45.96859, 6.10346, 45.96704, 6.10423, 45.96669,
      6.10452, 45.96677, 6.10463, 45.9662, 6.10487, 45.9658, 6.10534, 45.96554,
      6.10551, 45.96523, 6.10549, 45.9651, 6.10534, 45.96458, 6.10543, 45.96497,
      6.10719, 45.96485, 6.1077, 45.96423, 6.10821, 45.96395, 6.10894, 45.96375,
      6.10993, 45.9641, 6.11049, 45.96443, 6.11082, 45.96444, 6.11126, 45.96434,
      6.11204, 45.96436, 6.11317, 45.96372, 6.11529, 45.9632, 6.11664, 45.96124,
      6.12074, 45.95993, 6.12373, 45.96013, 6.12376, 45.96032, 6.12475, 45.9606,
      6.1251, 45.96076, 6.12543, 45.96104, 6.12563, 45.96104, 6.12579, 45.96132,
      6.12621, 45.96111, 6.12661, 45.96096, 6.12657, 45.96087, 6.12667,
      45.96083, 6.12733, 45.9607, 6.12733, 45.96061, 6.12768, 45.96036, 6.1279,
      45.96029, 6.12817, 45.96005, 6.12828, 45.9599, 6.12884, 45.95963, 6.12888,
      45.95869, 6.12961, 45.95852, 6.12979, 45.95823, 6.13038, 45.95828,
      6.13056, 45.95813, 6.13081, 45.9581, 6.13143, 45.95786, 6.13215, 45.95801,
      6.13247, 45.95795, 6.13268, 45.95801, 6.13298, 45.95795, 6.13356,
      45.95758, 6.13435, 45.95773, 6.13474, 45.95768, 6.13515, 45.95756,
      6.13527, 45.95759, 6.13546, 45.95751, 6.13582, 45.95761, 6.13634,
      45.95744, 6.13718, 45.95732, 6.1374, 45.95731, 6.1376, 45.95716, 6.13777,
      45.95717, 6.13796, 45.95707, 6.13818, 45.9572, 6.13895, 45.95708, 6.1396,
      45.95565, 6.13825, 45.95355, 6.13831, 45.95325, 6.13824, 45.95312,
      6.13797, 45.95296, 6.13798, 45.95267, 6.13773, 45.95259, 6.13714,
      45.95247, 6.13705, 45.95222, 6.13648, 45.95232, 6.13639, 45.9521, 6.13609,
      45.95212, 6.13589, 45.95201, 6.13566, 45.95189, 6.13561, 45.95177,
      6.13533, 45.95163, 6.13532, 45.95161, 6.13509, 45.95144, 6.13481,
      45.95087, 6.13407, 45.95083, 6.13315, 45.95063, 6.13275, 45.95046,
      6.13279, 45.95027, 6.13249, 45.9501, 6.13251, 45.94973, 6.13232, 45.94948,
      6.1324, 45.94943, 6.13231, 45.94922, 6.13244, 45.94892, 6.13219, 45.94861,
      6.13234, 45.94821, 6.1317, 45.94787, 6.1316, 45.94764, 6.13125, 45.94754,
      6.13122, 45.94749, 6.13113, 45.94753, 6.13094, 45.94738, 6.13075,
      45.94697, 6.13056, 45.94697, 6.13042, 45.94683, 6.13045, 45.94678,
      6.13031, 45.94658, 6.13035, 45.94644, 6.13024, 45.94626, 6.12968,
      45.94618, 6.12965, 45.94617, 6.12945, 45.94596, 6.1295, 45.94566, 6.12924,
      45.94544, 6.12923, 45.94535, 6.12909, 45.94498, 6.12909, 45.94404,
      6.12838, 45.94256, 6.12804, 45.94106, 6.12949, 45.94058, 6.12826,
      45.94018, 6.1287, 45.93981, 6.12961, 45.93907, 6.13081, 45.93832, 6.13133,
      45.93774, 6.13046, 45.937, 6.13078, 45.93655, 6.13118, 45.93643, 6.13088,
      45.93566, 6.13307, 45.9355, 6.13325, 45.93478, 6.13346, 45.9356, 6.13524,
      45.93574, 6.1358, 45.93577, 6.13642, 45.9357, 6.13719, 45.93536, 6.13902,
      45.93507, 6.14148, 45.9346, 6.14275, 45.93465, 6.14332, 45.93514, 6.14393,
      45.93714, 6.14569, 45.93737, 6.14806, 45.93718, 6.14903, 45.93719,
      6.14949, 45.93741, 6.14966, 45.93953, 6.1503, 45.94004, 6.15059, 45.94022,
      6.15082, 45.9404, 6.15127, 45.94034, 6.15162, 45.9391, 6.15484, 45.93908,
      6.15571, 45.93886, 6.15591, 45.93884, 6.1561, 45.93902, 6.15703, 45.93987,
      6.15925, 45.94064, 6.1622, 45.9412, 6.16339, 45.94124, 6.16372, 45.94112,
      6.16404, 45.94091, 6.16427, 45.94057, 6.16435, 45.93906, 6.16437,
      45.93817, 6.16471, 45.93791, 6.16491, 45.93763, 6.16537, 45.93737,
      6.16639, 45.93739, 6.16703, 45.93813, 6.17058, 45.93821, 6.17224,
      45.93812, 6.17257, 45.9378, 6.17288, 45.93693, 6.17275, 45.93669, 6.17293,
      45.93642, 6.17351, 45.93637, 6.17463, 45.93623, 6.17537, 45.93603, 6.1757,
      45.93571, 6.17592, 45.93539, 6.17586, 45.9343, 6.17534, 45.93226, 6.17624,
      45.93155, 6.17634, 45.93029, 6.17625, 45.92969, 6.17682, 45.92829,
      6.17968, 45.92813, 6.17979, 45.92644, 6.17997, 45.92569, 6.18038,
      45.92479, 6.18196, 45.92448, 6.18289, 45.92497, 6.18587, 45.92489,
      6.18781, 45.92466, 6.1888, 45.92438, 6.18933, 45.92382, 6.18994, 45.92149,
      6.19155, 45.92103, 6.192, 45.92072, 6.19254, 45.92057, 6.19332, 45.92041,
      6.19399, 45.92051, 6.19509, 45.92041, 6.1955, 45.91902, 6.19708, 45.91862,
      6.19726, 45.91864, 6.19807, 45.91891, 6.19879, 45.9187, 6.19928, 45.91796,
      6.19961, 45.91534, 6.19954, 45.91177, 6.20003, 45.91118, 6.19986,
      45.91035, 6.19944, 45.90942, 6.19959, 45.90869, 6.19994, 45.90839,
      6.20029, 45.90839, 6.20077, 45.90881, 6.20182, 45.90859, 6.2025, 45.90812,
      6.20316, 45.90719, 6.2042, 45.90684, 6.20439, 45.90662, 6.20369, 45.90568,
      6.20195, 45.90563, 6.20156, 45.90527, 6.2014, 45.90507, 6.20102, 45.90466,
      6.20082, 45.90405, 6.19994, 45.90399, 6.19944, 45.90315, 6.19895,
      45.90292, 6.19858, 45.90271, 6.19859, 45.9021, 6.19777, 45.90152, 6.19738,
      45.90125, 6.19732, 45.903, 6.19272, 45.90535, 6.18588, 45.90631, 6.1835,
      45.90664, 6.18285, 45.90578, 6.17924, 45.90529, 6.17801, 45.90481,
      6.17597, 45.90109, 6.16852, 45.89997, 6.16144, 45.89719, 6.14891,
      45.88278, 6.15593, 45.88331, 6.14233, 45.88354, 6.14062, 45.88207,
      6.14055, 45.88208, 6.13895, 45.88158, 6.13882, 45.88114, 6.13848,
      45.88049, 6.13759, 45.87998, 6.13768, 45.87969, 6.13632, 45.87507,
      6.12657, 45.87435, 6.1265, 45.87321, 6.12479, 45.87231, 6.12472, 45.87146,
      6.12407, 45.87077, 6.12392, 45.87043, 6.12325, 45.87025, 6.12315,
      45.86994, 6.12321, 45.86926, 6.12308, 45.86875, 6.12311, 45.86805,
      6.12338, 45.86699, 6.12413, 45.86594, 6.12417, 45.86556, 6.12367,
      45.86339, 6.12266, 45.86323, 6.12269, 45.86303, 6.12293, 45.86286,
      6.12294, 45.86182, 6.12251, 45.86081, 6.1227, 45.85967, 6.12275, 45.85936,
      6.12258, 45.85895, 6.12226, 45.8587, 6.12192, 45.85822, 6.12157, 45.85789,
      6.12095, 45.85768, 6.11963, 45.85751, 6.11932, 45.85753, 6.11886,
      45.85736, 6.11803, 45.85742, 6.11747, 45.85732, 6.11724, 45.857, 6.11706,
      45.85615, 6.11702, 45.85613, 6.11647, 45.85552, 6.116, 45.85546, 6.11576,
      45.85551, 6.11538, 45.85538, 6.11528, 45.85502, 6.11512, 45.85465,
      6.11525, 45.85397, 6.11531, 45.85356, 6.11524, 45.85249, 6.11451,
      45.85211, 6.11392, 45.85159, 6.11283, 45.85077, 6.11217, 45.85055,
      6.11186, 45.85041, 6.11124, 45.85043, 6.11048, 45.8502, 6.10934, 45.84995,
      6.10871, 45.84979, 6.10794, 45.84966, 6.10772, 45.84959, 6.10715,
      45.84943, 6.1071, 45.84946, 6.10692, 45.84875, 6.1071, 45.84845, 6.10743,
      45.84823, 6.1075, 45.84739, 6.10682, 45.84678, 6.1067, 45.84631, 6.10688,
      45.84581, 6.10736, 45.8456, 6.10743, 45.84475, 6.10743, 45.84431, 6.10767,
      45.84381, 6.10766, 45.843, 6.10726, 45.84237, 6.10669, 45.8415, 6.10615,
      45.84125, 6.10536, 45.8408, 6.10479, 45.83995, 6.10473, 45.83932, 6.10409,
      45.83912, 6.10403, 45.83878, 6.10399, 45.8383, 6.10433, 45.838, 6.10433,
      45.83765, 6.10349, 45.83765, 6.10323, 45.83778, 6.10289, 45.83771,
      6.10267, 45.84215, 6.09889, 45.84287, 6.09745, 45.8437, 6.0949, 45.84376,
      6.09509, 45.84438, 6.0949, 45.8448, 6.09549, 45.84508, 6.09566, 45.84564,
      6.09572, 45.84613, 6.09598, 45.84684, 6.09606, 45.84778, 6.09647,
      45.84885, 6.09403, 45.84885, 6.09389, 45.84905, 6.09404, 45.84939, 6.0939,
      45.84979, 6.0935, 45.85014, 6.09357, 45.85054, 6.09087, 45.85094, 6.08946,
      45.85112, 6.08948, 45.85115, 6.08916, 45.85138, 6.08931, 45.85273,
      6.08929, 45.85329, 6.08941, 45.85388, 6.08867, 45.85464, 6.08806,
      45.85549, 6.08507, 45.85516, 6.0838, 45.85467, 6.08244, 45.85397, 6.08132,
      45.85359, 6.08005, 45.85335, 6.07955, 45.85287, 6.07897, 45.85262, 6.0782,
      45.85231, 6.07764, 45.8522, 6.07693, 45.85117, 6.07485, 45.85024, 6.07398,
      45.84991, 6.07354, 45.84987, 6.07359, 45.84966, 6.07344, 45.84871,
      6.07236, 45.84855, 6.07279, 45.84814, 6.07325, 45.84747, 6.0733, 45.84665,
      6.07282, 45.84519, 6.07135, 45.84458, 6.07232, 45.8442, 6.07188, 45.84373,
      6.07154, 45.84254, 6.07105, 45.84165, 6.07084, 45.84107, 6.07173,
      45.84034, 6.07152, 45.83988, 6.07168, 45.83965, 6.07194, 45.83956,
      6.07231, 45.83939, 6.07253, 45.83892, 6.07354, 45.8382, 6.0727, 45.83777,
      6.07256, 45.83742, 6.07225, 45.83734, 6.07258, 45.83683, 6.0724, 45.83684,
      6.07261, 45.83674, 6.07276, 45.83604, 6.07331, 45.83539, 6.0723, 45.83536,
      6.07301, 45.83432, 6.07169, 45.83396, 6.07238, 45.83334, 6.07161,
      45.83301, 6.07197, 45.83212, 6.07236, 45.83117, 6.07207, 45.83045,
      6.07266, 45.8304, 6.07254, 45.8303, 6.07253, 45.83008, 6.07153, 45.82985,
      6.07127, 45.82971, 6.07064, 45.82976, 6.07037, 45.82966, 6.07002,
      45.82969, 6.06914, 45.82953, 6.06865, 45.82959, 6.06848, 45.82975,
      6.06838, 45.82967, 6.06798, 45.8296, 6.06781, 45.82949, 6.06777, 45.82945,
      6.06752, 45.82926, 6.06719, 45.82922, 6.06685, 45.82903, 6.06663,
      45.82895, 6.06631, 45.82877, 6.06613, 45.82865, 6.06582, 45.82848,
      6.06575, 45.8284, 6.06549, 45.82821, 6.06534, 45.82815, 6.06505, 45.82819,
      6.06485, 45.82805, 6.06477, 45.82799, 6.06463, 45.82801, 6.06446,
      45.82813, 6.06431, 45.82816, 6.06383, 45.82846, 6.06361, 45.82864,
      6.06306, 45.82862, 6.06238, 45.82853, 6.06194, 45.82859, 6.0614, 45.82871,
      6.06113, 45.82866, 6.06056, 45.82897, 6.06045, 45.82901, 6.06004,
      45.82923, 6.05987, 45.82931, 6.05937, 45.82952, 6.05912, 45.8295, 6.05886,
      45.82986, 6.05826, 45.82977, 6.05775, 45.82998, 6.05748, 45.8302, 6.05663,
      45.83036, 6.05647, 45.83035, 6.05614, 45.83047, 6.05609, 45.83068,
      6.05572, 45.83058, 6.05482, 45.83092, 6.05448, 45.83096, 6.05394,
      45.83089, 6.0536, 45.83098, 6.05315, 45.83064, 6.05265, 45.83103, 6.05169,
      45.83098, 6.05133, 45.83085, 6.05125, 45.83073, 6.05094, 45.83076,
      6.05045, 45.83089, 6.05024, 45.8315, 6.04982, 45.83145, 6.04967, 45.83166,
      6.04938, 45.83168, 6.049, 45.83145, 6.04851, 45.83165, 6.04841, 45.83188,
      6.04888, 45.83208, 6.04898, 45.83227, 6.04944, 45.83211, 6.05024,
      45.83236, 6.05094, 45.8326, 6.05115, 45.83276, 6.05163, 45.83314, 6.05215,
      45.83348, 6.05236, 45.83342, 6.05259, 45.8333, 6.05265, 45.83329, 6.05281,
      45.83339, 6.05298, 45.83381, 6.05329, 45.83382, 6.0541, 45.83416, 6.05417,
      45.83481, 6.05471, 45.835, 6.05472, 45.83529, 6.05493, 45.83582, 6.05567,
      45.83578, 6.05608, 45.83596, 6.05649, 45.83633, 6.05665, 45.83648,
      6.05728, 45.83675, 6.05721, 45.83705, 6.05741, 45.83727, 6.05724,
      45.83748, 6.05759, 45.83771, 6.05758, 45.83777, 6.05784, 45.83804,
      6.05804, 45.83841, 6.0579, 45.83883, 6.05806, 45.83923, 6.05775, 45.8395,
      6.05778, 45.83981, 6.0573, 45.83956, 6.05693, 45.83952, 6.05668, 45.83956,
      6.05649, 45.83979, 6.05632, 45.84016, 6.05631, 45.84117, 6.05545,
      45.84116, 6.05516, 45.84147, 6.05468, 45.84169, 6.05448, 45.84183,
      6.05407, 45.84202, 6.05403, 45.84232, 6.05356, 45.84243, 6.05363,
      45.84251, 6.05352, 45.84267, 6.0531, 45.84267, 6.05278, 45.84295, 6.05279,
      45.84336, 6.05231, 45.84347, 6.05237, 45.84361, 6.05204, 45.84381,
      6.05197, 45.8439, 6.05182, 45.84428, 6.05186, 45.84502, 6.05245, 45.84573,
      6.05184, 45.84594, 6.05183, 45.84664, 6.05233, 45.84709, 6.05296,
      45.84748, 6.05289, 45.84866, 6.05343, 45.84871, 6.05354, 45.84877,
      6.05376, 45.84784, 6.055, 45.84823, 6.0553, 45.84787, 6.05635, 45.84886,
      6.05873, 45.84914, 6.05862, 45.84928, 6.05826, 45.84948, 6.05803,
      45.85004, 6.05774, 45.85102, 6.05999, 45.85156, 6.06076, 45.85184,
      6.06148, 45.85253, 6.06281, 45.85302, 6.06335, 45.85334, 6.06344,
      45.85425, 6.06401, 45.8546, 6.06403, 45.85505, 6.06386, 45.85571, 6.06342,
      45.85639, 6.06364, 45.8567, 6.06384, 45.85765, 6.06385, 45.85779, 6.06367,
      45.85796, 6.0632, 45.85808, 6.06233, 45.85817, 6.06208, 45.85815, 6.06148,
      45.85837, 6.06155, 45.85874, 6.06076, 45.85922, 6.06007, 45.86021,
      6.06127, 45.86096, 6.0618, 45.86234, 6.0619, 45.86229, 6.0626, 45.86318,
      6.06203, 45.86314, 6.06193, 45.86365, 6.06159, 45.86402, 6.06179,
      45.86415, 6.062, 45.86415, 6.06172, 45.86516, 6.06119, 45.86508, 6.06096,
      45.86605, 6.06051, 45.86607, 6.06066, 45.86616, 6.06064, 45.86618,
      6.06081, 45.86689, 6.06068, 45.86723, 6.05988, 45.86727, 6.05928,
      45.86869, 6.05964, 45.86896, 6.05998, 45.86859, 6.06051, 45.86865,
      6.06076, 45.86854, 6.06227, 45.86861, 6.0627, 45.86868, 6.06288, 45.8688,
      6.06291, 45.86883, 6.06313, 45.86913, 6.06345, 45.86932, 6.0639, 45.86969,
      6.06393, 45.87015, 6.06431, 45.87018, 6.06477, 45.87048, 6.06579,
      45.87055, 6.06716, 45.87069, 6.06765, 45.87075, 6.06816, 45.87069,
      6.06858, 45.87078, 6.06877, 45.87101, 6.06878, 45.87097, 6.06913,
      45.87107, 6.06934, 45.87105, 6.06976, 45.87091, 6.06996, 45.8709, 6.07039,
      45.87081, 6.07075, 45.87157, 6.07086, 45.87158, 6.07113, 45.87213,
      6.07072, 45.87307, 6.0695, 45.87328, 6.069, 45.87338, 6.06814, 45.87466,
      6.06878, 45.87496, 6.0684, 45.87543, 6.06802, 45.87592, 6.06861, 45.87705,
      6.06874, 45.8774, 6.06888, 45.87755, 6.0685, 45.87878, 6.0672, 45.87907,
      6.06668, 45.87942, 6.06627, 45.87964, 6.06633, 45.87975, 6.06585,
      45.88063, 6.06591, 45.88105, 6.06536, 45.88114, 6.06483, 45.88165, 6.0649,
      45.88269, 6.06428, 45.88463, 6.06661, 45.88488, 6.06631, 45.88491,
      6.06665, 45.88587, 6.06642, 45.8858, 6.06693, 45.88581, 6.06899, 45.8861,
      6.06899, 45.88619, 6.07012, 45.8866, 6.07162, 45.88697, 6.07247, 45.88753,
      6.07292, 45.88761, 6.07311, 45.88737, 6.07354, 45.88786, 6.07408,
      45.88878, 6.07482, 45.88953, 6.07601, 45.8896, 6.07628, 45.88982, 6.07639,
      45.89005, 6.07669, 45.88989, 6.07762, 45.88976, 6.07784, 45.88979,
      6.07816, 45.89012, 6.07843, 45.89042, 6.07918, 45.89057, 6.07927,
      45.89061, 6.07965, 45.89078, 6.07989, 45.89077, 6.08015, 45.89089,
      6.08026, 45.8909, 6.08056, 45.89116, 6.08094
    ]
  ]
};

export const PALADRU_VILLAGES: LocationState = {
  id: 'FR-38292',
  name: 'Villages du Lac de Paladru',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      45.5039, 5.56803, 45.50411, 5.56911, 45.50411, 5.56945, 45.50391, 5.57052,
      45.50365, 5.57092, 45.50421, 5.57148, 45.50437, 5.57188, 45.50464,
      5.57231, 45.50491, 5.57307, 45.50566, 5.57456, 45.50573, 5.57522,
      45.50558, 5.57618, 45.50593, 5.57684, 45.50544, 5.57754, 45.50505,
      5.57859, 45.5048, 5.5789, 45.50344, 5.57973, 45.50257, 5.5804, 45.50328,
      5.58211, 45.50287, 5.58536, 45.50277, 5.58567, 45.50137, 5.58549,
      45.50097, 5.58573, 45.50043, 5.58537, 45.49986, 5.58482, 45.49902,
      5.58472, 45.49835, 5.58486, 45.49746, 5.58481, 45.49715, 5.58507, 45.4966,
      5.58524, 45.49636, 5.58559, 45.49413, 5.58574, 45.4924, 5.58634, 45.49123,
      5.58467, 45.49043, 5.58416, 45.49034, 5.58387, 45.48881, 5.58128,
      45.48822, 5.5797, 45.48739, 5.57796, 45.48613, 5.57705, 45.48347, 5.57423,
      45.48283, 5.57179, 45.48212, 5.57068, 45.482, 5.57037, 45.48196, 5.57005,
      45.48225, 5.56926, 45.48214, 5.56873, 45.48126, 5.56715, 45.48094, 5.567,
      45.48036, 5.56741, 45.47915, 5.56745, 45.47877, 5.56738, 45.47799,
      5.56697, 45.47762, 5.56709, 45.47753, 5.56703, 45.47739, 5.56659,
      45.47737, 5.5656, 45.47721, 5.56492, 45.47738, 5.56402, 45.47724, 5.5626,
      45.47727, 5.56185, 45.47717, 5.56055, 45.47687, 5.55996, 45.47621,
      5.55922, 45.4752, 5.55845, 45.47477, 5.55785, 45.47426, 5.55618, 45.47393,
      5.55581, 45.47354, 5.55514, 45.47333, 5.55438, 45.47131, 5.55285,
      45.46435, 5.54815, 45.45632, 5.54238, 45.4559, 5.54041, 45.4546, 5.53292,
      45.44618, 5.52392, 45.44504, 5.523, 45.44753, 5.51808, 45.44775, 5.5172,
      45.44784, 5.51599, 45.44815, 5.51475, 45.44774, 5.51298, 45.44732,
      5.51174, 45.44729, 5.51054, 45.44712, 5.5103, 45.44712, 5.50997, 45.44674,
      5.50922, 45.44634, 5.50887, 45.44476, 5.50686, 45.44273, 5.50366,
      45.44396, 5.50337, 45.44513, 5.50333, 45.44476, 5.50233, 45.44357, 5.5009,
      45.4433, 5.50025, 45.44318, 5.49946, 45.44327, 5.49801, 45.44299, 5.49706,
      45.44306, 5.49557, 45.4429, 5.49415, 45.44326, 5.49288, 45.44369, 5.49234,
      45.44387, 5.49194, 45.44389, 5.49108, 45.44403, 5.49033, 45.444, 5.48856,
      45.44407, 5.48775, 45.44382, 5.48488, 45.44388, 5.4828, 45.44406, 5.48234,
      45.44428, 5.48213, 45.44517, 5.48156, 45.44566, 5.48157, 45.446, 5.48192,
      45.44618, 5.48198, 45.44693, 5.48191, 45.44748, 5.48167, 45.44808,
      5.48222, 45.4494, 5.48275, 45.45029, 5.48274, 45.45091, 5.48241, 45.4512,
      5.48237, 45.45262, 5.4826, 45.45328, 5.48259, 45.45364, 5.48244, 45.45427,
      5.48259, 45.45514, 5.48353, 45.45576, 5.48389, 45.45667, 5.48528,
      45.45719, 5.48583, 45.45779, 5.48701, 45.45773, 5.48752, 45.45893,
      5.48747, 45.45912, 5.48761, 45.45959, 5.48828, 45.45996, 5.48848, 45.4611,
      5.48784, 45.46222, 5.48672, 45.4623, 5.4872, 45.46231, 5.48792, 45.46315,
      5.49007, 45.4631, 5.4904, 45.46213, 5.49255, 45.46184, 5.49354, 45.46176,
      5.49436, 45.46205, 5.49508, 45.46236, 5.49483, 45.46304, 5.49386,
      45.46348, 5.4937, 45.464, 5.49381, 45.46432, 5.49368, 45.46586, 5.49464,
      45.46642, 5.49449, 45.46682, 5.49506, 45.46708, 5.49526, 45.46811,
      5.49552, 45.46891, 5.49515, 45.46959, 5.49527, 45.4709, 5.49508, 45.47161,
      5.49458, 45.4725, 5.49425, 45.47323, 5.49359, 45.47449, 5.49352, 45.47503,
      5.49329, 45.47521, 5.49446, 45.47559, 5.49542, 45.47599, 5.49752,
      45.47608, 5.49814, 45.47609, 5.49961, 45.47616, 5.49996, 45.47661,
      5.50085, 45.47684, 5.50208, 45.47738, 5.50253, 45.47782, 5.50356,
      45.47755, 5.50471, 45.47768, 5.50485, 45.478, 5.50448, 45.47841, 5.50438,
      45.47902, 5.50351, 45.48025, 5.50314, 45.48089, 5.50222, 45.48156,
      5.50205, 45.48125, 5.50517, 45.48128, 5.50617, 45.48119, 5.50684,
      45.48033, 5.50653, 45.48015, 5.50838, 45.47986, 5.50907, 45.4792, 5.51005,
      45.47924, 5.51018, 45.48007, 5.51051, 45.47957, 5.51248, 45.47937,
      5.51362, 45.47961, 5.51434, 45.48001, 5.5148, 45.47974, 5.51509, 45.47899,
      5.51711, 45.47716, 5.51574, 45.47551, 5.51425, 45.47509, 5.51412,
      45.47438, 5.51412, 45.47379, 5.51492, 45.47329, 5.51515, 45.47213,
      5.51803, 45.47152, 5.51834, 45.47276, 5.52075, 45.47286, 5.52117,
      45.47428, 5.52266, 45.47492, 5.52372, 45.47547, 5.52431, 45.47643,
      5.52567, 45.47698, 5.52628, 45.47758, 5.52656, 45.47788, 5.52752,
      45.47796, 5.52801, 45.47813, 5.52838, 45.48001, 5.53051, 45.48105,
      5.53198, 45.48271, 5.5338, 45.48354, 5.53456, 45.48667, 5.53685, 45.48691,
      5.53776, 45.48777, 5.53922, 45.49198, 5.54477, 45.49256, 5.54718,
      45.49399, 5.55168, 45.49442, 5.55241, 45.50077, 5.55587, 45.50074,
      5.55969, 45.50275, 5.5648, 45.5039, 5.56803
    ]
  ]
};

export const EVRY_COURCOURONNES: LocationState = {
  id: 'FR-91228',
  name: 'Évry-Courcouronnes',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      48.61113, 2.43685, 48.61175, 2.43188, 48.612, 2.43171, 48.61278, 2.42871,
      48.61316, 2.42767, 48.61357, 2.42411, 48.61355, 2.42209, 48.61382,
      2.41591, 48.61363, 2.4145, 48.61347, 2.41178, 48.61347, 2.40821, 48.61341,
      2.40833, 48.61343, 2.40767, 48.61328, 2.40764, 48.61197, 2.40924,
      48.60885, 2.40806, 48.60917, 2.40583, 48.60978, 2.4001, 48.61447, 2.40181,
      48.61528, 2.39769, 48.61583, 2.39706, 48.61782, 2.39558, 48.61796,
      2.39376, 48.61836, 2.39369, 48.61848, 2.39249, 48.61918, 2.39242,
      48.61941, 2.39249, 48.62057, 2.39354, 48.62092, 2.39456, 48.62122,
      2.39507, 48.62259, 2.39878, 48.62271, 2.3989, 48.62269, 2.39956, 48.62322,
      2.40055, 48.62348, 2.40085, 48.62411, 2.39927, 48.6265, 2.3982, 48.62695,
      2.40012, 48.62693, 2.4021, 48.62647, 2.40366, 48.62722, 2.40422, 48.62903,
      2.4043, 48.63011, 2.40447, 48.63003, 2.40484, 48.63088, 2.40559, 48.63268,
      2.40681, 48.63319, 2.40635, 48.63331, 2.40634, 48.6337, 2.40645, 48.63421,
      2.40684, 48.63451, 2.40729, 48.63472, 2.40791, 48.63509, 2.4109, 48.6354,
      2.41229, 48.63552, 2.41268, 48.63587, 2.41286, 48.63606, 2.41335,
      48.63677, 2.41412, 48.63769, 2.41546, 48.63802, 2.41578, 48.63847,
      2.41602, 48.6381, 2.41923, 48.64549, 2.42128, 48.64429, 2.42262, 48.64233,
      2.42513, 48.64222, 2.4251, 48.64103, 2.42639, 48.64433, 2.43149, 48.64627,
      2.43483, 48.64729, 2.43553, 48.64792, 2.43671, 48.64855, 2.43761,
      48.64711, 2.43987, 48.645, 2.44208, 48.64356, 2.44405, 48.64323, 2.44469,
      48.63956, 2.44837, 48.63901, 2.44929, 48.63835, 2.45071, 48.63775, 2.4523,
      48.6367, 2.45511, 48.63626, 2.45588, 48.6347, 2.45793, 48.63064, 2.46089,
      48.62847, 2.46501, 48.62552, 2.47023, 48.62411, 2.46805, 48.62339,
      2.46553, 48.62276, 2.46151, 48.62255, 2.46159, 48.62199, 2.45976,
      48.62274, 2.4591, 48.62174, 2.45657, 48.62117, 2.45713, 48.6189, 2.45271,
      48.61658, 2.4487, 48.61717, 2.44803, 48.61421, 2.44416, 48.61332, 2.44224,
      48.61296, 2.44085, 48.61337, 2.43996, 48.61404, 2.4394, 48.61434, 2.43808,
      48.61303, 2.43758, 48.61274, 2.43802, 48.61113, 2.43685
    ]
  ]
};

export const EPAGNY_METZ_TESSY: LocationState = {
  id: 'FR-74112',
  name: 'Epagny Metz-Tessy',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      45.96445, 6.07486, 45.96487, 6.07769, 45.96478, 6.07874, 45.96402,
      6.07809, 45.96304, 6.07783, 45.96173, 6.07863, 45.9611, 6.07888, 45.96111,
      6.07956, 45.96034, 6.08675, 45.96007, 6.08718, 45.95999, 6.08809,
      45.95987, 6.08852, 45.95965, 6.08888, 45.9592, 6.08931, 45.95937, 6.09106,
      45.95946, 6.09309, 45.95928, 6.09635, 45.9588, 6.09697, 45.95587, 6.09998,
      45.95369, 6.09983, 45.95254, 6.101, 45.95233, 6.10147, 45.95209, 6.10264,
      45.95211, 6.10365, 45.95147, 6.10377, 45.9512, 6.104, 45.95011, 6.10457,
      45.94938, 6.10582, 45.94934, 6.10603, 45.94944, 6.10629, 45.94941,
      6.10643, 45.94884, 6.10689, 45.94845, 6.10699, 45.94683, 6.10868,
      45.94657, 6.10975, 45.94697, 6.11043, 45.94647, 6.11147, 45.9463, 6.11141,
      45.94619, 6.11148, 45.94602, 6.11203, 45.94587, 6.1122, 45.94556, 6.11226,
      45.94455, 6.11155, 45.9444, 6.11158, 45.94423, 6.11182, 45.94363, 6.1117,
      45.94322, 6.11115, 45.94301, 6.11124, 45.94292, 6.11143, 45.94306,
      6.11206, 45.94303, 6.11221, 45.9428, 6.11247, 45.94256, 6.11242, 45.94229,
      6.11295, 45.94232, 6.11315, 45.94052, 6.11651, 45.93971, 6.1198, 45.93948,
      6.11969, 45.9386, 6.12053, 45.93825, 6.12079, 45.93818, 6.12075, 45.93515,
      6.12384, 45.93508, 6.12353, 45.93478, 6.12298, 45.9348, 6.12307, 45.9341,
      6.12261, 45.93318, 6.12314, 45.93261, 6.12214, 45.93208, 6.12164,
      45.93131, 6.12128, 45.93005, 6.12088, 45.92789, 6.11932, 45.92655,
      6.11875, 45.92595, 6.11834, 45.92499, 6.11697, 45.9248, 6.11658, 45.92435,
      6.11604, 45.9233, 6.11529, 45.92223, 6.11488, 45.92145, 6.11404, 45.92435,
      6.10776, 45.92471, 6.10753, 45.92649, 6.10412, 45.92641, 6.10402,
      45.92745, 6.10224, 45.92713, 6.10186, 45.92789, 6.10038, 45.92803,
      6.10044, 45.9308, 6.09567, 45.9301, 6.09582, 45.92998, 6.09565, 45.92905,
      6.09427, 45.92919, 6.09402, 45.92855, 6.09319, 45.92865, 6.09305,
      45.92702, 6.09091, 45.92678, 6.08974, 45.9264, 6.08986, 45.92624, 6.08946,
      45.92595, 6.08944, 45.92566, 6.08903, 45.92526, 6.08978, 45.92498,
      6.09011, 45.92445, 6.08986, 45.92434, 6.08968, 45.92413, 6.08895,
      45.92293, 6.08791, 45.92249, 6.08778, 45.92229, 6.08802, 45.9219, 6.08821,
      45.92192, 6.08799, 45.92179, 6.08767, 45.92186, 6.08749, 45.92179,
      6.08708, 45.9215, 6.08677, 45.92155, 6.08651, 45.92138, 6.08646, 45.92126,
      6.08619, 45.92112, 6.08618, 45.92282, 6.08143, 45.92438, 6.07835,
      45.92685, 6.07625, 45.92756, 6.07627, 45.92809, 6.07652, 45.92868,
      6.07647, 45.92899, 6.0763, 45.92927, 6.07581, 45.92972, 6.07572, 45.93035,
      6.07685, 45.93053, 6.07745, 45.93123, 6.07865, 45.93162, 6.07903,
      45.93166, 6.07893, 45.9322, 6.07921, 45.93247, 6.07967, 45.93296, 6.07936,
      45.93309, 6.07909, 45.93345, 6.07879, 45.934, 6.07867, 45.93913, 6.06289,
      45.94488, 6.08195, 45.94579, 6.08242, 45.94632, 6.08303, 45.94668,
      6.08319, 45.94817, 6.08072, 45.94871, 6.07869, 45.9515, 6.06624, 45.95194,
      6.06619, 45.9528, 6.06633, 45.95395, 6.06618, 45.95438, 6.06641, 45.95486,
      6.06637, 45.95535, 6.0662, 45.95575, 6.06657, 45.95841, 6.06791, 45.95864,
      6.06747, 45.95889, 6.06737, 45.96103, 6.06939, 45.96108, 6.07037,
      45.96145, 6.07114, 45.96432, 6.07412, 45.96445, 6.07486
    ]
  ]
};

export const CHAUMES_EN_RETZ: LocationState = {
  id: 'FR-44005',
  name: 'Chaumes-en-Retz',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.17481, -1.89608, 47.17205, -1.89499, 47.17231, -1.89426, 47.1733,
      -1.89211, 47.17328, -1.89172, 47.17264, -1.88981, 47.17175, -1.88808,
      47.17152, -1.88715, 47.17114, -1.88648, 47.17086, -1.88616, 47.17061,
      -1.88565, 47.16944, -1.88397, 47.16823, -1.88073, 47.16737, -1.87867,
      47.16729, -1.87862, 47.16727, -1.87833, 47.16657, -1.87658, 47.16639,
      -1.87559, 47.16569, -1.87357, 47.1658, -1.8735, 47.16523, -1.86823,
      47.16471, -1.86721, 47.16432, -1.86592, 47.16394, -1.8641, 47.16372,
      -1.86438, 47.16317, -1.86544, 47.16283, -1.86487, 47.16179, -1.86529,
      47.16157, -1.86514, 47.16094, -1.86524, 47.15982, -1.86519, 47.15944,
      -1.86507, 47.15802, -1.86511, 47.15739, -1.86494, 47.15667, -1.86443,
      47.15604, -1.8636, 47.15798, -1.85839, 47.15813, -1.85783, 47.15685,
      -1.85572, 47.15579, -1.85435, 47.15531, -1.85332, 47.15371, -1.85161,
      47.15182, -1.85102, 47.14971, -1.84912, 47.1465, -1.84661, 47.14597,
      -1.84603, 47.14454, -1.84509, 47.14413, -1.84473, 47.14334, -1.84438,
      47.14286, -1.84439, 47.14091, -1.84564, 47.1396, -1.8462, 47.13908,
      -1.84663, 47.1379, -1.84806, 47.13777, -1.84804, 47.13592, -1.84321,
      47.13513, -1.84137, 47.13513, -1.84117, 47.1356, -1.84062, 47.13566,
      -1.84035, 47.13464, -1.83954, 47.13429, -1.83913, 47.13266, -1.83674,
      47.13203, -1.83566, 47.13158, -1.83518, 47.1309, -1.83385, 47.13071,
      -1.8337, 47.13026, -1.83446, 47.12971, -1.83519, 47.12938, -1.83548,
      47.12871, -1.83563, 47.12681, -1.83578, 47.12636, -1.83589, 47.12565,
      -1.83625, 47.12493, -1.83727, 47.12427, -1.83876, 47.12304, -1.84051,
      47.12258, -1.84056, 47.12204, -1.84083, 47.12068, -1.84185, 47.11866,
      -1.84183, 47.11844, -1.84196, 47.11807, -1.84198, 47.11788, -1.84183,
      47.1176, -1.84187, 47.11725, -1.84175, 47.1165, -1.84252, 47.11633,
      -1.84247, 47.11581, -1.84309, 47.11563, -1.84355, 47.11526, -1.84388,
      47.11513, -1.84443, 47.11484, -1.8447, 47.11469, -1.84559, 47.11429,
      -1.84562, 47.11423, -1.84617, 47.11406, -1.84663, 47.11425, -1.84707,
      47.11418, -1.84785, 47.11365, -1.84875, 47.11306, -1.84922, 47.1129,
      -1.84996, 47.11219, -1.85089, 47.1121, -1.8521, 47.11293, -1.85461,
      47.11314, -1.85567, 47.11332, -1.8573, 47.11353, -1.858, 47.11341,
      -1.85896, 47.11345, -1.85952, 47.11388, -1.86057, 47.11427, -1.86082,
      47.11448, -1.86116, 47.11473, -1.86176, 47.11504, -1.8628, 47.11556,
      -1.86365, 47.11582, -1.86446, 47.11595, -1.86541, 47.11584, -1.86562,
      47.11549, -1.86573, 47.11544, -1.86594, 47.11549, -1.86624, 47.11596,
      -1.86735, 47.11585, -1.86815, 47.11591, -1.8686, 47.11579, -1.86915,
      47.11605, -1.86982, 47.11588, -1.87045, 47.11599, -1.87079, 47.11566,
      -1.871, 47.11556, -1.87138, 47.11576, -1.87167, 47.11589, -1.87218,
      47.11615, -1.87236, 47.11594, -1.87312, 47.11605, -1.87341, 47.11608,
      -1.87386, 47.11597, -1.87445, 47.11614, -1.87484, 47.116, -1.87528,
      47.11611, -1.87566, 47.11612, -1.87606, 47.11613, -1.87663, 47.11603,
      -1.8769, 47.11604, -1.87722, 47.11621, -1.87778, 47.1164, -1.87897,
      47.11671, -1.87983, 47.11685, -1.88045, 47.11705, -1.88101, 47.11748,
      -1.88171, 47.11778, -1.88367, 47.11834, -1.88561, 47.11834, -1.88591,
      47.11818, -1.88688, 47.11768, -1.88856, 47.11734, -1.89046, 47.11731,
      -1.89105, 47.11747, -1.89286, 47.11753, -1.89491, 47.11738, -1.89524,
      47.11658, -1.89627, 47.11635, -1.89679, 47.11581, -1.89859, 47.11582,
      -1.89908, 47.11508, -1.89949, 47.11469, -1.89896, 47.11443, -1.8994,
      47.1141, -1.89963, 47.11284, -1.8986, 47.11271, -1.89828, 47.11045,
      -1.89792, 47.10956, -1.89672, 47.10961, -1.89866, 47.10954, -1.89966,
      47.10969, -1.90182, 47.10961, -1.90245, 47.10921, -1.90354, 47.10889,
      -1.90627, 47.1089, -1.90853, 47.10915, -1.9103, 47.10796, -1.9115,
      47.10772, -1.91214, 47.10644, -1.91243, 47.1057, -1.91339, 47.1053,
      -1.91278, 47.10516, -1.91306, 47.1051, -1.91351, 47.10527, -1.91366,
      47.10526, -1.91511, 47.10517, -1.91517, 47.1053, -1.91643, 47.10528,
      -1.91718, 47.10473, -1.91707, 47.10467, -1.91889, 47.10508, -1.91892,
      47.10524, -1.91946, 47.10573, -1.92313, 47.10564, -1.92423, 47.10523,
      -1.92447, 47.10538, -1.92666, 47.10574, -1.929, 47.10582, -1.93152,
      47.10574, -1.93155, 47.10566, -1.93267, 47.10573, -1.93529, 47.10569,
      -1.93602, 47.10609, -1.9362, 47.10612, -1.93866, 47.10598, -1.93994,
      47.10532, -1.94223, 47.10468, -1.94374, 47.10457, -1.94455, 47.10455,
      -1.94508, 47.10469, -1.94623, 47.10514, -1.94815, 47.10534, -1.94828,
      47.10556, -1.94898, 47.10597, -1.94976, 47.1067, -1.95053, 47.10771,
      -1.95079, 47.10885, -1.95088, 47.10906, -1.95173, 47.10958, -1.9523,
      47.11037, -1.95476, 47.1105, -1.95482, 47.1117, -1.95624, 47.11576,
      -1.95589, 47.11595, -1.95603, 47.11622, -1.95657, 47.1165, -1.9575,
      47.11669, -1.95934, 47.11686, -1.96009, 47.11684, -1.96056, 47.11701,
      -1.96122, 47.11699, -1.96145, 47.11668, -1.96218, 47.11669, -1.96241,
      47.1165, -1.96313, 47.11682, -1.96417, 47.11662, -1.96574, 47.11578,
      -1.96693, 47.11536, -1.96771, 47.11527, -1.96804, 47.11519, -1.96946,
      47.11597, -1.97058, 47.11623, -1.97115, 47.11631, -1.97175, 47.1162,
      -1.973, 47.11641, -1.97395, 47.11746, -1.97535, 47.11805, -1.97682,
      47.1187, -1.97816, 47.11881, -1.97847, 47.119, -1.97953, 47.11929,
      -1.9801, 47.12059, -1.98378, 47.12363, -1.98637, 47.12454, -1.98359,
      47.12522, -1.98081, 47.12746, -1.9807, 47.12859, -1.97933, 47.12909,
      -1.97938, 47.13036, -1.98005, 47.13098, -1.9785, 47.13104, -1.97522,
      47.13097, -1.97484, 47.13079, -1.97443, 47.13211, -1.97373, 47.13296,
      -1.97301, 47.13422, -1.97296, 47.13457, -1.9724, 47.13527, -1.9727,
      47.13639, -1.97236, 47.13783, -1.97221, 47.1389, -1.97183, 47.13991,
      -1.97206, 47.1423, -1.97324, 47.14246, -1.97351, 47.14269, -1.97347,
      47.14319, -1.97363, 47.14392, -1.97348, 47.14421, -1.97355, 47.14555,
      -1.97445, 47.14623, -1.9743, 47.14756, -1.97433, 47.14913, -1.97462,
      47.15054, -1.97433, 47.15128, -1.97432, 47.15223, -1.97437, 47.15287,
      -1.97458, 47.15424, -1.97429, 47.15515, -1.97421, 47.15535, -1.97315,
      47.15724, -1.97308, 47.15797, -1.97325, 47.15903, -1.97261, 47.15993,
      -1.97182, 47.16066, -1.97166, 47.16106, -1.97195, 47.16149, -1.97189,
      47.16175, -1.97205, 47.1621, -1.97379, 47.1623, -1.97373, 47.16296,
      -1.97454, 47.16338, -1.97476, 47.16436, -1.97412, 47.16544, -1.97497,
      47.16566, -1.97528, 47.16582, -1.97623, 47.16615, -1.9768, 47.16677,
      -1.97726, 47.16751, -1.97774, 47.16943, -1.97858, 47.16996, -1.97892,
      47.17144, -1.97931, 47.17262, -1.97794, 47.17334, -1.97691, 47.17273,
      -1.97598, 47.17456, -1.97467, 47.17483, -1.97394, 47.17525, -1.9738,
      47.17601, -1.97214, 47.17717, -1.97677, 47.17736, -1.97779, 47.1779,
      -1.97712, 47.18131, -1.97363, 47.18142, -1.97449, 47.18572, -1.97833,
      47.18665, -1.97712, 47.18893, -1.9881, 47.19001, -1.99155, 47.19037,
      -1.9942, 47.19163, -1.99377, 47.19289, -1.99307, 47.19307, -1.99245,
      47.1931, -1.99198, 47.19393, -1.99163, 47.19494, -1.99005, 47.19564,
      -1.98925, 47.19602, -1.99037, 47.19638, -1.99198, 47.19692, -1.99174,
      47.19762, -1.99289, 47.1984, -1.99383, 47.19757, -1.997, 47.19833,
      -1.99667, 47.19925, -1.99643, 47.20279, -1.99567, 47.20291, -1.99538,
      47.20375, -1.9949, 47.20536, -1.9936, 47.20508, -1.99253, 47.20477,
      -1.99003, 47.20472, -1.98829, 47.20457, -1.98746, 47.20454, -1.98539,
      47.20434, -1.98543, 47.20435, -1.98436, 47.20478, -1.98253, 47.20516,
      -1.98199, 47.20544, -1.98182, 47.20569, -1.98138, 47.20596, -1.98052,
      47.20614, -1.97921, 47.20609, -1.97754, 47.20569, -1.97651, 47.20557,
      -1.97541, 47.20532, -1.97441, 47.20516, -1.9721, 47.20494, -1.97134,
      47.20438, -1.97035, 47.20341, -1.97002, 47.20274, -1.96749, 47.20208,
      -1.96579, 47.20199, -1.9649, 47.20165, -1.96469, 47.20147, -1.96427,
      47.20171, -1.96368, 47.20295, -1.962, 47.20328, -1.96168, 47.20379,
      -1.9614, 47.20439, -1.96053, 47.20471, -1.96024, 47.20497, -1.95984,
      47.20515, -1.95936, 47.20538, -1.95834, 47.20544, -1.95694, 47.20571,
      -1.95549, 47.20572, -1.95499, 47.20448, -1.95261, 47.2044, -1.95211,
      47.20444, -1.9519, 47.20477, -1.95122, 47.20489, -1.95118, 47.20509,
      -1.9508, 47.20603, -1.95003, 47.20683, -1.94913, 47.20683, -1.94903,
      47.20648, -1.94865, 47.20648, -1.94854, 47.20695, -1.94727, 47.20723,
      -1.9468, 47.20742, -1.94619, 47.20749, -1.94559, 47.20754, -1.94382,
      47.2073, -1.94222, 47.20738, -1.94138, 47.20732, -1.94083, 47.20757,
      -1.93987, 47.20759, -1.93786, 47.20743, -1.9367, 47.20754, -1.93614,
      47.20753, -1.93526, 47.20763, -1.93486, 47.20743, -1.93459, 47.20728,
      -1.93399, 47.20747, -1.9331, 47.20733, -1.93258, 47.20733, -1.93157,
      47.20744, -1.93119, 47.20758, -1.93004, 47.2074, -1.92749, 47.20697,
      -1.92426, 47.20697, -1.9229, 47.20654, -1.92211, 47.20583, -1.92118,
      47.20506, -1.92132, 47.20328, -1.92191, 47.20311, -1.922, 47.20254,
      -1.9228, 47.20243, -1.92282, 47.20194, -1.92353, 47.20162, -1.92429,
      47.20158, -1.92479, 47.2013, -1.92531, 47.20131, -1.92565, 47.20106,
      -1.92656, 47.20076, -1.92691, 47.20053, -1.92822, 47.19971, -1.93025,
      47.1995, -1.93102, 47.19855, -1.93198, 47.19836, -1.93233, 47.19803,
      -1.9326, 47.19738, -1.93348, 47.19672, -1.9339, 47.19653, -1.93421,
      47.19652, -1.93438, 47.19632, -1.9348, 47.19607, -1.93507, 47.19597,
      -1.9354, 47.19524, -1.93563, 47.19503, -1.93583, 47.19481, -1.93623,
      47.19459, -1.93624, 47.19451, -1.93642, 47.19437, -1.93648, 47.19343,
      -1.93589, 47.19272, -1.93602, 47.19264, -1.9359, 47.19245, -1.93588,
      47.19216, -1.93648, 47.19159, -1.93667, 47.19124, -1.93656, 47.19074,
      -1.93614, 47.19045, -1.93605, 47.1903, -1.93584, 47.18953, -1.93534,
      47.18883, -1.93527, 47.18827, -1.93537, 47.1878, -1.93565, 47.18712,
      -1.93562, 47.1863, -1.93603, 47.18433, -1.9363, 47.18357, -1.93683,
      47.18176, -1.93907, 47.18024, -1.93958, 47.17944, -1.93929, 47.17839,
      -1.93845, 47.17794, -1.93832, 47.17756, -1.93843, 47.17622, -1.93817,
      47.17594, -1.93819, 47.17594, -1.93857, 47.17478, -1.93849, 47.17441,
      -1.93718, 47.1737, -1.93581, 47.17382, -1.93554, 47.17339, -1.93411,
      47.17317, -1.93226, 47.1733, -1.93211, 47.17301, -1.93043, 47.17225,
      -1.9286, 47.17166, -1.92683, 47.17153, -1.92621, 47.1701, -1.9252,
      47.16688, -1.9245, 47.16559, -1.92457, 47.16515, -1.9219, 47.1652,
      -1.92146, 47.16548, -1.92054, 47.16703, -1.91732, 47.16713, -1.91719,
      47.16763, -1.91793, 47.16793, -1.91612, 47.16856, -1.91621, 47.16858,
      -1.91399, 47.16879, -1.91338, 47.16991, -1.91264, 47.17028, -1.91227,
      47.17183, -1.90998, 47.17224, -1.90908, 47.17259, -1.90782, 47.17289,
      -1.90385, 47.17404, -1.89969, 47.17481, -1.89608
    ]
  ]
};

export const BELLEVIGNY: LocationState = {
  id: 'FR-85019',
  name: 'Bellevigny',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      46.82204, -1.4308, 46.82197, -1.43035, 46.82153, -1.42988, 46.82136,
      -1.42936, 46.82135, -1.42886, 46.82185, -1.42788, 46.82196, -1.42784,
      46.8224, -1.42816, 46.8227, -1.42811, 46.82314, -1.42786, 46.82415,
      -1.42814, 46.82434, -1.42807, 46.82457, -1.42779, 46.82508, -1.42789,
      46.82519, -1.4278, 46.82523, -1.42744, 46.82532, -1.42735, 46.82587,
      -1.4276, 46.82627, -1.4271, 46.8265, -1.42705, 46.82695, -1.42667,
      46.82714, -1.42636, 46.8273, -1.42635, 46.82802, -1.4253, 46.82809,
      -1.42501, 46.82769, -1.42437, 46.82762, -1.42378, 46.82709, -1.42369,
      46.82692, -1.42331, 46.82687, -1.42289, 46.82676, -1.42262, 46.82652,
      -1.42243, 46.82632, -1.42202, 46.82616, -1.42192, 46.82594, -1.42198,
      46.82557, -1.42177, 46.82494, -1.42121, 46.8247, -1.42072, 46.82455,
      -1.42074, 46.82454, -1.42053, 46.82473, -1.42015, 46.82454, -1.41999,
      46.82454, -1.41988, 46.82462, -1.41964, 46.82528, -1.41871, 46.82545,
      -1.41876, 46.82563, -1.419, 46.82573, -1.41898, 46.82586, -1.41874,
      46.82617, -1.41855, 46.8265, -1.41906, 46.82678, -1.41929, 46.82704,
      -1.4191, 46.8281, -1.41919, 46.82873, -1.41896, 46.82939, -1.41829,
      46.82955, -1.41789, 46.82926, -1.41766, 46.82908, -1.4172, 46.82923,
      -1.41702, 46.82932, -1.41715, 46.82932, -1.41741, 46.82953, -1.41731,
      46.82954, -1.41675, 46.82986, -1.41626, 46.82982, -1.41612, 46.82947,
      -1.41586, 46.82953, -1.41474, 46.82945, -1.41398, 46.82958, -1.413,
      46.82967, -1.41278, 46.83001, -1.41275, 46.83029, -1.41193, 46.83087,
      -1.41194, 46.83113, -1.41173, 46.83122, -1.41151, 46.83109, -1.4111,
      46.83112, -1.41102, 46.82848, -1.40956, 46.82657, -1.40812, 46.82536,
      -1.40707, 46.82481, -1.40643, 46.82407, -1.4062, 46.82209, -1.40512,
      46.82155, -1.40406, 46.82028, -1.40067, 46.81963, -1.3994, 46.81772,
      -1.39774, 46.81512, -1.39491, 46.81355, -1.39241, 46.81097, -1.3896,
      46.80946, -1.38738, 46.80607, -1.38099, 46.80483, -1.37914, 46.80448,
      -1.37875, 46.80325, -1.378, 46.80267, -1.37751, 46.80042, -1.37445,
      46.79949, -1.37303, 46.79798, -1.37113, 46.79728, -1.36974, 46.79687,
      -1.36873, 46.79475, -1.36256, 46.7945, -1.36007, 46.7935, -1.35821,
      46.79303, -1.35696, 46.79257, -1.35525, 46.79232, -1.35339, 46.7919,
      -1.3532, 46.79196, -1.35269, 46.79231, -1.35144, 46.79241, -1.35054,
      46.79288, -1.34942, 46.7926, -1.34951, 46.79218, -1.34988, 46.79135,
      -1.35038, 46.79023, -1.35074, 46.79012, -1.35085, 46.78877, -1.3499,
      46.78662, -1.35082, 46.78311, -1.35523, 46.78308, -1.35543, 46.78294,
      -1.35536, 46.7826, -1.35621, 46.78159, -1.3579, 46.78139, -1.35923,
      46.78103, -1.36041, 46.7804, -1.36126, 46.78032, -1.36183, 46.78019,
      -1.36212, 46.77956, -1.36286, 46.77914, -1.36303, 46.77894, -1.36349,
      46.77857, -1.36402, 46.77916, -1.3669, 46.77949, -1.36741, 46.77942,
      -1.36809, 46.77921, -1.36922, 46.77841, -1.37139, 46.77836, -1.37197,
      46.77824, -1.37215, 46.77807, -1.37199, 46.77752, -1.37246, 46.77732,
      -1.37369, 46.77924, -1.38275, 46.77877, -1.38308, 46.77966, -1.38369,
      46.77983, -1.38417, 46.77997, -1.38526, 46.78023, -1.38632, 46.77993,
      -1.38683, 46.77998, -1.38737, 46.77984, -1.38796, 46.77933, -1.38905,
      46.77913, -1.39053, 46.77864, -1.39163, 46.77858, -1.3925, 46.77928,
      -1.39383, 46.77946, -1.39435, 46.77986, -1.39439, 46.78024, -1.3946,
      46.78078, -1.39566, 46.78144, -1.39741, 46.78146, -1.39839, 46.78168,
      -1.39876, 46.78211, -1.39903, 46.78225, -1.40012, 46.78253, -1.40028,
      46.7828, -1.40069, 46.78291, -1.40071, 46.78274, -1.40211, 46.78126,
      -1.4023, 46.78042, -1.40185, 46.77891, -1.40197, 46.77636, -1.4009,
      46.77557, -1.40084, 46.77412, -1.39912, 46.77342, -1.3986, 46.77332,
      -1.39864, 46.77319, -1.39921, 46.7723, -1.39973, 46.77171, -1.4, 46.77141,
      -1.40001, 46.77112, -1.3997, 46.77054, -1.39859, 46.77004, -1.39831,
      46.76899, -1.39819, 46.76853, -1.39884, 46.76797, -1.39931, 46.76758,
      -1.39989, 46.76721, -1.4006, 46.76695, -1.40136, 46.76625, -1.40271,
      46.76565, -1.40374, 46.76508, -1.40448, 46.76459, -1.40571, 46.76394,
      -1.4061, 46.76309, -1.40592, 46.76255, -1.40743, 46.76227, -1.4075,
      46.76205, -1.40738, 46.7614, -1.40823, 46.76069, -1.40926, 46.75987,
      -1.41098, 46.75898, -1.41155, 46.75687, -1.41165, 46.75641, -1.41177,
      46.75521, -1.41268, 46.75391, -1.41326, 46.75193, -1.41656, 46.75182,
      -1.41692, 46.75183, -1.41738, 46.75164, -1.41793, 46.75139, -1.41837,
      46.7512, -1.41848, 46.75054, -1.41984, 46.74874, -1.42132, 46.74878,
      -1.42153, 46.74815, -1.4221, 46.74785, -1.42295, 46.74709, -1.42429,
      46.74573, -1.42637, 46.74509, -1.42777, 46.74489, -1.42801, 46.7439,
      -1.42864, 46.74424, -1.43042, 46.75512, -1.43067, 46.75572, -1.43199,
      46.75613, -1.43441, 46.75639, -1.43532, 46.75769, -1.43656, 46.75809,
      -1.4366, 46.75751, -1.43958, 46.75933, -1.44101, 46.75918, -1.44356,
      46.75924, -1.44907, 46.75934, -1.44999, 46.75977, -1.45153, 46.76082,
      -1.45052, 46.76267, -1.44911, 46.76402, -1.44766, 46.7649, -1.44693,
      46.76542, -1.44664, 46.76707, -1.44613, 46.76918, -1.44517, 46.76989,
      -1.44576, 46.77116, -1.44857, 46.77125, -1.44875, 46.77132, -1.44869,
      46.7722, -1.4502, 46.77231, -1.45055, 46.77238, -1.45136, 46.77296,
      -1.4537, 46.77354, -1.4546, 46.77482, -1.4536, 46.77673, -1.45291,
      46.77679, -1.45264, 46.77668, -1.45189, 46.77748, -1.45182, 46.77736,
      -1.44963, 46.77926, -1.44964, 46.77933, -1.44937, 46.77993, -1.44931,
      46.78019, -1.45038, 46.78061, -1.45146, 46.78154, -1.45296, 46.78192,
      -1.45401, 46.78156, -1.45527, 46.78301, -1.45584, 46.78326, -1.45609,
      46.7837, -1.45682, 46.78389, -1.45807, 46.78408, -1.45864, 46.78688,
      -1.45779, 46.78797, -1.45719, 46.78937, -1.45693, 46.78972, -1.45669,
      46.79029, -1.45582, 46.79112, -1.45568, 46.79162, -1.45531, 46.79166,
      -1.45544, 46.79141, -1.45853, 46.79172, -1.45904, 46.7918, -1.45937,
      46.79176, -1.46042, 46.79293, -1.46083, 46.793, -1.46123, 46.79448,
      -1.46141, 46.79454, -1.46317, 46.79489, -1.46509, 46.7958, -1.46433,
      46.79629, -1.46374, 46.79725, -1.46308, 46.79854, -1.46233, 46.79941,
      -1.4617, 46.79951, -1.4607, 46.79974, -1.45959, 46.80008, -1.45911,
      46.80075, -1.45894, 46.801, -1.45878, 46.80177, -1.45883, 46.80242,
      -1.45912, 46.80295, -1.45899, 46.80311, -1.45873, 46.80377, -1.45695,
      46.80488, -1.45522, 46.80569, -1.45439, 46.80603, -1.45477, 46.80596,
      -1.45352, 46.80629, -1.44511, 46.8079, -1.44602, 46.80978, -1.44781,
      46.81066, -1.44838, 46.81096, -1.44811, 46.81159, -1.44806, 46.81247,
      -1.44763, 46.81382, -1.44656, 46.81433, -1.44594, 46.81511, -1.4452,
      46.81495, -1.44262, 46.81516, -1.43926, 46.8154, -1.4379, 46.81549,
      -1.4365, 46.81561, -1.43601, 46.81681, -1.43568, 46.81806, -1.43437,
      46.8183, -1.43374, 46.81849, -1.43354, 46.81873, -1.43281, 46.8194,
      -1.43177, 46.81958, -1.43162, 46.81988, -1.43153, 46.82102, -1.43082,
      46.82204, -1.4308
    ]
  ]
};

export const ANCENIS_SAINT_GEREON: LocationState = {
  id: 'FR-44003',
  name: 'Ancenis-Saint-Géréon ',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.35012, -1.22796, 47.35047, -1.22783, 47.35088, -1.2272, 47.35131,
      -1.22585, 47.35165, -1.22424, 47.35209, -1.22018, 47.35277, -1.21642,
      47.35334, -1.21128, 47.35361, -1.20996, 47.35423, -1.20817, 47.35448,
      -1.20772, 47.35486, -1.20725, 47.35519, -1.20711, 47.35522, -1.20879,
      47.35549, -1.21213, 47.35587, -1.21454, 47.35579, -1.21586, 47.35686,
      -1.21726, 47.35676, -1.21887, 47.3566, -1.21904, 47.3565, -1.21945,
      47.35626, -1.22273, 47.35703, -1.22376, 47.35777, -1.21791, 47.35904,
      -1.21837, 47.35887, -1.21987, 47.35905, -1.21997, 47.35919, -1.22019,
      47.35989, -1.22169, 47.36091, -1.22306, 47.3612, -1.22416, 47.36134,
      -1.22431, 47.3616, -1.22435, 47.36224, -1.22418, 47.36235, -1.22402,
      47.363, -1.22402, 47.36311, -1.22578, 47.36325, -1.22561, 47.36364,
      -1.22626, 47.36351, -1.22642, 47.36379, -1.22679, 47.36409, -1.22679,
      47.36433, -1.22696, 47.36502, -1.22772, 47.36528, -1.2277, 47.36588,
      -1.22702, 47.36659, -1.22594, 47.36683, -1.22574, 47.3672, -1.22554,
      47.36736, -1.22559, 47.3675, -1.22593, 47.36805, -1.22583, 47.36807,
      -1.22441, 47.36826, -1.22443, 47.36826, -1.2247, 47.36868, -1.22478,
      47.36868, -1.22492, 47.3688, -1.22495, 47.36882, -1.22461, 47.36928,
      -1.2245, 47.36919, -1.22526, 47.36953, -1.22521, 47.36993, -1.22536,
      47.37048, -1.22527, 47.37056, -1.22587, 47.37066, -1.22599, 47.37191,
      -1.22608, 47.37267, -1.22598, 47.37297, -1.2263, 47.3738, -1.22621,
      47.37393, -1.22631, 47.37407, -1.22677, 47.37495, -1.22668, 47.37532,
      -1.22701, 47.37586, -1.22688, 47.3771, -1.22588, 47.37735, -1.22561,
      47.3774, -1.22545, 47.37763, -1.22567, 47.37818, -1.22575, 47.37935,
      -1.22624, 47.37949, -1.22658, 47.38054, -1.22658, 47.38142, -1.2271,
      47.38281, -1.22739, 47.38364, -1.22743, 47.38445, -1.2272, 47.38511,
      -1.22663, 47.38589, -1.22624, 47.38687, -1.22542, 47.38818, -1.22654,
      47.39014, -1.22851, 47.39076, -1.2293, 47.3923, -1.23181, 47.39503,
      -1.21844, 47.39698, -1.20994, 47.39737, -1.21026, 47.39742, -1.20989,
      47.39825, -1.20813, 47.39841, -1.20712, 47.39848, -1.20698, 47.39865,
      -1.20709, 47.39889, -1.20618, 47.3993, -1.20537, 47.39945, -1.20514,
      47.39991, -1.20576, 47.40011, -1.20456, 47.4003, -1.2047, 47.40075,
      -1.20472, 47.4012, -1.20451, 47.40195, -1.20459, 47.40333, -1.20441,
      47.40382, -1.20424, 47.40469, -1.20503, 47.40498, -1.20557, 47.40577,
      -1.20372, 47.40531, -1.20325, 47.40393, -1.20145, 47.405, -1.19973,
      47.4056, -1.19934, 47.40579, -1.1991, 47.40608, -1.19848, 47.40685,
      -1.19629, 47.40714, -1.19656, 47.40901, -1.19693, 47.40906, -1.19675,
      47.41033, -1.19927, 47.41095, -1.19772, 47.41101, -1.19721, 47.41077,
      -1.19402, 47.41041, -1.19094, 47.41014, -1.19002, 47.41001, -1.18905,
      47.40979, -1.18821, 47.41052, -1.18626, 47.41069, -1.18529, 47.41137,
      -1.18344, 47.41105, -1.18064, 47.41096, -1.18059, 47.41065, -1.17935,
      47.41081, -1.17883, 47.41069, -1.17855, 47.41067, -1.17821, 47.41133,
      -1.17701, 47.41194, -1.17631, 47.41182, -1.17521, 47.41246, -1.17261,
      47.41246, -1.17233, 47.41235, -1.17199, 47.4127, -1.17148, 47.41343,
      -1.17006, 47.41404, -1.16944, 47.41499, -1.16706, 47.4147, -1.16685,
      47.4149, -1.1666, 47.4149, -1.16644, 47.41474, -1.16617, 47.41425,
      -1.16587, 47.41406, -1.16595, 47.41385, -1.16563, 47.41363, -1.16563,
      47.4136, -1.16517, 47.4134, -1.16491, 47.41312, -1.16491, 47.41293,
      -1.1653, 47.41271, -1.16506, 47.41261, -1.16528, 47.41246, -1.16527,
      47.41231, -1.16488, 47.41266, -1.16452, 47.41255, -1.16426, 47.4123,
      -1.16424, 47.41215, -1.16444, 47.4118, -1.16447, 47.41164, -1.16477,
      47.41136, -1.16466, 47.41131, -1.16482, 47.41146, -1.16509, 47.41133,
      -1.1652, 47.41114, -1.16501, 47.41083, -1.16508, 47.41072, -1.16476,
      47.41092, -1.16451, 47.41093, -1.16438, 47.41053, -1.16422, 47.41041,
      -1.16441, 47.41019, -1.16517, 47.40987, -1.16495, 47.40976, -1.16458,
      47.40982, -1.16431, 47.41012, -1.16416, 47.40997, -1.16386, 47.40976,
      -1.16377, 47.40966, -1.16348, 47.4097, -1.16318, 47.40945, -1.16299,
      47.40797, -1.16313, 47.4079, -1.16319, 47.40784, -1.16366, 47.4076,
      -1.16382, 47.40724, -1.16376, 47.4069, -1.16316, 47.40657, -1.1631,
      47.40646, -1.1632, 47.40635, -1.16368, 47.40619, -1.1638, 47.40591,
      -1.16367, 47.40568, -1.16432, 47.40543, -1.16443, 47.40518, -1.16425,
      47.40507, -1.16337, 47.405, -1.1633, 47.40484, -1.16333, 47.40434,
      -1.16382, 47.40405, -1.16381, 47.40383, -1.16369, 47.40379, -1.16316,
      47.40364, -1.16302, 47.40347, -1.16322, 47.40326, -1.16377, 47.40314,
      -1.16385, 47.40224, -1.16309, 47.40193, -1.16313, 47.40159, -1.16355,
      47.40138, -1.16327, 47.40127, -1.16324, 47.40057, -1.16397, 47.39999,
      -1.16549, 47.40003, -1.16589, 47.40036, -1.16668, 47.40031, -1.16683,
      47.39994, -1.16708, 47.39992, -1.1673, 47.40004, -1.16744, 47.40048,
      -1.16758, 47.40053, -1.16781, 47.40035, -1.16821, 47.39962, -1.16891,
      47.39926, -1.16891, 47.39914, -1.16878, 47.3991, -1.16847, 47.39928,
      -1.16782, 47.3992, -1.1677, 47.39883, -1.16758, 47.39809, -1.16797,
      47.39783, -1.16799, 47.39751, -1.16768, 47.39727, -1.16687, 47.39712,
      -1.16667, 47.39626, -1.16742, 47.39608, -1.1674, 47.39551, -1.1673,
      47.39505, -1.16656, 47.39465, -1.16673, 47.39096, -1.16525, 47.38835,
      -1.16546, 47.38514, -1.16545, 47.38116, -1.16417, 47.38097, -1.16474,
      47.38101, -1.16484, 47.38045, -1.16766, 47.37828, -1.169, 47.3758,
      -1.1673, 47.37407, -1.1658, 47.37311, -1.1663, 47.37275, -1.16663,
      47.37249, -1.16669, 47.37191, -1.16725, 47.37142, -1.16794, 47.37108,
      -1.16794, 47.37108, -1.1685, 47.37001, -1.16846, 47.37049, -1.16721,
      47.37079, -1.16618, 47.37101, -1.1603, 47.37085, -1.15973, 47.37058,
      -1.15933, 47.37019, -1.15824, 47.37013, -1.15765, 47.37053, -1.15372,
      47.37114, -1.15034, 47.37178, -1.14768, 47.3722, -1.14717, 47.37297,
      -1.1468, 47.37335, -1.14627, 47.37353, -1.14568, 47.37387, -1.14402,
      47.37372, -1.14302, 47.37345, -1.13883, 47.37262, -1.13514, 47.37265,
      -1.13265, 47.37286, -1.1312, 47.37219, -1.1285, 47.37207, -1.12457,
      47.37054, -1.12429, 47.37073, -1.12265, 47.37332, -1.11509, 47.37292,
      -1.11069, 47.37171, -1.1055, 47.37147, -1.10488, 47.37119, -1.1046,
      47.3709, -1.10447, 47.37057, -1.10456, 47.37035, -1.10476, 47.36987,
      -1.10563, 47.36946, -1.10666, 47.369, -1.10798, 47.3685, -1.10993,
      47.36757, -1.11412, 47.3674, -1.11547, 47.36692, -1.12571, 47.36687,
      -1.12802, 47.367, -1.12977, 47.36736, -1.13183, 47.36795, -1.13373,
      47.36861, -1.13526, 47.36753, -1.13633, 47.36746, -1.13714, 47.3671,
      -1.13869, 47.36686, -1.13866, 47.36678, -1.13901, 47.36755, -1.13912,
      47.36774, -1.14195, 47.36809, -1.14415, 47.36903, -1.14456, 47.36716,
      -1.1516, 47.36625, -1.15384, 47.36591, -1.15486, 47.36557, -1.15733,
      47.36555, -1.1607, 47.3653, -1.16442, 47.36539, -1.16475, 47.3665,
      -1.16579, 47.3653, -1.16935, 47.36461, -1.17103, 47.36365, -1.17287,
      47.36265, -1.17434, 47.36221, -1.17517, 47.36086, -1.17959, 47.35892,
      -1.17775, 47.35793, -1.17951, 47.35753, -1.17996, 47.35501, -1.18383,
      47.35431, -1.18298, 47.35411, -1.18351, 47.35362, -1.1844, 47.35305,
      -1.18639, 47.35229, -1.18866, 47.35171, -1.19315, 47.35144, -1.19436,
      47.35093, -1.19598, 47.35147, -1.19806, 47.35005, -1.20196, 47.34903,
      -1.20817, 47.34986, -1.20833, 47.34982, -1.20881, 47.34987, -1.2089,
      47.35031, -1.20913, 47.35073, -1.20949, 47.35098, -1.21101, 47.35131,
      -1.21196, 47.35144, -1.21311, 47.35137, -1.21451, 47.35092, -1.21557,
      47.35058, -1.21663, 47.35027, -1.217, 47.34908, -1.21721, 47.35019,
      -1.22519, 47.35027, -1.22688, 47.35025, -1.22741, 47.35012, -1.22796
    ]
  ]
};

export const BELLEVILLE: LocationState = {
  id: 'FR-69019',
  name: 'Belleville-en-Beaujolais',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      46.10703, 4.76741, 46.10408, 4.76426, 46.10249, 4.762, 46.10106, 4.76024,
      46.0984, 4.757, 46.09687, 4.75544, 46.09663, 4.75501, 46.09639, 4.75393,
      46.09602, 4.75308, 46.09573, 4.75248, 46.0948, 4.75104, 46.09386, 4.74991,
      46.09254, 4.74883, 46.09184, 4.74842, 46.09033, 4.74763, 46.08944,
      4.74732, 46.08751, 4.74698, 46.08579, 4.74693, 46.08429, 4.74748,
      46.08374, 4.74786, 46.08322, 4.74828, 46.08224, 4.74944, 46.08177,
      4.74784, 46.08163, 4.74761, 46.08162, 4.74691, 46.08166, 4.74697,
      46.08179, 4.7469, 46.08197, 4.74712, 46.08213, 4.74704, 46.08233, 4.74733,
      46.08248, 4.74737, 46.08251, 4.74664, 46.08285, 4.74658, 46.08295,
      4.74544, 46.08308, 4.74524, 46.08331, 4.74524, 46.08349, 4.74452,
      46.08406, 4.74459, 46.08568, 4.74407, 46.08564, 4.73882, 46.08701,
      4.73411, 46.08725, 4.73303, 46.0878, 4.7323, 46.08832, 4.73097, 46.0886,
      4.72858, 46.08922, 4.72654, 46.08932, 4.72596, 46.08966, 4.725, 46.09008,
      4.72256, 46.09011, 4.72239, 46.09037, 4.72244, 46.09061, 4.72224,
      46.09059, 4.72182, 46.09077, 4.7217, 46.09077, 4.72154, 46.09092, 4.72157,
      46.09098, 4.72145, 46.09109, 4.72145, 46.09113, 4.72131, 46.09155,
      4.72098, 46.09183, 4.72109, 46.09194, 4.72094, 46.0921, 4.72103, 46.09232,
      4.72084, 46.09276, 4.7207, 46.09296, 4.72039, 46.09312, 4.72043, 46.09412,
      4.71976, 46.09457, 4.71922, 46.09478, 4.71911, 46.09498, 4.7191, 46.09545,
      4.71938, 46.0957, 4.71904, 46.09604, 4.71908, 46.09604, 4.71857, 46.09618,
      4.71832, 46.09626, 4.71788, 46.09634, 4.71795, 46.09665, 4.71786,
      46.09672, 4.71747, 46.09692, 4.71708, 46.09669, 4.7164, 46.09723, 4.71576,
      46.09735, 4.71576, 46.09741, 4.71559, 46.09756, 4.71555, 46.09759,
      4.71542, 46.09795, 4.71514, 46.09802, 4.71499, 46.09795, 4.71482,
      46.09808, 4.71466, 46.09859, 4.71446, 46.0986, 4.71427, 46.09881, 4.7141,
      46.09914, 4.71329, 46.09937, 4.71307, 46.09962, 4.71316, 46.09991,
      4.71259, 46.10029, 4.71227, 46.10054, 4.71191, 46.10074, 4.71192,
      46.10082, 4.71114, 46.10108, 4.71036, 46.10115, 4.71024, 46.10144,
      4.71019, 46.10155, 4.70912, 46.10161, 4.70829, 46.10098, 4.70787,
      46.10094, 4.70691, 46.10086, 4.70671, 46.10104, 4.70642, 46.10102,
      4.70617, 46.10144, 4.70552, 46.10165, 4.70495, 46.10163, 4.70476, 46.1019,
      4.70434, 46.10185, 4.70402, 46.102, 4.70382, 46.1019, 4.70307, 46.10203,
      4.70261, 46.10206, 4.7019, 46.10221, 4.70153, 46.10202, 4.70122, 46.10217,
      4.70064, 46.10225, 4.70071, 46.10235, 4.70058, 46.10229, 4.70048,
      46.10234, 4.70008, 46.10232, 4.69968, 46.10228, 4.69968, 46.10225,
      4.69957, 46.1021, 4.69972, 46.10192, 4.69959, 46.10183, 4.69941, 46.1018,
      4.6991, 46.10214, 4.69854, 46.10334, 4.69771, 46.10413, 4.69677, 46.10602,
      4.69698, 46.10623, 4.69711, 46.10629, 4.69697, 46.10717, 4.69713, 46.1073,
      4.6968, 46.10893, 4.69534, 46.10995, 4.6951, 46.11116, 4.69467, 46.11173,
      4.69474, 46.11189, 4.69372, 46.11318, 4.69377, 46.11459, 4.69537,
      46.11558, 4.69582, 46.11698, 4.69596, 46.11815, 4.69649, 46.11908,
      4.69824, 46.1192, 4.69908, 46.11947, 4.69913, 46.11967, 4.69907, 46.11959,
      4.69872, 46.11969, 4.69867, 46.11962, 4.69832, 46.12293, 4.69719,
      46.12498, 4.6969, 46.12514, 4.69682, 46.12496, 4.6963, 46.12617, 4.69617,
      46.12656, 4.69623, 46.12659, 4.69642, 46.12691, 4.69685, 46.12694,
      4.69713, 46.12593, 4.69795, 46.12579, 4.69827, 46.12583, 4.69848,
      46.12624, 4.699, 46.12998, 4.69953, 46.12992, 4.69833, 46.13005, 4.69789,
      46.13005, 4.69759, 46.12925, 4.69743, 46.12935, 4.69704, 46.12967,
      4.69661, 46.13052, 4.69589, 46.13042, 4.69506, 46.13045, 4.69479,
      46.13122, 4.69388, 46.13222, 4.69315, 46.13332, 4.69081, 46.13376,
      4.69176, 46.13371, 4.69186, 46.13407, 4.69253, 46.13409, 4.69294,
      46.13449, 4.69282, 46.13407, 4.69402, 46.13466, 4.69617, 46.13472,
      4.69705, 46.13731, 4.70012, 46.13856, 4.70019, 46.13956, 4.70114,
      46.14119, 4.70322, 46.14209, 4.70216, 46.14213, 4.70363, 46.14462,
      4.70304, 46.14517, 4.70305, 46.14574, 4.70343, 46.14733, 4.70772,
      46.14406, 4.71195, 46.14371, 4.7127, 46.14263, 4.71434, 46.1402, 4.71525,
      46.13983, 4.71576, 46.13975, 4.71606, 46.13943, 4.7163, 46.13923, 4.71633,
      46.13867, 4.71607, 46.13885, 4.71667, 46.13884, 4.71697, 46.13731, 4.7176,
      46.13729, 4.71879, 46.13747, 4.72114, 46.13724, 4.72158, 46.13677,
      4.72197, 46.13664, 4.72219, 46.13633, 4.72364, 46.13636, 4.72402,
      46.13695, 4.72528, 46.13732, 4.72654, 46.13985, 4.73235, 46.14048,
      4.73258, 46.14062, 4.73272, 46.14142, 4.7345, 46.14152, 4.73505, 46.14168,
      4.73872, 46.14228, 4.74149, 46.14219, 4.74217, 46.14221, 4.74298,
      46.14287, 4.74443, 46.14248, 4.75075, 46.14272, 4.75099, 46.14202,
      4.75528, 46.14209, 4.75453, 46.1419, 4.75441, 46.14172, 4.75304, 46.14158,
      4.75254, 46.14122, 4.75181, 46.14039, 4.75102, 46.1403, 4.75084, 46.14017,
      4.74962, 46.14014, 4.74833, 46.13997, 4.74682, 46.14025, 4.74515,
      46.14002, 4.74505, 46.14006, 4.7441, 46.14084, 4.74269, 46.14097, 4.7427,
      46.14103, 4.74204, 46.13697, 4.74395, 46.13565, 4.74439, 46.13516,
      4.74443, 46.13205, 4.74686, 46.13098, 4.74714, 46.12913, 4.75036,
      46.12876, 4.7503, 46.12696, 4.75083, 46.12595, 4.74976, 46.12511, 4.7523,
      46.12457, 4.75228, 46.12239, 4.75101, 46.12181, 4.75055, 46.11979,
      4.74947, 46.11887, 4.74885, 46.11863, 4.74882, 46.11523, 4.74729,
      46.11182, 4.74999, 46.11169, 4.75111, 46.11154, 4.75137, 46.11125,
      4.75168, 46.10981, 4.75226, 46.10951, 4.75248, 46.10973, 4.7533, 46.11018,
      4.75449, 46.11006, 4.75451, 46.10994, 4.75484, 46.10997, 4.75556,
      46.11007, 4.75617, 46.10987, 4.75669, 46.1091, 4.75802, 46.10811, 4.76008,
      46.10756, 4.76093, 46.10707, 4.76127, 46.10674, 4.76134, 46.10548,
      4.76306, 46.10759, 4.76618, 46.10758, 4.76652, 46.10703, 4.76741
    ]
  ]
};

export const MACHECOUL_SAINT_MEME: LocationState = {
  id: 'FR-44087',
  name: 'Machecoul-Saint-Même',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      46.94783, -1.82537, 46.94899, -1.82583, 46.95035, -1.82675, 46.95118,
      -1.82715, 46.95183, -1.82723, 46.95208, -1.82756, 46.95244, -1.82757,
      46.95292, -1.82798, 46.95317, -1.82874, 46.95328, -1.83034, 46.95364,
      -1.83108, 46.95373, -1.8316, 46.95357, -1.83418, 46.95326, -1.83465,
      46.95319, -1.83497, 46.95318, -1.83554, 46.95343, -1.83602, 46.95367,
      -1.83765, 46.95384, -1.83833, 46.95363, -1.83868, 46.95368, -1.83893,
      46.95363, -1.83983, 46.95374, -1.84011, 46.95353, -1.84054, 46.95393,
      -1.84197, 46.95405, -1.84274, 46.95408, -1.84424, 46.95429, -1.84477,
      46.9544, -1.84531, 46.95437, -1.84578, 46.95445, -1.84651, 46.95428,
      -1.84659, 46.95427, -1.84721, 46.95408, -1.84802, 46.95418, -1.84824,
      46.95413, -1.8487, 46.95445, -1.84967, 46.95438, -1.84999, 46.95441,
      -1.85043, 46.95424, -1.85051, 46.95415, -1.85108, 46.9539, -1.85127,
      46.95365, -1.85183, 46.95368, -1.85232, 46.95322, -1.85373, 46.95305,
      -1.85463, 46.95299, -1.85553, 46.95306, -1.85621, 46.95374, -1.85773,
      46.95399, -1.85875, 46.95397, -1.85932, 46.95424, -1.86032, 46.95393,
      -1.8612, 46.95402, -1.86166, 46.95424, -1.86215, 46.95444, -1.86224,
      46.95459, -1.86245, 46.95465, -1.86487, 46.95463, -1.86524, 46.95442,
      -1.86526, 46.9543, -1.86563, 46.95354, -1.86617, 46.95324, -1.86587,
      46.95259, -1.8656, 46.95215, -1.86582, 46.95197, -1.8658, 46.95198,
      -1.86604, 46.95188, -1.86629, 46.95211, -1.86719, 46.95226, -1.86856,
      46.95258, -1.86905, 46.95289, -1.87025, 46.9533, -1.87114, 46.95322,
      -1.87349, 46.95334, -1.87402, 46.95339, -1.87484, 46.95371, -1.87586,
      46.95367, -1.87698, 46.95372, -1.87726, 46.95426, -1.87782, 46.9543,
      -1.87895, 46.95468, -1.87997, 46.95482, -1.88012, 46.95538, -1.88028,
      46.95558, -1.8807, 46.95568, -1.88133, 46.95606, -1.88153, 46.95647,
      -1.88208, 46.95698, -1.88249, 46.957, -1.88286, 46.95689, -1.88303,
      46.95679, -1.88349, 46.95703, -1.88411, 46.95698, -1.8852, 46.95725,
      -1.88578, 46.95774, -1.88803, 46.95757, -1.88892, 46.95708, -1.88944,
      46.95704, -1.88975, 46.95725, -1.8903, 46.95785, -1.89109, 46.95943,
      -1.89139, 46.95966, -1.89177, 46.9596, -1.89221, 46.95973, -1.89266,
      46.95984, -1.89278, 46.96026, -1.89283, 46.96055, -1.89323, 46.9604,
      -1.89385, 46.96061, -1.89461, 46.96052, -1.89513, 46.96062, -1.89545,
      46.96159, -1.89532, 46.96194, -1.89633, 46.96184, -1.89658, 46.96183,
      -1.89703, 46.96157, -1.89738, 46.96157, -1.89845, 46.96125, -1.89856,
      46.96089, -1.8985, 46.96088, -1.89892, 46.9607, -1.89905, 46.96099,
      -1.89947, 46.96202, -1.89982, 46.96226, -1.89958, 46.9624, -1.89918,
      46.96275, -1.89874, 46.96276, -1.89858, 46.96349, -1.89882, 46.96348,
      -1.89935, 46.96325, -1.90037, 46.96332, -1.90054, 46.96415, -1.90051,
      46.96477, -1.90001, 46.96488, -1.9003, 46.96506, -1.90049, 46.96481,
      -1.90085, 46.96493, -1.90127, 46.96536, -1.90119, 46.96539, -1.90141,
      46.96556, -1.90157, 46.96591, -1.90132, 46.96624, -1.90153, 46.96665,
      -1.90202, 46.96697, -1.9022, 46.96699, -1.90248, 46.96671, -1.90338,
      46.96668, -1.90417, 46.96688, -1.90528, 46.96737, -1.90492, 46.96747,
      -1.90474, 46.96752, -1.90408, 46.96779, -1.90345, 46.96837, -1.90336,
      46.96868, -1.90308, 46.96883, -1.90316, 46.96866, -1.90339, 46.96856,
      -1.90384, 46.96889, -1.90468, 46.96919, -1.90583, 46.96934, -1.90593,
      46.97012, -1.90581, 46.97077, -1.90636, 46.97081, -1.90654, 46.97059,
      -1.90766, 46.96978, -1.90871, 46.96942, -1.90939, 46.9691, -1.90957,
      46.96853, -1.90955, 46.96828, -1.90991, 46.96833, -1.91027, 46.96862,
      -1.91105, 46.9687, -1.9117, 46.96891, -1.91168, 46.96917, -1.91108,
      46.96938, -1.91084, 46.97009, -1.91082, 46.97065, -1.91098, 46.97122,
      -1.91163, 46.97118, -1.9123, 46.97132, -1.91348, 46.97171, -1.91387,
      46.97191, -1.91467, 46.97202, -1.91487, 46.9724, -1.91465, 46.9733,
      -1.91376, 46.97378, -1.91307, 46.97395, -1.91302, 46.97414, -1.91398,
      46.97461, -1.91488, 46.97446, -1.9163, 46.97462, -1.9168, 46.97483,
      -1.91716, 46.9752, -1.91727, 46.97637, -1.91704, 46.97648, -1.91687,
      46.97656, -1.91637, 46.97673, -1.91599, 46.97774, -1.91514, 46.97805,
      -1.91419, 46.97866, -1.91327, 46.97918, -1.91215, 46.9795, -1.91196,
      46.97994, -1.91187, 46.9803, -1.91209, 46.98038, -1.91302, 46.98084,
      -1.91445, 46.98078, -1.91497, 46.98061, -1.91535, 46.97982, -1.91595,
      46.97965, -1.91618, 46.97958, -1.91641, 46.97989, -1.91703, 46.98011,
      -1.9181, 46.98021, -1.91825, 46.98153, -1.91804, 46.98175, -1.91785,
      46.98189, -1.91752, 46.98174, -1.91682, 46.98173, -1.91637, 46.98188,
      -1.9159, 46.98209, -1.91552, 46.98227, -1.91537, 46.98291, -1.91534,
      46.9831, -1.91543, 46.98358, -1.91591, 46.9838, -1.91712, 46.98447,
      -1.9186, 46.98474, -1.91883, 46.98516, -1.91911, 46.98578, -1.91927,
      46.9864, -1.91872, 46.98711, -1.91883, 46.98741, -1.91877, 46.98762,
      -1.91858, 46.98781, -1.91765, 46.98729, -1.91655, 46.98725, -1.91623,
      46.98773, -1.91585, 46.98845, -1.91466, 46.98873, -1.9145, 46.98904,
      -1.91457, 46.98948, -1.91511, 46.99006, -1.91538, 46.99007, -1.91606,
      46.99017, -1.91643, 46.99067, -1.91722, 46.99085, -1.91722, 46.9911,
      -1.91659, 46.99214, -1.91669, 46.99216, -1.91625, 46.99268, -1.9163,
      46.99287, -1.9165, 46.99457, -1.91527, 46.99505, -1.91463, 46.99563,
      -1.9141, 46.99573, -1.91359, 46.99577, -1.91284, 46.9967, -1.91222,
      46.99792, -1.91213, 46.99885, -1.91223, 46.99953, -1.91263, 47.00016,
      -1.91342, 47.00034, -1.91353, 47.00075, -1.91332, 47.00116, -1.9125,
      47.00152, -1.91224, 47.00155, -1.91179, 47.00145, -1.911, 47.00129,
      -1.91048, 47.00096, -1.90983, 47.00124, -1.90804, 47.0016, -1.90729,
      47.00162, -1.90705, 47.00122, -1.9066, 47.00073, -1.90646, 47.00029,
      -1.90612, 47.00021, -1.90588, 47.00012, -1.90486, 46.99971, -1.90415,
      46.99927, -1.90394, 46.99889, -1.90386, 46.9986, -1.90355, 46.99849,
      -1.90293, 46.99854, -1.9022, 46.99793, -1.90131, 46.9978, -1.90089,
      46.99764, -1.89968, 46.99798, -1.8985, 46.99791, -1.89713, 46.99846,
      -1.89651, 46.99867, -1.89582, 46.99841, -1.89489, 46.99787, -1.89466,
      46.99755, -1.89484, 46.99752, -1.89441, 46.99762, -1.89375, 46.99776,
      -1.89348, 46.99806, -1.89373, 46.99826, -1.89359, 46.9987, -1.89373,
      46.99895, -1.89361, 46.99898, -1.89372, 46.9989, -1.89392, 46.99898,
      -1.89422, 46.99941, -1.89404, 46.99971, -1.89406, 47.00043, -1.89283,
      47.00044, -1.89241, 47.00023, -1.89209, 47.00033, -1.8917, 47.00048,
      -1.89169, 47.00059, -1.89201, 47.00065, -1.89168, 47.00093, -1.89146,
      47.0009, -1.89105, 47.00078, -1.89087, 47.00075, -1.89057, 47.00055,
      -1.89026, 47.00033, -1.89025, 47.00019, -1.89, 46.9996, -1.88956,
      46.99906, -1.8896, 46.99899, -1.88953, 46.99888, -1.88896, 46.99921,
      -1.88792, 46.99944, -1.88779, 46.99958, -1.88756, 47.00027, -1.8876,
      47.00044, -1.88791, 47.00089, -1.88768, 47.00104, -1.88742, 47.00121,
      -1.88742, 47.00125, -1.88729, 47.00118, -1.88722, 47.00158, -1.88667,
      47.00161, -1.88645, 47.00185, -1.88619, 47.00232, -1.88603, 47.00241,
      -1.88579, 47.00283, -1.88592, 47.00317, -1.88619, 47.00357, -1.8856,
      47.00301, -1.88426, 47.00258, -1.88385, 47.00191, -1.88366, 47.00159,
      -1.88304, 47.00166, -1.8828, 47.00196, -1.88241, 47.0023, -1.88214,
      47.00266, -1.88162, 47.00272, -1.88108, 47.00256, -1.88061, 47.00228,
      -1.88052, 47.00218, -1.88035, 47.00194, -1.87955, 47.00185, -1.87883,
      47.00156, -1.87807, 47.00118, -1.87745, 47.00114, -1.87676, 47.00187,
      -1.87649, 47.00209, -1.87626, 47.0021, -1.87559, 47.00228, -1.87462,
      47.00228, -1.87268, 47.00244, -1.87136, 47.00257, -1.8709, 47.00381,
      -1.86958, 47.00519, -1.86604, 47.00551, -1.86542, 47.00594, -1.86314,
      47.00597, -1.86168, 47.00557, -1.86077, 47.00535, -1.85922, 47.00519,
      -1.85776, 47.00523, -1.85637, 47.00632, -1.85613, 47.00672, -1.85506,
      47.00667, -1.85473, 47.00727, -1.85441, 47.00737, -1.85415, 47.00792,
      -1.85386, 47.00811, -1.85362, 47.00803, -1.85315, 47.00736, -1.85191,
      47.0072, -1.85117, 47.00723, -1.85039, 47.00758, -1.84956, 47.00851,
      -1.84858, 47.0087, -1.84701, 47.00672, -1.84219, 47.01112, -1.83512,
      47.01361, -1.83636, 47.01399, -1.83607, 47.01422, -1.83577, 47.01443,
      -1.83575, 47.0148, -1.83524, 47.01503, -1.83517, 47.01536, -1.83474,
      47.01598, -1.83362, 47.01641, -1.83316, 47.01729, -1.83307, 47.01774,
      -1.83271, 47.01784, -1.83212, 47.019, -1.83196, 47.01914, -1.83217,
      47.01985, -1.83229, 47.02042, -1.83263, 47.02053, -1.83313, 47.0208,
      -1.83312, 47.02102, -1.8335, 47.02158, -1.83328, 47.02194, -1.83328,
      47.02257, -1.83348, 47.02265, -1.83364, 47.02295, -1.83382, 47.02301,
      -1.83361, 47.02356, -1.83373, 47.0238, -1.83347, 47.02401, -1.83306,
      47.02421, -1.83307, 47.02478, -1.83256, 47.02528, -1.83204, 47.02542,
      -1.83178, 47.02564, -1.8319, 47.02596, -1.83178, 47.02586, -1.83146,
      47.02611, -1.83133, 47.02627, -1.8311, 47.02773, -1.83024, 47.02802,
      -1.82931, 47.02885, -1.82826, 47.02887, -1.82721, 47.0294, -1.82671,
      47.03023, -1.82609, 47.03066, -1.82596, 47.03112, -1.82556, 47.03158,
      -1.82478, 47.03176, -1.82481, 47.03274, -1.82411, 47.03382, -1.82391,
      47.03387, -1.82481, 47.03619, -1.82391, 47.03622, -1.82364, 47.03658,
      -1.82345, 47.03664, -1.82315, 47.03699, -1.82239, 47.03723, -1.82139,
      47.0375, -1.82099, 47.03771, -1.82086, 47.03847, -1.81975, 47.03895,
      -1.82005, 47.04014, -1.81862, 47.04041, -1.81858, 47.04111, -1.81923,
      47.04166, -1.81907, 47.04452, -1.81969, 47.04462, -1.81805, 47.04478,
      -1.81747, 47.04506, -1.81729, 47.04587, -1.81729, 47.04647, -1.81674,
      47.04696, -1.81717, 47.04726, -1.81727, 47.0483, -1.81911, 47.04898,
      -1.81926, 47.04957, -1.81907, 47.05031, -1.81744, 47.05104, -1.81618,
      47.05125, -1.81548, 47.05162, -1.81498, 47.05164, -1.81405, 47.05191,
      -1.8134, 47.05225, -1.81183, 47.05265, -1.81116, 47.05351, -1.8103,
      47.05431, -1.8099, 47.05458, -1.80905, 47.05503, -1.80806, 47.05511,
      -1.80757, 47.05541, -1.80674, 47.05556, -1.80649, 47.05598, -1.80626,
      47.05636, -1.8056, 47.05645, -1.80436, 47.05726, -1.8036, 47.05759,
      -1.80316, 47.05787, -1.80177, 47.05807, -1.80149, 47.05824, -1.80148,
      47.05851, -1.80126, 47.05919, -1.80025, 47.05923, -1.80003, 47.05915,
      -1.79986, 47.05922, -1.79951, 47.05956, -1.79877, 47.06011, -1.79793,
      47.06015, -1.7975, 47.06, -1.79651, 47.05994, -1.79518, 47.06016,
      -1.79495, 47.06014, -1.79415, 47.06121, -1.79427, 47.06237, -1.79424,
      47.0631, -1.79406, 47.06394, -1.79364, 47.06384, -1.79343, 47.06309,
      -1.79265, 47.06308, -1.79243, 47.06295, -1.79215, 47.06201, -1.79067,
      47.06123, -1.78912, 47.06103, -1.78749, 47.06104, -1.78669, 47.06049,
      -1.78683, 47.06003, -1.78603, 47.05871, -1.78481, 47.05679, -1.78391,
      47.05621, -1.78352, 47.05269, -1.78302, 47.05214, -1.78304, 47.05163,
      -1.78348, 47.05108, -1.78228, 47.04981, -1.78036, 47.04942, -1.77956,
      47.04917, -1.77529, 47.04838, -1.77262, 47.04684, -1.77241, 47.04544,
      -1.77278, 47.04534, -1.77262, 47.04528, -1.77192, 47.03935, -1.77607,
      47.03869, -1.77684, 47.03826, -1.77683, 47.03812, -1.77693, 47.03598,
      -1.77377, 47.03555, -1.77283, 47.03484, -1.77263, 47.03475, -1.77241,
      47.03448, -1.77216, 47.03328, -1.77257, 47.03301, -1.77234, 47.03207,
      -1.77203, 47.03076, -1.77206, 47.02953, -1.77252, 47.02835, -1.77273,
      47.02774, -1.77345, 47.02753, -1.77434, 47.02735, -1.77409, 47.02678,
      -1.77428, 47.02681, -1.77393, 47.02662, -1.77382, 47.02651, -1.77396,
      47.02622, -1.77372, 47.02594, -1.77413, 47.0258, -1.77404, 47.0257,
      -1.77309, 47.02505, -1.7728, 47.02428, -1.77272, 47.02419, -1.77212,
      47.024, -1.77172, 47.02397, -1.77124, 47.0238, -1.77112, 47.02342,
      -1.77046, 47.02319, -1.76985, 47.02292, -1.76955, 47.02264, -1.76878,
      47.02265, -1.76851, 47.02285, -1.76796, 47.02271, -1.76754, 47.02271,
      -1.76726, 47.02295, -1.76657, 47.02314, -1.76629, 47.0231, -1.76585,
      47.02321, -1.76576, 47.02348, -1.76582, 47.02341, -1.76525, 47.0233,
      -1.76507, 47.02296, -1.76462, 47.02255, -1.76448, 47.02224, -1.76389,
      47.02223, -1.76323, 47.02233, -1.76239, 47.02261, -1.76122, 47.02324,
      -1.76001, 47.02352, -1.75889, 47.02492, -1.75725, 47.02559, -1.75581,
      47.02625, -1.75626, 47.02695, -1.7558, 47.02746, -1.75619, 47.02794,
      -1.75514, 47.02811, -1.75441, 47.02896, -1.75257, 47.02931, -1.7521,
      47.02904, -1.75156, 47.02913, -1.75137, 47.02988, -1.75077, 47.03037,
      -1.74837, 47.03038, -1.74784, 47.02939, -1.74539, 47.02908, -1.74412,
      47.02901, -1.74352, 47.03039, -1.74006, 47.03175, -1.73901, 47.03242,
      -1.73865, 47.03163, -1.73584, 47.03219, -1.73601, 47.03314, -1.73654,
      47.03367, -1.73335, 47.03457, -1.73372, 47.03509, -1.73315, 47.0359,
      -1.73362, 47.036, -1.73308, 47.03627, -1.73309, 47.03619, -1.73253,
      47.03624, -1.73207, 47.03565, -1.73141, 47.03569, -1.73127, 47.03478,
      -1.7309, 47.03474, -1.72884, 47.03444, -1.72662, 47.03291, -1.72707,
      47.03248, -1.72707, 47.0335, -1.72588, 47.03502, -1.72388, 47.03389,
      -1.72261, 47.03368, -1.72214, 47.03357, -1.7216, 47.0337, -1.72029,
      47.0335, -1.71969, 47.03316, -1.71907, 47.03309, -1.71847, 47.03254,
      -1.71757, 47.03232, -1.7167, 47.03335, -1.71541, 47.03291, -1.71351,
      47.03265, -1.71154, 47.03181, -1.70893, 47.02996, -1.70978, 47.02851,
      -1.71123, 47.02829, -1.71216, 47.02731, -1.71433, 47.02723, -1.71478,
      47.02628, -1.71665, 47.02483, -1.71375, 47.02338, -1.71313, 47.02111,
      -1.71099, 47.01967, -1.70977, 47.01948, -1.70976, 47.01915, -1.70999,
      47.01885, -1.7099, 47.01656, -1.70754, 47.01648, -1.70752, 47.01621,
      -1.70779, 47.01613, -1.70773, 47.0154, -1.70676, 47.015, -1.70556,
      47.01457, -1.70468, 47.01343, -1.70356, 47.01169, -1.70241, 47.01119,
      -1.70182, 47.01131, -1.70129, 47.01062, -1.70026, 47.01006, -1.69862,
      47.00842, -1.69642, 47.0079, -1.69624, 47.00755, -1.69558, 47.00677,
      -1.69475, 47.00574, -1.69336, 47.0051, -1.69614, 47.00473, -1.699,
      47.00454, -1.69994, 47.00375, -1.70288, 47.00369, -1.70379, 47.00341,
      -1.70448, 47.00401, -1.70625, 47.00426, -1.70722, 47.00433, -1.70769,
      47.00427, -1.70788, 47.00409, -1.70804, 47.00414, -1.70818, 47.0048,
      -1.70946, 47.00554, -1.71035, 47.00549, -1.71071, 47.00554, -1.7109,
      47.00564, -1.71089, 47.00576, -1.71072, 47.00583, -1.71077, 47.00597,
      -1.71129, 47.00608, -1.71133, 47.00616, -1.71154, 47.00607, -1.71166,
      47.006, -1.71224, 47.00579, -1.71268, 47.00587, -1.71282, 47.00602,
      -1.71283, 47.00601, -1.71311, 47.00608, -1.71334, 47.00603, -1.71362,
      47.00612, -1.71383, 47.00598, -1.7143, 47.00602, -1.71457, 47.00598,
      -1.71472, 47.00616, -1.71464, 47.00624, -1.71542, 47.00642, -1.71556,
      47.00633, -1.71602, 47.00649, -1.71626, 47.00651, -1.7165, 47.00633,
      -1.71725, 47.00661, -1.71791, 47.00671, -1.71942, 47.00689, -1.72061,
      47.00765, -1.72288, 47.00787, -1.72339, 47.00808, -1.72363, 47.00821,
      -1.7242, 47.00836, -1.72441, 47.00834, -1.72458, 47.00817, -1.72476,
      47.00808, -1.72532, 47.00815, -1.72547, 47.00833, -1.72547, 47.0084,
      -1.72561, 47.00829, -1.72598, 47.00829, -1.72687, 47.00838, -1.72723,
      47.00861, -1.72733, 47.00863, -1.72848, 47.0085, -1.72901, 47.00817,
      -1.72906, 47.00796, -1.72937, 47.00805, -1.72998, 47.00776, -1.73118,
      47.00787, -1.73307, 47.00777, -1.73321, 47.0074, -1.73327, 47.00727,
      -1.73341, 47.00726, -1.73404, 47.00707, -1.73489, 47.00706, -1.73568,
      47.00717, -1.73668, 47.00726, -1.73702, 47.0075, -1.73743, 47.00732,
      -1.73764, 47.00739, -1.73788, 47.00712, -1.73794, 47.00706, -1.73839,
      47.00724, -1.73855, 47.00721, -1.73868, 47.00659, -1.73874, 47.00664,
      -1.74062, 47.0074, -1.74256, 47.00759, -1.74379, 47.00776, -1.74427,
      47.00778, -1.74508, 47.00805, -1.74601, 47.00821, -1.74732, 47.00844,
      -1.74755, 47.00839, -1.74804, 47.00846, -1.74837, 47.00836, -1.74873,
      47.00835, -1.74934, 47.00812, -1.7496, 47.00781, -1.7496, 47.00768,
      -1.75015, 47.00749, -1.7503, 47.00768, -1.75106, 47.00758, -1.7518,
      47.00791, -1.75244, 47.00784, -1.7526, 47.00785, -1.75294, 47.00768,
      -1.75334, 47.00765, -1.75379, 47.00743, -1.75433, 47.00689, -1.7546,
      47.00681, -1.75478, 47.00696, -1.75501, 47.00688, -1.75594, 47.00693,
      -1.75615, 47.00666, -1.75709, 47.00671, -1.75854, 47.00655, -1.75916,
      47.00647, -1.75985, 47.00621, -1.7604, 47.00532, -1.76118, 47.00482,
      -1.76129, 47.00444, -1.76153, 47.00386, -1.76143, 47.00373, -1.7618,
      47.00368, -1.76228, 47.00395, -1.76261, 47.00396, -1.76278, 47.00389,
      -1.76315, 47.00375, -1.76333, 47.00372, -1.76382, 47.00311, -1.7643,
      47.00285, -1.76483, 47.00238, -1.76509, 47.00226, -1.76552, 47.00227,
      -1.76601, 47.0022, -1.76642, 47.00199, -1.76713, 47.0017, -1.76759,
      47.00163, -1.76817, 47.00132, -1.76818, 47.00118, -1.76835, 47.00119,
      -1.76883, 47.00105, -1.76921, 47.00101, -1.76996, 47.00112, -1.77083,
      47.00132, -1.77163, 47.00106, -1.77205, 47.00095, -1.77239, 47.00057,
      -1.77211, 47.00028, -1.77218, 47.0001, -1.77211, 46.99973, -1.77146,
      46.9994, -1.77125, 46.99927, -1.77106, 46.99903, -1.77015, 46.99861,
      -1.77018, 46.99847, -1.76998, 46.99824, -1.76927, 46.99793, -1.76912,
      46.99762, -1.76873, 46.99711, -1.7684, 46.99711, -1.76792, 46.99682,
      -1.76739, 46.997, -1.76673, 46.99689, -1.76632, 46.99701, -1.76573,
      46.99675, -1.76543, 46.99662, -1.76455, 46.99663, -1.76423, 46.99682,
      -1.76355, 46.99611, -1.76398, 46.99498, -1.76421, 46.99354, -1.7648,
      46.99345, -1.76564, 46.99292, -1.76586, 46.99275, -1.76796, 46.99288,
      -1.77336, 46.99277, -1.77503, 46.99218, -1.77803, 46.99167, -1.78126,
      46.99068, -1.78455, 46.99033, -1.78612, 46.98978, -1.78598, 46.98934,
      -1.78573, 46.98741, -1.78574, 46.98701, -1.78593, 46.98606, -1.78688,
      46.98577, -1.78614, 46.9851, -1.78728, 46.98403, -1.78881, 46.98144,
      -1.79182, 46.98092, -1.79269, 46.98076, -1.7939, 46.98012, -1.79505,
      46.97961, -1.79543, 46.97902, -1.79569, 46.97857, -1.79608, 46.97797,
      -1.79728, 46.97646, -1.79959, 46.97547, -1.80045, 46.97443, -1.80109,
      46.9742, -1.80186, 46.97409, -1.8031, 46.97377, -1.80387, 46.97363,
      -1.80499, 46.97186, -1.80466, 46.97075, -1.80424, 46.96954, -1.80415,
      46.96879, -1.80453, 46.9687, -1.80616, 46.96854, -1.80649, 46.96779,
      -1.80679, 46.96763, -1.80723, 46.96738, -1.8067, 46.96701, -1.80663,
      46.96671, -1.80665, 46.96614, -1.80705, 46.96533, -1.80823, 46.96438,
      -1.8099, 46.96362, -1.81001, 46.96283, -1.81035, 46.96086, -1.81079,
      46.96029, -1.81081, 46.95961, -1.81156, 46.95885, -1.81105, 46.95856,
      -1.81106, 46.95792, -1.81138, 46.95674, -1.81164, 46.95438, -1.81285,
      46.95491, -1.81566, 46.95486, -1.81615, 46.95424, -1.81687, 46.95386,
      -1.81676, 46.95246, -1.81803, 46.9498, -1.82072, 46.9497, -1.82084,
      46.94994, -1.82223, 46.94865, -1.82323, 46.94751, -1.82388, 46.94783,
      -1.82537
    ]
  ]
};

export const VALLONS_DE_L_ERDRE: LocationState = {
  id: 'FR-44180',
  name: "Vallons-de-l'Erdre",
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.59665, -1.24342, 47.59658, -1.24117, 47.59632, -1.23855, 47.59481,
      -1.23269, 47.59524, -1.23244, 47.59506, -1.23101, 47.59429, -1.23112,
      47.59409, -1.22995, 47.59368, -1.22845, 47.59356, -1.22722, 47.593,
      -1.22763, 47.59246, -1.22237, 47.59267, -1.22189, 47.59215, -1.22079,
      47.59194, -1.22012, 47.59168, -1.21983, 47.59142, -1.21829, 47.59089,
      -1.21844, 47.59065, -1.21751, 47.59132, -1.21719, 47.59119, -1.21598,
      47.5918, -1.21572, 47.59147, -1.21467, 47.59121, -1.21446, 47.59092,
      -1.21464, 47.5904, -1.21352, 47.59023, -1.21207, 47.59089, -1.20887,
      47.59082, -1.20723, 47.58986, -1.20484, 47.58939, -1.20298, 47.58866,
      -1.20291, 47.58833, -1.20155, 47.58804, -1.19994, 47.588, -1.1994,
      47.58775, -1.19887, 47.58759, -1.19753, 47.58736, -1.19691, 47.58734,
      -1.19619, 47.58752, -1.19576, 47.58739, -1.19514, 47.58751, -1.1945,
      47.58762, -1.19305, 47.58761, -1.19227, 47.58749, -1.19165, 47.58762,
      -1.19045, 47.58758, -1.18929, 47.58715, -1.18912, 47.58668, -1.18919,
      47.58553, -1.1897, 47.58452, -1.19048, 47.58418, -1.19095, 47.5837,
      -1.19099, 47.58347, -1.19122, 47.58308, -1.19117, 47.58244, -1.19134,
      47.58228, -1.19034, 47.58117, -1.18934, 47.58109, -1.18598, 47.58085,
      -1.18474, 47.58056, -1.18415, 47.5804, -1.18361, 47.57998, -1.18292,
      47.57927, -1.18254, 47.57875, -1.18198, 47.57693, -1.18266, 47.57632,
      -1.18195, 47.57569, -1.18031, 47.57525, -1.17862, 47.57369, -1.17519,
      47.57278, -1.17294, 47.57264, -1.17214, 47.57249, -1.17171, 47.57171,
      -1.17056, 47.57104, -1.16756, 47.57129, -1.16512, 47.57133, -1.16392,
      47.57111, -1.16331, 47.57128, -1.15881, 47.57114, -1.15709, 47.5711,
      -1.15416, 47.57117, -1.15142, 47.57085, -1.14909, 47.57068, -1.14464,
      47.57038, -1.14294, 47.57023, -1.14153, 47.57023, -1.13922, 47.57042,
      -1.13698, 47.56997, -1.1321, 47.57003, -1.13155, 47.56987, -1.13036,
      47.5695, -1.12316, 47.56938, -1.12282, 47.56917, -1.1214, 47.56878,
      -1.12075, 47.56826, -1.11909, 47.56775, -1.11799, 47.56688, -1.1149,
      47.56698, -1.11399, 47.56671, -1.11231, 47.56641, -1.11163, 47.5657,
      -1.11078, 47.56516, -1.10924, 47.56513, -1.10803, 47.56527, -1.10478,
      47.56522, -1.10362, 47.56586, -1.10054, 47.56582, -1.09994, 47.56652,
      -1.10022, 47.56736, -1.10087, 47.56743, -1.10148, 47.56784, -1.102,
      47.56792, -1.10148, 47.56839, -1.10055, 47.56884, -1.10034, 47.56964,
      -1.10069, 47.5697, -1.10033, 47.56999, -1.10048, 47.57145, -1.10067,
      47.57257, -1.10049, 47.57273, -1.1008, 47.57314, -1.1009, 47.57399,
      -1.10146, 47.57457, -1.10223, 47.57466, -1.10254, 47.57471, -1.1034,
      47.57502, -1.10389, 47.57519, -1.10657, 47.57534, -1.10703, 47.57527,
      -1.1074, 47.57514, -1.11027, 47.57533, -1.1121, 47.5752, -1.11311,
      47.57531, -1.11439, 47.57521, -1.11472, 47.57526, -1.11558, 47.57498,
      -1.11635, 47.5746, -1.11693, 47.57453, -1.11794, 47.57424, -1.11857,
      47.57418, -1.11893, 47.57448, -1.12027, 47.57445, -1.121, 47.57461,
      -1.12191, 47.57462, -1.12241, 47.57517, -1.12296, 47.57618, -1.12233,
      47.57776, -1.12227, 47.58075, -1.12154, 47.5839, -1.1213, 47.58719,
      -1.11999, 47.59006, -1.11864, 47.592, -1.11759, 47.59929, -1.11619,
      47.60373, -1.11599, 47.60419, -1.11584, 47.60856, -1.11565, 47.60941,
      -1.11591, 47.61081, -1.11594, 47.61204, -1.1187, 47.61259, -1.11967,
      47.61304, -1.12142, 47.61651, -1.12249, 47.61797, -1.1228, 47.61809,
      -1.1231, 47.61846, -1.12328, 47.61879, -1.12361, 47.61922, -1.12379,
      47.62031, -1.12117, 47.62053, -1.12024, 47.62026, -1.1179, 47.61969,
      -1.11466, 47.62018, -1.11217, 47.62075, -1.11034, 47.62091, -1.10892,
      47.62101, -1.10685, 47.62074, -1.10455, 47.62101, -1.10321, 47.62065,
      -1.10174, 47.62066, -1.10075, 47.6204, -1.10039, 47.62017, -1.09964,
      47.61972, -1.09864, 47.61927, -1.09845, 47.61891, -1.09617, 47.61806,
      -1.09614, 47.6169, -1.09341, 47.61608, -1.09187, 47.61145, -1.0919,
      47.61133, -1.09082, 47.61143, -1.09072, 47.61138, -1.09021, 47.61014,
      -1.08712, 47.60925, -1.0852, 47.60921, -1.08281, 47.60877, -1.08105,
      47.60844, -1.08034, 47.60786, -1.07964, 47.60709, -1.07893, 47.60672,
      -1.07821, 47.60656, -1.07763, 47.60621, -1.07488, 47.60593, -1.07407,
      47.60583, -1.07316, 47.60527, -1.0717, 47.60458, -1.07128, 47.60439,
      -1.07065, 47.60469, -1.06831, 47.6046, -1.06744, 47.60432, -1.06697,
      47.60421, -1.06625, 47.60396, -1.06583, 47.60394, -1.06553, 47.60375,
      -1.06501, 47.6034, -1.06437, 47.60341, -1.06408, 47.60306, -1.06192,
      47.60297, -1.06188, 47.60312, -1.06142, 47.60306, -1.0613, 47.60282,
      -1.06116, 47.60285, -1.06062, 47.60255, -1.06066, 47.60248, -1.06039,
      47.60177, -1.05965, 47.60171, -1.05921, 47.60146, -1.05896, 47.60142,
      -1.05845, 47.60151, -1.05763, 47.6015, -1.05738, 47.60139, -1.05722,
      47.60149, -1.05694, 47.60139, -1.05672, 47.60148, -1.05638, 47.60146,
      -1.05507, 47.60168, -1.05323, 47.60155, -1.05242, 47.60172, -1.05222,
      47.60185, -1.05164, 47.60179, -1.05144, 47.60158, -1.05124, 47.60177,
      -1.05103, 47.60173, -1.05035, 47.60161, -1.05031, 47.60175, -1.04992,
      47.60207, -1.04995, 47.60209, -1.04926, 47.60249, -1.04927, 47.60269,
      -1.04864, 47.60327, -1.04764, 47.60326, -1.04568, 47.60335, -1.04325,
      47.60293, -1.04187, 47.60269, -1.04175, 47.60259, -1.04141, 47.60271,
      -1.0403, 47.60243, -1.03862, 47.60237, -1.03666, 47.60208, -1.03627,
      47.60185, -1.03622, 47.60192, -1.0359, 47.60185, -1.03574, 47.6014,
      -1.03559, 47.60138, -1.03548, 47.60099, -1.03537, 47.60096, -1.03524,
      47.60026, -1.03489, 47.5999, -1.03484, 47.59969, -1.03446, 47.59944,
      -1.03433, 47.59951, -1.03364, 47.59913, -1.03354, 47.59913, -1.03343,
      47.59932, -1.03328, 47.59943, -1.03207, 47.59924, -1.03069, 47.59909,
      -1.0305, 47.59918, -1.03024, 47.59911, -1.02977, 47.5993, -1.02928,
      47.59883, -1.02852, 47.5987, -1.02815, 47.59871, -1.02787, 47.59886,
      -1.02779, 47.59897, -1.02757, 47.59897, -1.02726, 47.59915, -1.02716,
      47.59916, -1.02681, 47.59938, -1.02587, 47.5993, -1.02564, 47.59885,
      -1.02514, 47.59789, -1.02462, 47.59756, -1.02411, 47.59726, -1.02381,
      47.59709, -1.02269, 47.59676, -1.02173, 47.59684, -1.02098, 47.59656,
      -1.02071, 47.5957, -1.02049, 47.59542, -1.02015, 47.59519, -1.01927,
      47.59519, -1.01874, 47.59498, -1.01791, 47.59446, -1.01715, 47.59413,
      -1.01543, 47.59331, -1.01257, 47.59332, -1.01042, 47.59219, -1.01017,
      47.5917, -1.00994, 47.59127, -1.00887, 47.59107, -1.00867, 47.59069,
      -1.00856, 47.59044, -1.00891, 47.59025, -1.00886, 47.58981, -1.00825,
      47.58941, -1.00803, 47.58938, -1.00729, 47.58913, -1.00732, 47.58912,
      -1.00685, 47.58881, -1.00679, 47.58868, -1.00708, 47.58818, -1.00732,
      47.58611, -1.00917, 47.58598, -1.0097, 47.58568, -1.01029, 47.58503,
      -1.01307, 47.5846, -1.01346, 47.58408, -1.01432, 47.58388, -1.01432,
      47.58384, -1.01485, 47.58352, -1.01507, 47.58348, -1.01538, 47.58278,
      -1.01594, 47.58249, -1.01646, 47.58187, -1.01712, 47.58125, -1.01662,
      47.58055, -1.01713, 47.58055, -1.0176, 47.58043, -1.01763, 47.5804,
      -1.01776, 47.58004, -1.01762, 47.57995, -1.01787, 47.57946, -1.0176,
      47.57929, -1.01802, 47.57856, -1.01873, 47.57823, -1.01869, 47.57792,
      -1.0192, 47.57766, -1.01908, 47.57672, -1.01914, 47.57673, -1.01998,
      47.57652, -1.02086, 47.57653, -1.02146, 47.57639, -1.022, 47.57641,
      -1.02245, 47.57545, -1.02436, 47.5754, -1.02498, 47.57464, -1.02565,
      47.57451, -1.02621, 47.57407, -1.02632, 47.57408, -1.02671, 47.57391,
      -1.02753, 47.57351, -1.02761, 47.57342, -1.02784, 47.57305, -1.02824,
      47.57307, -1.02902, 47.57323, -1.0297, 47.57317, -1.02987, 47.57273,
      -1.03002, 47.57262, -1.03045, 47.57279, -1.03105, 47.57275, -1.03172,
      47.57247, -1.03255, 47.57225, -1.0327, 47.57207, -1.03319, 47.57199,
      -1.03348, 47.57179, -1.03361, 47.57171, -1.03463, 47.57174, -1.03499,
      47.57158, -1.03516, 47.5715, -1.03561, 47.57136, -1.03585, 47.57134,
      -1.03647, 47.57112, -1.03673, 47.57081, -1.03672, 47.57058, -1.03692,
      47.56999, -1.03793, 47.56992, -1.03878, 47.56969, -1.03894, 47.56985,
      -1.03934, 47.56979, -1.03965, 47.56992, -1.04018, 47.56992, -1.04082,
      47.56923, -1.04232, 47.56916, -1.04265, 47.56876, -1.04281, 47.5687,
      -1.04271, 47.56833, -1.04267, 47.56803, -1.04325, 47.56759, -1.04457,
      47.56729, -1.04434, 47.56611, -1.04234, 47.56528, -1.0415, 47.56479,
      -1.04124, 47.56449, -1.04072, 47.56421, -1.04051, 47.56388, -1.04062,
      47.56348, -1.04102, 47.56266, -1.04135, 47.5623, -1.04187, 47.56341,
      -1.04612, 47.56346, -1.04629, 47.56357, -1.04628, 47.56337, -1.04669,
      47.56412, -1.04887, 47.56412, -1.05021, 47.56532, -1.05556, 47.56434,
      -1.05598, 47.56401, -1.05493, 47.56367, -1.0549, 47.56341, -1.05382,
      47.56037, -1.05421, 47.56017, -1.05352, 47.56011, -1.05279, 47.55908,
      -1.05279, 47.55905, -1.05218, 47.55841, -1.05217, 47.55783, -1.05235,
      47.55787, -1.05277, 47.55764, -1.05279, 47.55762, -1.05356, 47.55738,
      -1.05362, 47.55738, -1.05339, 47.55713, -1.05339, 47.55603, -1.06049,
      47.55517, -1.06002, 47.55405, -1.05989, 47.55412, -1.05899, 47.55153,
      -1.05849, 47.55158, -1.05812, 47.55191, -1.05723, 47.55212, -1.05513,
      47.55215, -1.05387, 47.55241, -1.05391, 47.55243, -1.05228, 47.55218,
      -1.05263, 47.55094, -1.05277, 47.55036, -1.05254, 47.55024, -1.05059,
      47.54885, -1.05073, 47.54777, -1.05137, 47.5474, -1.05082, 47.54722,
      -1.05034, 47.54759, -1.04865, 47.54787, -1.04587, 47.5486, -1.04509,
      47.5472, -1.04431, 47.54859, -1.04185, 47.54888, -1.0415, 47.54881,
      -1.0408, 47.5487, -1.04057, 47.54917, -1.03906, 47.54827, -1.0387,
      47.54821, -1.03895, 47.54752, -1.03869, 47.54749, -1.03855, 47.54462,
      -1.03809, 47.54456, -1.03831, 47.54441, -1.03839, 47.54435, -1.03908,
      47.54372, -1.03903, 47.54359, -1.04036, 47.54278, -1.04054, 47.54246,
      -1.03997, 47.54054, -1.03874, 47.54024, -1.03962, 47.53949, -1.03902,
      47.53867, -1.03895, 47.53847, -1.0388, 47.53843, -1.03808, 47.5369,
      -1.03788, 47.53534, -1.03823, 47.53555, -1.03915, 47.53442, -1.03968,
      47.53425, -1.03841, 47.53363, -1.03851, 47.53206, -1.03902, 47.532,
      -1.0384, 47.53082, -1.03835, 47.53077, -1.03918, 47.52875, -1.03958,
      47.52887, -1.03983, 47.52663, -1.03996, 47.52654, -1.03946, 47.52656,
      -1.03915, 47.5264, -1.03929, 47.52559, -1.03935, 47.52557, -1.0389,
      47.52514, -1.03932, 47.52526, -1.04044, 47.5252, -1.04084, 47.52465,
      -1.04082, 47.5246, -1.04225, 47.52403, -1.04238, 47.52239, -1.04216,
      47.51973, -1.04206, 47.51983, -1.043, 47.5155, -1.04482, 47.51502,
      -1.0449, 47.51237, -1.04595, 47.51158, -1.04563, 47.51008, -1.04532,
      47.50848, -1.0448, 47.50557, -1.04446, 47.50548, -1.04751, 47.50529,
      -1.04931, 47.50497, -1.05078, 47.50492, -1.05076, 47.50457, -1.05179,
      47.50421, -1.05303, 47.50403, -1.05591, 47.50405, -1.05801, 47.50389,
      -1.06061, 47.50354, -1.06259, 47.50323, -1.06357, 47.50275, -1.06599,
      47.50266, -1.06703, 47.50267, -1.06876, 47.5024, -1.06991, 47.50233,
      -1.07089, 47.50199, -1.07199, 47.50173, -1.07254, 47.50134, -1.07259,
      47.50052, -1.07334, 47.49894, -1.07417, 47.49837, -1.07421, 47.49799,
      -1.07408, 47.49703, -1.07404, 47.49658, -1.07462, 47.49586, -1.07523,
      47.49472, -1.07683, 47.49355, -1.07749, 47.49223, -1.07801, 47.49065,
      -1.07834, 47.48987, -1.07889, 47.4889, -1.07937, 47.48805, -1.07933,
      47.48723, -1.07909, 47.48477, -1.0789, 47.48448, -1.07896, 47.48347,
      -1.07866, 47.48196, -1.07844, 47.48135, -1.07819, 47.48085, -1.07817,
      47.48076, -1.07842, 47.47986, -1.07851, 47.48, -1.07881, 47.47997,
      -1.08008, 47.48024, -1.0814, 47.48001, -1.08206, 47.48022, -1.08223,
      47.47997, -1.08275, 47.4801, -1.08317, 47.47975, -1.08402, 47.47972,
      -1.0842, 47.47985, -1.08458, 47.47957, -1.08498, 47.47965, -1.08537,
      47.47922, -1.0856, 47.47905, -1.08592, 47.47898, -1.0863, 47.47926,
      -1.08708, 47.47959, -1.08729, 47.47982, -1.08773, 47.47976, -1.0883,
      47.47983, -1.08852, 47.47975, -1.0888, 47.47982, -1.08904, 47.47978,
      -1.0894, 47.47982, -1.09005, 47.47999, -1.09118, 47.4802, -1.09161,
      47.47881, -1.09039, 47.47572, -1.0918, 47.47568, -1.09117, 47.47309,
      -1.09275, 47.47224, -1.09468, 47.47153, -1.09531, 47.47143, -1.09528,
      47.47001, -1.0961, 47.4689, -1.09719, 47.46801, -1.09782, 47.46738,
      -1.09867, 47.46632, -1.10042, 47.46221, -1.10351, 47.46207, -1.10378,
      47.46197, -1.10383, 47.46207, -1.10403, 47.46201, -1.10414, 47.45936,
      -1.10692, 47.45867, -1.1073, 47.45799, -1.10718, 47.45809, -1.10765,
      47.45747, -1.10822, 47.4572, -1.10797, 47.45705, -1.10807, 47.45685,
      -1.10857, 47.45636, -1.1092, 47.456, -1.10811, 47.45558, -1.10838,
      47.45571, -1.10861, 47.45419, -1.1096, 47.45119, -1.11433, 47.45054,
      -1.11508, 47.4501, -1.11602, 47.44977, -1.11701, 47.44949, -1.118,
      47.44884, -1.11893, 47.44869, -1.11981, 47.45045, -1.12044, 47.45031,
      -1.12061, 47.4532, -1.12125, 47.45392, -1.1218, 47.45545, -1.12234,
      47.4565, -1.12402, 47.45711, -1.12461, 47.45732, -1.12511, 47.45757,
      -1.12624, 47.45771, -1.12765, 47.45765, -1.12981, 47.45781, -1.12998,
      47.45797, -1.13056, 47.45814, -1.13068, 47.45829, -1.13124, 47.45843,
      -1.13134, 47.4584, -1.13155, 47.45848, -1.13174, 47.45847, -1.13203,
      47.45863, -1.13213, 47.45865, -1.1324, 47.45887, -1.13287, 47.45916,
      -1.13454, 47.45929, -1.13473, 47.45979, -1.135, 47.45996, -1.13532,
      47.45999, -1.13606, 47.46022, -1.13677, 47.46005, -1.13771, 47.46011,
      -1.13882, 47.45991, -1.13914, 47.45973, -1.14085, 47.45949, -1.14103,
      47.45938, -1.14138, 47.45984, -1.14288, 47.45969, -1.14311, 47.45973,
      -1.14345, 47.45951, -1.14429, 47.45948, -1.1447, 47.45955, -1.14497,
      47.45939, -1.14532, 47.45935, -1.14611, 47.45959, -1.1461, 47.45964,
      -1.14675, 47.45947, -1.14673, 47.45958, -1.1481, 47.45952, -1.14824,
      47.45973, -1.14878, 47.4603, -1.14962, 47.46064, -1.14939, 47.4608,
      -1.14966, 47.46098, -1.14972, 47.46118, -1.14934, 47.46146, -1.14917,
      47.46167, -1.14944, 47.46188, -1.14945, 47.462, -1.14933, 47.46226,
      -1.14859, 47.46239, -1.14845, 47.46265, -1.1487, 47.46311, -1.14873,
      47.46322, -1.14854, 47.46302, -1.14828, 47.4631, -1.14781, 47.4635,
      -1.14793, 47.46368, -1.14757, 47.46392, -1.14747, 47.46397, -1.14703,
      47.46455, -1.14682, 47.4658, -1.14736, 47.46586, -1.14749, 47.46569,
      -1.14757, 47.46564, -1.14769, 47.46575, -1.14804, 47.46559, -1.14822,
      47.46565, -1.14837, 47.46559, -1.14862, 47.46566, -1.14884, 47.46584,
      -1.149, 47.46597, -1.14868, 47.46605, -1.14868, 47.4662, -1.14906,
      47.46671, -1.14946, 47.46698, -1.14949, 47.4671, -1.14926, 47.46757,
      -1.14909, 47.46779, -1.14879, 47.4679, -1.14833, 47.46798, -1.14833,
      47.46809, -1.1486, 47.46829, -1.14859, 47.46874, -1.14816, 47.46888,
      -1.14782, 47.46899, -1.14779, 47.46935, -1.14844, 47.46964, -1.14863,
      47.46978, -1.14914, 47.46992, -1.14922, 47.47004, -1.14901, 47.47018,
      -1.149, 47.4704, -1.14943, 47.4706, -1.14948, 47.47065, -1.14983,
      47.47105, -1.15035, 47.47153, -1.15053, 47.47232, -1.15039, 47.47269,
      -1.15059, 47.47307, -1.15043, 47.47328, -1.15003, 47.4739, -1.14659,
      47.47414, -1.14648, 47.47411, -1.14669, 47.47661, -1.14761, 47.47806,
      -1.14892, 47.47844, -1.14915, 47.47893, -1.14931, 47.47962, -1.14972,
      47.47994, -1.14932, 47.48106, -1.1498, 47.48292, -1.15091, 47.48337,
      -1.15101, 47.48427, -1.15088, 47.48442, -1.15078, 47.48578, -1.15076,
      47.48672, -1.15026, 47.48723, -1.15013, 47.48817, -1.15041, 47.4878,
      -1.15249, 47.48757, -1.15328, 47.48774, -1.15604, 47.4895, -1.15597,
      47.49147, -1.15535, 47.49492, -1.15499, 47.49594, -1.15464, 47.49685,
      -1.15409, 47.49744, -1.15393, 47.49994, -1.15385, 47.50031, -1.15386,
      47.50135, -1.15457, 47.50475, -1.15579, 47.50495, -1.15795, 47.50512,
      -1.16195, 47.50526, -1.16254, 47.50579, -1.16369, 47.50661, -1.16678,
      47.50672, -1.16749, 47.50669, -1.16937, 47.50632, -1.17094, 47.50625,
      -1.17357, 47.50604, -1.17518, 47.50521, -1.17636, 47.50497, -1.17809,
      47.50497, -1.17834, 47.50535, -1.17947, 47.50524, -1.18094, 47.50552,
      -1.1835, 47.50552, -1.18544, 47.50508, -1.18936, 47.50474, -1.19162,
      47.50483, -1.19238, 47.50477, -1.19319, 47.5046, -1.19356, 47.50468,
      -1.19394, 47.50467, -1.19442, 47.50396, -1.19437, 47.50378, -1.19457,
      47.50358, -1.19556, 47.50346, -1.19749, 47.50398, -1.19989, 47.50398,
      -1.20206, 47.50437, -1.20249, 47.50468, -1.20361, 47.50503, -1.20533,
      47.50525, -1.20723, 47.5056, -1.20887, 47.50605, -1.20995, 47.50634,
      -1.21013, 47.50639, -1.2107, 47.50629, -1.21191, 47.50643, -1.21253,
      47.50636, -1.2126, 47.50698, -1.21681, 47.50791, -1.22652, 47.50804,
      -1.22954, 47.50799, -1.23326, 47.50807, -1.23506, 47.50799, -1.2365,
      47.50754, -1.2417, 47.5074, -1.24225, 47.50705, -1.24498, 47.50685,
      -1.24493, 47.50699, -1.24643, 47.50731, -1.2473, 47.50748, -1.24737,
      47.50759, -1.24761, 47.50743, -1.2486, 47.50686, -1.25057, 47.50726,
      -1.25157, 47.50751, -1.25291, 47.50812, -1.25396, 47.50868, -1.25469,
      47.50859, -1.25482, 47.50915, -1.25566, 47.51005, -1.25796, 47.51067,
      -1.25896, 47.51164, -1.26165, 47.51202, -1.26384, 47.51353, -1.2651,
      47.51412, -1.26603, 47.51423, -1.26645, 47.51462, -1.2672, 47.51474,
      -1.26814, 47.51518, -1.2689, 47.51523, -1.26922, 47.51511, -1.26961,
      47.5151, -1.27001, 47.51544, -1.27089, 47.51555, -1.27163, 47.51576,
      -1.27204, 47.51594, -1.27219, 47.51589, -1.27229, 47.51605, -1.27242,
      47.51609, -1.27284, 47.51659, -1.27365, 47.51672, -1.27344, 47.51717,
      -1.27317, 47.51747, -1.27281, 47.51852, -1.27221, 47.519, -1.27241,
      47.51964, -1.27199, 47.52015, -1.27183, 47.52088, -1.27121, 47.52125,
      -1.27075, 47.52138, -1.26967, 47.52174, -1.26911, 47.52206, -1.26809,
      47.52202, -1.26768, 47.52159, -1.26758, 47.5214, -1.26711, 47.52142,
      -1.2653, 47.52269, -1.2655, 47.52385, -1.26583, 47.52397, -1.26627,
      47.52397, -1.26703, 47.52422, -1.26796, 47.52425, -1.2684, 47.52488,
      -1.26857, 47.52525, -1.26879, 47.52542, -1.26907, 47.5255, -1.26949,
      47.52544, -1.27033, 47.52576, -1.27056, 47.52646, -1.27081, 47.52693,
      -1.27086, 47.52817, -1.27048, 47.52849, -1.27049, 47.52943, -1.27155,
      47.52961, -1.27163, 47.53018, -1.27106, 47.53118, -1.27045, 47.53189,
      -1.27025, 47.53265, -1.27036, 47.53753, -1.26263, 47.54315, -1.25397,
      47.54365, -1.25583, 47.54519, -1.25945, 47.54522, -1.26026, 47.54474,
      -1.26078, 47.54497, -1.26185, 47.5452, -1.26184, 47.54588, -1.26126,
      47.54662, -1.2639, 47.5468, -1.26405, 47.54719, -1.26371, 47.54782,
      -1.26359, 47.54809, -1.26386, 47.54871, -1.26504, 47.54873, -1.26528,
      47.54894, -1.26585, 47.54965, -1.26762, 47.5505, -1.26935, 47.55133,
      -1.27167, 47.55168, -1.27329, 47.55165, -1.27415, 47.55214, -1.27445,
      47.55235, -1.27499, 47.55266, -1.27521, 47.55346, -1.27533, 47.55502,
      -1.27597, 47.5603, -1.27078, 47.5617, -1.26859, 47.56188, -1.26812,
      47.56235, -1.26762, 47.56382, -1.26666, 47.56503, -1.26515, 47.56577,
      -1.26453, 47.56661, -1.26366, 47.56726, -1.26317, 47.56797, -1.26198,
      47.56909, -1.26123, 47.56928, -1.26119, 47.5696, -1.26201, 47.56992,
      -1.26223, 47.57135, -1.26194, 47.57222, -1.26213, 47.57329, -1.26272,
      47.57369, -1.26269, 47.57637, -1.26178, 47.57663, -1.26143, 47.57689,
      -1.26084, 47.57746, -1.26006, 47.57706, -1.2564, 47.57706, -1.25563,
      47.57929, -1.2554, 47.58041, -1.25468, 47.5805, -1.25443, 47.58276,
      -1.25345, 47.58499, -1.25242, 47.5861, -1.25153, 47.58666, -1.25123,
      47.58835, -1.25164, 47.58936, -1.25172, 47.58947, -1.25243, 47.59055,
      -1.25383, 47.59205, -1.2534, 47.5946, -1.25244, 47.59476, -1.25159,
      47.59521, -1.25162, 47.59532, -1.25109, 47.59529, -1.24909, 47.59549,
      -1.24851, 47.59546, -1.24784, 47.59518, -1.24696, 47.59518, -1.24676,
      47.59665, -1.24601, 47.59665, -1.24342
    ]
  ]
};

export const LOIREAUXENCE: LocationState = {
  id: 'FR-44213',
  name: 'Loireauxence',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.49158, -0.9655, 47.48964, -0.96482, 47.48729, -0.96341, 47.48514,
      -0.96241, 47.48323, -0.96058, 47.48121, -0.95892, 47.4804, -0.96011,
      47.47981, -0.96144, 47.47936, -0.96207, 47.47815, -0.96342, 47.4775,
      -0.96432, 47.47595, -0.96605, 47.47444, -0.96737, 47.47412, -0.96752,
      47.47358, -0.96751, 47.47237, -0.96736, 47.47139, -0.96758, 47.47051,
      -0.96815, 47.47016, -0.96826, 47.46859, -0.96801, 47.46799, -0.9678,
      47.46802, -0.96743, 47.46838, -0.96685, 47.46829, -0.96648, 47.46677,
      -0.96613, 47.46562, -0.96521, 47.46523, -0.96504, 47.46447, -0.96434,
      47.46349, -0.96292, 47.4619, -0.96116, 47.46092, -0.9605, 47.45957,
      -0.96009, 47.45934, -0.95986, 47.45898, -0.95981, 47.4584, -0.95946,
      47.45795, -0.95889, 47.45683, -0.95708, 47.45589, -0.95585, 47.45553,
      -0.95556, 47.45353, -0.95453, 47.45223, -0.9529, 47.45195, -0.95268,
      47.45083, -0.95253, 47.44988, -0.95285, 47.44908, -0.95278, 47.44264,
      -0.95387, 47.44267, -0.95412, 47.44062, -0.95374, 47.44, -0.95344,
      47.43994, -0.95355, 47.43976, -0.9535, 47.43869, -0.95309, 47.43841,
      -0.95282, 47.43694, -0.95285, 47.4357, -0.95306, 47.43398, -0.95255,
      47.43354, -0.95216, 47.43243, -0.95029, 47.43068, -0.94887, 47.42967,
      -0.94739, 47.42916, -0.94697, 47.42807, -0.94674, 47.42766, -0.94675,
      47.42684, -0.94722, 47.42588, -0.94812, 47.42472, -0.94846, 47.42267,
      -0.94831, 47.42303, -0.95024, 47.42357, -0.95151, 47.42381, -0.95262,
      47.42414, -0.95357, 47.42319, -0.95438, 47.42298, -0.95522, 47.42381,
      -0.95601, 47.42347, -0.95673, 47.4233, -0.95761, 47.42315, -0.95754,
      47.42306, -0.95925, 47.42322, -0.95934, 47.42298, -0.96115, 47.42301,
      -0.96204, 47.42313, -0.96258, 47.42348, -0.96315, 47.42374, -0.96333,
      47.42389, -0.96425, 47.42368, -0.96564, 47.42362, -0.96683, 47.42357,
      -0.96707, 47.42238, -0.9657, 47.42208, -0.96669, 47.42191, -0.96817,
      47.422, -0.96979, 47.42237, -0.97163, 47.42242, -0.97171, 47.42255,
      -0.97165, 47.42271, -0.97211, 47.4231, -0.974, 47.42339, -0.97435,
      47.42402, -0.97479, 47.42433, -0.97516, 47.4244, -0.97529, 47.42439,
      -0.97557, 47.42416, -0.97743, 47.42388, -0.97854, 47.42376, -0.97882,
      47.4236, -0.97893, 47.42356, -0.97928, 47.42334, -0.97951, 47.42281,
      -0.97968, 47.42201, -0.98046, 47.42137, -0.98125, 47.4218, -0.98186,
      47.4225, -0.98251, 47.42276, -0.9829, 47.42307, -0.98354, 47.42291,
      -0.98415, 47.423, -0.98441, 47.42295, -0.98462, 47.42323, -0.98508,
      47.42329, -0.98513, 47.42343, -0.98498, 47.42418, -0.98501, 47.42468,
      -0.98575, 47.42561, -0.98663, 47.42585, -0.98709, 47.42625, -0.98857,
      47.42629, -0.99004, 47.42654, -0.99121, 47.42673, -0.9917, 47.42676,
      -0.99213, 47.42754, -0.99365, 47.428, -0.99502, 47.42721, -0.99535,
      47.42606, -0.9954, 47.42552, -0.99544, 47.42508, -0.99519, 47.42391,
      -0.995, 47.42314, -0.99521, 47.42185, -0.99538, 47.41972, -0.99536,
      47.41819, -0.99613, 47.4184, -0.99771, 47.41774, -0.99908, 47.41746,
      -0.99925, 47.41551, -0.99847, 47.415, -0.998, 47.41426, -0.99785,
      47.41311, -0.99692, 47.4097, -0.99574, 47.40942, -0.99583, 47.40816,
      -0.99538, 47.40698, -0.99597, 47.4066, -0.99593, 47.40502, -0.99526,
      47.4045, -0.9949, 47.40435, -0.99461, 47.40288, -0.99373, 47.40181,
      -0.99323, 47.40142, -0.99329, 47.40082, -0.9931, 47.39919, -0.99217,
      47.39914, -0.99179, 47.39862, -0.99117, 47.39805, -0.9898, 47.3984,
      -0.98972, 47.39838, -0.9896, 47.39795, -0.98954, 47.39745, -0.9889,
      47.39626, -0.98822, 47.39642, -0.98704, 47.39513, -0.98721, 47.39412,
      -0.98676, 47.39375, -0.98611, 47.39249, -0.98591, 47.39123, -0.98599,
      47.3911, -0.9846, 47.39087, -0.98402, 47.39057, -0.98369, 47.38963,
      -0.98303, 47.38944, -0.98297, 47.38918, -0.98305, 47.38909, -0.98289,
      47.38835, -0.98283, 47.38823, -0.98161, 47.38827, -0.98046, 47.38801,
      -0.97874, 47.38827, -0.97684, 47.38718, -0.97645, 47.38697, -0.97575,
      47.38596, -0.97533, 47.38577, -0.97536, 47.38419, -0.97499, 47.38328,
      -0.97468, 47.38267, -0.97435, 47.38232, -0.97633, 47.38227, -0.97765,
      47.38155, -0.97824, 47.38108, -0.97849, 47.38074, -0.97883, 47.37979,
      -0.9789, 47.37937, -0.9801, 47.37892, -0.98045, 47.37867, -0.98108,
      47.37574, -0.98044, 47.37599, -0.97838, 47.37664, -0.9747, 47.37717,
      -0.97088, 47.37651, -0.96812, 47.37473, -0.96938, 47.37399, -0.97016,
      47.37295, -0.97159, 47.37218, -0.97279, 47.37178, -0.97358, 47.37134,
      -0.97486, 47.37116, -0.97596, 47.37095, -0.97706, 47.37071, -0.97966,
      47.37081, -0.98098, 47.37123, -0.98398, 47.37134, -0.9853, 47.37101,
      -0.98795, 47.37147, -0.98887, 47.37197, -0.99014, 47.37244, -0.99058,
      47.37284, -0.9912, 47.3729, -0.9915, 47.37286, -0.99226, 47.37248,
      -0.99301, 47.37208, -0.99344, 47.37095, -0.99388, 47.37083, -0.99368,
      47.37013, -0.99477, 47.36973, -0.99572, 47.3696, -0.99624, 47.36949,
      -0.99726, 47.36971, -0.99904, 47.36933, -1.00059, 47.36867, -1.0021,
      47.3687, -1.00286, 47.36883, -1.00329, 47.36911, -1.00386, 47.36916,
      -1.0042, 47.36916, -1.00518, 47.36904, -1.00593, 47.36876, -1.0068,
      47.36831, -1.00761, 47.36635, -1.0105, 47.36645, -1.01066, 47.3661,
      -1.01136, 47.36567, -1.01257, 47.3653, -1.01409, 47.3651, -1.01535,
      47.365, -1.01654, 47.36542, -1.02066, 47.3654, -1.02461, 47.36558,
      -1.02887, 47.36504, -1.03259, 47.36487, -1.04138, 47.36506, -1.04322,
      47.36537, -1.04519, 47.36549, -1.04582, 47.36598, -1.04728, 47.3669,
      -1.0507, 47.36902, -1.05594, 47.36945, -1.05757, 47.37038, -1.06365,
      47.37269, -1.0639, 47.37312, -1.06407, 47.37312, -1.06399, 47.37348,
      -1.06395, 47.37436, -1.06393, 47.378, -1.06465, 47.37866, -1.06466,
      47.37889, -1.06471, 47.37984, -1.0654, 47.3811, -1.06486, 47.38119,
      -1.06538, 47.38251, -1.06458, 47.38818, -1.06847, 47.38807, -1.06898,
      47.38847, -1.06867, 47.38865, -1.0691, 47.38921, -1.06899, 47.38933,
      -1.06932, 47.38981, -1.06881, 47.39065, -1.06816, 47.39079, -1.06792,
      47.39107, -1.06792, 47.39127, -1.06779, 47.39254, -1.06631, 47.39367,
      -1.06675, 47.39392, -1.06655, 47.39415, -1.06698, 47.39501, -1.06784,
      47.39499, -1.06795, 47.39509, -1.06805, 47.39574, -1.06823, 47.396,
      -1.06683, 47.39623, -1.06673, 47.39643, -1.06636, 47.39687, -1.06637,
      47.39734, -1.06615, 47.39757, -1.06598, 47.39793, -1.06593, 47.40017,
      -1.0667, 47.40164, -1.06786, 47.40262, -1.06612, 47.40223, -1.06538,
      47.40132, -1.06413, 47.40107, -1.06357, 47.40244, -1.06243, 47.40368,
      -1.06162, 47.40366, -1.06195, 47.40439, -1.06189, 47.40511, -1.06199,
      47.40613, -1.06131, 47.40604, -1.06024, 47.40614, -1.05977, 47.40722,
      -1.05915, 47.40801, -1.05825, 47.4088, -1.05777, 47.40974, -1.0577,
      47.4104, -1.05788, 47.41088, -1.05785, 47.41114, -1.05699, 47.41173,
      -1.05649, 47.41217, -1.05632, 47.41341, -1.05592, 47.4149, -1.05608,
      47.4153, -1.0563, 47.41656, -1.05603, 47.41706, -1.05581, 47.41809,
      -1.05462, 47.41891, -1.05414, 47.42023, -1.05389, 47.42066, -1.05355,
      47.42096, -1.05349, 47.4216, -1.05354, 47.42208, -1.05374, 47.42216,
      -1.05278, 47.4229, -1.05303, 47.42401, -1.05164, 47.42432, -1.05113,
      47.42486, -1.04991, 47.42484, -1.05005, 47.42497, -1.05033, 47.42547,
      -1.05069, 47.4259, -1.05214, 47.42614, -1.0543, 47.42595, -1.05635,
      47.42567, -1.05834, 47.42535, -1.05933, 47.42515, -1.05936, 47.42501,
      -1.05967, 47.42503, -1.05978, 47.42476, -1.06056, 47.4245, -1.06377,
      47.42498, -1.06449, 47.42497, -1.06504, 47.42506, -1.06517, 47.42544,
      -1.06528, 47.42597, -1.06613, 47.4262, -1.06708, 47.42607, -1.06717,
      47.4264, -1.06842, 47.42648, -1.06841, 47.42649, -1.0683, 47.42691,
      -1.06816, 47.42726, -1.06897, 47.42788, -1.0708, 47.42778, -1.071,
      47.42786, -1.07178, 47.42779, -1.07215, 47.42795, -1.07298, 47.42837,
      -1.0734, 47.4285, -1.07368, 47.42825, -1.07749, 47.42857, -1.07777,
      47.42936, -1.07818, 47.43058, -1.08017, 47.43107, -1.0807, 47.43187,
      -1.08127, 47.43215, -1.08159, 47.43272, -1.08248, 47.43323, -1.08385,
      47.43408, -1.08533, 47.43464, -1.08688, 47.43499, -1.08748, 47.43501,
      -1.08812, 47.43515, -1.08871, 47.43467, -1.08893, 47.43483, -1.08985,
      47.43469, -1.09267, 47.43483, -1.09272, 47.43549, -1.0967, 47.43544,
      -1.09754, 47.43551, -1.09874, 47.43597, -1.10011, 47.43679, -1.10202,
      47.43706, -1.10231, 47.43737, -1.10289, 47.43744, -1.10376, 47.43789,
      -1.10503, 47.43802, -1.10564, 47.43801, -1.10587, 47.43739, -1.10798,
      47.43746, -1.10824, 47.4372, -1.1084, 47.43711, -1.10859, 47.43706,
      -1.1096, 47.43699, -1.10979, 47.43712, -1.11035, 47.43726, -1.11061,
      47.43724, -1.1108, 47.43686, -1.11194, 47.43672, -1.11185, 47.4366,
      -1.11203, 47.43634, -1.11181, 47.43619, -1.11197, 47.43584, -1.11324,
      47.43598, -1.11341, 47.43613, -1.11397, 47.43578, -1.1143, 47.43603,
      -1.11436, 47.43649, -1.1147, 47.43697, -1.11483, 47.43767, -1.1149,
      47.43766, -1.11454, 47.43842, -1.1148, 47.43852, -1.11384, 47.4401,
      -1.11492, 47.44015, -1.11409, 47.43998, -1.11393, 47.43999, -1.11268,
      47.44037, -1.11264, 47.44114, -1.11125, 47.44129, -1.11022, 47.44156,
      -1.11042, 47.44266, -1.1121, 47.44316, -1.11315, 47.44362, -1.11387,
      47.44399, -1.11524, 47.44457, -1.11669, 47.44473, -1.11611, 47.4475,
      -1.11273, 47.44805, -1.11307, 47.44904, -1.11338, 47.44867, -1.11683,
      47.44977, -1.11701, 47.4501, -1.11602, 47.45054, -1.11508, 47.45119,
      -1.11433, 47.45419, -1.1096, 47.45571, -1.10861, 47.45558, -1.10838,
      47.456, -1.10811, 47.45636, -1.1092, 47.45685, -1.10857, 47.45705,
      -1.10807, 47.4572, -1.10797, 47.45747, -1.10822, 47.45809, -1.10765,
      47.45799, -1.10718, 47.45867, -1.1073, 47.45936, -1.10692, 47.46201,
      -1.10414, 47.46207, -1.10403, 47.46197, -1.10383, 47.46207, -1.10378,
      47.46221, -1.10351, 47.46632, -1.10042, 47.46738, -1.09867, 47.46801,
      -1.09782, 47.4689, -1.09719, 47.47001, -1.0961, 47.47143, -1.09528,
      47.47153, -1.09531, 47.47224, -1.09468, 47.47309, -1.09275, 47.47568,
      -1.09117, 47.47572, -1.0918, 47.47881, -1.09039, 47.4802, -1.09161,
      47.47999, -1.09118, 47.47982, -1.09005, 47.47978, -1.0894, 47.47982,
      -1.08904, 47.47975, -1.0888, 47.47983, -1.08852, 47.47976, -1.0883,
      47.47982, -1.08773, 47.47959, -1.08729, 47.47926, -1.08708, 47.47898,
      -1.0863, 47.47905, -1.08592, 47.47922, -1.0856, 47.47965, -1.08537,
      47.47957, -1.08498, 47.47985, -1.08458, 47.47972, -1.0842, 47.47975,
      -1.08402, 47.4801, -1.08317, 47.47997, -1.08275, 47.48022, -1.08223,
      47.48001, -1.08206, 47.48024, -1.0814, 47.47997, -1.08008, 47.48,
      -1.07881, 47.47986, -1.07851, 47.48076, -1.07842, 47.48085, -1.07817,
      47.48135, -1.07819, 47.48196, -1.07844, 47.48347, -1.07866, 47.48448,
      -1.07896, 47.48477, -1.0789, 47.48723, -1.07909, 47.48805, -1.07933,
      47.4889, -1.07937, 47.48987, -1.07889, 47.49065, -1.07834, 47.49223,
      -1.07801, 47.49355, -1.07749, 47.49472, -1.07683, 47.49586, -1.07523,
      47.49658, -1.07462, 47.49703, -1.07404, 47.49799, -1.07408, 47.49837,
      -1.07421, 47.49894, -1.07417, 47.50052, -1.07334, 47.50134, -1.07259,
      47.50173, -1.07254, 47.50199, -1.07199, 47.50233, -1.07089, 47.5024,
      -1.06991, 47.50267, -1.06876, 47.50266, -1.06703, 47.50275, -1.06599,
      47.50323, -1.06357, 47.50354, -1.06259, 47.50389, -1.06061, 47.50405,
      -1.05801, 47.50403, -1.05591, 47.50421, -1.05303, 47.50457, -1.05179,
      47.50492, -1.05076, 47.50497, -1.05078, 47.50529, -1.04931, 47.50548,
      -1.04751, 47.50557, -1.04446, 47.50546, -1.04015, 47.50526, -1.03893,
      47.50434, -1.03524, 47.50407, -1.03454, 47.50291, -1.0333, 47.50119,
      -1.03019, 47.50104, -1.02859, 47.50041, -1.02603, 47.50041, -1.02507,
      47.50055, -1.02426, 47.5005, -1.02415, 47.49938, -1.02312, 47.49685,
      -1.022, 47.49597, -1.02139, 47.49545, -1.02081, 47.49439, -1.0193,
      47.49323, -1.01784, 47.49297, -1.0171, 47.49196, -1.0149, 47.49092,
      -1.01363, 47.49029, -1.01301, 47.48987, -1.01279, 47.48939, -1.01277,
      47.48961, -1.01124, 47.48965, -1.00972, 47.48988, -1.00933, 47.48862,
      -1.00753, 47.48804, -1.00636, 47.48803, -1.00607, 47.48816, -1.00564,
      47.48892, -1.00418, 47.48897, -1.00304, 47.48917, -1.00177, 47.48948,
      -1.00102, 47.48956, -0.99915, 47.48964, -0.99884, 47.49013, -0.99736,
      47.4905, -0.9974, 47.49058, -0.99728, 47.4908, -0.99619, 47.49091,
      -0.99598, 47.49083, -0.9949, 47.49069, -0.99433, 47.49054, -0.99196,
      47.49005, -0.99051, 47.48992, -0.98976, 47.49004, -0.98757, 47.48981,
      -0.98664, 47.4897, -0.98548, 47.4899, -0.98291, 47.48984, -0.98069,
      47.49003, -0.98079, 47.49035, -0.97978, 47.49107, -0.97803, 47.4913,
      -0.97713, 47.4916, -0.97505, 47.49241, -0.97308, 47.49274, -0.972,
      47.49254, -0.9695, 47.49276, -0.96753, 47.49269, -0.96707, 47.49236,
      -0.96625, 47.49158, -0.9655
    ]
  ]
};

export const ESSARTS_EN_BOCAGE: LocationState = {
  id: 'FR-85084',
  name: 'Essarts en Bocage',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      46.80089, -1.28087, 46.80223, -1.28, 46.80241, -1.27956, 46.80332,
      -1.27816, 46.80363, -1.27816, 46.80441, -1.27704, 46.80478, -1.27679,
      46.80493, -1.27627, 46.80512, -1.27592, 46.80597, -1.27571, 46.80671,
      -1.2752, 46.80751, -1.27499, 46.80837, -1.27448, 46.80894, -1.27393,
      46.80957, -1.27402, 46.80981, -1.27383, 46.81049, -1.27396, 46.81103,
      -1.27357, 46.81113, -1.27308, 46.81149, -1.27315, 46.81162, -1.27294,
      46.81177, -1.27242, 46.8121, -1.27215, 46.81247, -1.27135, 46.81279,
      -1.27115, 46.81336, -1.27041, 46.81459, -1.26933, 46.81524, -1.26891,
      46.81679, -1.26828, 46.81712, -1.26817, 46.81757, -1.26824, 46.81805,
      -1.26804, 46.81841, -1.26784, 46.81888, -1.26719, 46.81926, -1.26707,
      46.81943, -1.2668, 46.81944, -1.26649, 46.81921, -1.26571, 46.81892,
      -1.26556, 46.81858, -1.26512, 46.81815, -1.26489, 46.81751, -1.2648,
      46.81684, -1.26491, 46.81643, -1.26509, 46.81618, -1.26532, 46.81593,
      -1.2653, 46.8157, -1.26508, 46.81523, -1.26435, 46.81494, -1.26411,
      46.81501, -1.26345, 46.81486, -1.26251, 46.81453, -1.26156, 46.81451,
      -1.26122, 46.81406, -1.26091, 46.81383, -1.26054, 46.8136, -1.26055,
      46.81335, -1.26034, 46.8131, -1.26031, 46.8128, -1.26066, 46.8129,
      -1.26101, 46.81287, -1.26116, 46.8127, -1.26127, 46.81248, -1.2607,
      46.81256, -1.26027, 46.81238, -1.26014, 46.81211, -1.26033, 46.81193,
      -1.26025, 46.81185, -1.25997, 46.81189, -1.25968, 46.81146, -1.25926,
      46.81133, -1.25874, 46.81105, -1.25852, 46.81098, -1.25833, 46.81077,
      -1.25832, 46.81061, -1.25812, 46.81077, -1.25771, 46.81071, -1.25743,
      46.81085, -1.25712, 46.81146, -1.25714, 46.81194, -1.25684, 46.8129,
      -1.25533, 46.81356, -1.25457, 46.81569, -1.25691, 46.8163, -1.25571,
      46.81588, -1.25475, 46.81512, -1.25336, 46.8149, -1.25222, 46.81495,
      -1.25199, 46.81552, -1.25113, 46.81466, -1.24916, 46.81526, -1.24755,
      46.81571, -1.24667, 46.81482, -1.2446, 46.81902, -1.2384, 46.819,
      -1.23643, 46.81872, -1.23655, 46.81839, -1.23687, 46.81794, -1.23761,
      46.81666, -1.23743, 46.81537, -1.23681, 46.81406, -1.23572, 46.81289,
      -1.2345, 46.813, -1.2336, 46.8129, -1.2332, 46.813, -1.23176, 46.8128,
      -1.23026, 46.81341, -1.22963, 46.81387, -1.23046, 46.81423, -1.23086,
      46.81668, -1.23258, 46.81728, -1.23209, 46.81773, -1.23196, 46.81924,
      -1.23116, 46.81933, -1.2302, 46.82077, -1.23038, 46.82258, -1.23026,
      46.82309, -1.23004, 46.82384, -1.23009, 46.82434, -1.23055, 46.82564,
      -1.23443, 46.82612, -1.23401, 46.82663, -1.23391, 46.82733, -1.23395,
      46.82743, -1.2321, 46.82903, -1.23173, 46.82949, -1.22979, 46.83003,
      -1.22815, 46.8303, -1.22703, 46.82783, -1.22593, 46.82811, -1.22457,
      46.82814, -1.22411, 46.82807, -1.22298, 46.82791, -1.22189, 46.82578,
      -1.2217, 46.82525, -1.221, 46.82488, -1.22029, 46.82421, -1.21989,
      46.82359, -1.2198, 46.82043, -1.21865, 46.81922, -1.21868, 46.8183,
      -1.21847, 46.81794, -1.21826, 46.8175, -1.21778, 46.817, -1.21619,
      46.81631, -1.21276, 46.81352, -1.20503, 46.81282, -1.20164, 46.81225,
      -1.19904, 46.81195, -1.19682, 46.81155, -1.19534, 46.8092, -1.19611,
      46.8067, -1.19744, 46.80699, -1.19655, 46.80705, -1.19588, 46.80689,
      -1.1947, 46.80603, -1.19301, 46.80505, -1.19206, 46.80441, -1.19121,
      46.80508, -1.1899, 46.80531, -1.18847, 46.80512, -1.18681, 46.80524,
      -1.18603, 46.80511, -1.18587, 46.80497, -1.18497, 46.80524, -1.18426,
      46.80539, -1.18415, 46.80529, -1.18279, 46.80537, -1.18197, 46.80533,
      -1.18031, 46.80529, -1.17967, 46.80456, -1.17768, 46.80365, -1.17452,
      46.80566, -1.17262, 46.80599, -1.17134, 46.80625, -1.17066, 46.80679,
      -1.16972, 46.80809, -1.16847, 46.80857, -1.16782, 46.80949, -1.16714,
      46.80963, -1.16694, 46.80971, -1.16668, 46.80968, -1.16636, 46.80921,
      -1.1649, 46.80917, -1.1641, 46.80943, -1.16324, 46.80993, -1.16252,
      46.81117, -1.16137, 46.81168, -1.16118, 46.81234, -1.16132, 46.81295,
      -1.16061, 46.81281, -1.15971, 46.81259, -1.15909, 46.81274, -1.15597,
      46.81257, -1.15538, 46.81257, -1.15452, 46.81262, -1.15382, 46.81282,
      -1.15317, 46.81358, -1.15308, 46.81495, -1.15151, 46.81521, -1.15157,
      46.81561, -1.15145, 46.81627, -1.15049, 46.8166, -1.14981, 46.81657,
      -1.14877, 46.81681, -1.14839, 46.817, -1.1482, 46.81769, -1.14792,
      46.81813, -1.14762, 46.81861, -1.14704, 46.8165, -1.14508, 46.81654,
      -1.14486, 46.81739, -1.14383, 46.81668, -1.14048, 46.81598, -1.13815,
      46.81579, -1.1368, 46.81582, -1.1365, 46.81546, -1.1334, 46.81583, -1.133,
      46.81579, -1.13189, 46.81594, -1.1288, 46.8148, -1.1247, 46.81398,
      -1.12272, 46.8136, -1.12209, 46.81384, -1.12134, 46.8111, -1.1226,
      46.81039, -1.12309, 46.80938, -1.12352, 46.80651, -1.12064, 46.80604,
      -1.12006, 46.80518, -1.1196, 46.80389, -1.11966, 46.80372, -1.11954,
      46.80359, -1.11929, 46.80292, -1.11692, 46.80257, -1.11703, 46.80247,
      -1.11687, 46.80194, -1.11648, 46.80183, -1.11656, 46.80171, -1.11645,
      46.8009, -1.11468, 46.80098, -1.11453, 46.80043, -1.11357, 46.80018,
      -1.11297, 46.79966, -1.11227, 46.79962, -1.11192, 46.79967, -1.11161,
      46.79988, -1.1109, 46.80003, -1.11066, 46.80103, -1.11062, 46.80141,
      -1.10988, 46.80115, -1.10675, 46.8008, -1.10646, 46.80016, -1.10639,
      46.79988, -1.10608, 46.79959, -1.10453, 46.79976, -1.10382, 46.79959,
      -1.10319, 46.79921, -1.10274, 46.79902, -1.10279, 46.79887, -1.10309,
      46.79865, -1.10294, 46.79807, -1.10321, 46.79788, -1.10311, 46.79787,
      -1.10284, 46.79772, -1.10271, 46.79745, -1.10272, 46.79705, -1.10248,
      46.79634, -1.10255, 46.79591, -1.10289, 46.79519, -1.10391, 46.79495,
      -1.10413, 46.79423, -1.10438, 46.79381, -1.10487, 46.79345, -1.10486,
      46.79275, -1.1052, 46.7921, -1.10533, 46.79134, -1.10595, 46.79096,
      -1.10579, 46.79071, -1.10542, 46.79029, -1.10437, 46.79006, -1.10422,
      46.78953, -1.10432, 46.78929, -1.10425, 46.78862, -1.10359, 46.78845,
      -1.10329, 46.78718, -1.10333, 46.78686, -1.10316, 46.78665, -1.10343,
      46.78639, -1.10354, 46.78609, -1.10326, 46.78607, -1.10342, 46.78592,
      -1.10349, 46.78559, -1.10317, 46.78516, -1.10346, 46.78475, -1.10358,
      46.78463, -1.10375, 46.78447, -1.10353, 46.7842, -1.10382, 46.78394,
      -1.10385, 46.78374, -1.10413, 46.78344, -1.10407, 46.78312, -1.10432,
      46.78294, -1.10432, 46.7829, -1.10415, 46.78277, -1.1042, 46.78267,
      -1.10404, 46.7824, -1.10463, 46.78225, -1.10465, 46.78211, -1.10521,
      46.78212, -1.10554, 46.78161, -1.10622, 46.781, -1.10651, 46.78062,
      -1.10641, 46.78024, -1.10669, 46.77984, -1.10716, 46.77971, -1.10778,
      46.77806, -1.10946, 46.77664, -1.1099, 46.7755, -1.10852, 46.77504,
      -1.10723, 46.77485, -1.10551, 46.77403, -1.10465, 46.77346, -1.10374,
      46.77117, -1.10305, 46.77059, -1.10326, 46.76758, -1.1038, 46.7669,
      -1.10329, 46.76554, -1.10326, 46.765, -1.1031, 46.76458, -1.10281,
      46.76399, -1.10278, 46.76312, -1.10297, 46.76298, -1.1034, 46.76232,
      -1.10314, 46.7616, -1.10245, 46.76086, -1.1015, 46.75955, -1.10098,
      46.75887, -1.1005, 46.75865, -1.10017, 46.75734, -1.09908, 46.75702,
      -1.09937, 46.75618, -1.10104, 46.75397, -1.10355, 46.75345, -1.10439,
      46.75284, -1.10625, 46.75229, -1.11023, 46.75217, -1.11071, 46.75205,
      -1.1109, 46.7503, -1.11239, 46.75001, -1.11256, 46.74942, -1.11376,
      46.74882, -1.11431, 46.74828, -1.11444, 46.74682, -1.1143, 46.74675,
      -1.11406, 46.74601, -1.11376, 46.74598, -1.11424, 46.7465, -1.11791,
      46.74705, -1.11968, 46.7478, -1.11914, 46.74798, -1.11967, 46.74848,
      -1.11958, 46.74867, -1.12021, 46.74883, -1.12031, 46.74927, -1.12007,
      46.74968, -1.11914, 46.75005, -1.11946, 46.75023, -1.11894, 46.75079,
      -1.11888, 46.75085, -1.11855, 46.75102, -1.1182, 46.75115, -1.11747,
      46.75148, -1.11753, 46.75158, -1.11717, 46.75175, -1.11715, 46.75205,
      -1.11734, 46.7521, -1.11772, 46.75228, -1.11776, 46.75251, -1.11712,
      46.75275, -1.117, 46.75311, -1.11788, 46.75328, -1.11808, 46.75362,
      -1.11816, 46.75372, -1.11898, 46.7543, -1.11952, 46.75413, -1.11965,
      46.75409, -1.11991, 46.75437, -1.12079, 46.75436, -1.1213, 46.7545,
      -1.12147, 46.75513, -1.12157, 46.7554, -1.12149, 46.75559, -1.12159,
      46.75568, -1.12133, 46.75579, -1.12127, 46.75601, -1.12133, 46.75633,
      -1.12162, 46.75654, -1.12134, 46.75701, -1.1211, 46.75712, -1.12113,
      46.75732, -1.12146, 46.7578, -1.12125, 46.75793, -1.12135, 46.75791,
      -1.12219, 46.75798, -1.12238, 46.75834, -1.12234, 46.75841, -1.12192,
      46.75872, -1.12211, 46.75889, -1.12237, 46.75916, -1.1225, 46.75923,
      -1.12281, 46.7596, -1.12303, 46.75961, -1.12339, 46.75933, -1.12345,
      46.75922, -1.12407, 46.75955, -1.12614, 46.76018, -1.12619, 46.76045,
      -1.12564, 46.76076, -1.12565, 46.7609, -1.12535, 46.76153, -1.12544,
      46.76141, -1.12596, 46.7617, -1.12616, 46.76215, -1.12599, 46.76247,
      -1.12572, 46.76265, -1.12599, 46.76308, -1.12601, 46.76314, -1.1264,
      46.76331, -1.1265, 46.76348, -1.12708, 46.7638, -1.12695, 46.76393,
      -1.12746, 46.76444, -1.12748, 46.76485, -1.12795, 46.76519, -1.12813,
      46.76542, -1.12817, 46.76594, -1.1286, 46.7661, -1.12914, 46.76617,
      -1.12983, 46.76597, -1.13028, 46.76595, -1.13051, 46.76611, -1.13101,
      46.76613, -1.13156, 46.76624, -1.13185, 46.76621, -1.13213, 46.76648,
      -1.13267, 46.76647, -1.13282, 46.76629, -1.1329, 46.76626, -1.13302,
      46.76667, -1.13344, 46.76669, -1.13389, 46.76699, -1.13434, 46.76704,
      -1.13473, 46.76693, -1.13487, 46.76707, -1.13542, 46.76728, -1.13554,
      46.76738, -1.13576, 46.76747, -1.13665, 46.76722, -1.13681, 46.76715,
      -1.1374, 46.76718, -1.13764, 46.76748, -1.13838, 46.76748, -1.13857,
      46.7676, -1.13879, 46.76786, -1.1386, 46.76824, -1.13889, 46.76834,
      -1.13939, 46.76855, -1.13992, 46.76873, -1.14007, 46.76906, -1.13966,
      46.76952, -1.13979, 46.76961, -1.13999, 46.76957, -1.14056, 46.76971,
      -1.14081, 46.7695, -1.14097, 46.76909, -1.14173, 46.76876, -1.1428,
      46.76866, -1.14368, 46.76868, -1.14443, 46.76873, -1.14456, 46.76898,
      -1.14467, 46.76901, -1.14515, 46.76871, -1.14568, 46.76834, -1.14711,
      46.76795, -1.14765, 46.76794, -1.14784, 46.76814, -1.14846, 46.76799,
      -1.14918, 46.76734, -1.1506, 46.76693, -1.1513, 46.767, -1.15161,
      46.76601, -1.15248, 46.76577, -1.15221, 46.76574, -1.15241, 46.76543,
      -1.15296, 46.76529, -1.15289, 46.76502, -1.15313, 46.76506, -1.15339,
      46.76451, -1.15447, 46.76446, -1.15501, 46.76477, -1.15561, 46.76484,
      -1.15592, 46.76463, -1.15642, 46.76454, -1.1571, 46.7638, -1.15799,
      46.76395, -1.15817, 46.76379, -1.15864, 46.76388, -1.15899, 46.76391,
      -1.16007, 46.76411, -1.16097, 46.76388, -1.16159, 46.76372, -1.16304,
      46.76326, -1.1635, 46.76158, -1.16449, 46.7613, -1.16494, 46.76114,
      -1.1653, 46.76108, -1.16605, 46.76119, -1.16645, 46.76118, -1.16667,
      46.76063, -1.1671, 46.7592, -1.1676, 46.7592, -1.16721, 46.75895,
      -1.16734, 46.75882, -1.16725, 46.75879, -1.16749, 46.75833, -1.16739,
      46.75824, -1.16762, 46.75816, -1.1675, 46.75787, -1.16761, 46.75754,
      -1.16743, 46.75723, -1.16745, 46.75721, -1.16762, 46.75683, -1.16759,
      46.7567, -1.16798, 46.75633, -1.16768, 46.75597, -1.16837, 46.75568,
      -1.16842, 46.75551, -1.16863, 46.75508, -1.16981, 46.75384, -1.17216,
      46.75344, -1.17236, 46.7528, -1.17297, 46.75288, -1.17459, 46.75245,
      -1.17478, 46.75212, -1.17466, 46.75114, -1.17549, 46.75068, -1.1755,
      46.7506, -1.17609, 46.75073, -1.17788, 46.75085, -1.17798, 46.75042,
      -1.18009, 46.75022, -1.18032, 46.74988, -1.18125, 46.7501, -1.1823,
      46.75015, -1.18325, 46.7501, -1.18437, 46.75031, -1.18541, 46.74983,
      -1.18551, 46.74968, -1.18629, 46.74968, -1.18713, 46.75025, -1.18931,
      46.75042, -1.18961, 46.75056, -1.18945, 46.75119, -1.19045, 46.7513,
      -1.19031, 46.75291, -1.19296, 46.75269, -1.19373, 46.75363, -1.19511,
      46.75308, -1.19646, 46.75344, -1.19708, 46.7531, -1.19784, 46.75413,
      -1.20105, 46.75184, -1.20223, 46.75186, -1.20251, 46.75167, -1.20257,
      46.75175, -1.20283, 46.75114, -1.20316, 46.75137, -1.20409, 46.74957,
      -1.20524, 46.74968, -1.20565, 46.74938, -1.20593, 46.74838, -1.20646,
      46.7474, -1.20817, 46.74585, -1.21115, 46.74604, -1.21218, 46.74399,
      -1.2136, 46.74365, -1.21469, 46.7434, -1.21487, 46.74372, -1.21587,
      46.74382, -1.21683, 46.74366, -1.21722, 46.74349, -1.21742, 46.74331,
      -1.2183, 46.7431, -1.2189, 46.74318, -1.22117, 46.74304, -1.22168,
      46.74315, -1.22257, 46.74341, -1.22329, 46.74342, -1.22361, 46.74314,
      -1.2249, 46.74307, -1.22562, 46.7428, -1.22625, 46.74271, -1.22718,
      46.74244, -1.22836, 46.74256, -1.22851, 46.74255, -1.22883, 46.74219,
      -1.22954, 46.74198, -1.2296, 46.74194, -1.22978, 46.74193, -1.23094,
      46.74205, -1.23134, 46.74199, -1.23207, 46.74227, -1.23317, 46.74229,
      -1.23382, 46.74269, -1.23467, 46.74277, -1.23505, 46.74317, -1.23571,
      46.74348, -1.23674, 46.74349, -1.23733, 46.74357, -1.23757, 46.74387,
      -1.23825, 46.7441, -1.23828, 46.74408, -1.2388, 46.74419, -1.23965,
      46.74416, -1.23994, 46.744, -1.24001, 46.74396, -1.24013, 46.74402,
      -1.24043, 46.74423, -1.24079, 46.74408, -1.24154, 46.74312, -1.24267,
      46.74262, -1.24373, 46.74258, -1.24425, 46.74309, -1.2456, 46.74387,
      -1.24683, 46.74392, -1.24719, 46.74375, -1.24745, 46.74409, -1.24823,
      46.7446, -1.24862, 46.74487, -1.24977, 46.7456, -1.25164, 46.74611,
      -1.25245, 46.74675, -1.25306, 46.74703, -1.25322, 46.74733, -1.25321,
      46.74746, -1.25359, 46.74769, -1.25344, 46.74785, -1.25351, 46.74787,
      -1.25361, 46.74781, -1.25435, 46.74757, -1.25535, 46.74753, -1.25581,
      46.74769, -1.25673, 46.74805, -1.25732, 46.74802, -1.25746, 46.74774,
      -1.25793, 46.74741, -1.25792, 46.74632, -1.25876, 46.74624, -1.25902,
      46.74605, -1.2592, 46.7461, -1.2594, 46.74637, -1.25948, 46.74627,
      -1.25994, 46.74623, -1.26012, 46.74647, -1.26064, 46.74652, -1.26127,
      46.74683, -1.26186, 46.74694, -1.26229, 46.74725, -1.26231, 46.74745,
      -1.26318, 46.74803, -1.26367, 46.74821, -1.26364, 46.74835, -1.26347,
      46.74846, -1.26353, 46.74853, -1.26414, 46.74864, -1.2643, 46.74966,
      -1.26497, 46.74947, -1.26622, 46.74926, -1.26651, 46.74931, -1.26694,
      46.74903, -1.26765, 46.74868, -1.2679, 46.74883, -1.26823, 46.74895,
      -1.26878, 46.74909, -1.269, 46.74913, -1.26934, 46.74946, -1.26952,
      46.74958, -1.2697, 46.74975, -1.27028, 46.74987, -1.27026, 46.75001,
      -1.27001, 46.75015, -1.27009, 46.75051, -1.2706, 46.75045, -1.271,
      46.75063, -1.27111, 46.75067, -1.27143, 46.75088, -1.27131, 46.75099,
      -1.27136, 46.75109, -1.27229, 46.75156, -1.27357, 46.75144, -1.27393,
      46.75242, -1.27609, 46.75283, -1.27663, 46.75362, -1.2767, 46.75425,
      -1.27692, 46.7544, -1.2768, 46.75443, -1.27659, 46.75454, -1.27659,
      46.75519, -1.27704, 46.75548, -1.27702, 46.75595, -1.27734, 46.75645,
      -1.27745, 46.75658, -1.27768, 46.7568, -1.27758, 46.75765, -1.27787,
      46.75763, -1.27814, 46.75806, -1.27872, 46.75814, -1.27924, 46.75842,
      -1.27962, 46.75881, -1.27974, 46.75884, -1.28007, 46.75902, -1.2802,
      46.75905, -1.28056, 46.75946, -1.28042, 46.75964, -1.28064, 46.75978,
      -1.28118, 46.75965, -1.28142, 46.75958, -1.2823, 46.76007, -1.28305,
      46.76038, -1.28377, 46.76056, -1.28396, 46.76062, -1.28427, 46.76082,
      -1.28462, 46.7605, -1.285, 46.76037, -1.28536, 46.76044, -1.28556,
      46.76077, -1.2857, 46.76063, -1.28643, 46.76097, -1.28645, 46.76105,
      -1.28673, 46.76122, -1.28689, 46.76115, -1.28754, 46.76147, -1.28745,
      46.76164, -1.28759, 46.76166, -1.2882, 46.7619, -1.28819, 46.762,
      -1.28839, 46.76202, -1.28861, 46.76189, -1.28901, 46.76229, -1.28933,
      46.76255, -1.28934, 46.76276, -1.28956, 46.7631, -1.2902, 46.76314,
      -1.29059, 46.76324, -1.29072, 46.76395, -1.29082, 46.76404, -1.2906,
      46.76418, -1.29053, 46.76514, -1.29104, 46.7651, -1.29177, 46.76552,
      -1.29262, 46.76573, -1.29353, 46.76607, -1.29408, 46.76627, -1.29471,
      46.76645, -1.29487, 46.7664, -1.29539, 46.7661, -1.2957, 46.76619,
      -1.29627, 46.76638, -1.2967, 46.7674, -1.29817, 46.76778, -1.29816,
      46.76859, -1.29868, 46.76895, -1.29867, 46.76934, -1.2985, 46.76972,
      -1.29901, 46.7707, -1.29895, 46.77115, -1.29908, 46.77145, -1.29888,
      46.77161, -1.29949, 46.77191, -1.29976, 46.77198, -1.30003, 46.77193,
      -1.30028, 46.77217, -1.30052, 46.77213, -1.30073, 46.77235, -1.301,
      46.7726, -1.30178, 46.77244, -1.30242, 46.77205, -1.30292, 46.77196,
      -1.30323, 46.77199, -1.30467, 46.77239, -1.30515, 46.77237, -1.3053,
      46.7722, -1.30544, 46.77221, -1.30562, 46.77255, -1.30589, 46.77244,
      -1.3063, 46.77217, -1.30608, 46.77207, -1.30616, 46.77211, -1.30632,
      46.77241, -1.30653, 46.77229, -1.3072, 46.77233, -1.30737, 46.77265,
      -1.30738, 46.77275, -1.30701, 46.77291, -1.30691, 46.77307, -1.30737,
      46.77327, -1.30743, 46.77341, -1.30726, 46.77354, -1.30726, 46.77372,
      -1.30742, 46.77392, -1.30784, 46.77409, -1.30778, 46.77422, -1.30758,
      46.77454, -1.30748, 46.77487, -1.30781, 46.77505, -1.30775, 46.77536,
      -1.3084, 46.77576, -1.30857, 46.77578, -1.30898, 46.77602, -1.30874,
      46.77616, -1.30908, 46.77649, -1.30911, 46.7766, -1.30964, 46.77668,
      -1.30959, 46.77681, -1.30921, 46.77698, -1.30926, 46.77705, -1.30945,
      46.777, -1.30996, 46.77706, -1.31008, 46.77762, -1.30997, 46.77807,
      -1.31052, 46.77841, -1.31019, 46.77857, -1.31017, 46.77868, -1.31037,
      46.77877, -1.311, 46.77908, -1.31127, 46.77918, -1.31148, 46.77931,
      -1.31219, 46.77915, -1.31244, 46.77884, -1.31237, 46.77871, -1.31244,
      46.77874, -1.31355, 46.77894, -1.3139, 46.77913, -1.31475, 46.77945,
      -1.31521, 46.77979, -1.31499, 46.77995, -1.31524, 46.78011, -1.3149,
      46.7805, -1.31506, 46.78106, -1.31475, 46.78132, -1.31481, 46.78147,
      -1.31509, 46.7816, -1.31513, 46.78194, -1.31439, 46.78216, -1.31415,
      46.78233, -1.31413, 46.78261, -1.31435, 46.78281, -1.31461, 46.78299,
      -1.31525, 46.78313, -1.31546, 46.7831, -1.31597, 46.78275, -1.31593,
      46.78271, -1.31617, 46.78286, -1.31639, 46.78306, -1.31644, 46.78318,
      -1.31658, 46.78343, -1.31654, 46.78348, -1.31697, 46.78333, -1.31726,
      46.78345, -1.31745, 46.78367, -1.31742, 46.78361, -1.31781, 46.78385,
      -1.31762, 46.78394, -1.31765, 46.78397, -1.31834, 46.78425, -1.31846,
      46.78432, -1.31875, 46.78451, -1.31906, 46.78449, -1.31969, 46.78441,
      -1.32, 46.78458, -1.32029, 46.78509, -1.32084, 46.78528, -1.32065,
      46.78538, -1.32066, 46.78548, -1.32179, 46.786, -1.32231, 46.78643,
      -1.32252, 46.78658, -1.32271, 46.78683, -1.32272, 46.78716, -1.32428,
      46.7876, -1.32502, 46.78812, -1.32563, 46.7886, -1.32599, 46.78898,
      -1.32647, 46.78917, -1.32659, 46.78933, -1.3264, 46.78923, -1.32605,
      46.78949, -1.32589, 46.78955, -1.32542, 46.78973, -1.32515, 46.78986,
      -1.32448, 46.79024, -1.32392, 46.79035, -1.32396, 46.79044, -1.32423,
      46.79054, -1.32426, 46.79068, -1.32408, 46.79069, -1.32374, 46.79079,
      -1.3237, 46.79098, -1.32391, 46.79108, -1.32424, 46.79132, -1.32394,
      46.79194, -1.32466, 46.7928, -1.32503, 46.79339, -1.32498, 46.79365,
      -1.32472, 46.79391, -1.32411, 46.79437, -1.32344, 46.79503, -1.3233,
      46.79543, -1.32281, 46.79589, -1.32253, 46.79606, -1.32254, 46.79638,
      -1.32222, 46.7968, -1.3223, 46.79708, -1.32225, 46.79745, -1.32271,
      46.79803, -1.32317, 46.79871, -1.32427, 46.79905, -1.32466, 46.79916,
      -1.32501, 46.79934, -1.32501, 46.79951, -1.3248, 46.79993, -1.32556,
      46.80015, -1.32556, 46.80043, -1.32539, 46.80101, -1.32458, 46.8016,
      -1.32412, 46.80293, -1.32277, 46.80378, -1.32259, 46.8041, -1.32257,
      46.80441, -1.3227, 46.80461, -1.32295, 46.80504, -1.32402, 46.80504,
      -1.3246, 46.80539, -1.32503, 46.8053, -1.32538, 46.80541, -1.32563,
      46.8059, -1.32572, 46.80581, -1.32503, 46.80588, -1.32496, 46.80635,
      -1.325, 46.80648, -1.32468, 46.80641, -1.3242, 46.8067, -1.32316,
      46.80666, -1.32247, 46.80663, -1.32213, 46.80598, -1.31992, 46.8057,
      -1.31959, 46.80433, -1.31862, 46.80368, -1.31789, 46.80327, -1.31712,
      46.80332, -1.31652, 46.80343, -1.31632, 46.80347, -1.31599, 46.80229,
      -1.31495, 46.80248, -1.31336, 46.80281, -1.31242, 46.80689, -1.30449,
      46.80733, -1.3039, 46.80721, -1.30379, 46.80621, -1.30353, 46.80594,
      -1.30334, 46.805, -1.30106, 46.80381, -1.29945, 46.80267, -1.29812,
      46.80044, -1.29515, 46.79971, -1.29438, 46.79946, -1.29383, 46.80044,
      -1.29042, 46.8005, -1.28976, 46.80041, -1.28901, 46.80047, -1.28883,
      46.80166, -1.28833, 46.80233, -1.28768, 46.80165, -1.28537, 46.80144,
      -1.28496, 46.80118, -1.28355, 46.80122, -1.28281, 46.80142, -1.28237,
      46.80089, -1.28087
    ]
  ]
};

export const OREE_D_ANJOU: LocationState = {
  id: 'FR-49069',
  name: "Orée d'Anjou",
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.23966, -1.23142, 47.23981, -1.23179, 47.23985, -1.23218, 47.2404,
      -1.2329, 47.24053, -1.23399, 47.24075, -1.23444, 47.24089, -1.23519,
      47.24102, -1.23544, 47.24119, -1.23616, 47.2411, -1.23668, 47.24157,
      -1.23724, 47.24147, -1.23761, 47.24166, -1.23779, 47.24238, -1.239,
      47.24266, -1.2389, 47.2429, -1.23912, 47.24305, -1.23945, 47.24322,
      -1.24048, 47.24316, -1.2414, 47.24319, -1.24233, 47.24359, -1.24313,
      47.24411, -1.24332, 47.24431, -1.24315, 47.24464, -1.24317, 47.24486,
      -1.24337, 47.24516, -1.24309, 47.24537, -1.24274, 47.24564, -1.24294,
      47.24645, -1.24265, 47.2469, -1.24275, 47.24702, -1.24287, 47.24708,
      -1.24344, 47.24733, -1.2438, 47.24752, -1.24463, 47.2476, -1.24572,
      47.24753, -1.2464, 47.2476, -1.24712, 47.24736, -1.24851, 47.24702,
      -1.24955, 47.24706, -1.25084, 47.24693, -1.2512, 47.24713, -1.25195,
      47.24754, -1.25294, 47.24758, -1.25346, 47.24797, -1.25435, 47.24832,
      -1.25482, 47.24854, -1.25486, 47.24865, -1.2551, 47.2488, -1.25516,
      47.24911, -1.25512, 47.24945, -1.25487, 47.24979, -1.25427, 47.25017,
      -1.25421, 47.25029, -1.25423, 47.2506, -1.2539, 47.25084, -1.25279,
      47.25155, -1.25336, 47.25152, -1.2536, 47.2518, -1.25389, 47.25185,
      -1.25418, 47.25279, -1.25469, 47.25325, -1.25467, 47.25329, -1.25574,
      47.25349, -1.25657, 47.25361, -1.25676, 47.25421, -1.25694, 47.25422,
      -1.25745, 47.25454, -1.25785, 47.25511, -1.25816, 47.25538, -1.25815,
      47.25541, -1.25865, 47.25548, -1.25873, 47.2558, -1.25887, 47.25596,
      -1.25868, 47.25616, -1.25878, 47.2567, -1.25951, 47.25677, -1.25991,
      47.25686, -1.26001, 47.25766, -1.26065, 47.25857, -1.26094, 47.25906,
      -1.26164, 47.25921, -1.26225, 47.25929, -1.26449, 47.25957, -1.26646,
      47.25963, -1.26787, 47.25978, -1.26823, 47.26, -1.26844, 47.26053,
      -1.26824, 47.26111, -1.26833, 47.26162, -1.26882, 47.26196, -1.26962,
      47.26226, -1.27004, 47.26252, -1.27024, 47.26335, -1.27051, 47.26346,
      -1.27063, 47.26339, -1.27136, 47.2635, -1.272, 47.26377, -1.27248,
      47.26429, -1.27294, 47.26487, -1.27303, 47.26535, -1.27326, 47.26582,
      -1.2732, 47.26603, -1.27337, 47.26651, -1.27409, 47.26681, -1.27428,
      47.26705, -1.27459, 47.26743, -1.27455, 47.2677, -1.27472, 47.26828,
      -1.27454, 47.26919, -1.27493, 47.26941, -1.27529, 47.26962, -1.27641,
      47.2705, -1.27753, 47.27073, -1.2781, 47.27101, -1.27802, 47.27132,
      -1.27824, 47.27194, -1.27803, 47.27233, -1.27816, 47.27265, -1.27847,
      47.27303, -1.27851, 47.27317, -1.27873, 47.27365, -1.27887, 47.27403,
      -1.27912, 47.27437, -1.27952, 47.2745, -1.27982, 47.27506, -1.2795,
      47.27532, -1.27887, 47.2755, -1.27891, 47.27577, -1.27874, 47.276,
      -1.27817, 47.27606, -1.2776, 47.27685, -1.2759, 47.27715, -1.27557,
      47.27736, -1.27556, 47.27787, -1.27595, 47.27862, -1.27715, 47.27886,
      -1.27734, 47.27959, -1.2776, 47.27989, -1.27759, 47.28027, -1.27742,
      47.28078, -1.27763, 47.28096, -1.27801, 47.28092, -1.27852, 47.28097,
      -1.27889, 47.28132, -1.27931, 47.28165, -1.2793, 47.2818, -1.27907,
      47.28168, -1.27864, 47.28148, -1.27839, 47.28153, -1.27817, 47.28212,
      -1.27774, 47.28269, -1.27753, 47.28316, -1.27692, 47.28335, -1.27679,
      47.2837, -1.2768, 47.28461, -1.27742, 47.28498, -1.27794, 47.28554,
      -1.2782, 47.28595, -1.27813, 47.28623, -1.27826, 47.28672, -1.27821,
      47.28689, -1.27797, 47.28754, -1.27769, 47.28788, -1.27737, 47.2881,
      -1.27703, 47.28866, -1.27667, 47.28919, -1.27567, 47.28938, -1.27512,
      47.28993, -1.27482, 47.29052, -1.27482, 47.29101, -1.27519, 47.29136,
      -1.27597, 47.29138, -1.27755, 47.29116, -1.27859, 47.29146, -1.27957,
      47.29197, -1.28028, 47.29238, -1.28049, 47.29263, -1.28076, 47.29314,
      -1.28038, 47.2936, -1.28063, 47.29383, -1.28062, 47.2943, -1.28003,
      47.29457, -1.27987, 47.29478, -1.28014, 47.29519, -1.28027, 47.29547,
      -1.28084, 47.29571, -1.28085, 47.29602, -1.28167, 47.29608, -1.28274,
      47.29603, -1.28306, 47.29567, -1.28374, 47.29554, -1.28457, 47.29527,
      -1.28504, 47.29526, -1.28536, 47.29546, -1.28592, 47.29593, -1.28628,
      47.29576, -1.28673, 47.29578, -1.28688, 47.29599, -1.2872, 47.29624,
      -1.28734, 47.29674, -1.28714, 47.29688, -1.28748, 47.29672, -1.28805,
      47.29703, -1.28828, 47.29786, -1.28739, 47.29807, -1.28731, 47.29824,
      -1.28736, 47.29834, -1.28758, 47.29836, -1.28801, 47.29884, -1.289,
      47.29873, -1.28939, 47.29874, -1.28955, 47.29905, -1.28994, 47.29936,
      -1.29079, 47.29956, -1.29097, 47.29993, -1.29099, 47.29987, -1.29167,
      47.30006, -1.29197, 47.3001, -1.29232, 47.30006, -1.29258, 47.29983,
      -1.2928, 47.2999, -1.29319, 47.30016, -1.29337, 47.30019, -1.29368,
      47.30033, -1.29379, 47.30031, -1.29421, 47.30046, -1.29445, 47.30075,
      -1.29468, 47.30113, -1.29455, 47.30135, -1.29466, 47.3014, -1.29486,
      47.3019, -1.29502, 47.302, -1.29521, 47.30225, -1.29531, 47.30235,
      -1.29548, 47.30296, -1.2953, 47.30339, -1.2956, 47.30351, -1.29547,
      47.30355, -1.29515, 47.30365, -1.29501, 47.30408, -1.29569, 47.30423,
      -1.29559, 47.30437, -1.29502, 47.30455, -1.29491, 47.30485, -1.29535,
      47.30512, -1.29535, 47.30523, -1.29561, 47.30555, -1.29553, 47.30573,
      -1.29574, 47.30602, -1.29576, 47.30634, -1.29643, 47.30632, -1.29663,
      47.30602, -1.29707, 47.30571, -1.29732, 47.30499, -1.29734, 47.30443,
      -1.29804, 47.30428, -1.29843, 47.30394, -1.29858, 47.30372, -1.299,
      47.3034, -1.29894, 47.30321, -1.29955, 47.30325, -1.30039, 47.30311,
      -1.30297, 47.30316, -1.30363, 47.30304, -1.30401, 47.30277, -1.30427,
      47.30343, -1.30587, 47.30419, -1.30708, 47.30433, -1.30806, 47.30405,
      -1.3086, 47.30366, -1.30859, 47.3034, -1.30939, 47.3029, -1.31018,
      47.30287, -1.3117, 47.30308, -1.31376, 47.30322, -1.31473, 47.30348,
      -1.31507, 47.30318, -1.31566, 47.30331, -1.31618, 47.30295, -1.31621,
      47.30242, -1.31575, 47.3019, -1.31578, 47.30177, -1.31608, 47.30155,
      -1.31625, 47.3015, -1.31713, 47.30133, -1.31725, 47.30126, -1.31744,
      47.30139, -1.31797, 47.30117, -1.31828, 47.30118, -1.31853, 47.30133,
      -1.3187, 47.30175, -1.31872, 47.30208, -1.31907, 47.30234, -1.31907,
      47.30252, -1.31891, 47.30275, -1.31888, 47.30291, -1.31867, 47.30295,
      -1.31773, 47.30304, -1.31759, 47.30325, -1.31782, 47.30331, -1.31832,
      47.30344, -1.31853, 47.30359, -1.31846, 47.30371, -1.31806, 47.30383,
      -1.318, 47.30388, -1.31817, 47.30375, -1.31887, 47.30431, -1.31957,
      47.30506, -1.31966, 47.30507, -1.31988, 47.30492, -1.3203, 47.30499,
      -1.32053, 47.30527, -1.3206, 47.30562, -1.32036, 47.30581, -1.32042,
      47.3059, -1.3206, 47.30571, -1.32138, 47.30572, -1.32199, 47.30565,
      -1.32222, 47.30484, -1.32235, 47.30453, -1.32229, 47.30388, -1.32247,
      47.30315, -1.32322, 47.30313, -1.32366, 47.30325, -1.32484, 47.30348,
      -1.32568, 47.30344, -1.32713, 47.30332, -1.32755, 47.30289, -1.32803,
      47.30274, -1.32835, 47.30271, -1.32905, 47.30255, -1.32997, 47.30265,
      -1.33051, 47.303, -1.33124, 47.3032, -1.33147, 47.30312, -1.33198,
      47.30254, -1.33312, 47.30241, -1.33378, 47.30206, -1.33444, 47.30206,
      -1.33516, 47.30174, -1.33527, 47.30128, -1.33492, 47.30099, -1.33499,
      47.30086, -1.33523, 47.30085, -1.33632, 47.30022, -1.3372, 47.30072,
      -1.33828, 47.3009, -1.33904, 47.30082, -1.33982, 47.30097, -1.34078,
      47.30092, -1.34118, 47.30087, -1.34134, 47.30036, -1.34181, 47.30061,
      -1.34302, 47.301, -1.34366, 47.3009, -1.34453, 47.30093, -1.34487,
      47.30115, -1.3452, 47.30199, -1.34533, 47.30186, -1.34673, 47.30225,
      -1.34881, 47.30251, -1.34969, 47.3033, -1.35124, 47.30415, -1.3542,
      47.30487, -1.35279, 47.30724, -1.34939, 47.31353, -1.34251, 47.31503,
      -1.34067, 47.31712, -1.3377, 47.31917, -1.33508, 47.31908, -1.33299,
      47.31916, -1.33192, 47.319, -1.331, 47.3194, -1.33082, 47.31971, -1.32766,
      47.32041, -1.32468, 47.32157, -1.32057, 47.32152, -1.31999, 47.32185,
      -1.31995, 47.32541, -1.31762, 47.32573, -1.31759, 47.3261, -1.31736,
      47.3272, -1.31599, 47.32732, -1.31594, 47.3281, -1.31482, 47.32833,
      -1.31422, 47.32874, -1.31347, 47.32904, -1.31243, 47.32941, -1.31185,
      47.32996, -1.31148, 47.33043, -1.31148, 47.33065, -1.31128, 47.33111,
      -1.31144, 47.33177, -1.31077, 47.33202, -1.31063, 47.33325, -1.30859,
      47.33383, -1.30737, 47.33448, -1.30655, 47.33478, -1.30578, 47.33636,
      -1.30264, 47.33762, -1.29988, 47.33861, -1.2969, 47.33944, -1.29358,
      47.33975, -1.29164, 47.34058, -1.28236, 47.34158, -1.26938, 47.3416,
      -1.26777, 47.34113, -1.26526, 47.34107, -1.26298, 47.34088, -1.26131,
      47.34119, -1.25763, 47.34436, -1.24446, 47.34727, -1.23383, 47.34782,
      -1.23248, 47.35012, -1.22796, 47.35025, -1.22741, 47.35027, -1.22688,
      47.35019, -1.22519, 47.34908, -1.21721, 47.35027, -1.217, 47.35058,
      -1.21663, 47.35092, -1.21557, 47.35137, -1.21451, 47.35144, -1.21311,
      47.35131, -1.21196, 47.35098, -1.21101, 47.35073, -1.20949, 47.35031,
      -1.20913, 47.34987, -1.2089, 47.34982, -1.20881, 47.34986, -1.20833,
      47.34903, -1.20817, 47.35005, -1.20196, 47.35147, -1.19806, 47.35093,
      -1.19598, 47.35144, -1.19436, 47.35171, -1.19315, 47.35229, -1.18866,
      47.35305, -1.18639, 47.35362, -1.1844, 47.35411, -1.18351, 47.35431,
      -1.18298, 47.35501, -1.18383, 47.35753, -1.17996, 47.35793, -1.17951,
      47.35892, -1.17775, 47.36086, -1.17959, 47.36221, -1.17517, 47.36265,
      -1.17434, 47.36365, -1.17287, 47.36461, -1.17103, 47.3653, -1.16935,
      47.3665, -1.16579, 47.36539, -1.16475, 47.3653, -1.16442, 47.36555,
      -1.1607, 47.36557, -1.15733, 47.36591, -1.15486, 47.36625, -1.15384,
      47.36716, -1.1516, 47.36903, -1.14456, 47.36809, -1.14415, 47.36774,
      -1.14195, 47.36755, -1.13912, 47.36678, -1.13901, 47.36686, -1.13866,
      47.3671, -1.13869, 47.36746, -1.13714, 47.36753, -1.13633, 47.36861,
      -1.13526, 47.36795, -1.13373, 47.36736, -1.13183, 47.367, -1.12977,
      47.36687, -1.12802, 47.36692, -1.12571, 47.3674, -1.11547, 47.36757,
      -1.11412, 47.36635, -1.11405, 47.36616, -1.11162, 47.36581, -1.10787,
      47.36675, -1.10182, 47.36622, -1.10178, 47.36521, -1.10082, 47.36505,
      -1.10046, 47.36484, -1.09947, 47.36484, -1.09912, 47.36514, -1.09846,
      47.36533, -1.097, 47.36533, -1.09672, 47.36508, -1.09607, 47.365,
      -1.09534, 47.36508, -1.09484, 47.36542, -1.09413, 47.36506, -1.09331,
      47.3649, -1.09261, 47.36446, -1.09164, 47.36409, -1.09116, 47.36312,
      -1.09039, 47.36263, -1.09008, 47.36244, -1.09009, 47.36141, -1.08898,
      47.36114, -1.08788, 47.36058, -1.08772, 47.35974, -1.08778, 47.35971,
      -1.08771, 47.35968, -1.08828, 47.35941, -1.08901, 47.359, -1.08944,
      47.35851, -1.09028, 47.35835, -1.09085, 47.35725, -1.0917, 47.35705,
      -1.09264, 47.35721, -1.09296, 47.35753, -1.09298, 47.35771, -1.09324,
      47.3578, -1.09349, 47.35781, -1.0944, 47.35756, -1.09461, 47.35729,
      -1.09504, 47.35686, -1.09516, 47.35685, -1.09526, 47.35584, -1.09514,
      47.356, -1.09494, 47.35603, -1.09458, 47.35624, -1.09403, 47.35621,
      -1.09295, 47.35641, -1.09256, 47.35615, -1.09197, 47.35565, -1.0912,
      47.35536, -1.09113, 47.35461, -1.0914, 47.35451, -1.09138, 47.3544,
      -1.0912, 47.35418, -1.09129, 47.354, -1.09115, 47.35365, -1.09134,
      47.35302, -1.09194, 47.35277, -1.09202, 47.35221, -1.0919, 47.35174,
      -1.09145, 47.35165, -1.09088, 47.35081, -1.0905, 47.35075, -1.09076,
      47.35038, -1.09046, 47.35012, -1.0898, 47.34997, -1.08919, 47.34997,
      -1.08826, 47.34984, -1.08797, 47.3497, -1.08775, 47.34901, -1.08722,
      47.34822, -1.08691, 47.34803, -1.08578, 47.3478, -1.0856, 47.34767,
      -1.08508, 47.3476, -1.08398, 47.34788, -1.08183, 47.34772, -1.08056,
      47.34776, -1.07717, 47.34615, -1.0747, 47.34562, -1.07343, 47.3451,
      -1.0728, 47.34468, -1.07508, 47.3432, -1.07576, 47.3432, -1.07608,
      47.34119, -1.07727, 47.34105, -1.07743, 47.34108, -1.07806, 47.34099,
      -1.07923, 47.3401, -1.07925, 47.34007, -1.07976, 47.34015, -1.07977,
      47.34018, -1.07999, 47.34013, -1.08037, 47.34039, -1.08213, 47.34034,
      -1.08264, 47.3399, -1.08446, 47.33975, -1.08476, 47.33998, -1.08502,
      47.33866, -1.08492, 47.33725, -1.08548, 47.33675, -1.08544, 47.3361,
      -1.08483, 47.33583, -1.08437, 47.33525, -1.08382, 47.33497, -1.0837,
      47.33469, -1.0838, 47.33423, -1.08377, 47.33408, -1.08389, 47.33396,
      -1.08363, 47.33381, -1.08358, 47.33306, -1.08383, 47.33253, -1.08381,
      47.33233, -1.08273, 47.33156, -1.08203, 47.32999, -1.08128, 47.32911,
      -1.08119, 47.32824, -1.08157, 47.32818, -1.08167, 47.32821, -1.08204,
      47.32806, -1.08219, 47.32794, -1.08294, 47.32793, -1.08365, 47.32774,
      -1.08391, 47.3275, -1.08463, 47.32743, -1.08509, 47.32754, -1.08541,
      47.32749, -1.08586, 47.32703, -1.08705, 47.3268, -1.08747, 47.3261,
      -1.08807, 47.32568, -1.08858, 47.32526, -1.08879, 47.32467, -1.08895,
      47.32399, -1.08898, 47.323, -1.08868, 47.32091, -1.08749, 47.31958,
      -1.08613, 47.31874, -1.08587, 47.31839, -1.08561, 47.31659, -1.08553,
      47.31597, -1.08504, 47.31584, -1.08516, 47.31614, -1.08782, 47.3165,
      -1.09017, 47.31643, -1.09027, 47.31696, -1.09511, 47.31684, -1.09619,
      47.31685, -1.09762, 47.31688, -1.0988, 47.31691, -1.09896, 47.31707,
      -1.0991, 47.31706, -1.0999, 47.31682, -1.10033, 47.31685, -1.10041,
      47.31441, -1.10191, 47.31291, -1.10254, 47.31272, -1.10438, 47.31267,
      -1.10585, 47.31252, -1.10646, 47.31252, -1.1072, 47.31292, -1.10827,
      47.31437, -1.11082, 47.31532, -1.11186, 47.31594, -1.11478, 47.31638,
      -1.11594, 47.31647, -1.11752, 47.31623, -1.11866, 47.31596, -1.11908,
      47.31601, -1.11944, 47.31596, -1.1208, 47.31461, -1.12122, 47.31178,
      -1.12117, 47.31224, -1.12475, 47.31219, -1.12695, 47.31182, -1.12667,
      47.3105, -1.12607, 47.30964, -1.12531, 47.30886, -1.12513, 47.3084,
      -1.12534, 47.30776, -1.12486, 47.30644, -1.12465, 47.3055, -1.12478,
      47.30497, -1.12501, 47.30442, -1.12547, 47.30372, -1.12635, 47.30213,
      -1.12709, 47.30197, -1.1273, 47.30122, -1.1292, 47.3, -1.13085, 47.29953,
      -1.13192, 47.29826, -1.13395, 47.29806, -1.13485, 47.29785, -1.13694,
      47.29808, -1.13859, 47.29741, -1.13822, 47.29662, -1.13797, 47.29431,
      -1.13777, 47.29317, -1.13749, 47.29188, -1.13683, 47.29045, -1.13578,
      47.28909, -1.13492, 47.28808, -1.13442, 47.2867, -1.13404, 47.28518,
      -1.13559, 47.2852, -1.13652, 47.28505, -1.13684, 47.28417, -1.13764,
      47.28281, -1.13947, 47.28232, -1.14001, 47.28004, -1.14021, 47.27881,
      -1.14015, 47.27844, -1.14033, 47.27726, -1.14154, 47.27685, -1.14226,
      47.27651, -1.14311, 47.27563, -1.1446, 47.27187, -1.1468, 47.27151,
      -1.14722, 47.27039, -1.14896, 47.2682, -1.14985, 47.2664, -1.14787,
      47.26551, -1.14734, 47.26404, -1.14685, 47.26347, -1.14655, 47.26285,
      -1.14641, 47.26288, -1.14685, 47.2624, -1.14819, 47.26201, -1.15041,
      47.26202, -1.15099, 47.26186, -1.1513, 47.26201, -1.15216, 47.26171,
      -1.15286, 47.26154, -1.15304, 47.26151, -1.1538, 47.26136, -1.15399,
      47.26098, -1.15401, 47.26083, -1.15462, 47.26044, -1.15508, 47.26001,
      -1.1553, 47.25997, -1.15565, 47.2597, -1.15572, 47.25942, -1.15653,
      47.259, -1.15692, 47.25898, -1.15731, 47.25877, -1.15744, 47.25862,
      -1.15776, 47.25843, -1.15785, 47.25818, -1.15819, 47.25776, -1.15908,
      47.25757, -1.15929, 47.25752, -1.16017, 47.25712, -1.16096, 47.25707,
      -1.16123, 47.25715, -1.16154, 47.25705, -1.16178, 47.25719, -1.16239,
      47.25709, -1.163, 47.25724, -1.1633, 47.25717, -1.16346, 47.25724,
      -1.16433, 47.25696, -1.16496, 47.25676, -1.16594, 47.25631, -1.16687,
      47.25635, -1.16721, 47.2562, -1.16764, 47.25619, -1.16822, 47.2559,
      -1.1685, 47.25576, -1.1692, 47.25532, -1.17019, 47.25543, -1.17054,
      47.25535, -1.17074, 47.25499, -1.1711, 47.25489, -1.17142, 47.25474,
      -1.1712, 47.25465, -1.17121, 47.25425, -1.17202, 47.25371, -1.17229,
      47.25366, -1.17262, 47.2534, -1.1724, 47.25322, -1.17269, 47.25292,
      -1.17284, 47.25263, -1.17273, 47.25233, -1.17283, 47.25229, -1.17312,
      47.25219, -1.17328, 47.25187, -1.17326, 47.25146, -1.17374, 47.25138,
      -1.17411, 47.25116, -1.17398, 47.25092, -1.17429, 47.25073, -1.17414,
      47.25008, -1.17423, 47.24995, -1.17413, 47.24985, -1.17419, 47.24976,
      -1.17452, 47.24956, -1.1745, 47.24929, -1.17473, 47.24897, -1.17475,
      47.24879, -1.17504, 47.24876, -1.17536, 47.2485, -1.17534, 47.24828,
      -1.17567, 47.24802, -1.17565, 47.24783, -1.17593, 47.24762, -1.17603,
      47.2475, -1.17639, 47.24731, -1.17638, 47.24711, -1.17655, 47.24691,
      -1.17687, 47.2468, -1.17724, 47.24636, -1.17786, 47.24593, -1.17771,
      47.24561, -1.17788, 47.24551, -1.17815, 47.24515, -1.17805, 47.24488,
      -1.17834, 47.24454, -1.17827, 47.244, -1.17868, 47.24376, -1.17906,
      47.24324, -1.17932, 47.24271, -1.17938, 47.24243, -1.17986, 47.24217,
      -1.18003, 47.24207, -1.18036, 47.24177, -1.18082, 47.2409, -1.18139,
      47.24113, -1.18223, 47.24176, -1.18341, 47.24199, -1.18364, 47.24254,
      -1.18486, 47.24293, -1.18698, 47.24348, -1.18792, 47.2435, -1.18832,
      47.24372, -1.18896, 47.24396, -1.18923, 47.24445, -1.19008, 47.24578,
      -1.19114, 47.24711, -1.19248, 47.24807, -1.19395, 47.24849, -1.19503,
      47.24982, -1.19497, 47.25088, -1.19467, 47.25216, -1.19413, 47.25218,
      -1.19501, 47.25232, -1.1957, 47.2527, -1.19679, 47.253, -1.19802,
      47.25303, -1.199, 47.25297, -1.19948, 47.25301, -1.20022, 47.25292,
      -1.20306, 47.25304, -1.20492, 47.25319, -1.20738, 47.25268, -1.20807,
      47.24974, -1.21284, 47.24587, -1.21289, 47.24489, -1.21434, 47.24463,
      -1.21536, 47.24468, -1.21597, 47.24451, -1.2173, 47.24436, -1.21754,
      47.24429, -1.218, 47.24382, -1.21861, 47.24376, -1.21886, 47.24361,
      -1.21897, 47.24338, -1.21937, 47.24334, -1.21987, 47.24317, -1.22023,
      47.24301, -1.22032, 47.24289, -1.22069, 47.24289, -1.22113, 47.2418,
      -1.2226, 47.24175, -1.22326, 47.24098, -1.22527, 47.2409, -1.22597,
      47.24073, -1.2266, 47.23985, -1.22818, 47.23966, -1.2291, 47.23962,
      -1.22967, 47.23947, -1.23016, 47.23961, -1.23076, 47.23966, -1.23142
    ]
  ]
};

export const MARENNES_HIERS_BROUAGE: LocationState = {
  id: 'FR-17219',
  name: 'Marennes-Hiers-Brouage',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      45.82247, -1.14024, 45.82267, -1.13955, 45.82286, -1.1395, 45.8245,
      -1.13801, 45.82553, -1.1372, 45.82608, -1.13661, 45.82656, -1.13589,
      45.82674, -1.13548, 45.82675, -1.13457, 45.82684, -1.13422, 45.82758,
      -1.13349, 45.82805, -1.13204, 45.82951, -1.13011, 45.83412, -1.12608,
      45.83546, -1.12548, 45.83588, -1.12519, 45.83564, -1.12475, 45.83526,
      -1.12368, 45.83512, -1.12353, 45.83544, -1.12306, 45.83522, -1.1221,
      45.83566, -1.12165, 45.83669, -1.12003, 45.83755, -1.11917, 45.83852,
      -1.11798, 45.83922, -1.11751, 45.84089, -1.11769, 45.84108, -1.11801,
      45.84119, -1.11805, 45.84297, -1.11749, 45.84308, -1.11992, 45.84352,
      -1.12127, 45.84397, -1.12102, 45.84432, -1.12036, 45.84444, -1.11992,
      45.84522, -1.1196, 45.84528, -1.11951, 45.84505, -1.1186, 45.84476,
      -1.11677, 45.84437, -1.11546, 45.84484, -1.11483, 45.84541, -1.1132,
      45.84469, -1.11338, 45.8445, -1.1116, 45.84431, -1.11066, 45.84429,
      -1.11028, 45.84476, -1.10951, 45.84497, -1.10881, 45.84587, -1.10737,
      45.84636, -1.10786, 45.84747, -1.10829, 45.84863, -1.10822, 45.84876,
      -1.1085, 45.84876, -1.10884, 45.84858, -1.1094, 45.84825, -1.11002,
      45.84815, -1.11128, 45.84852, -1.11105, 45.84882, -1.11104, 45.85005,
      -1.11179, 45.85059, -1.10946, 45.85067, -1.10844, 45.85129, -1.10696,
      45.85153, -1.10603, 45.85211, -1.1046, 45.85237, -1.10367, 45.85247,
      -1.10293, 45.85231, -1.10185, 45.85254, -1.10167, 45.85325, -1.10197,
      45.85323, -1.10203, 45.85345, -1.1021, 45.85399, -1.1026, 45.85652,
      -1.09703, 45.85662, -1.09667, 45.85752, -1.09737, 45.86079, -1.09938,
      45.86151, -1.10008, 45.86182, -1.10015, 45.86235, -1.09988, 45.86275,
      -1.09992, 45.86318, -1.10079, 45.86354, -1.10118, 45.8643, -1.1009,
      45.86487, -1.10086, 45.86504, -1.10106, 45.8652, -1.10147, 45.86564,
      -1.10281, 45.86579, -1.10416, 45.86598, -1.10477, 45.8663, -1.10534,
      45.86686, -1.10592, 45.86704, -1.10553, 45.86746, -1.10575, 45.86911,
      -1.10618, 45.87133, -1.10466, 45.87299, -1.10335, 45.87424, -1.10254,
      45.87572, -1.10215, 45.87668, -1.10203, 45.8786, -1.10121, 45.87881,
      -1.10095, 45.87932, -1.10072, 45.879, -1.09973, 45.87881, -1.09951,
      45.87869, -1.09878, 45.87889, -1.09861, 45.87784, -1.09335, 45.87786,
      -1.09218, 45.87799, -1.09051, 45.87785, -1.08978, 45.8777, -1.08939,
      45.87649, -1.08778, 45.87586, -1.08673, 45.8747, -1.08416, 45.87159,
      -1.08068, 45.87122, -1.07997, 45.87087, -1.07891, 45.87076, -1.07845,
      45.87072, -1.07785, 45.87095, -1.07619, 45.87084, -1.07581, 45.87029,
      -1.07478, 45.87017, -1.07393, 45.87027, -1.07336, 45.87091, -1.07241,
      45.87098, -1.07189, 45.87077, -1.07102, 45.86995, -1.07017, 45.86978,
      -1.06959, 45.86977, -1.06799, 45.86947, -1.06407, 45.86912, -1.0629,
      45.86844, -1.05976, 45.86872, -1.05638, 45.86869, -1.05545, 45.86854,
      -1.0545, 45.86806, -1.05265, 45.86781, -1.05201, 45.86696, -1.05055,
      45.86561, -1.04859, 45.86255, -1.04222, 45.86224, -1.04104, 45.86232,
      -1.03988, 45.86262, -1.03895, 45.8626, -1.03765, 45.8624, -1.03595,
      45.86205, -1.03515, 45.86153, -1.03451, 45.86102, -1.03295, 45.8596,
      -1.0302, 45.85939, -1.03043, 45.85891, -1.02945, 45.8581, -1.0282,
      45.8579, -1.02767, 45.85751, -1.02732, 45.85666, -1.02622, 45.85595,
      -1.02562, 45.85552, -1.02499, 45.85542, -1.0244, 45.85426, -1.02324,
      45.8529, -1.02244, 45.8522, -1.02218, 45.85176, -1.02216, 45.85109,
      -1.02153, 45.85045, -1.0207, 45.84956, -1.01888, 45.84954, -1.01867,
      45.84965, -1.01837, 45.84977, -1.01757, 45.84972, -1.01692, 45.84956,
      -1.01648, 45.84872, -1.01489, 45.84848, -1.01451, 45.84826, -1.01436,
      45.84817, -1.0141, 45.84707, -1.01259, 45.84705, -1.01237, 45.84585,
      -1.01158, 45.84384, -1.01088, 45.84223, -1.01067, 45.84146, -1.0107,
      45.84086, -1.01075, 45.84001, -1.011, 45.83972, -1.0112, 45.83911,
      -1.01202, 45.83827, -1.0122, 45.8374, -1.01208, 45.83703, -1.0119,
      45.83668, -1.01187, 45.83575, -1.01134, 45.83487, -1.01073, 45.83457,
      -1.01045, 45.83432, -1.01003, 45.83373, -1.00953, 45.83291, -1.00907,
      45.83241, -1.0082, 45.83184, -1.00689, 45.83203, -1.0067, 45.83172,
      -1.00659, 45.83159, -1.00613, 45.83146, -1.0049, 45.8312, -1.00361,
      45.83047, -1.00153, 45.83038, -1.00108, 45.82989, -1.00052, 45.82953,
      -1.00087, 45.8293, -1.00096, 45.82849, -1.00053, 45.82788, -1.00042,
      45.82577, -1.00209, 45.8241, -1.0036, 45.8236, -1.00421, 45.82234,
      -1.00624, 45.82161, -1.0079, 45.82088, -1.00894, 45.81956, -1.00986,
      45.81931, -1.00996, 45.81889, -1.00996, 45.81862, -1.01027, 45.81849,
      -1.01055, 45.81848, -1.01087, 45.81886, -1.01161, 45.81889, -1.01249,
      45.8192, -1.01377, 45.82009, -1.01491, 45.82034, -1.01586, 45.82005,
      -1.01753, 45.81984, -1.01798, 45.81944, -1.0184, 45.81841, -1.01901,
      45.81744, -1.01935, 45.81663, -1.01999, 45.81599, -1.0198, 45.81506,
      -1.01982, 45.81431, -1.01932, 45.81387, -1.01919, 45.81307, -1.01961,
      45.81266, -1.02034, 45.81237, -1.02046, 45.81154, -1.01982, 45.8114,
      -1.01939, 45.81133, -1.0184, 45.81109, -1.01794, 45.81094, -1.01711,
      45.81057, -1.01686, 45.81013, -1.01717, 45.80974, -1.01771, 45.80931,
      -1.01893, 45.8093, -1.01971, 45.80902, -1.02002, 45.80827, -1.02011,
      45.80728, -1.01915, 45.8071, -1.01915, 45.80675, -1.01986, 45.80673,
      -1.02008, 45.80698, -1.02085, 45.80695, -1.02139, 45.80675, -1.02157,
      45.80629, -1.02146, 45.8059, -1.02157, 45.80567, -1.0218, 45.80527,
      -1.02265, 45.80537, -1.02354, 45.80558, -1.02396, 45.80547, -1.02472,
      45.80555, -1.02486, 45.80584, -1.02499, 45.80594, -1.02532, 45.80556,
      -1.02581, 45.80529, -1.02633, 45.80484, -1.0265, 45.80447, -1.02726,
      45.8043, -1.02803, 45.80445, -1.02855, 45.80432, -1.0293, 45.80437,
      -1.02948, 45.80502, -1.0302, 45.80556, -1.03008, 45.8068, -1.0304,
      45.80738, -1.03096, 45.80778, -1.03151, 45.80809, -1.03167, 45.8084,
      -1.032, 45.80891, -1.03304, 45.80927, -1.03322, 45.80965, -1.03378,
      45.82008, -1.04997, 45.81998, -1.05011, 45.82009, -1.05053, 45.82126,
      -1.05309, 45.82137, -1.05406, 45.82164, -1.05464, 45.82167, -1.05505,
      45.82213, -1.05637, 45.8221, -1.05697, 45.82222, -1.05761, 45.82243,
      -1.05811, 45.82249, -1.05888, 45.82281, -1.05952, 45.82306, -1.05976,
      45.82329, -1.06094, 45.82349, -1.06144, 45.82366, -1.06223, 45.82379,
      -1.06235, 45.82388, -1.06262, 45.8243, -1.06274, 45.82462, -1.06262,
      45.82481, -1.06266, 45.82506, -1.06295, 45.82519, -1.06338, 45.82551,
      -1.06369, 45.82678, -1.06425, 45.82711, -1.06424, 45.82897, -1.06604,
      45.8294, -1.06666, 45.8297, -1.06689, 45.83019, -1.06764, 45.83029,
      -1.06812, 45.83029, -1.06877, 45.83006, -1.06976, 45.83017, -1.07067,
      45.83069, -1.07274, 45.83079, -1.07363, 45.83093, -1.07392, 45.82844,
      -1.08062, 45.828, -1.08164, 45.82771, -1.08204, 45.82389, -1.08532,
      45.8233, -1.08562, 45.82252, -1.08574, 45.82241, -1.08585, 45.82219,
      -1.08571, 45.82087, -1.08591, 45.81723, -1.08831, 45.81691, -1.08845,
      45.81687, -1.08839, 45.8164, -1.08854, 45.81587, -1.08854, 45.81555,
      -1.08878, 45.81528, -1.08859, 45.81518, -1.08884, 45.81522, -1.08921,
      45.81514, -1.08984, 45.81495, -1.08996, 45.81451, -1.09066, 45.81439,
      -1.09105, 45.81406, -1.09157, 45.81382, -1.09166, 45.81341, -1.09199,
      45.81331, -1.0919, 45.8133, -1.09151, 45.81314, -1.09142, 45.8128,
      -1.09187, 45.81218, -1.09201, 45.8117, -1.09255, 45.81162, -1.09306,
      45.81126, -1.09348, 45.8107, -1.09367, 45.81015, -1.09416, 45.8095,
      -1.09399, 45.80914, -1.09439, 45.80868, -1.09513, 45.80846, -1.09505,
      45.80695, -1.09525, 45.80636, -1.09555, 45.80569, -1.09529, 45.80546,
      -1.0953, 45.80516, -1.09555, 45.80489, -1.09633, 45.80468, -1.09648,
      45.80434, -1.09635, 45.80398, -1.09644, 45.80329, -1.09634, 45.80271,
      -1.0959, 45.80108, -1.09547, 45.80033, -1.09549, 45.79925, -1.0961,
      45.79921, -1.09638, 45.79943, -1.09697, 45.79921, -1.09855, 45.79907,
      -1.0988, 45.79859, -1.09877, 45.79838, -1.09908, 45.79832, -1.09938,
      45.79848, -1.10034, 45.79825, -1.10169, 45.79829, -1.10236, 45.79822,
      -1.1031, 45.79886, -1.10407, 45.79915, -1.10417, 45.79962, -1.1041,
      45.80049, -1.10502, 45.80047, -1.10537, 45.79989, -1.10769, 45.79968,
      -1.10822, 45.79953, -1.10901, 45.79958, -1.10983, 45.79974, -1.11046,
      45.80027, -1.11187, 45.80025, -1.1123, 45.8, -1.1125, 45.79973, -1.11288,
      45.79954, -1.11361, 45.79941, -1.11382, 45.79861, -1.11458, 45.79824,
      -1.1153, 45.79736, -1.11618, 45.79602, -1.11682, 45.79489, -1.11703,
      45.79416, -1.11706, 45.79273, -1.11688, 45.79228, -1.11707, 45.79191,
      -1.11758, 45.79172, -1.11809, 45.79138, -1.11963, 45.79096, -1.12031,
      45.78953, -1.12171, 45.7863, -1.12526, 45.78901, -1.1286, 45.79247,
      -1.13258, 45.79892, -1.13916, 45.80116, -1.1351, 45.80131, -1.13526,
      45.80177, -1.13526, 45.80204, -1.13429, 45.80248, -1.13448, 45.80258,
      -1.13419, 45.80347, -1.13461, 45.8036, -1.13445, 45.80395, -1.13464,
      45.80401, -1.13479, 45.80447, -1.13497, 45.80443, -1.13517, 45.80424,
      -1.13529, 45.80428, -1.13551, 45.80483, -1.13563, 45.80502, -1.13563,
      45.80501, -1.13552, 45.80559, -1.13578, 45.80571, -1.13564, 45.8063,
      -1.13597, 45.80651, -1.13583, 45.80675, -1.13584, 45.80833, -1.13617,
      45.80871, -1.13612, 45.80872, -1.13604, 45.80878, -1.13602, 45.8088,
      -1.13638, 45.80925, -1.13648, 45.80923, -1.13599, 45.80964, -1.13597,
      45.80961, -1.13518, 45.80989, -1.13524, 45.80996, -1.13644, 45.81044,
      -1.13653, 45.81042, -1.13596, 45.81064, -1.13595, 45.81065, -1.13607,
      45.81088, -1.13608, 45.81091, -1.1367, 45.81118, -1.13674, 45.81121,
      -1.13721, 45.81141, -1.13724, 45.81141, -1.13663, 45.8121, -1.13672,
      45.81211, -1.13682, 45.81259, -1.13692, 45.81261, -1.13717, 45.81284,
      -1.13719, 45.81302, -1.13715, 45.81301, -1.13676, 45.81463, -1.13684,
      45.81593, -1.13793, 45.81648, -1.13821, 45.81737, -1.13846, 45.81838,
      -1.13857, 45.81908, -1.13839, 45.8194, -1.13822, 45.81985, -1.13736,
      45.82, -1.13734, 45.82037, -1.1375, 45.82112, -1.13823, 45.82163,
      -1.13885, 45.82179, -1.13915, 45.82184, -1.13942, 45.82257, -1.13974,
      45.82247, -1.14024
    ]
  ]
};

export const LES_ABRETS_EN_DAUPHINE: LocationState = {
  id: 'FR-38001',
  name: 'Les Abrets en Dauphiné',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      45.53327, 5.57211, 45.52996, 5.55649, 45.53052, 5.55668, 45.53102,
      5.55747, 45.53277, 5.55916, 45.53444, 5.55486, 45.5352, 5.54665, 45.53517,
      5.54461, 45.53529, 5.54426, 45.53567, 5.54412, 45.53654, 5.5447, 45.53692,
      5.54296, 45.53738, 5.54179, 45.53773, 5.54199, 45.53785, 5.54226,
      45.53846, 5.54297, 45.5386, 5.54363, 45.53882, 5.54386, 45.53899, 5.54429,
      45.53998, 5.54488, 45.54021, 5.54557, 45.5411, 5.54609, 45.54361, 5.54816,
      45.54507, 5.5492, 45.54599, 5.55003, 45.54678, 5.55052, 45.54723, 5.55066,
      45.54789, 5.55075, 45.54871, 5.55056, 45.55428, 5.54898, 45.55366,
      5.54417, 45.55525, 5.54106, 45.55555, 5.54072, 45.55606, 5.54039, 45.5569,
      5.54045, 45.55795, 5.54076, 45.55814, 5.5409, 45.5586, 5.54162, 45.56002,
      5.54252, 45.56026, 5.54284, 45.56019, 5.54321, 45.56048, 5.54376,
      45.56047, 5.54433, 45.56179, 5.54567, 45.56483, 5.55233, 45.56523,
      5.55274, 45.56534, 5.55505, 45.56653, 5.55725, 45.56767, 5.56167,
      45.56865, 5.56185, 45.56825, 5.56402, 45.56826, 5.56512, 45.56837,
      5.56562, 45.56812, 5.56803, 45.56754, 5.56923, 45.56752, 5.5698, 45.56719,
      5.57043, 45.56701, 5.57054, 45.56699, 5.57099, 45.56723, 5.57147,
      45.56763, 5.5718, 45.56937, 5.5728, 45.5693, 5.57417, 45.56891, 5.57541,
      45.5689, 5.57659, 45.56858, 5.57778, 45.56814, 5.57884, 45.56662, 5.58101,
      45.56569, 5.58251, 45.56496, 5.58342, 45.56433, 5.58482, 45.56302,
      5.58684, 45.56264, 5.5872, 45.56102, 5.58821, 45.56037, 5.58907, 45.55973,
      5.58968, 45.55926, 5.59029, 45.5575, 5.59176, 45.55688, 5.59251, 45.55641,
      5.59339, 45.55467, 5.5975, 45.55412, 5.59959, 45.55365, 5.60176, 45.55287,
      5.60369, 45.55246, 5.60426, 45.55227, 5.60513, 45.55128, 5.60768,
      45.55117, 5.60841, 45.55137, 5.61016, 45.55135, 5.61174, 45.55082,
      5.61164, 45.55045, 5.61144, 45.55028, 5.61163, 45.55013, 5.61221, 45.5497,
      5.61309, 45.54966, 5.61361, 45.54949, 5.61416, 45.54866, 5.61614,
      45.54863, 5.61651, 45.54833, 5.61655, 45.54779, 5.61595, 45.54634,
      5.61532, 45.54518, 5.61555, 45.54388, 5.61628, 45.54332, 5.61706, 45.5428,
      5.61742, 45.54256, 5.61697, 45.54261, 5.61659, 45.54194, 5.61501,
      45.54143, 5.61402, 45.54007, 5.61244, 45.53484, 5.60723, 45.53172,
      5.60589, 45.53122, 5.60752, 45.53055, 5.60883, 45.52948, 5.61004, 45.5293,
      5.61003, 45.52871, 5.60971, 45.52864, 5.61017, 45.52891, 5.61086,
      45.52895, 5.61141, 45.5285, 5.61224, 45.52787, 5.6119, 45.5269, 5.61413,
      45.52536, 5.61556, 45.52498, 5.61612, 45.52459, 5.61565, 45.52441,
      5.61576, 45.52366, 5.61668, 45.52369, 5.617, 45.52386, 5.61733, 45.5239,
      5.619, 45.52335, 5.61948, 45.52291, 5.61933, 45.5215, 5.61815, 45.52096,
      5.61799, 45.52087, 5.61824, 45.52066, 5.61985, 45.52007, 5.61984,
      45.51967, 5.61915, 45.51933, 5.61888, 45.5186, 5.61913, 45.5185, 5.62084,
      45.52072, 5.62298, 45.52068, 5.62391, 45.52039, 5.6249, 45.52003, 5.62574,
      45.51942, 5.62636, 45.5181, 5.6265, 45.51786, 5.62716, 45.5169, 5.62761,
      45.51572, 5.62786, 45.51471, 5.62851, 45.5138, 5.62877, 45.51353, 5.62869,
      45.51266, 5.62787, 45.51133, 5.62696, 45.51035, 5.62677, 45.51026,
      5.62664, 45.51037, 5.62594, 45.50998, 5.62582, 45.50989, 5.62547,
      45.50953, 5.62595, 45.50939, 5.62588, 45.50952, 5.62644, 45.50948,
      5.62657, 45.50924, 5.62685, 45.50889, 5.6275, 45.50846, 5.62746, 45.50835,
      5.62694, 45.50785, 5.62681, 45.50769, 5.62616, 45.50721, 5.62579,
      45.50705, 5.62549, 45.50674, 5.62543, 45.50653, 5.62523, 45.50696,
      5.62638, 45.50709, 5.6271, 45.50695, 5.62785, 45.50667, 5.62828, 45.5065,
      5.62885, 45.50641, 5.62829, 45.50622, 5.62826, 45.50604, 5.62773, 45.5058,
      5.62754, 45.50525, 5.62762, 45.50479, 5.62748, 45.50455, 5.62751,
      45.50443, 5.62764, 45.50447, 5.62801, 45.50441, 5.62817, 45.50417,
      5.62824, 45.50354, 5.62813, 45.50312, 5.62831, 45.50299, 5.62881, 45.5028,
      5.62871, 45.50279, 5.62828, 45.50244, 5.62837, 45.50221, 5.62805,
      45.50201, 5.62827, 45.5018, 5.62803, 45.50158, 5.62806, 45.50133, 5.62778,
      45.50112, 5.62784, 45.50104, 5.6275, 45.50084, 5.6274, 45.50055, 5.62766,
      45.50041, 5.62764, 45.50048, 5.62724, 45.50014, 5.62729, 45.49993,
      5.62712, 45.50002, 5.62673, 45.49978, 5.62627, 45.49973, 5.62589,
      45.49923, 5.6259, 45.49927, 5.62513, 45.49912, 5.62503, 45.49893, 5.62522,
      45.49873, 5.62468, 45.49788, 5.62379, 45.49716, 5.62227, 45.49673,
      5.62045, 45.49641, 5.61981, 45.49543, 5.61881, 45.49511, 5.61874,
      45.49473, 5.61797, 45.49408, 5.61769, 45.49363, 5.61692, 45.49351,
      5.61685, 45.49279, 5.61739, 45.49178, 5.61779, 45.49081, 5.61765,
      45.49131, 5.61732, 45.49229, 5.61605, 45.49263, 5.61396, 45.49297,
      5.61331, 45.49292, 5.61213, 45.49304, 5.61116, 45.49285, 5.60982,
      45.49228, 5.6088, 45.49109, 5.60731, 45.49089, 5.60671, 45.49269, 5.60483,
      45.49182, 5.60337, 45.4929, 5.60301, 45.49371, 5.60165, 45.49457, 5.60054,
      45.49569, 5.59968, 45.49806, 5.59762, 45.49957, 5.59521, 45.50066,
      5.59244, 45.50131, 5.59027, 45.50209, 5.5882, 45.50272, 5.58605, 45.50277,
      5.58567, 45.50287, 5.58536, 45.50328, 5.58211, 45.50257, 5.5804, 45.50344,
      5.57973, 45.5048, 5.5789, 45.50505, 5.57859, 45.50544, 5.57754, 45.50593,
      5.57684, 45.50654, 5.57716, 45.50696, 5.57753, 45.50721, 5.57703, 45.5077,
      5.57719, 45.50911, 5.5783, 45.51013, 5.57887, 45.51083, 5.57942, 45.51191,
      5.58422, 45.51276, 5.58747, 45.51348, 5.58905, 45.5152, 5.59172, 45.51695,
      5.59361, 45.51837, 5.59541, 45.51952, 5.59533, 45.52219, 5.5956, 45.52307,
      5.59543, 45.52414, 5.59546, 45.5245, 5.5957, 45.52538, 5.59592, 45.52581,
      5.5962, 45.52634, 5.59619, 45.52851, 5.59688, 45.52917, 5.59781, 45.52944,
      5.59807, 45.52959, 5.598, 45.52998, 5.59741, 45.53021, 5.59735, 45.53044,
      5.59434, 45.53143, 5.58629, 45.53138, 5.58522, 45.53153, 5.58449,
      45.53221, 5.58245, 45.5325, 5.58069, 45.53382, 5.57458, 45.53327, 5.57211
    ]
  ]
};

export const VILLEMAURY: LocationState = {
  id: 'FR-28330',
  name: 'Villemaury',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.98227, 1.52012, 47.98493, 1.51472, 47.98279, 1.51474, 47.98436,
      1.50889, 47.98693, 1.50699, 47.98633, 1.50446, 47.98802, 1.50342,
      47.98509, 1.49897, 47.98736, 1.49367, 47.98848, 1.49478, 47.9902, 1.49043,
      47.9906, 1.48961, 47.99299, 1.48569, 47.99569, 1.48239, 47.9965, 1.4816,
      47.99704, 1.48155, 47.99757, 1.48094, 47.99847, 1.47852, 47.99945,
      1.47499, 47.99975, 1.47353, 48.00184, 1.46908, 48.00263, 1.46547,
      48.00275, 1.46519, 48.00358, 1.46379, 48.00609, 1.4609, 48.00786, 1.4578,
      48.0085, 1.45555, 48.00919, 1.45248, 48.01063, 1.44721, 48.01164, 1.44161,
      48.01199, 1.43925, 48.01157, 1.43814, 48.01054, 1.43649, 48.00976,
      1.43506, 48.00867, 1.43266, 48.00987, 1.43252, 48.0118, 1.43208, 48.01332,
      1.43118, 48.01426, 1.43043, 48.01624, 1.42999, 48.01731, 1.42997,
      48.01809, 1.43103, 48.01822, 1.43103, 48.01946, 1.42866, 48.01999,
      1.42732, 48.02013, 1.42725, 48.02107, 1.42793, 48.02153, 1.42657,
      48.02236, 1.42519, 48.02367, 1.42333, 48.02556, 1.41949, 48.02761,
      1.41653, 48.02822, 1.41545, 48.03248, 1.40549, 48.03379, 1.40083,
      48.03696, 1.39282, 48.04026, 1.38596, 48.04089, 1.38484, 48.0429, 1.38185,
      48.04471, 1.37785, 48.04637, 1.37449, 48.04745, 1.3718, 48.04895, 1.37313,
      48.04893, 1.37368, 48.04963, 1.37771, 48.05009, 1.37871, 48.05064,
      1.37951, 48.05128, 1.38084, 48.05155, 1.38097, 48.05449, 1.38123,
      48.05709, 1.38167, 48.0596, 1.38156, 48.06293, 1.38177, 48.06235, 1.38768,
      48.06257, 1.38768, 48.06267, 1.38781, 48.06246, 1.39029, 48.06472,
      1.39112, 48.06476, 1.39133, 48.06433, 1.39328, 48.06429, 1.39395,
      48.06465, 1.39448, 48.06845, 1.39709, 48.0685, 1.39723, 48.06761, 1.39892,
      48.06673, 1.40115, 48.06611, 1.4021, 48.06611, 1.4023, 48.06638, 1.40223,
      48.06687, 1.40242, 48.06855, 1.4036, 48.06905, 1.40406, 48.0746, 1.40705,
      48.0801, 1.41022, 48.08078, 1.41268, 48.08351, 1.411, 48.08411, 1.41436,
      48.08364, 1.41431, 48.08633, 1.42353, 48.08775, 1.42896, 48.09022, 1.4282,
      48.09263, 1.4276, 48.09306, 1.42758, 48.0947, 1.42787, 48.09549, 1.43366,
      48.09263, 1.43365, 48.09311, 1.43693, 48.09197, 1.43708, 48.09049,
      1.43676, 48.09081, 1.43827, 48.08926, 1.43907, 48.09042, 1.44504,
      48.08949, 1.44536, 48.09036, 1.44828, 48.0921, 1.44789, 48.09353, 1.45325,
      48.09174, 1.45383, 48.09394, 1.46196, 48.09682, 1.45839, 48.09812,
      1.46325, 48.10491, 1.46294, 48.10951, 1.4765, 48.10885, 1.47691, 48.10805,
      1.47784, 48.10685, 1.4801, 48.10717, 1.48048, 48.10743, 1.48025, 48.10756,
      1.48047, 48.10819, 1.48252, 48.10731, 1.48306, 48.10704, 1.48404,
      48.10719, 1.48461, 48.10708, 1.48521, 48.1098, 1.49295, 48.11025, 1.4944,
      48.11034, 1.49498, 48.11043, 1.49514, 48.11321, 1.49639, 48.11306,
      1.49719, 48.11281, 1.49774, 48.11259, 1.49789, 48.11242, 1.49818,
      48.11212, 1.49968, 48.11165, 1.50037, 48.11157, 1.50072, 48.11047,
      1.50158, 48.11004, 1.50169, 48.10902, 1.50237, 48.10792, 1.50286,
      48.10761, 1.50312, 48.10735, 1.50304, 48.10682, 1.50257, 48.10437, 1.4985,
      48.1038, 1.49778, 48.10315, 1.49737, 48.10243, 1.4976, 48.10162, 1.49842,
      48.10129, 1.49922, 48.10116, 1.50081, 48.1014, 1.50125, 48.10145, 1.50257,
      48.10129, 1.50384, 48.10071, 1.50531, 48.10017, 1.50603, 48.09971,
      1.50652, 48.09903, 1.50692, 48.09824, 1.5077, 48.09809, 1.50745, 48.09492,
      1.50489, 48.0944, 1.50431, 48.09462, 1.50298, 48.09402, 1.50373, 48.09345,
      1.50392, 48.089, 1.50946, 48.08497, 1.50467, 48.08325, 1.50878, 48.08228,
      1.50977, 48.0809, 1.51192, 48.07701, 1.51505, 48.07401, 1.51493, 48.07326,
      1.51361, 48.07257, 1.51204, 48.07203, 1.51024, 48.0705, 1.5071, 48.07221,
      1.50454, 48.07011, 1.50165, 48.06957, 1.50225, 48.07001, 1.50149,
      48.06744, 1.49797, 48.06822, 1.49465, 48.06582, 1.49342, 48.06255,
      1.49223, 48.0589, 1.49053, 48.05511, 1.48885, 48.05153, 1.48668, 48.04815,
      1.48491, 48.04747, 1.48948, 48.04295, 1.48828, 48.04393, 1.4823, 48.03607,
      1.47645, 48.03487, 1.48105, 48.03456, 1.4819, 48.03251, 1.49045, 48.03012,
      1.49872, 48.01772, 1.49279, 48.01766, 1.49718, 48.01436, 1.49714,
      48.01411, 1.4949, 48.01403, 1.49331, 48.01181, 1.49378, 48.01164, 1.49541,
      48.0104, 1.50352, 48.00906, 1.5039, 48.00925, 1.5063, 48.0097, 1.50655,
      48.0103, 1.50625, 48.01174, 1.5179, 48.01187, 1.51849, 48.0108, 1.5186,
      48.01135, 1.52325, 48.00805, 1.52411, 48.00719, 1.52663, 48.00175,
      1.52299, 47.99697, 1.51706, 47.99283, 1.52541, 47.99022, 1.5223, 47.99106,
      1.5206, 47.98991, 1.51884, 47.9893, 1.52013, 47.98921, 1.5202, 47.98683,
      1.51828, 47.98662, 1.51852, 47.98573, 1.52055, 47.98528, 1.51968,
      47.98515, 1.51961, 47.98364, 1.52258, 47.98227, 1.52012
    ]
  ]
};

export const OMBREE_D_ANJOU: LocationState = {
  id: 'FR-49248',
  name: "Ombrée d'Anjou",
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.7749, -1.1403, 47.77522, -1.13924, 47.77536, -1.1371, 47.7768,
      -1.13764, 47.77846, -1.13793, 47.77888, -1.13846, 47.77957, -1.13848,
      47.78209, -1.13956, 47.78251, -1.13817, 47.78221, -1.13707, 47.78259,
      -1.13713, 47.7824, -1.13641, 47.7838, -1.13258, 47.78366, -1.1317,
      47.78368, -1.13093, 47.78396, -1.12951, 47.78398, -1.12694, 47.7842,
      -1.12473, 47.78397, -1.12377, 47.78404, -1.12239, 47.7836, -1.12043,
      47.7836, -1.11934, 47.78343, -1.11757, 47.78339, -1.116, 47.78283,
      -1.11381, 47.78276, -1.11249, 47.78249, -1.11149, 47.7824, -1.11083,
      47.78212, -1.10984, 47.78185, -1.10812, 47.7824, -1.10438, 47.78192,
      -1.09885, 47.78155, -1.09623, 47.78174, -1.09453, 47.78203, -1.09339,
      47.78135, -1.09269, 47.78098, -1.09103, 47.7807, -1.09067, 47.78038,
      -1.08997, 47.7802, -1.08981, 47.78007, -1.0899, 47.77962, -1.08963,
      47.77926, -1.08975, 47.77858, -1.08813, 47.77845, -1.08803, 47.7782,
      -1.08744, 47.7782, -1.08713, 47.77783, -1.08645, 47.77747, -1.08611,
      47.77721, -1.0862, 47.77688, -1.08652, 47.77615, -1.08672, 47.77622,
      -1.08576, 47.77607, -1.08493, 47.77582, -1.08405, 47.77508, -1.0824,
      47.77474, -1.0808, 47.77445, -1.07539, 47.7743, -1.0752, 47.77377,
      -1.07097, 47.7731, -1.06695, 47.7733, -1.0647, 47.77327, -1.06334,
      47.77287, -1.06201, 47.77275, -1.06117, 47.77277, -1.0608, 47.77294,
      -1.06026, 47.77317, -1.05874, 47.77301, -1.05804, 47.77322, -1.05464,
      47.77362, -1.05245, 47.77371, -1.04997, 47.77363, -1.04734, 47.77328,
      -1.047, 47.77296, -1.04622, 47.77288, -1.04579, 47.77305, -1.04548,
      47.77268, -1.04361, 47.77234, -1.04135, 47.77117, -1.04148, 47.77108,
      -1.0411, 47.77148, -1.041, 47.77145, -1.04075, 47.77098, -1.04076,
      47.77072, -1.03932, 47.77179, -1.03927, 47.77192, -1.04057, 47.77223,
      -1.04052, 47.77207, -1.0387, 47.77115, -1.0368, 47.77077, -1.03387,
      47.77068, -1.0336, 47.7706, -1.03152, 47.77079, -1.03085, 47.77078,
      -1.02979, 47.77436, -1.02792, 47.77321, -1.02548, 47.77503, -1.02468,
      47.7755, -1.0278, 47.77682, -1.0272, 47.77648, -1.0258, 47.77735,
      -1.02544, 47.77698, -1.02337, 47.77733, -1.02325, 47.7772, -1.02237,
      47.77768, -1.02218, 47.77774, -1.02241, 47.77827, -1.02235, 47.77778,
      -1.01964, 47.77649, -1.01983, 47.7766, -1.02065, 47.77602, -1.0208,
      47.77567, -1.01961, 47.77597, -1.01948, 47.77571, -1.01811, 47.77482,
      -1.01843, 47.77527, -1.01988, 47.77394, -1.02108, 47.77433, -1.02272,
      47.77263, -1.02338, 47.77251, -1.02274, 47.77186, -1.02079, 47.77178,
      -1.01988, 47.77131, -1.01803, 47.7707, -1.01848, 47.77049, -1.01805,
      47.76988, -1.01562, 47.76969, -1.01563, 47.76963, -1.01425, 47.76752,
      -1.01452, 47.7669, -1.01337, 47.76716, -1.01309, 47.7677, -1.00987,
      47.76808, -1.00906, 47.76832, -1.00619, 47.76685, -1.00653, 47.76664,
      -1.00677, 47.76649, -1.00717, 47.76643, -1.00716, 47.76579, -1.0052,
      47.76504, -1.00231, 47.76427, -0.99976, 47.7643, -0.99851, 47.76414,
      -0.99685, 47.76418, -0.99437, 47.76413, -0.99289, 47.76424, -0.99085,
      47.76396, -0.98993, 47.76384, -0.98868, 47.76361, -0.98858, 47.76313,
      -0.98662, 47.76305, -0.98658, 47.76128, -0.98758, 47.75998, -0.98858,
      47.7595, -0.98684, 47.75548, -0.98758, 47.7575, -0.9903, 47.75722,
      -0.99059, 47.75466, -0.99235, 47.75422, -0.99116, 47.75395, -0.98973,
      47.75324, -0.99095, 47.7531, -0.99048, 47.75217, -0.99089, 47.75193,
      -0.98961, 47.75162, -0.98951, 47.75145, -0.98884, 47.75121, -0.98847,
      47.74857, -0.99187, 47.74853, -0.99173, 47.74769, -0.99343, 47.7466,
      -0.99529, 47.74602, -0.99378, 47.74447, -0.99468, 47.74429, -0.99521,
      47.74467, -0.99723, 47.74391, -0.99737, 47.74238, -0.99819, 47.74055,
      -0.99872, 47.74056, -0.99909, 47.74042, -0.99975, 47.7404, -1.00047,
      47.74085, -1.00342, 47.74099, -1.00814, 47.74281, -1.00785, 47.74309,
      -1.00794, 47.74366, -1.00787, 47.74416, -1.01187, 47.74459, -1.01461,
      47.7448, -1.01709, 47.74514, -1.01992, 47.74521, -1.02121, 47.74407,
      -1.02042, 47.74328, -1.02068, 47.74335, -1.02285, 47.74347, -1.02365,
      47.7404, -1.02578, 47.74029, -1.02603, 47.7405, -1.02686, 47.73876,
      -1.02776, 47.73905, -1.02882, 47.73786, -1.02914, 47.73687, -1.0288,
      47.73637, -1.02894, 47.73572, -1.02942, 47.73495, -1.0303, 47.73405,
      -1.03211, 47.73396, -1.03334, 47.73414, -1.03431, 47.73397, -1.0359,
      47.73411, -1.03723, 47.73363, -1.03834, 47.7335, -1.03946, 47.73315,
      -1.04028, 47.73251, -1.04038, 47.73199, -1.04015, 47.73072, -1.03744,
      47.72752, -1.02999, 47.72446, -1.02279, 47.72393, -1.02136, 47.72026,
      -1.00648, 47.72026, -1.00611, 47.72118, -1.0037, 47.72174, -1.00321,
      47.72255, -1.00178, 47.7231, -1.0013, 47.7242, -1.00076, 47.72485,
      -0.99976, 47.72509, -0.99912, 47.72532, -0.99804, 47.72548, -0.99776,
      47.72467, -0.99584, 47.72431, -0.99475, 47.72346, -0.99297, 47.72322,
      -0.99258, 47.72286, -0.99235, 47.72267, -0.99178, 47.72261, -0.991,
      47.72227, -0.9898, 47.7221, -0.98824, 47.72174, -0.98688, 47.72185,
      -0.98624, 47.72171, -0.98561, 47.72173, -0.9832, 47.72148, -0.98252,
      47.72167, -0.98137, 47.72162, -0.98036, 47.72146, -0.97995, 47.72092,
      -0.97917, 47.72032, -0.9775, 47.71729, -0.97909, 47.71661, -0.97742,
      47.71633, -0.97626, 47.71437, -0.97922, 47.71368, -0.97956, 47.71352,
      -0.97959, 47.7133, -0.9794, 47.7131, -0.97939, 47.71263, -0.9795,
      47.71206, -0.9792, 47.71143, -0.97924, 47.71074, -0.97899, 47.71028,
      -0.97903, 47.71007, -0.97855, 47.70892, -0.97915, 47.70887, -0.9793,
      47.7084, -0.97965, 47.7086, -0.98028, 47.70886, -0.98163, 47.70768,
      -0.98209, 47.70749, -0.98203, 47.70692, -0.98259, 47.70626, -0.9828,
      47.70524, -0.98331, 47.70523, -0.98359, 47.70433, -0.98391, 47.70299,
      -0.98485, 47.70243, -0.98449, 47.70198, -0.98441, 47.70159, -0.98346,
      47.70149, -0.98353, 47.70093, -0.98638, 47.70064, -0.9873, 47.70047,
      -0.98761, 47.7002, -0.98767, 47.69983, -0.98745, 47.6985, -0.98746,
      47.69675, -0.98792, 47.69647, -0.98793, 47.6961, -0.98615, 47.69576,
      -0.98491, 47.69542, -0.98405, 47.69401, -0.98513, 47.69346, -0.98199,
      47.69344, -0.9813, 47.69482, -0.98088, 47.69465, -0.98001, 47.69393,
      -0.98009, 47.69327, -0.98032, 47.69269, -0.98035, 47.69056, -0.98147,
      47.68957, -0.98165, 47.6891, -0.98226, 47.6888, -0.98228, 47.68854,
      -0.98218, 47.68777, -0.98103, 47.68744, -0.98108, 47.6872, -0.98098,
      47.68699, -0.98063, 47.686, -0.9798, 47.68554, -0.97918, 47.68502,
      -0.97895, 47.68426, -0.98011, 47.68457, -0.98094, 47.68425, -0.98207,
      47.68453, -0.98305, 47.68457, -0.98381, 47.68426, -0.98462, 47.6843,
      -0.98606, 47.68424, -0.9863, 47.68388, -0.98667, 47.68384, -0.98686,
      47.68403, -0.98763, 47.68417, -0.98783, 47.68398, -0.98835, 47.68395,
      -0.98882, 47.68407, -0.98938, 47.68436, -0.98976, 47.68438, -0.99084,
      47.68447, -0.99104, 47.68486, -0.99142, 47.6849, -0.99239, 47.68514,
      -0.99277, 47.68522, -0.99366, 47.68548, -0.99403, 47.68554, -0.99428,
      47.68559, -0.99551, 47.68587, -0.99748, 47.68576, -0.99788, 47.6854,
      -0.99821, 47.68451, -0.99998, 47.68445, -1.0003, 47.68451, -1.00082,
      47.6831, -1.00076, 47.68304, -1.00086, 47.68184, -1.00113, 47.68161,
      -1.00025, 47.6808, -1.00047, 47.68053, -1.00104, 47.67966, -1.00121,
      47.67921, -1.00157, 47.67899, -1.00186, 47.67873, -1.00188, 47.67869,
      -1.00246, 47.67734, -1.0025, 47.67735, -1.00238, 47.67605, -1.00248,
      47.67629, -1.00374, 47.67513, -1.00446, 47.67514, -1.00529, 47.6754,
      -1.00529, 47.67542, -1.00566, 47.67385, -1.00567, 47.67373, -1.00593,
      47.67373, -1.00641, 47.6728, -1.00707, 47.67273, -1.00689, 47.67203,
      -1.00748, 47.67147, -1.00679, 47.67119, -1.00664, 47.67047, -1.00714,
      47.67032, -1.00751, 47.66994, -1.00793, 47.6697, -1.00765, 47.66958,
      -1.00625, 47.6693, -1.00652, 47.66827, -1.00695, 47.66804, -1.00763,
      47.668, -1.00852, 47.66734, -1.00932, 47.66741, -1.01021, 47.66667,
      -1.01017, 47.66608, -1.01033, 47.66601, -1.00944, 47.66567, -1.00846,
      47.66537, -1.00812, 47.66542, -1.00843, 47.66509, -1.00852, 47.66493,
      -1.00789, 47.66481, -1.00788, 47.66462, -1.00698, 47.66445, -1.00697,
      47.66428, -1.00677, 47.66412, -1.00692, 47.66368, -1.00694, 47.66223,
      -1.00646, 47.66186, -1.00653, 47.66094, -1.00598, 47.65932, -1.00572,
      47.65918, -1.00566, 47.65916, -1.00544, 47.6589, -1.00547, 47.65889,
      -1.00592, 47.65844, -1.00681, 47.6577, -1.0078, 47.65743, -1.0084,
      47.65733, -1.00788, 47.65783, -1.00725, 47.65763, -1.00703, 47.65749,
      -1.00631, 47.65754, -1.00606, 47.65734, -1.00597, 47.65724, -1.00573,
      47.65724, -1.00547, 47.65703, -1.00539, 47.65699, -1.00527, 47.65668,
      -1.00521, 47.65644, -1.00471, 47.65572, -1.0045, 47.65568, -1.00397,
      47.65538, -1.00397, 47.65531, -1.00431, 47.65422, -1.0038, 47.65412,
      -1.00481, 47.65303, -1.00528, 47.65292, -1.00481, 47.6528, -1.00354,
      47.6512, -1.00467, 47.65073, -1.0055, 47.65021, -1.00596, 47.64984,
      -1.00647, 47.64912, -1.00703, 47.64865, -1.0077, 47.64763, -1.00876,
      47.64718, -1.00903, 47.64822, -1.02062, 47.64898, -1.02302, 47.64898,
      -1.02324, 47.64909, -1.02345, 47.6527, -1.03849, 47.65358, -1.04156,
      47.65543, -1.04664, 47.6563, -1.04873, 47.65696, -1.0511, 47.657,
      -1.05186, 47.65692, -1.05309, 47.65624, -1.05897, 47.65618, -1.06087,
      47.65589, -1.06526, 47.65528, -1.06874, 47.65513, -1.07149, 47.65477,
      -1.07552, 47.6546, -1.07665, 47.65474, -1.07676, 47.65697, -1.08221,
      47.65777, -1.08501, 47.65835, -1.08659, 47.65939, -1.09159, 47.66022,
      -1.09466, 47.66081, -1.09775, 47.66144, -1.10208, 47.66171, -1.10353,
      47.66217, -1.10348, 47.66316, -1.10713, 47.6609, -1.1084, 47.6609,
      -1.1086, 47.66164, -1.11038, 47.65932, -1.11076, 47.65946, -1.11343,
      47.6593, -1.11396, 47.65885, -1.11383, 47.65825, -1.11337, 47.65818,
      -1.11563, 47.65672, -1.11551, 47.6537, -1.11482, 47.65361, -1.11689,
      47.65342, -1.11892, 47.65334, -1.12186, 47.65312, -1.12237, 47.65287,
      -1.12338, 47.65191, -1.12587, 47.64883, -1.12519, 47.64701, -1.12516,
      47.64667, -1.12858, 47.64664, -1.12985, 47.64364, -1.13346, 47.64248,
      -1.13505, 47.64108, -1.13791, 47.63727, -1.14625, 47.63631, -1.14815,
      47.63839, -1.15262, 47.63816, -1.15285, 47.63735, -1.1522, 47.63595,
      -1.15714, 47.63598, -1.15741, 47.63638, -1.15768, 47.63667, -1.15771,
      47.63692, -1.15807, 47.63717, -1.15826, 47.63808, -1.15835, 47.63814,
      -1.15819, 47.63889, -1.15804, 47.63912, -1.15725, 47.64024, -1.15714,
      47.6411, -1.15676, 47.64228, -1.15685, 47.64245, -1.15677, 47.64291,
      -1.1562, 47.6434, -1.15629, 47.64463, -1.15597, 47.64562, -1.15597,
      47.64642, -1.15615, 47.64876, -1.15784, 47.64968, -1.1579, 47.65049,
      -1.15766, 47.65107, -1.15789, 47.65184, -1.15845, 47.65285, -1.15993,
      47.65296, -1.1596, 47.65353, -1.1604, 47.65385, -1.16051, 47.65423,
      -1.16093, 47.65504, -1.16151, 47.65597, -1.16112, 47.65655, -1.1607,
      47.657, -1.16055, 47.65813, -1.16035, 47.6586, -1.16055, 47.65893,
      -1.16052, 47.66049, -1.15965, 47.66126, -1.16029, 47.66136, -1.16047,
      47.66063, -1.16289, 47.66049, -1.16294, 47.66048, -1.16328, 47.66005,
      -1.16418, 47.66006, -1.16685, 47.65989, -1.16803, 47.6593, -1.16966,
      47.65928, -1.17019, 47.65962, -1.17235, 47.65975, -1.17402, 47.65963,
      -1.17521, 47.65927, -1.17652, 47.65993, -1.1769, 47.6608, -1.17489,
      47.6616, -1.17094, 47.66194, -1.17023, 47.6624, -1.16967, 47.66272,
      -1.16975, 47.66357, -1.17044, 47.6636, -1.17139, 47.66406, -1.17291,
      47.66478, -1.17397, 47.66487, -1.17452, 47.66479, -1.17474, 47.66488,
      -1.17535, 47.66474, -1.17608, 47.66497, -1.17653, 47.66503, -1.17716,
      47.66482, -1.1778, 47.66478, -1.17871, 47.66461, -1.17971, 47.66459,
      -1.18015, 47.66465, -1.18034, 47.66426, -1.18209, 47.66468, -1.18204,
      47.66504, -1.18186, 47.66494, -1.1809, 47.66581, -1.18075, 47.66611,
      -1.18026, 47.66652, -1.18043, 47.66742, -1.18048, 47.66828, -1.18081,
      47.66796, -1.1823, 47.668, -1.18243, 47.66947, -1.18293, 47.6704, -1.1816,
      47.67111, -1.18185, 47.67257, -1.18193, 47.67411, -1.18167, 47.67429,
      -1.18176, 47.67655, -1.18033, 47.67714, -1.18019, 47.67738, -1.18051,
      47.67749, -1.18036, 47.67765, -1.17954, 47.67786, -1.17919, 47.6803,
      -1.17803, 47.68034, -1.1765, 47.68082, -1.17465, 47.68135, -1.17463,
      47.68147, -1.17447, 47.68169, -1.17403, 47.6818, -1.17321, 47.68204,
      -1.17337, 47.68294, -1.17506, 47.68397, -1.17617, 47.68419, -1.17732,
      47.68449, -1.17736, 47.68476, -1.17719, 47.68627, -1.17742, 47.68718,
      -1.17815, 47.68885, -1.17844, 47.68926, -1.17827, 47.68989, -1.17778,
      47.69031, -1.17775, 47.69083, -1.1771, 47.69113, -1.17649, 47.69167,
      -1.17616, 47.69197, -1.17569, 47.69234, -1.17547, 47.69244, -1.17526,
      47.69358, -1.17491, 47.69438, -1.17505, 47.69541, -1.17384, 47.69584,
      -1.17284, 47.69602, -1.17264, 47.69654, -1.17245, 47.69739, -1.17239,
      47.69759, -1.17192, 47.69825, -1.1715, 47.6988, -1.17086, 47.69896,
      -1.17036, 47.69883, -1.1695, 47.69883, -1.16885, 47.69905, -1.16789,
      47.699, -1.16674, 47.69872, -1.16587, 47.69875, -1.16534, 47.69901,
      -1.16483, 47.69954, -1.16415, 47.69945, -1.16334, 47.69979, -1.16362,
      47.70102, -1.16142, 47.70047, -1.16058, 47.69856, -1.15915, 47.69755,
      -1.15826, 47.69815, -1.15657, 47.69849, -1.156, 47.69853, -1.15579,
      47.6985, -1.15198, 47.69828, -1.15163, 47.69857, -1.1504, 47.69864,
      -1.14923, 47.69767, -1.14642, 47.6974, -1.14432, 47.69672, -1.1411,
      47.69648, -1.1406, 47.69628, -1.13931, 47.69661, -1.13753, 47.6975,
      -1.13557, 47.69763, -1.13557, 47.69803, -1.13475, 47.69806, -1.1336,
      47.69833, -1.13259, 47.69835, -1.13219, 47.6982, -1.13172, 47.69804,
      -1.12976, 47.69778, -1.12847, 47.69778, -1.12756, 47.69732, -1.12638,
      47.6973, -1.12588, 47.69673, -1.12505, 47.6963, -1.12505, 47.69614,
      -1.12495, 47.69559, -1.12305, 47.6953, -1.12144, 47.69482, -1.12093,
      47.69463, -1.12094, 47.6946, -1.12107, 47.69445, -1.12113, 47.69383,
      -1.12089, 47.69363, -1.12059, 47.69367, -1.12011, 47.69345, -1.11972,
      47.69342, -1.11899, 47.69328, -1.1185, 47.69308, -1.11815, 47.69248,
      -1.115, 47.692, -1.11405, 47.69218, -1.11351, 47.69224, -1.11284,
      47.69235, -1.11272, 47.69262, -1.11267, 47.69284, -1.11228, 47.69282,
      -1.11173, 47.69295, -1.11146, 47.69283, -1.11106, 47.69287, -1.11091,
      47.69335, -1.11037, 47.69434, -1.1111, 47.69464, -1.11037, 47.6949,
      -1.1101, 47.69495, -1.11047, 47.6964, -1.11181, 47.69703, -1.11171,
      47.69741, -1.11153, 47.69743, -1.11217, 47.69768, -1.11285, 47.69807,
      -1.11335, 47.69844, -1.1144, 47.69903, -1.11391, 47.69993, -1.11273,
      47.70028, -1.1124, 47.7007, -1.11298, 47.70227, -1.11288, 47.70277,
      -1.11357, 47.7031, -1.1137, 47.70339, -1.1135, 47.70419, -1.11208,
      47.70531, -1.11202, 47.70672, -1.1112, 47.70709, -1.11162, 47.70773,
      -1.11104, 47.70828, -1.11068, 47.7085, -1.11102, 47.70985, -1.10938,
      47.71021, -1.1092, 47.7123, -1.10867, 47.71471, -1.10783, 47.71623,
      -1.10812, 47.71654, -1.10706, 47.71692, -1.10724, 47.71795, -1.10739,
      47.7185, -1.10768, 47.72117, -1.10802, 47.72174, -1.10819, 47.72238,
      -1.10855, 47.72383, -1.10866, 47.72401, -1.10849, 47.72561, -1.10886,
      47.72657, -1.10949, 47.72735, -1.10962, 47.72749, -1.11002, 47.72761,
      -1.1101, 47.72909, -1.10925, 47.72947, -1.10873, 47.72996, -1.10835,
      47.73222, -1.10423, 47.7327, -1.11292, 47.73306, -1.11615, 47.73326,
      -1.12002, 47.73327, -1.12267, 47.73347, -1.12406, 47.73384, -1.12469,
      47.73353, -1.12486, 47.73451, -1.12915, 47.73445, -1.13365, 47.73436,
      -1.13546, 47.73498, -1.13932, 47.7335, -1.14195, 47.73227, -1.14295,
      47.73224, -1.14412, 47.73236, -1.14744, 47.73228, -1.14794, 47.73194,
      -1.1489, 47.7322, -1.14991, 47.7322, -1.15148, 47.73131, -1.15232,
      47.73111, -1.15286, 47.73083, -1.15484, 47.73001, -1.15537, 47.72984,
      -1.1557, 47.72954, -1.15574, 47.7292, -1.15634, 47.72839, -1.15651,
      47.72813, -1.156, 47.72765, -1.15623, 47.72761, -1.15606, 47.72718,
      -1.15564, 47.72712, -1.15546, 47.72715, -1.15485, 47.72673, -1.15467,
      47.72673, -1.15438, 47.7256, -1.15382, 47.72523, -1.15407, 47.72435,
      -1.15347, 47.72306, -1.15469, 47.72275, -1.15477, 47.72224, -1.15509,
      47.72175, -1.15572, 47.721, -1.15639, 47.72015, -1.1569, 47.72015,
      -1.15638, 47.71966, -1.15706, 47.71891, -1.15557, 47.71867, -1.15444,
      47.71861, -1.15479, 47.71867, -1.15543, 47.71774, -1.15569, 47.71723,
      -1.15628, 47.71672, -1.15709, 47.71652, -1.15843, 47.71658, -1.16002,
      47.71574, -1.1617, 47.7156, -1.16315, 47.71535, -1.164, 47.71492,
      -1.16484, 47.71431, -1.16549, 47.71347, -1.16593, 47.71337, -1.1655,
      47.7124, -1.16588, 47.71171, -1.1665, 47.7115, -1.16743, 47.71065,
      -1.16881, 47.7093, -1.17207, 47.7092, -1.17261, 47.70924, -1.1731,
      47.70908, -1.1739, 47.70914, -1.17422, 47.70865, -1.17492, 47.70852,
      -1.17528, 47.70763, -1.17903, 47.70164, -1.17968, 47.70123, -1.1798,
      47.70107, -1.17972, 47.69722, -1.18017, 47.697, -1.18031, 47.69681,
      -1.18078, 47.6983, -1.18404, 47.69873, -1.18614, 47.69917, -1.1895,
      47.70048, -1.18912, 47.70173, -1.18814, 47.70182, -1.18674, 47.70168,
      -1.1852, 47.70127, -1.18257, 47.7012, -1.1819, 47.70129, -1.18173,
      47.70157, -1.18159, 47.7022, -1.18157, 47.70305, -1.18176, 47.7035,
      -1.18159, 47.70445, -1.18298, 47.70499, -1.18462, 47.70547, -1.18505,
      47.70571, -1.18587, 47.70615, -1.18623, 47.70722, -1.19286, 47.70803,
      -1.19499, 47.70863, -1.195, 47.70937, -1.19591, 47.7099, -1.19622,
      47.71121, -1.19617, 47.71213, -1.19543, 47.71227, -1.19544, 47.71447,
      -1.19586, 47.71451, -1.19609, 47.7148, -1.19637, 47.71567, -1.19678,
      47.71625, -1.1968, 47.71646, -1.1967, 47.71682, -1.19629, 47.71797,
      -1.19579, 47.7184, -1.19538, 47.71938, -1.19528, 47.71946, -1.19425,
      47.721, -1.19478, 47.7218, -1.19536, 47.72174, -1.19568, 47.72295,
      -1.19601, 47.72277, -1.19678, 47.72202, -1.19782, 47.723, -1.19868,
      47.72363, -1.19611, 47.72612, -1.1973, 47.72764, -1.1962, 47.72798,
      -1.19569, 47.72956, -1.1965, 47.73052, -1.19932, 47.73076, -1.20109,
      47.73045, -1.20331, 47.7304, -1.20464, 47.73085, -1.20576, 47.73109,
      -1.20598, 47.73168, -1.20694, 47.73235, -1.20846, 47.73246, -1.20946,
      47.73262, -1.20989, 47.73354, -1.20992, 47.73428, -1.21086, 47.73508,
      -1.21318, 47.73565, -1.21289, 47.73598, -1.21379, 47.73697, -1.21312,
      47.73738, -1.21301, 47.73812, -1.21306, 47.73892, -1.2135, 47.73961,
      -1.21413, 47.74014, -1.21486, 47.74137, -1.21537, 47.74177, -1.21526,
      47.74198, -1.21541, 47.74201, -1.21765, 47.74223, -1.2197, 47.74241,
      -1.22325, 47.74245, -1.22484, 47.74238, -1.22515, 47.74258, -1.22538,
      47.74267, -1.22669, 47.74289, -1.2283, 47.74288, -1.22935, 47.7427,
      -1.2307, 47.74281, -1.23225, 47.7426, -1.23366, 47.74245, -1.23555,
      47.74261, -1.23814, 47.74284, -1.23951, 47.74286, -1.24173, 47.74579,
      -1.24146, 47.74589, -1.24113, 47.74697, -1.2412, 47.74742, -1.24111,
      47.74786, -1.2426, 47.74822, -1.24278, 47.74999, -1.24168, 47.75118,
      -1.2408, 47.75158, -1.24001, 47.75207, -1.23956, 47.75248, -1.23769,
      47.7532, -1.23669, 47.7537, -1.23576, 47.75462, -1.23519, 47.75489,
      -1.23445, 47.75516, -1.23417, 47.75642, -1.23384, 47.75708, -1.2343,
      47.75672, -1.23687, 47.75701, -1.238, 47.75755, -1.23955, 47.75804,
      -1.24007, 47.75786, -1.24051, 47.75823, -1.24063, 47.75851, -1.24054,
      47.75856, -1.24075, 47.75849, -1.24108, 47.7586, -1.24122, 47.75882,
      -1.24111, 47.75903, -1.24118, 47.75909, -1.24135, 47.75901, -1.24154,
      47.75922, -1.24184, 47.75919, -1.24206, 47.75905, -1.2421, 47.75885,
      -1.24247, 47.75885, -1.24272, 47.75874, -1.24293, 47.75889, -1.24331,
      47.75884, -1.24335, 47.75985, -1.24452, 47.76028, -1.24534, 47.76078,
      -1.24538, 47.76122, -1.2456, 47.76156, -1.24557, 47.76212, -1.24527,
      47.76226, -1.24529, 47.76238, -1.24557, 47.76314, -1.24642, 47.76328,
      -1.24854, 47.76361, -1.24904, 47.765, -1.24991, 47.7653, -1.24989,
      47.76593, -1.24939, 47.76629, -1.24791, 47.76647, -1.24764, 47.76689,
      -1.24729, 47.76935, -1.2475, 47.77016, -1.24736, 47.77119, -1.24696,
      47.77328, -1.2469, 47.77362, -1.24677, 47.77371, -1.2466, 47.77417,
      -1.24671, 47.77448, -1.247, 47.77502, -1.2469, 47.77528, -1.24646,
      47.77579, -1.24656, 47.77673, -1.24607, 47.77912, -1.24471, 47.77955,
      -1.24436, 47.78093, -1.24457, 47.78228, -1.24454, 47.78483, -1.24353,
      47.78487, -1.24362, 47.78534, -1.24347, 47.78713, -1.24279, 47.79039,
      -1.24129, 47.79107, -1.24166, 47.79116, -1.24136, 47.79167, -1.2413,
      47.79233, -1.24095, 47.79351, -1.24077, 47.79459, -1.2387, 47.79544,
      -1.23764, 47.79566, -1.23746, 47.7973, -1.23698, 47.79826, -1.23652,
      47.79988, -1.23723, 47.79993, -1.23836, 47.80013, -1.23876, 47.80047,
      -1.23896, 47.80054, -1.23912, 47.8005, -1.23928, 47.80089, -1.23958,
      47.80228, -1.2402, 47.80384, -1.23963, 47.8043, -1.23984, 47.80453,
      -1.23982, 47.80473, -1.23969, 47.80488, -1.23936, 47.80606, -1.23944,
      47.80656, -1.23992, 47.80744, -1.2418, 47.80792, -1.24264, 47.80828,
      -1.24206, 47.80831, -1.24164, 47.80847, -1.24171, 47.80855, -1.24164,
      47.80858, -1.2413, 47.80865, -1.24119, 47.80911, -1.24105, 47.80917,
      -1.24093, 47.80912, -1.24056, 47.80933, -1.24024, 47.80973, -1.2401,
      47.80967, -1.23969, 47.80988, -1.23922, 47.80976, -1.23888, 47.80999,
      -1.23825, 47.80982, -1.23811, 47.8096, -1.23876, 47.80946, -1.23878,
      47.80932, -1.23824, 47.80912, -1.2379, 47.80884, -1.23786, 47.80873,
      -1.23766, 47.80866, -1.23724, 47.80857, -1.23722, 47.80843, -1.23738,
      47.80833, -1.23714, 47.80843, -1.23644, 47.80859, -1.23624, 47.8087,
      -1.23582, 47.80854, -1.23545, 47.80866, -1.23513, 47.80871, -1.2346,
      47.80861, -1.23443, 47.80842, -1.23436, 47.80832, -1.23379, 47.8084,
      -1.23296, 47.80857, -1.23261, 47.80855, -1.23208, 47.80828, -1.23169,
      47.80803, -1.23103, 47.80801, -1.23087, 47.80811, -1.2306, 47.80805,
      -1.23041, 47.80733, -1.22986, 47.80716, -1.22963, 47.80535, -1.22614,
      47.8049, -1.22343, 47.80367, -1.21814, 47.80358, -1.21547, 47.80296,
      -1.21371, 47.80154, -1.21336, 47.80119, -1.21315, 47.80085, -1.21254,
      47.8007, -1.2116, 47.80058, -1.21128, 47.8004, -1.2112, 47.80021,
      -1.21134, 47.79987, -1.21108, 47.79901, -1.20969, 47.79885, -1.20975,
      47.79704, -1.21149, 47.79673, -1.21169, 47.79667, -1.21128, 47.79647,
      -1.21067, 47.79622, -1.20875, 47.79524, -1.20617, 47.79524, -1.20572,
      47.79558, -1.20357, 47.7953, -1.20153, 47.79522, -1.19878, 47.79531,
      -1.19695, 47.79356, -1.19614, 47.79226, -1.1953, 47.79192, -1.19481,
      47.78961, -1.19372, 47.78886, -1.19308, 47.78743, -1.19304, 47.78739,
      -1.19329, 47.78585, -1.19332, 47.78563, -1.192, 47.7855, -1.18971, 47.785,
      -1.18714, 47.78493, -1.18623, 47.78486, -1.18501, 47.78493, -1.18322,
      47.78467, -1.17796, 47.78387, -1.17816, 47.78361, -1.17665, 47.78349,
      -1.1745, 47.78302, -1.17265, 47.78283, -1.1707, 47.78025, -1.17109,
      47.77927, -1.1711, 47.77906, -1.16653, 47.77882, -1.16459, 47.77891,
      -1.16068, 47.77823, -1.15316, 47.77527, -1.14914, 47.77453, -1.14404,
      47.7749, -1.1403
    ]
  ]
};

export const THIZY_LES_BOURGS: LocationState = {
  id: 'FR-69248',
  name: 'Thizy-les-Bourgs',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      46.03335, 4.35646, 46.03256, 4.35608, 46.03204, 4.3552, 46.03139, 4.35479,
      46.03113, 4.35451, 46.03061, 4.35446, 46.03028, 4.35407, 46.02939,
      4.35368, 46.02877, 4.35319, 46.02826, 4.35322, 46.02757, 4.35359,
      46.02721, 4.35361, 46.02679, 4.35152, 46.02689, 4.35113, 46.02713,
      4.35089, 46.0278, 4.34944, 46.02803, 4.34836, 46.02829, 4.34792, 46.02841,
      4.34713, 46.02845, 4.34597, 46.02877, 4.3452, 46.02882, 4.34482, 46.02862,
      4.3442, 46.02845, 4.34411, 46.02823, 4.34416, 46.02703, 4.34002, 46.02644,
      4.33995, 46.02482, 4.33845, 46.02422, 4.33817, 46.02361, 4.33826,
      46.02267, 4.33806, 46.0218, 4.33807, 46.02059, 4.33876, 46.01985, 4.33899,
      46.01954, 4.33901, 46.01832, 4.33876, 46.01747, 4.33882, 46.01773,
      4.33853, 46.01776, 4.33826, 46.01835, 4.3375, 46.01558, 4.33245, 46.0154,
      4.33205, 46.01523, 4.33119, 46.01503, 4.33076, 46.01605, 4.32894,
      46.01727, 4.32569, 46.01802, 4.32481, 46.01807, 4.32443, 46.01836, 4.3242,
      46.01846, 4.32357, 46.01881, 4.32331, 46.01855, 4.32292, 46.0184, 4.32286,
      46.01776, 4.3213, 46.01703, 4.32019, 46.01686, 4.31953, 46.01602, 4.31836,
      46.01555, 4.31748, 46.015, 4.31705, 46.01482, 4.31677, 46.0144, 4.31661,
      46.01408, 4.31633, 46.01292, 4.31594, 46.01209, 4.3152, 46.01119, 4.31481,
      46.01086, 4.3148, 46.00953, 4.31387, 46.00882, 4.3127, 46.00814, 4.3107,
      46.00829, 4.31016, 46.00861, 4.3098, 46.00898, 4.30985, 46.0103, 4.30966,
      46.01135, 4.3085, 46.01227, 4.30728, 46.01433, 4.30741, 46.0162, 4.30775,
      46.01713, 4.3081, 46.01721, 4.30765, 46.01861, 4.30836, 46.0194, 4.30803,
      46.02028, 4.30744, 46.02083, 4.30694, 46.0217, 4.30587, 46.02154, 4.30491,
      46.02134, 4.30427, 46.02092, 4.30326, 46.02101, 4.30218, 46.02073,
      4.30117, 46.02085, 4.30069, 46.02125, 4.3001, 46.02152, 4.29919, 46.02159,
      4.29876, 46.02156, 4.29803, 46.02141, 4.29724, 46.02056, 4.29501,
      46.01995, 4.29366, 46.01991, 4.2933, 46.02007, 4.29267, 46.01939, 4.29239,
      46.01919, 4.29219, 46.01892, 4.29162, 46.01841, 4.29097, 46.01786,
      4.29004, 46.01747, 4.28885, 46.01715, 4.28824, 46.0177, 4.28755, 46.01834,
      4.28744, 46.01912, 4.28503, 46.02222, 4.28083, 46.02212, 4.28058,
      46.02187, 4.2804, 46.02163, 4.28003, 46.02154, 4.2797, 46.02233, 4.27759,
      46.02281, 4.27692, 46.02305, 4.27672, 46.02361, 4.27641, 46.02426,
      4.27626, 46.02562, 4.27518, 46.03187, 4.27148, 46.03475, 4.26912,
      46.03553, 4.26802, 46.03576, 4.26736, 46.03577, 4.26507, 46.03539, 4.2641,
      46.03522, 4.26336, 46.03522, 4.26291, 46.03541, 4.26221, 46.03684,
      4.26129, 46.03697, 4.26132, 46.038, 4.26076, 46.03905, 4.26115, 46.03953,
      4.26111, 46.04029, 4.26072, 46.04058, 4.26078, 46.04079, 4.26032,
      46.04104, 4.25883, 46.04124, 4.25845, 46.04143, 4.25837, 46.04264,
      4.25846, 46.04323, 4.25824, 46.04396, 4.25773, 46.04462, 4.25652,
      46.04508, 4.25595, 46.04551, 4.25521, 46.04576, 4.25486, 46.04637,
      4.25437, 46.04712, 4.25299, 46.04787, 4.25267, 46.04808, 4.25243,
      46.04857, 4.25152, 46.0498, 4.25084, 46.0501, 4.2505, 46.05026, 4.25059,
      46.05097, 4.25175, 46.05127, 4.2523, 46.05128, 4.25267, 46.0514, 4.25307,
      46.05211, 4.25414, 46.05243, 4.25354, 46.05286, 4.25475, 46.0535, 4.25618,
      46.05386, 4.2564, 46.05403, 4.25609, 46.05411, 4.25614, 46.05437, 4.25689,
      46.05454, 4.25798, 46.05411, 4.25844, 46.0542, 4.25895, 46.05176, 4.26496,
      46.05172, 4.26722, 46.05301, 4.27363, 46.05325, 4.27534, 46.05379,
      4.27664, 46.05386, 4.27702, 46.05383, 4.27753, 46.05315, 4.27841,
      46.05294, 4.27892, 46.0531, 4.28044, 46.05331, 4.28089, 46.05339, 4.28124,
      46.05337, 4.28153, 46.0531, 4.28241, 46.05306, 4.28285, 46.05314, 4.28348,
      46.05344, 4.2847, 46.05375, 4.28589, 46.05403, 4.28658, 46.05455, 4.28752,
      46.05563, 4.28875, 46.05599, 4.28981, 46.05694, 4.29052, 46.05756,
      4.29084, 46.05932, 4.29097, 46.05975, 4.2911, 46.06005, 4.29132, 46.0607,
      4.29241, 46.06094, 4.29424, 46.06111, 4.29478, 46.06145, 4.29517,
      46.06221, 4.29541, 46.06255, 4.29563, 46.06359, 4.29741, 46.06338,
      4.29743, 46.06326, 4.29773, 46.06298, 4.29798, 46.06203, 4.29835,
      46.06187, 4.29867, 46.06147, 4.3001, 46.06148, 4.30069, 46.06167, 4.3017,
      46.06172, 4.30289, 46.06167, 4.30338, 46.06142, 4.30389, 46.06142,
      4.30418, 46.06171, 4.305, 46.06177, 4.3058, 46.06173, 4.30623, 46.06149,
      4.30722, 46.06097, 4.30763, 46.06069, 4.3083, 46.06017, 4.30846, 46.05938,
      4.3094, 46.05845, 4.30988, 46.05813, 4.3102, 46.05803, 4.31072, 46.05823,
      4.3113, 46.05812, 4.31149, 46.05785, 4.31145, 46.0578, 4.31202, 46.05731,
      4.31202, 46.05703, 4.31161, 46.05646, 4.31174, 46.05531, 4.31267,
      46.05494, 4.31402, 46.05541, 4.31482, 46.0559, 4.31712, 46.05603, 4.31813,
      46.05617, 4.31853, 46.05634, 4.31875, 46.05711, 4.31922, 46.05813,
      4.31918, 46.05815, 4.31932, 46.05834, 4.31942, 46.05952, 4.31977,
      46.06044, 4.31933, 46.06132, 4.31904, 46.06147, 4.31894, 46.06141,
      4.31878, 46.06185, 4.31883, 46.0633, 4.31867, 46.06369, 4.31871, 46.06383,
      4.31891, 46.06403, 4.3197, 46.0651, 4.32257, 46.06614, 4.32413, 46.06738,
      4.3243, 46.0684, 4.32466, 46.06909, 4.32276, 46.06922, 4.32262, 46.06988,
      4.32369, 46.07052, 4.32324, 46.07114, 4.32369, 46.07127, 4.32339,
      46.07227, 4.32418, 46.0722, 4.32545, 46.07279, 4.32437, 46.07302, 4.32366,
      46.07359, 4.32247, 46.07475, 4.3212, 46.07556, 4.3223, 46.07566, 4.32255,
      46.0767, 4.32245, 46.07668, 4.32306, 46.07718, 4.32287, 46.07724, 4.32503,
      46.07788, 4.32515, 46.07811, 4.3251, 46.07839, 4.32542, 46.07863, 4.32425,
      46.07886, 4.3242, 46.07954, 4.3237, 46.07966, 4.32403, 46.07986, 4.32386,
      46.07999, 4.3242, 46.08046, 4.32378, 46.08055, 4.32329, 46.08052, 4.32141,
      46.07997, 4.32062, 46.07891, 4.31971, 46.07835, 4.31898, 46.07889,
      4.31855, 46.07929, 4.31839, 46.0803, 4.31975, 46.08222, 4.32283, 46.08418,
      4.32983, 46.08403, 4.33269, 46.08498, 4.33348, 46.08543, 4.334, 46.08616,
      4.33452, 46.08791, 4.33664, 46.09014, 4.33837, 46.09164, 4.34029, 46.092,
      4.34134, 46.09207, 4.3425, 46.092, 4.34255, 46.09196, 4.3446, 46.09205,
      4.34725, 46.09214, 4.34799, 46.09232, 4.34868, 46.09235, 4.34936,
      46.09223, 4.35058, 46.09242, 4.35152, 46.09282, 4.35222, 46.09282,
      4.35296, 46.09246, 4.3533, 46.09184, 4.35427, 46.09167, 4.35514, 46.09148,
      4.35549, 46.09075, 4.35603, 46.09009, 4.35693, 46.0895, 4.35796, 46.08871,
      4.35962, 46.08809, 4.36055, 46.08769, 4.36144, 46.0862, 4.36367, 46.0859,
      4.36439, 46.08568, 4.36532, 46.08575, 4.36683, 46.08567, 4.36727,
      46.08547, 4.36788, 46.08517, 4.36824, 46.08376, 4.36914, 46.08278,
      4.37009, 46.08186, 4.37073, 46.08076, 4.37011, 46.0802, 4.36953, 46.07939,
      4.36919, 46.0781, 4.36938, 46.07722, 4.36973, 46.07634, 4.36986, 46.07562,
      4.36968, 46.07381, 4.36885, 46.07239, 4.36905, 46.07159, 4.36878,
      46.07087, 4.36907, 46.06888, 4.36871, 46.06816, 4.36803, 46.06793,
      4.36793, 46.06583, 4.36872, 46.0635, 4.36983, 46.0625, 4.36999, 46.06208,
      4.37026, 46.06134, 4.37099, 46.06068, 4.37125, 46.06019, 4.37126,
      46.05746, 4.37117, 46.05513, 4.37065, 46.05315, 4.37003, 46.05138,
      4.36996, 46.04893, 4.37034, 46.04799, 4.37027, 46.04651, 4.37081,
      46.04603, 4.37113, 46.04585, 4.371, 46.04571, 4.37071, 46.04505, 4.37016,
      46.04344, 4.36913, 46.04222, 4.36853, 46.04099, 4.36836, 46.04067,
      4.36871, 46.0403, 4.36886, 46.03898, 4.36883, 46.03744, 4.36896, 46.03712,
      4.36886, 46.03485, 4.36982, 46.03429, 4.3698, 46.0337, 4.36964, 46.03297,
      4.36978, 46.03261, 4.36973, 46.03189, 4.36909, 46.03219, 4.36815, 46.0323,
      4.36733, 46.03209, 4.36693, 46.03225, 4.36588, 46.03271, 4.36406, 46.0328,
      4.3632, 46.03279, 4.36227, 46.03317, 4.36161, 46.03338, 4.36063, 46.03341,
      4.35986, 46.03321, 4.35917, 46.03341, 4.358, 46.03345, 4.35692, 46.03335,
      4.35646
    ]
  ]
};

export const BEAUPREAU_EN_MAUGES: LocationState = {
  id: 'FR-49023',
  name: 'Beaupréau-en-Mauges',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.17051, -1.16992, 47.17081, -1.16947, 47.17146, -1.16949, 47.17194,
      -1.16891, 47.1723, -1.16876, 47.17255, -1.16882, 47.17268, -1.16875,
      47.1729, -1.16813, 47.17325, -1.16792, 47.17333, -1.16748, 47.17365,
      -1.16675, 47.17394, -1.16652, 47.1741, -1.16655, 47.17427, -1.16679,
      47.17458, -1.16677, 47.17477, -1.16705, 47.17508, -1.16717, 47.17566,
      -1.16794, 47.17592, -1.16769, 47.17613, -1.16794, 47.1763, -1.16799,
      47.1764, -1.16775, 47.177, -1.16743, 47.17703, -1.16715, 47.1768,
      -1.16687, 47.17681, -1.16655, 47.17686, -1.1664, 47.17714, -1.16632,
      47.1772, -1.16589, 47.17742, -1.16571, 47.17744, -1.16552, 47.17734,
      -1.1652, 47.17719, -1.1654, 47.17703, -1.1654, 47.1769, -1.16536,
      47.17674, -1.16513, 47.17673, -1.16504, 47.17701, -1.16508, 47.17703,
      -1.165, 47.17687, -1.16471, 47.17703, -1.16435, 47.17691, -1.16417,
      47.17688, -1.16365, 47.17733, -1.16236, 47.17785, -1.16169, 47.17818,
      -1.16164, 47.17854, -1.16181, 47.17852, -1.16238, 47.17862, -1.16267,
      47.17866, -1.16319, 47.17887, -1.16346, 47.17917, -1.16433, 47.17937,
      -1.1646, 47.17967, -1.16467, 47.18011, -1.16424, 47.18054, -1.16475,
      47.18089, -1.16482, 47.18111, -1.16475, 47.18124, -1.16455, 47.18109,
      -1.16396, 47.18158, -1.16208, 47.18196, -1.16186, 47.18217, -1.16148,
      47.18298, -1.16094, 47.1832, -1.16088, 47.18317, -1.16124, 47.18323,
      -1.16142, 47.18372, -1.1613, 47.18378, -1.16138, 47.18377, -1.16168,
      47.1839, -1.16189, 47.18433, -1.16198, 47.1845, -1.1628, 47.18491,
      -1.16345, 47.18545, -1.16351, 47.1856, -1.16321, 47.18595, -1.16289,
      47.18597, -1.16268, 47.18579, -1.16209, 47.18583, -1.16127, 47.18563,
      -1.16132, 47.18551, -1.16118, 47.18521, -1.16108, 47.18502, -1.16068,
      47.18503, -1.16056, 47.18558, -1.15993, 47.18547, -1.15945, 47.18555,
      -1.15883, 47.18612, -1.15905, 47.18649, -1.1589, 47.18673, -1.15894,
      47.18703, -1.1587, 47.18792, -1.1587, 47.18795, -1.15855, 47.18772,
      -1.1582, 47.1879, -1.15763, 47.18818, -1.15771, 47.18847, -1.1575,
      47.18841, -1.15703, 47.18818, -1.15701, 47.18766, -1.15662, 47.18745,
      -1.15657, 47.18714, -1.15619, 47.18722, -1.15566, 47.18698, -1.15507,
      47.18696, -1.15475, 47.18711, -1.15427, 47.18728, -1.15416, 47.18746,
      -1.15424, 47.18775, -1.15412, 47.18814, -1.15425, 47.18813, -1.15398,
      47.18836, -1.1537, 47.18887, -1.15403, 47.18872, -1.15456, 47.18889,
      -1.1553, 47.1893, -1.15539, 47.18998, -1.15434, 47.19047, -1.15284,
      47.19091, -1.15254, 47.19106, -1.15223, 47.19146, -1.15176, 47.19189,
      -1.15183, 47.19203, -1.15147, 47.19229, -1.15113, 47.19241, -1.15024,
      47.19271, -1.15007, 47.19269, -1.1496, 47.19275, -1.14939, 47.1929,
      -1.1493, 47.19285, -1.14893, 47.19277, -1.14881, 47.19226, -1.14879,
      47.19202, -1.14861, 47.19201, -1.14848, 47.19223, -1.14793, 47.19197,
      -1.14742, 47.19176, -1.14743, 47.19174, -1.14695, 47.19157, -1.14687,
      47.1914, -1.14695, 47.19135, -1.14667, 47.19104, -1.1465, 47.19114,
      -1.14607, 47.1914, -1.1461, 47.19156, -1.14576, 47.19192, -1.14562,
      47.19282, -1.14465, 47.19296, -1.14429, 47.19322, -1.14395, 47.19361,
      -1.14415, 47.19396, -1.14388, 47.19455, -1.14409, 47.1953, -1.14339,
      47.1956, -1.14349, 47.19573, -1.1434, 47.19609, -1.1436, 47.19626,
      -1.14354, 47.19675, -1.14277, 47.19694, -1.14301, 47.19713, -1.14251,
      47.19745, -1.14218, 47.19775, -1.14231, 47.19823, -1.14173, 47.19863,
      -1.14171, 47.1989, -1.14146, 47.19918, -1.14141, 47.19977, -1.14104,
      47.20106, -1.13982, 47.20128, -1.13932, 47.20159, -1.13925, 47.20209,
      -1.13817, 47.20279, -1.13781, 47.20371, -1.13707, 47.20448, -1.13589,
      47.20521, -1.13546, 47.20601, -1.13468, 47.20659, -1.13446, 47.207,
      -1.13469, 47.20747, -1.13462, 47.2084, -1.1342, 47.20886, -1.13426,
      47.20912, -1.13404, 47.20934, -1.13403, 47.2098, -1.13426, 47.21001,
      -1.13452, 47.21057, -1.13431, 47.21139, -1.13461, 47.21133, -1.13497,
      47.21158, -1.13519, 47.21178, -1.13568, 47.21287, -1.13551, 47.21314,
      -1.13494, 47.21373, -1.13428, 47.2139, -1.13391, 47.21416, -1.13387,
      47.21448, -1.13399, 47.21472, -1.13388, 47.21484, -1.13353, 47.21473,
      -1.13314, 47.21482, -1.1328, 47.2147, -1.13241, 47.21477, -1.13212,
      47.21523, -1.13183, 47.21587, -1.13183, 47.21603, -1.13159, 47.21627,
      -1.13082, 47.21672, -1.13069, 47.21734, -1.13029, 47.21781, -1.12973,
      47.21807, -1.12884, 47.21798, -1.12849, 47.21803, -1.12821, 47.21788,
      -1.12747, 47.21791, -1.12697, 47.21814, -1.12668, 47.21856, -1.12679,
      47.21856, -1.12652, 47.21879, -1.12614, 47.21896, -1.12605, 47.21899,
      -1.12582, 47.21907, -1.1258, 47.21936, -1.12523, 47.21936, -1.12504,
      47.21907, -1.12449, 47.21901, -1.12346, 47.21914, -1.12308, 47.21953,
      -1.12285, 47.21988, -1.12282, 47.22038, -1.12261, 47.21971, -1.12084,
      47.21855, -1.11904, 47.21795, -1.11795, 47.21703, -1.11577, 47.2169,
      -1.11563, 47.21639, -1.11613, 47.2163, -1.11606, 47.21588, -1.11491,
      47.21561, -1.11496, 47.21474, -1.11543, 47.2147, -1.11536, 47.2151,
      -1.11415, 47.21567, -1.11366, 47.21521, -1.11297, 47.21463, -1.11146,
      47.21492, -1.10956, 47.21582, -1.10486, 47.2158, -1.10393, 47.21553,
      -1.10259, 47.21448, -1.10021, 47.21432, -1.09941, 47.21443, -1.0971,
      47.21425, -1.09584, 47.21396, -1.09496, 47.21406, -1.09457, 47.21443,
      -1.094, 47.21165, -1.09382, 47.2104, -1.09321, 47.21022, -1.09303,
      47.21019, -1.09289, 47.20969, -1.09283, 47.20889, -1.09253, 47.20879,
      -1.09304, 47.20876, -1.09397, 47.20856, -1.09468, 47.20763, -1.09429,
      47.20736, -1.09434, 47.20612, -1.09511, 47.20555, -1.09462, 47.2059,
      -1.0915, 47.20606, -1.08838, 47.20602, -1.08826, 47.20503, -1.08904,
      47.20411, -1.08638, 47.20328, -1.08354, 47.2032, -1.08284, 47.20293,
      -1.08238, 47.20218, -1.08042, 47.20207, -1.07947, 47.202, -1.07946,
      47.20196, -1.07929, 47.20197, -1.0784, 47.19991, -1.07973, 47.1988,
      -1.07913, 47.19863, -1.07885, 47.19844, -1.07778, 47.19799, -1.07619,
      47.19649, -1.07557, 47.19613, -1.0755, 47.19562, -1.07714, 47.1951,
      -1.0781, 47.19372, -1.08007, 47.19209, -1.07927, 47.19183, -1.07902,
      47.19133, -1.078, 47.19111, -1.07656, 47.19039, -1.07629, 47.18814,
      -1.07587, 47.18607, -1.0758, 47.18528, -1.07589, 47.18523, -1.07526,
      47.18531, -1.07389, 47.18518, -1.07258, 47.18173, -1.07176, 47.18034,
      -1.07168, 47.17836, -1.07181, 47.17759, -1.07171, 47.17847, -1.07011,
      47.17894, -1.06895, 47.17936, -1.06831, 47.17956, -1.06747, 47.18135,
      -1.06634, 47.18369, -1.0657, 47.18498, -1.06502, 47.18472, -1.0635,
      47.18491, -1.06193, 47.18489, -1.06087, 47.18461, -1.0598, 47.18329,
      -1.05707, 47.18345, -1.05642, 47.18319, -1.05594, 47.18322, -1.05482,
      47.18314, -1.05455, 47.18482, -1.05248, 47.18563, -1.05108, 47.1861,
      -1.05051, 47.18618, -1.04926, 47.18666, -1.04811, 47.18713, -1.04602,
      47.1873, -1.04491, 47.18733, -1.0424, 47.18701, -1.03941, 47.18635,
      -1.03719, 47.18624, -1.03593, 47.18629, -1.03461, 47.1864, -1.03396,
      47.18622, -1.03256, 47.18651, -1.0326, 47.18662, -1.03247, 47.18668,
      -1.03218, 47.1868, -1.03228, 47.18706, -1.03211, 47.18702, -1.03177,
      47.18712, -1.03168, 47.18777, -1.03156, 47.18795, -1.03171, 47.1884,
      -1.03145, 47.18861, -1.03169, 47.18907, -1.03179, 47.18915, -1.03202,
      47.18918, -1.0326, 47.1895, -1.03267, 47.18957, -1.03296, 47.18968,
      -1.03292, 47.18987, -1.03339, 47.19014, -1.03328, 47.19043, -1.03344,
      47.19074, -1.03338, 47.19093, -1.03367, 47.19119, -1.03364, 47.19132,
      -1.03353, 47.19117, -1.03309, 47.19138, -1.0328, 47.19155, -1.03209,
      47.19156, -1.0312, 47.19149, -1.03097, 47.19169, -1.03064, 47.19211,
      -1.03069, 47.19216, -1.03011, 47.19237, -1.03003, 47.19227, -1.02981,
      47.19238, -1.0295, 47.19252, -1.02947, 47.19279, -1.02969, 47.19289,
      -1.02965, 47.19289, -1.02925, 47.19306, -1.02929, 47.19317, -1.02917,
      47.19352, -1.02936, 47.19373, -1.02932, 47.19384, -1.02944, 47.19393,
      -1.02988, 47.19404, -1.02999, 47.19423, -1.03048, 47.19381, -1.03123,
      47.19377, -1.03185, 47.19367, -1.03221, 47.19321, -1.03274, 47.1931,
      -1.03376, 47.19283, -1.03479, 47.19282, -1.03516, 47.19289, -1.03536,
      47.19338, -1.03578, 47.19351, -1.03611, 47.19342, -1.03782, 47.19363,
      -1.03811, 47.19388, -1.03828, 47.19588, -1.03906, 47.19679, -1.03958,
      47.19791, -1.03968, 47.19828, -1.03986, 47.19847, -1.03978, 47.19857,
      -1.03942, 47.1988, -1.03939, 47.19914, -1.03916, 47.1994, -1.03855,
      47.19952, -1.03794, 47.19972, -1.03551, 47.20021, -1.03393, 47.20011,
      -1.03259, 47.20046, -1.0318, 47.20047, -1.03082, 47.20068, -1.03013,
      47.20062, -1.02909, 47.20146, -1.02728, 47.20347, -1.02524, 47.20401,
      -1.02499, 47.20509, -1.02429, 47.20549, -1.02414, 47.20592, -1.0242,
      47.20618, -1.02356, 47.20633, -1.02346, 47.20667, -1.02335, 47.20729,
      -1.02337, 47.20804, -1.02371, 47.20833, -1.02364, 47.20883, -1.02323,
      47.20915, -1.02338, 47.20939, -1.02379, 47.20935, -1.02435, 47.20964,
      -1.0251, 47.20984, -1.02704, 47.20984, -1.02775, 47.21, -1.0286, 47.20988,
      -1.02919, 47.20956, -1.02971, 47.20957, -1.03053, 47.20973, -1.03105,
      47.20973, -1.0315, 47.20945, -1.03234, 47.20866, -1.03298, 47.20784,
      -1.03333, 47.20756, -1.03382, 47.20701, -1.03534, 47.20646, -1.03779,
      47.20629, -1.03924, 47.20593, -1.04006, 47.20556, -1.04047, 47.20546,
      -1.04144, 47.20573, -1.04164, 47.20581, -1.04195, 47.20608, -1.04226,
      47.2064, -1.04246, 47.20675, -1.04249, 47.20967, -1.04139, 47.21074,
      -1.04037, 47.21197, -1.0404, 47.21381, -1.03896, 47.21443, -1.03798,
      47.21644, -1.03792, 47.21719, -1.03805, 47.21796, -1.03764, 47.21831,
      -1.03725, 47.21899, -1.03697, 47.2194, -1.03606, 47.2195, -1.03549,
      47.21923, -1.03216, 47.21884, -1.03114, 47.21899, -1.02997, 47.21889,
      -1.02975, 47.21851, -1.02938, 47.21825, -1.0289, 47.21776, -1.02847,
      47.21753, -1.02813, 47.21744, -1.02755, 47.21772, -1.02721, 47.21777,
      -1.02684, 47.21753, -1.02636, 47.21717, -1.02593, 47.21722, -1.02571,
      47.21713, -1.02547, 47.21717, -1.02477, 47.21736, -1.02429, 47.21777,
      -1.02402, 47.21824, -1.02352, 47.21858, -1.0234, 47.21929, -1.02291,
      47.21984, -1.02302, 47.22015, -1.02353, 47.22073, -1.02404, 47.2221,
      -1.02631, 47.22248, -1.02715, 47.22328, -1.02826, 47.22346, -1.02889,
      47.22352, -1.02971, 47.22337, -1.03067, 47.22356, -1.03129, 47.2235,
      -1.03233, 47.22354, -1.03272, 47.22438, -1.03326, 47.22497, -1.03345,
      47.22538, -1.03349, 47.22575, -1.03318, 47.2266, -1.03163, 47.22677,
      -1.03093, 47.2268, -1.03037, 47.22642, -1.02911, 47.22586, -1.02884,
      47.2254, -1.0282, 47.225, -1.02515, 47.22455, -1.02384, 47.22406,
      -1.02294, 47.22379, -1.02226, 47.22371, -1.02171, 47.22375, -1.0215,
      47.22386, -1.02145, 47.22381, -1.02035, 47.22406, -1.02002, 47.22452,
      -1.01996, 47.22524, -1.02023, 47.22541, -1.02067, 47.22686, -1.02308,
      47.22733, -1.02424, 47.22763, -1.02474, 47.22882, -1.02613, 47.22975,
      -1.0262, 47.23001, -1.02597, 47.23017, -1.0254, 47.2301, -1.02421,
      47.23003, -1.02402, 47.22964, -1.02387, 47.22891, -1.02328, 47.22884,
      -1.02259, 47.22895, -1.02223, 47.22938, -1.02151, 47.23105, -1.02075,
      47.23152, -1.02073, 47.23198, -1.0209, 47.23272, -1.02158, 47.23436,
      -1.02366, 47.23619, -1.02441, 47.2377, -1.02635, 47.23783, -1.02586,
      47.23836, -1.02505, 47.23886, -1.024, 47.23924, -1.02341, 47.23937,
      -1.02245, 47.23969, -1.0213, 47.23985, -1.02023, 47.24035, -1.01936,
      47.2409, -1.01888, 47.24124, -1.01758, 47.24091, -1.01723, 47.24058,
      -1.01642, 47.24005, -1.01592, 47.2388, -1.01399, 47.23735, -1.01294,
      47.23756, -1.01157, 47.23837, -1.01171, 47.24039, -1.01181, 47.24176,
      -1.01225, 47.24193, -1.00674, 47.24229, -1.00531, 47.24098, -1.00442,
      47.2409, -1.00415, 47.2405, -1.00368, 47.24029, -1.00261, 47.23958,
      -1.00288, 47.23945, -1.00306, 47.23918, -1.00309, 47.23897, -1.00299,
      47.2388, -1.0031, 47.23862, -1.00257, 47.23857, -1.00168, 47.23849,
      -1.00146, 47.23809, -1.00116, 47.238, -1.00072, 47.23789, -1.00065,
      47.23776, -1.00073, 47.23747, -1.00044, 47.2375, -0.99998, 47.2365,
      -0.99867, 47.2362, -0.99852, 47.23596, -0.9981, 47.23575, -0.99803,
      47.23557, -0.99748, 47.23557, -0.99702, 47.23577, -0.99713, 47.23611,
      -0.99675, 47.23617, -0.99648, 47.23646, -0.99629, 47.23662, -0.996,
      47.23667, -0.99542, 47.23689, -0.99501, 47.23694, -0.99413, 47.23676,
      -0.99408, 47.23681, -0.99389, 47.23657, -0.9935, 47.2366, -0.99324,
      47.23636, -0.99289, 47.23632, -0.9925, 47.23616, -0.99227, 47.23612,
      -0.99205, 47.23631, -0.99183, 47.23637, -0.99203, 47.23647, -0.99198,
      47.23656, -0.99154, 47.23686, -0.99123, 47.23684, -0.991, 47.23692,
      -0.99083, 47.23705, -0.99081, 47.237, -0.9905, 47.23716, -0.99011,
      47.23697, -0.98998, 47.23696, -0.98939, 47.23688, -0.98909, 47.2371,
      -0.98905, 47.23711, -0.98887, 47.23707, -0.98878, 47.23689, -0.98884,
      47.23683, -0.98877, 47.2367, -0.98811, 47.23679, -0.9878, 47.23674,
      -0.9876, 47.23659, -0.98766, 47.23648, -0.98748, 47.23649, -0.98739,
      47.23671, -0.98732, 47.23671, -0.9872, 47.23658, -0.98716, 47.23652,
      -0.98682, 47.23671, -0.98673, 47.23671, -0.9866, 47.23661, -0.98649,
      47.23665, -0.98627, 47.23657, -0.98614, 47.23671, -0.98575, 47.2367,
      -0.98555, 47.23663, -0.98552, 47.23641, -0.98575, 47.23633, -0.98561,
      47.2362, -0.98559, 47.23617, -0.98545, 47.2363, -0.98534, 47.23627,
      -0.98518, 47.23607, -0.9852, 47.23608, -0.985, 47.23582, -0.98476,
      47.23587, -0.98439, 47.23574, -0.98429, 47.23546, -0.98454, 47.23499,
      -0.98361, 47.23499, -0.98321, 47.23527, -0.9829, 47.23533, -0.9823,
      47.23528, -0.98129, 47.23493, -0.98143, 47.23441, -0.98219, 47.2343,
      -0.98278, 47.23413, -0.9827, 47.234, -0.98243, 47.2337, -0.98042,
      47.23415, -0.98012, 47.2346, -0.97954, 47.2345, -0.97931, 47.23447,
      -0.97881, 47.23431, -0.9785, 47.23437, -0.97822, 47.23458, -0.97804,
      47.23452, -0.97778, 47.2346, -0.97733, 47.23485, -0.97729, 47.23499,
      -0.97712, 47.23495, -0.97681, 47.23533, -0.97652, 47.23546, -0.97617,
      47.2356, -0.97606, 47.23569, -0.97626, 47.23585, -0.97616, 47.23613,
      -0.97571, 47.23619, -0.97525, 47.23633, -0.97492, 47.23654, -0.97469,
      47.23647, -0.9741, 47.23582, -0.97401, 47.23556, -0.97347, 47.23538,
      -0.97332, 47.23519, -0.97212, 47.23525, -0.97186, 47.23567, -0.97156,
      47.23547, -0.97093, 47.23541, -0.9703, 47.23514, -0.97006, 47.2346,
      -0.96923, 47.23384, -0.96855, 47.23219, -0.96838, 47.23219, -0.96733,
      47.23238, -0.96586, 47.23233, -0.96527, 47.23205, -0.96393, 47.23165,
      -0.96391, 47.23047, -0.96423, 47.22956, -0.96265, 47.22855, -0.96385,
      47.22711, -0.96587, 47.2269, -0.96467, 47.22778, -0.96083, 47.22794,
      -0.95971, 47.22844, -0.95809, 47.22855, -0.95724, 47.2283, -0.9565,
      47.22829, -0.95544, 47.22821, -0.95491, 47.2283, -0.95339, 47.22845,
      -0.95294, 47.22887, -0.95237, 47.22942, -0.95194, 47.23024, -0.95166,
      47.23028, -0.95034, 47.22989, -0.94827, 47.22979, -0.947, 47.22986,
      -0.94602, 47.23073, -0.94561, 47.23106, -0.94504, 47.23122, -0.9419,
      47.23143, -0.94005, 47.23066, -0.93978, 47.22926, -0.93873, 47.22801,
      -0.93837, 47.22771, -0.93789, 47.22737, -0.93703, 47.22783, -0.93691,
      47.22866, -0.93768, 47.23071, -0.93834, 47.23088, -0.93729, 47.23089,
      -0.9367, 47.23055, -0.93602, 47.23026, -0.93493, 47.23033, -0.93485,
      47.22988, -0.93332, 47.22931, -0.93217, 47.22852, -0.9314, 47.22829,
      -0.93053, 47.22788, -0.92983, 47.22811, -0.92968, 47.22887, -0.92833,
      47.22977, -0.92876, 47.23011, -0.92931, 47.23053, -0.93033, 47.23096,
      -0.93102, 47.23155, -0.93244, 47.23271, -0.93443, 47.23261, -0.9363,
      47.23286, -0.93669, 47.23289, -0.93701, 47.23436, -0.93528, 47.23399,
      -0.93461, 47.23411, -0.93439, 47.23399, -0.93378, 47.23406, -0.93285,
      47.23401, -0.9316, 47.23498, -0.9306, 47.23521, -0.93055, 47.23556,
      -0.93077, 47.23653, -0.93088, 47.23735, -0.93017, 47.23792, -0.92875,
      47.23843, -0.92781, 47.23922, -0.92895, 47.24187, -0.92755, 47.24368,
      -0.93264, 47.24412, -0.9313, 47.24522, -0.92992, 47.24591, -0.92887,
      47.24645, -0.92773, 47.24631, -0.92501, 47.24675, -0.92524, 47.2475,
      -0.92326, 47.25225, -0.929, 47.25352, -0.93032, 47.25332, -0.93177,
      47.25302, -0.93731, 47.25285, -0.93846, 47.25276, -0.93857, 47.25275,
      -0.93875, 47.253, -0.93897, 47.25323, -0.93974, 47.25315, -0.94016,
      47.25342, -0.94186, 47.25343, -0.94353, 47.25325, -0.94445, 47.2533,
      -0.94502, 47.25351, -0.9457, 47.25415, -0.9464, 47.25459, -0.94712,
      47.2549, -0.94649, 47.25541, -0.94435, 47.25548, -0.94348, 47.25604,
      -0.94231, 47.25658, -0.94211, 47.25796, -0.94243, 47.25882, -0.94198,
      47.25882, -0.9463, 47.25954, -0.94755, 47.26044, -0.94809, 47.26143,
      -0.94825, 47.26318, -0.94788, 47.26344, -0.94773, 47.2638, -0.94726,
      47.26391, -0.94737, 47.26431, -0.94632, 47.2652, -0.94447, 47.26553,
      -0.94344, 47.26639, -0.94201, 47.26746, -0.94046, 47.26666, -0.93908,
      47.26618, -0.93701, 47.26542, -0.93511, 47.26505, -0.93396, 47.26441,
      -0.93273, 47.26436, -0.93249, 47.26462, -0.93137, 47.26577, -0.93031,
      47.26736, -0.92715, 47.26767, -0.92508, 47.26831, -0.92377, 47.26839,
      -0.92292, 47.26913, -0.92012, 47.26958, -0.92031, 47.27017, -0.92073,
      47.27154, -0.92245, 47.27247, -0.92298, 47.27461, -0.92388, 47.27471,
      -0.9232, 47.27474, -0.92296, 47.2709, -0.92048, 47.27013, -0.9199,
      47.26986, -0.91945, 47.2706, -0.91726, 47.27104, -0.91737, 47.27241,
      -0.91826, 47.27266, -0.91776, 47.2727, -0.91695, 47.27176, -0.91694,
      47.27085, -0.91605, 47.27088, -0.91551, 47.27191, -0.9118, 47.27327,
      -0.90989, 47.27255, -0.90915, 47.27183, -0.90911, 47.27143, -0.90869,
      47.27164, -0.90814, 47.27199, -0.9086, 47.27339, -0.90795, 47.27341,
      -0.90729, 47.2732, -0.90674, 47.27129, -0.90418, 47.27134, -0.90405,
      47.27217, -0.90338, 47.27209, -0.90284, 47.27097, -0.89973, 47.27037,
      -0.89857, 47.27053, -0.89772, 47.27037, -0.89698, 47.26955, -0.89555,
      47.26956, -0.89535, 47.27087, -0.89364, 47.27235, -0.89194, 47.2724,
      -0.89175, 47.27238, -0.89109, 47.27182, -0.88854, 47.27216, -0.88679,
      47.26945, -0.8875, 47.26948, -0.88773, 47.26883, -0.8881, 47.26695,
      -0.88984, 47.26615, -0.88859, 47.26585, -0.88791, 47.26572, -0.88784,
      47.26516, -0.88808, 47.26494, -0.88708, 47.26637, -0.88543, 47.2671,
      -0.88495, 47.26841, -0.8847, 47.27009, -0.88482, 47.2711, -0.88288,
      47.27032, -0.8825, 47.26891, -0.88135, 47.26813, -0.88045, 47.26786,
      -0.88036, 47.26713, -0.88072, 47.26695, -0.8809, 47.26681, -0.88083,
      47.26615, -0.8786, 47.26586, -0.87667, 47.26602, -0.87585, 47.26585,
      -0.87477, 47.26588, -0.87418, 47.26614, -0.87343, 47.26765, -0.87347,
      47.26815, -0.87331, 47.26809, -0.87218, 47.26856, -0.8715, 47.26808,
      -0.87039, 47.26797, -0.86968, 47.26808, -0.86811, 47.26847, -0.86652,
      47.26829, -0.86569, 47.26826, -0.86379, 47.26802, -0.86311, 47.26717,
      -0.86402, 47.26672, -0.86421, 47.26627, -0.86423, 47.26563, -0.86334,
      47.26335, -0.86173, 47.26192, -0.86034, 47.26131, -0.85989, 47.26122,
      -0.85985, 47.26109, -0.86036, 47.26018, -0.86032, 47.25932, -0.86042,
      47.2585, -0.86003, 47.25834, -0.85987, 47.25826, -0.85974, 47.25653,
      -0.85899, 47.25618, -0.85864, 47.25596, -0.85816, 47.25586, -0.85696,
      47.25625, -0.85277, 47.2562, -0.85114, 47.25602, -0.85055, 47.25581,
      -0.85043, 47.25583, -0.84905, 47.25548, -0.84739, 47.25543, -0.84586,
      47.25535, -0.84536, 47.25391, -0.83962, 47.25357, -0.83543, 47.25353,
      -0.83398, 47.25362, -0.8329, 47.25354, -0.83015, 47.25316, -0.82853,
      47.253, -0.82812, 47.25238, -0.82709, 47.2521, -0.82642, 47.25153,
      -0.82381, 47.25116, -0.82323, 47.25113, -0.82277, 47.25136, -0.82245,
      47.25142, -0.82206, 47.25156, -0.82173, 47.25137, -0.82053, 47.25136,
      -0.81921, 47.25117, -0.81801, 47.2511, -0.81404, 47.25117, -0.81248,
      47.25159, -0.80991, 47.25197, -0.80899, 47.25165, -0.80865, 47.25045,
      -0.80825, 47.24851, -0.80806, 47.24762, -0.80971, 47.24665, -0.81069,
      47.24609, -0.80991, 47.2456, -0.80949, 47.24458, -0.80894, 47.2436,
      -0.80864, 47.24389, -0.8078, 47.24417, -0.80735, 47.24409, -0.80688,
      47.24308, -0.80451, 47.2423, -0.80321, 47.24208, -0.803, 47.2403,
      -0.79941, 47.23995, -0.79894, 47.23958, -0.80058, 47.23905, -0.80203,
      47.2372, -0.80312, 47.23661, -0.80387, 47.23504, -0.80543, 47.23508,
      -0.80563, 47.23617, -0.80695, 47.23746, -0.80794, 47.23723, -0.80853,
      47.23706, -0.81014, 47.23687, -0.81105, 47.23675, -0.8114, 47.2366,
      -0.81155, 47.23435, -0.80834, 47.23397, -0.80795, 47.23378, -0.80794,
      47.23297, -0.80861, 47.23042, -0.80648, 47.22991, -0.8078, 47.22945,
      -0.80747, 47.22969, -0.80676, 47.22965, -0.80656, 47.22728, -0.80444,
      47.22641, -0.80333, 47.2258, -0.8027, 47.22575, -0.80277, 47.22572,
      -0.80622, 47.22669, -0.80793, 47.22436, -0.80941, 47.22322, -0.8048,
      47.22301, -0.80299, 47.22142, -0.80462, 47.2208, -0.80599, 47.22022,
      -0.80693, 47.22016, -0.80716, 47.21972, -0.80776, 47.21862, -0.80898,
      47.21788, -0.81076, 47.21756, -0.81239, 47.21767, -0.81386, 47.21796,
      -0.81489, 47.21812, -0.816, 47.21786, -0.81638, 47.21738, -0.8176,
      47.21582, -0.82022, 47.21555, -0.82172, 47.21546, -0.8233, 47.2152,
      -0.82468, 47.21422, -0.82684, 47.21365, -0.82747, 47.21258, -0.82916,
      47.21218, -0.83054, 47.21217, -0.83137, 47.2111, -0.8315, 47.21034,
      -0.83113, 47.2092, -0.83, 47.20856, -0.82897, 47.20788, -0.82748,
      47.20788, -0.82722, 47.20883, -0.82674, 47.20962, -0.82203, 47.21024,
      -0.82181, 47.21021, -0.82038, 47.21054, -0.81962, 47.21074, -0.81804,
      47.20926, -0.81792, 47.20881, -0.81728, 47.20926, -0.8163, 47.20946,
      -0.81526, 47.20979, -0.81459, 47.20977, -0.81361, 47.20965, -0.81298,
      47.2096, -0.81209, 47.20856, -0.81259, 47.20786, -0.81315, 47.20726,
      -0.8139, 47.2069, -0.81388, 47.20456, -0.81209, 47.20484, -0.81137,
      47.20339, -0.81044, 47.20308, -0.81035, 47.20275, -0.8108, 47.2024,
      -0.81064, 47.20202, -0.81135, 47.19908, -0.81025, 47.19886, -0.81143,
      47.1985, -0.81141, 47.19832, -0.81153, 47.19812, -0.81244, 47.19808,
      -0.81323, 47.19715, -0.81388, 47.19663, -0.8141, 47.19547, -0.81202,
      47.19697, -0.81115, 47.19658, -0.80972, 47.19614, -0.80889, 47.19558,
      -0.80793, 47.19471, -0.80702, 47.19452, -0.80721, 47.19433, -0.80773,
      47.19445, -0.80813, 47.19342, -0.80965, 47.19388, -0.81087, 47.1947,
      -0.81231, 47.19478, -0.81264, 47.19427, -0.81327, 47.1943, -0.81367,
      47.19365, -0.81449, 47.19346, -0.81461, 47.19249, -0.81402, 47.1919,
      -0.81439, 47.19122, -0.81428, 47.18917, -0.81332, 47.18976, -0.81076,
      47.18915, -0.81112, 47.1879, -0.81211, 47.18719, -0.80882, 47.18604,
      -0.80949, 47.18599, -0.80968, 47.18643, -0.81169, 47.18645, -0.81261,
      47.18536, -0.81321, 47.18472, -0.8133, 47.18418, -0.81321, 47.18356,
      -0.81284, 47.18313, -0.81242, 47.18325, -0.8113, 47.18306, -0.81004,
      47.18276, -0.80936, 47.18253, -0.80954, 47.18215, -0.81018, 47.18078,
      -0.81103, 47.17997, -0.80964, 47.17964, -0.8094, 47.17896, -0.80914,
      47.17809, -0.8083, 47.17725, -0.80717, 47.17684, -0.80639, 47.17546,
      -0.80782, 47.17528, -0.80832, 47.17522, -0.80948, 47.17498, -0.81017,
      47.17478, -0.81054, 47.17373, -0.81178, 47.17319, -0.81265, 47.17237,
      -0.8118, 47.17187, -0.81261, 47.17173, -0.81267, 47.1696, -0.80992,
      47.1694, -0.81109, 47.1691, -0.81169, 47.16896, -0.81175, 47.16881,
      -0.81231, 47.16727, -0.81438, 47.16699, -0.81456, 47.16628, -0.81558,
      47.16584, -0.8164, 47.16558, -0.8163, 47.1649, -0.8153, 47.16467,
      -0.81475, 47.16431, -0.8122, 47.16407, -0.80978, 47.16364, -0.80966,
      47.16267, -0.80989, 47.16097, -0.81112, 47.15976, -0.81283, 47.15929,
      -0.81334, 47.15859, -0.81439, 47.1585, -0.81493, 47.15848, -0.81619,
      47.1583, -0.8178, 47.15804, -0.81861, 47.15763, -0.81931, 47.15835,
      -0.81951, 47.15875, -0.81984, 47.15889, -0.82011, 47.15956, -0.82221,
      47.15981, -0.82501, 47.16021, -0.82607, 47.16019, -0.82687, 47.15973,
      -0.82849, 47.15958, -0.82841, 47.15951, -0.82822, 47.15925, -0.82807,
      47.15913, -0.82766, 47.15885, -0.82759, 47.1581, -0.82689, 47.15737,
      -0.82561, 47.15685, -0.82534, 47.1558, -0.82605, 47.15559, -0.82608,
      47.15371, -0.82757, 47.15236, -0.8278, 47.1525, -0.82841, 47.15251,
      -0.82907, 47.15265, -0.82989, 47.15266, -0.83008, 47.15255, -0.83011,
      47.15272, -0.83032, 47.15124, -0.83069, 47.15107, -0.83063, 47.15049,
      -0.83111, 47.15008, -0.83166, 47.15036, -0.8323, 47.15045, -0.83294,
      47.15028, -0.83462, 47.15031, -0.83547, 47.15052, -0.83678, 47.15033,
      -0.83797, 47.15003, -0.83879, 47.14997, -0.83955, 47.15024, -0.84138,
      47.15038, -0.84287, 47.15114, -0.84409, 47.15143, -0.84502, 47.15153,
      -0.84663, 47.1515, -0.84755, 47.1516, -0.84802, 47.15173, -0.84851,
      47.15247, -0.84996, 47.15268, -0.85053, 47.15284, -0.85146, 47.15282,
      -0.85565, 47.15244, -0.85738, 47.15136, -0.86048, 47.15131, -0.86168,
      47.1516, -0.86314, 47.15211, -0.86395, 47.15285, -0.86453, 47.15332,
      -0.86514, 47.15358, -0.86517, 47.15376, -0.8658, 47.15428, -0.86633,
      47.15555, -0.86696, 47.15688, -0.8668, 47.15703, -0.86824, 47.15695,
      -0.86877, 47.15706, -0.86974, 47.15742, -0.87069, 47.15746, -0.87106,
      47.15806, -0.87303, 47.1583, -0.87346, 47.15834, -0.87369, 47.15824,
      -0.87384, 47.15826, -0.87407, 47.15868, -0.87482, 47.15887, -0.87499,
      47.15915, -0.87501, 47.15922, -0.87516, 47.15911, -0.8754, 47.15938,
      -0.87548, 47.15951, -0.87587, 47.1597, -0.87596, 47.15942, -0.87603,
      47.15929, -0.87627, 47.15912, -0.87622, 47.15902, -0.87645, 47.15915,
      -0.87659, 47.15917, -0.87681, 47.159, -0.87711, 47.15902, -0.8773,
      47.15891, -0.87738, 47.15903, -0.87899, 47.15933, -0.87943, 47.15939,
      -0.87976, 47.15919, -0.88029, 47.15936, -0.88086, 47.159, -0.88328,
      47.1589, -0.88351, 47.15894, -0.88372, 47.15883, -0.88402, 47.15897,
      -0.88421, 47.15885, -0.88504, 47.15896, -0.88582, 47.15921, -0.88634,
      47.15982, -0.8872, 47.16016, -0.88804, 47.16016, -0.88853, 47.16037,
      -0.88868, 47.1602, -0.89047, 47.16023, -0.89098, 47.16083, -0.8933,
      47.16107, -0.89391, 47.16114, -0.89436, 47.16114, -0.89537, 47.16126,
      -0.89581, 47.16101, -0.89755, 47.16069, -0.89867, 47.16043, -0.89908,
      47.16045, -0.90124, 47.161, -0.90294, 47.1601, -0.90419, 47.16112,
      -0.90482, 47.1611, -0.90682, 47.16022, -0.90885, 47.16069, -0.90934,
      47.16119, -0.90967, 47.16497, -0.91113, 47.16476, -0.91191, 47.16469,
      -0.91273, 47.16325, -0.9123, 47.16116, -0.91112, 47.16084, -0.9113,
      47.16027, -0.91093, 47.15993, -0.91035, 47.15965, -0.91013, 47.15931,
      -0.91163, 47.15915, -0.91541, 47.15926, -0.91646, 47.15915, -0.91659,
      47.15964, -0.91875, 47.15995, -0.91957, 47.16073, -0.92108, 47.16065,
      -0.92139, 47.16159, -0.92287, 47.16202, -0.92332, 47.16298, -0.92501,
      47.16368, -0.92607, 47.16337, -0.92648, 47.16321, -0.92686, 47.16277,
      -0.92721, 47.16286, -0.92856, 47.16276, -0.92893, 47.16191, -0.93038,
      47.1617, -0.93108, 47.16154, -0.93213, 47.16134, -0.93244, 47.16113,
      -0.93303, 47.16056, -0.93383, 47.16033, -0.93444, 47.16016, -0.93467,
      47.15909, -0.93545, 47.15876, -0.93585, 47.15837, -0.93607, 47.15722,
      -0.93733, 47.15745, -0.93782, 47.15741, -0.93833, 47.15569, -0.93955,
      47.15486, -0.9408, 47.15419, -0.94032, 47.15388, -0.93988, 47.15367,
      -0.94004, 47.15372, -0.94026, 47.15308, -0.94205, 47.15274, -0.94176,
      47.15211, -0.9416, 47.15206, -0.94186, 47.15226, -0.9421, 47.15223,
      -0.94303, 47.15209, -0.94354, 47.15155, -0.9439, 47.15014, -0.94353,
      47.14992, -0.94364, 47.14976, -0.9433, 47.14955, -0.94342, 47.14999,
      -0.94508, 47.15005, -0.94559, 47.14985, -0.94574, 47.14947, -0.94679,
      47.14939, -0.94719, 47.14934, -0.94829, 47.14796, -0.95103, 47.14783,
      -0.95146, 47.14702, -0.95275, 47.14483, -0.95464, 47.14479, -0.95546,
      47.14428, -0.95503, 47.14419, -0.95504, 47.1442, -0.95559, 47.14479,
      -0.95646, 47.1447, -0.95687, 47.14472, -0.95716, 47.14501, -0.95785,
      47.14515, -0.95972, 47.14537, -0.96035, 47.14539, -0.96061, 47.14547,
      -0.96069, 47.14538, -0.96186, 47.14514, -0.96306, 47.14633, -0.9661,
      47.14737, -0.96807, 47.14827, -0.97056, 47.14829, -0.97237, 47.1482,
      -0.97273, 47.14796, -0.97307, 47.14808, -0.97388, 47.14804, -0.97508,
      47.14813, -0.97538, 47.14836, -0.97558, 47.14887, -0.97728, 47.14793,
      -0.97979, 47.146, -0.98279, 47.14622, -0.98423, 47.14634, -0.98446,
      47.14661, -0.98601, 47.14711, -0.98733, 47.14817, -0.98755, 47.14955,
      -0.98766, 47.15107, -0.98819, 47.15072, -0.98921, 47.15076, -0.99047,
      47.14975, -0.99259, 47.14951, -0.99368, 47.1493, -0.99409, 47.14956,
      -0.99447, 47.14968, -0.99524, 47.1504, -0.99525, 47.15124, -0.995,
      47.15268, -0.99518, 47.15333, -0.9956, 47.15266, -0.99753, 47.1516,
      -0.99906, 47.15145, -1, 47.15167, -1.00168, 47.15206, -1.00291, 47.15282,
      -1.00479, 47.1527, -1.00495, 47.15244, -1.00653, 47.15186, -1.00801,
      47.15115, -1.0088, 47.1506, -1.00986, 47.15043, -1.00982, 47.15038,
      -1.01001, 47.14976, -1.00942, 47.14935, -1.01059, 47.14846, -1.01022,
      47.14888, -1.00967, 47.1492, -1.00894, 47.14951, -1.00873, 47.14902,
      -1.00794, 47.14896, -1.00776, 47.14905, -1.00756, 47.14845, -1.00647,
      47.14862, -1.0058, 47.14809, -1.00493, 47.1481, -1.00451, 47.14732,
      -1.00402, 47.14672, -1.00388, 47.14647, -1.00558, 47.14607, -1.00727,
      47.146, -1.00788, 47.14608, -1.00803, 47.14547, -1.00991, 47.14482,
      -1.01072, 47.14413, -1.01199, 47.14362, -1.01252, 47.14356, -1.01272,
      47.1448, -1.01411, 47.14527, -1.01354, 47.1471, -1.01346, 47.14741,
      -1.01337, 47.14827, -1.01487, 47.1474, -1.01635, 47.14759, -1.01682,
      47.14732, -1.01722, 47.147, -1.01809, 47.14689, -1.01815, 47.14661,
      -1.01902, 47.14625, -1.01976, 47.1462, -1.0201, 47.14593, -1.02056,
      47.14578, -1.0212, 47.14561, -1.02158, 47.14515, -1.02197, 47.14502,
      -1.02248, 47.14466, -1.02304, 47.1445, -1.02378, 47.14464, -1.02392,
      47.14445, -1.02442, 47.14449, -1.02464, 47.14464, -1.02468, 47.14493,
      -1.02453, 47.14502, -1.02426, 47.14515, -1.02424, 47.14544, -1.02462,
      47.1454, -1.02486, 47.14566, -1.0252, 47.14564, -1.02547, 47.1458,
      -1.02575, 47.14572, -1.02631, 47.14576, -1.02645, 47.14558, -1.02677,
      47.14556, -1.02718, 47.1455, -1.02729, 47.14523, -1.02735, 47.14508,
      -1.02831, 47.14486, -1.02849, 47.14474, -1.02915, 47.14499, -1.02947,
      47.14526, -1.03003, 47.14579, -1.03057, 47.14575, -1.03091, 47.14543,
      -1.03124, 47.14538, -1.03145, 47.14585, -1.03242, 47.14565, -1.0328,
      47.14561, -1.0336, 47.14525, -1.0337, 47.14521, -1.03402, 47.14531,
      -1.03484, 47.14568, -1.03567, 47.14554, -1.03609, 47.14571, -1.03672,
      47.14584, -1.03688, 47.14575, -1.03737, 47.14599, -1.03755, 47.14602,
      -1.03775, 47.14582, -1.0384, 47.14565, -1.03848, 47.1456, -1.03894,
      47.14536, -1.03875, 47.14503, -1.03869, 47.14448, -1.03908, 47.14433,
      -1.03929, 47.14424, -1.03981, 47.14427, -1.04015, 47.14442, -1.04067,
      47.14474, -1.04135, 47.14523, -1.04188, 47.14546, -1.04198, 47.14569,
      -1.04256, 47.14594, -1.04261, 47.14619, -1.04284, 47.14637, -1.04318,
      47.14579, -1.04363, 47.14519, -1.04364, 47.14454, -1.04435, 47.144,
      -1.04462, 47.14373, -1.04501, 47.14361, -1.04559, 47.14333, -1.04598,
      47.14315, -1.0464, 47.14242, -1.04717, 47.14225, -1.04762, 47.14207,
      -1.04777, 47.14157, -1.04865, 47.14097, -1.04934, 47.1403, -1.04988,
      47.14002, -1.04977, 47.13983, -1.05011, 47.1398, -1.05099, 47.13986,
      -1.05107, 47.13977, -1.05138, 47.13943, -1.05197, 47.13935, -1.05229,
      47.13939, -1.05265, 47.13922, -1.05304, 47.13879, -1.05553, 47.13879,
      -1.05582, 47.13854, -1.05623, 47.13837, -1.05683, 47.13804, -1.05728,
      47.138, -1.05782, 47.13778, -1.05794, 47.13763, -1.05825, 47.13736,
      -1.05893, 47.13732, -1.05928, 47.13653, -1.06051, 47.13637, -1.06061,
      47.13598, -1.06139, 47.1358, -1.06198, 47.13607, -1.0623, 47.13572,
      -1.06276, 47.1355, -1.06322, 47.13486, -1.06647, 47.13458, -1.06633,
      47.13372, -1.06841, 47.13315, -1.06902, 47.13272, -1.06964, 47.1321,
      -1.07183, 47.13201, -1.07275, 47.13188, -1.07326, 47.13091, -1.07491,
      47.13215, -1.07581, 47.13615, -1.07709, 47.13649, -1.07738, 47.13762,
      -1.07873, 47.13827, -1.07849, 47.13974, -1.07687, 47.14085, -1.07636,
      47.14193, -1.07495, 47.14302, -1.07448, 47.1435, -1.07401, 47.14432,
      -1.07355, 47.14464, -1.07374, 47.14572, -1.07357, 47.14751, -1.07374,
      47.15032, -1.07488, 47.15147, -1.07584, 47.15179, -1.07638, 47.15155,
      -1.07674, 47.15124, -1.07692, 47.15074, -1.07753, 47.15056, -1.07817,
      47.15008, -1.07893, 47.15029, -1.07967, 47.15037, -1.08055, 47.15039,
      -1.08316, 47.15131, -1.08328, 47.15208, -1.08302, 47.15359, -1.08308,
      47.15608, -1.08239, 47.15666, -1.08483, 47.15701, -1.08521, 47.15662,
      -1.08584, 47.15667, -1.08597, 47.1569, -1.08598, 47.15716, -1.08651,
      47.15744, -1.08678, 47.15782, -1.08767, 47.158, -1.08849, 47.15796,
      -1.08966, 47.15786, -1.08999, 47.1577, -1.09013, 47.15689, -1.09054,
      47.15655, -1.09082, 47.15621, -1.09095, 47.15533, -1.09096, 47.15535,
      -1.09125, 47.15641, -1.0916, 47.15718, -1.09156, 47.15644, -1.09206,
      47.15474, -1.09222, 47.1536, -1.09281, 47.15254, -1.09367, 47.15244,
      -1.09386, 47.15352, -1.09652, 47.15357, -1.09716, 47.1535, -1.09807,
      47.15359, -1.09882, 47.15422, -1.10074, 47.1537, -1.10112, 47.14944,
      -1.10571, 47.14816, -1.10452, 47.14697, -1.10677, 47.14805, -1.10769,
      47.14742, -1.10893, 47.14617, -1.11096, 47.14871, -1.11191, 47.14909,
      -1.11251, 47.14966, -1.11431, 47.15106, -1.11651, 47.15121, -1.11753,
      47.15179, -1.11992, 47.15239, -1.12155, 47.15167, -1.12336, 47.15056,
      -1.12488, 47.15442, -1.12704, 47.15481, -1.12733, 47.15538, -1.1281,
      47.15613, -1.12716, 47.15755, -1.12627, 47.15823, -1.12611, 47.16039,
      -1.12632, 47.16045, -1.12731, 47.1607, -1.12779, 47.16148, -1.12849,
      47.16177, -1.12896, 47.16184, -1.13044, 47.16251, -1.13064, 47.163,
      -1.13092, 47.16329, -1.13074, 47.16394, -1.13126, 47.16442, -1.13139,
      47.16467, -1.13158, 47.16601, -1.13344, 47.16667, -1.13517, 47.16771,
      -1.1366, 47.16872, -1.13899, 47.16875, -1.13966, 47.16859, -1.14052,
      47.16849, -1.14059, 47.16841, -1.14112, 47.16813, -1.14191, 47.16744,
      -1.14317, 47.16698, -1.14379, 47.16671, -1.14385, 47.16672, -1.14437,
      47.16646, -1.14584, 47.16648, -1.14697, 47.16638, -1.14742, 47.16686,
      -1.14911, 47.16754, -1.15092, 47.1696, -1.155, 47.17011, -1.15675,
      47.17057, -1.15697, 47.17083, -1.15731, 47.17107, -1.15735, 47.17132,
      -1.15767, 47.17143, -1.15793, 47.17135, -1.15846, 47.1714, -1.15897,
      47.17177, -1.15936, 47.1718, -1.15971, 47.17209, -1.16014, 47.17197,
      -1.16045, 47.17196, -1.16105, 47.17179, -1.16134, 47.17168, -1.16135,
      47.17169, -1.16171, 47.17153, -1.16184, 47.17151, -1.16223, 47.17139,
      -1.16237, 47.1714, -1.16266, 47.17122, -1.16279, 47.17113, -1.16303,
      47.17109, -1.16355, 47.17094, -1.16408, 47.17114, -1.16474, 47.17109,
      -1.16484, 47.17066, -1.16495, 47.17052, -1.16518, 47.17027, -1.16502,
      47.17003, -1.16605, 47.17013, -1.16647, 47.17039, -1.16679, 47.17051,
      -1.16709, 47.17052, -1.1672, 47.1703, -1.16754, 47.17041, -1.16784,
      47.1703, -1.16799, 47.17041, -1.16818, 47.17028, -1.16848, 47.17052,
      -1.16905, 47.17033, -1.16934, 47.1703, -1.16964, 47.17051, -1.16992
    ]
  ]
};

export const INGRANDES_LE_FRESNE_SUR_LOIRE: LocationState = {
  id: 'FR-49160',
  name: 'Ingrandes-Le Fresne sur Loire',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.42655, -0.92269, 47.42705, -0.91987, 47.42656, -0.91656, 47.42615,
      -0.91568, 47.4265, -0.91047, 47.42645, -0.9085, 47.42543, -0.90778,
      47.42493, -0.90703, 47.42483, -0.9065, 47.42478, -0.90535, 47.42444,
      -0.90412, 47.42346, -0.90157, 47.4233, -0.90065, 47.42328, -0.90006,
      47.42211, -0.89978, 47.42147, -0.89942, 47.42065, -0.89834, 47.42014,
      -0.89734, 47.41982, -0.89726, 47.41959, -0.89697, 47.41846, -0.8963,
      47.41821, -0.89629, 47.41795, -0.89644, 47.41763, -0.89679, 47.41743,
      -0.89718, 47.4163, -0.89798, 47.41606, -0.89791, 47.41602, -0.8976,
      47.41572, -0.89744, 47.4153, -0.89763, 47.41447, -0.89767, 47.41402,
      -0.89751, 47.41352, -0.89717, 47.41322, -0.89753, 47.41231, -0.89681,
      47.4114, -0.89657, 47.41038, -0.89668, 47.40992, -0.89808, 47.40985,
      -0.89852, 47.40987, -0.89964, 47.41001, -0.89991, 47.41003, -0.90024,
      47.40994, -0.90027, 47.41018, -0.90033, 47.41029, -0.90061, 47.41033,
      -0.90056, 47.41058, -0.90164, 47.41069, -0.90272, 47.41046, -0.90466,
      47.41008, -0.90539, 47.40999, -0.90573, 47.4099, -0.90692, 47.4098,
      -0.90737, 47.40979, -0.90781, 47.40985, -0.90784, 47.40969, -0.90876,
      47.40962, -0.90856, 47.40969, -0.90852, 47.40972, -0.90799, 47.40966,
      -0.908, 47.40959, -0.90826, 47.40954, -0.90896, 47.40945, -0.90923,
      47.40871, -0.91001, 47.40808, -0.91104, 47.40752, -0.91281, 47.40693,
      -0.91421, 47.4064, -0.91654, 47.406, -0.91761, 47.40575, -0.91812,
      47.40543, -0.91851, 47.40448, -0.91925, 47.40424, -0.91926, 47.40197,
      -0.91619, 47.39914, -0.92322, 47.39739, -0.92568, 47.39625, -0.92711,
      47.39447, -0.92893, 47.38862, -0.93433, 47.38583, -0.9367, 47.38487,
      -0.93791, 47.38254, -0.94256, 47.38364, -0.94383, 47.38475, -0.94288,
      47.38651, -0.94107, 47.38814, -0.93912, 47.38911, -0.94073, 47.38758,
      -0.94271, 47.38725, -0.94337, 47.38698, -0.94413, 47.38695, -0.94444,
      47.38698, -0.94666, 47.38686, -0.94891, 47.38706, -0.94898, 47.3896,
      -0.95085, 47.39277, -0.95102, 47.39311, -0.95114, 47.39336, -0.95106,
      47.39363, -0.95117, 47.39402, -0.94993, 47.39493, -0.94814, 47.39561,
      -0.94806, 47.3971, -0.94833, 47.39898, -0.94922, 47.39898, -0.94861,
      47.39947, -0.94866, 47.39949, -0.94936, 47.40046, -0.94956, 47.40045,
      -0.94974, 47.4026, -0.95056, 47.4039, -0.95091, 47.40404, -0.95073,
      47.40428, -0.94954, 47.40637, -0.95009, 47.4069, -0.95048, 47.40745,
      -0.95147, 47.40763, -0.95198, 47.40934, -0.95325, 47.40972, -0.95394,
      47.4099, -0.95431, 47.40984, -0.9544, 47.41032, -0.95534, 47.41055,
      -0.95637, 47.41087, -0.95667, 47.41146, -0.95663, 47.41372, -0.95707,
      47.41483, -0.95815, 47.41571, -0.95887, 47.41615, -0.95906, 47.41671,
      -0.95891, 47.41833, -0.96128, 47.41914, -0.96106, 47.42298, -0.96115,
      47.42322, -0.95934, 47.42306, -0.95925, 47.42315, -0.95754, 47.4233,
      -0.95761, 47.42347, -0.95673, 47.42381, -0.95601, 47.42298, -0.95522,
      47.42319, -0.95438, 47.42414, -0.95357, 47.42381, -0.95262, 47.42357,
      -0.95151, 47.42303, -0.95024, 47.42267, -0.94831, 47.42082, -0.94804,
      47.42001, -0.94729, 47.41987, -0.94682, 47.41965, -0.9447, 47.41952,
      -0.9442, 47.4194, -0.94399, 47.4186, -0.94326, 47.41895, -0.94224,
      47.42011, -0.94275, 47.42046, -0.9428, 47.42085, -0.94277, 47.42141,
      -0.94255, 47.42202, -0.94157, 47.42297, -0.94055, 47.42508, -0.93646,
      47.42631, -0.93284, 47.42673, -0.9313, 47.42691, -0.93023, 47.42696,
      -0.92946, 47.42685, -0.92514, 47.42671, -0.9238, 47.42658, -0.92328,
      47.42635, -0.92282, 47.4264, -0.92265, 47.42655, -0.92269
    ]
  ]
};

export const SEVREMOINE: LocationState = {
  id: 'FR-49301',
  name: 'Sèvremoine',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      47.00356, -1.0163, 47.00379, -1.01683, 47.00443, -1.01737, 47.00493,
      -1.01798, 47.00514, -1.01872, 47.0052, -1.01924, 47.00515, -1.02018,
      47.00484, -1.02177, 47.00448, -1.02228, 47.00409, -1.02267, 47.00397,
      -1.02345, 47.00401, -1.02463, 47.00393, -1.0261, 47.00394, -1.02775,
      47.00438, -1.02852, 47.00448, -1.02885, 47.00465, -1.02956, 47.00486,
      -1.03131, 47.00505, -1.03188, 47.00512, -1.03246, 47.00507, -1.03334,
      47.00465, -1.0345, 47.00438, -1.03549, 47.00435, -1.03593, 47.00444,
      -1.03642, 47.00475, -1.037, 47.00501, -1.03813, 47.00524, -1.03867,
      47.00543, -1.03877, 47.00605, -1.03867, 47.00626, -1.03875, 47.00654,
      -1.03931, 47.00659, -1.03967, 47.00659, -1.04054, 47.00628, -1.04128,
      47.00534, -1.04213, 47.00452, -1.04264, 47.00436, -1.04307, 47.00431,
      -1.04366, 47.00491, -1.0461, 47.00519, -1.0469, 47.00536, -1.04713,
      47.00564, -1.0472, 47.00634, -1.04714, 47.007, -1.04731, 47.00718,
      -1.04747, 47.00726, -1.04776, 47.00721, -1.04816, 47.00677, -1.04896,
      47.00624, -1.05025, 47.00545, -1.05054, 47.00524, -1.05072, 47.00493,
      -1.05124, 47.00485, -1.0518, 47.00446, -1.05318, 47.00439, -1.05464,
      47.00447, -1.05508, 47.00455, -1.05517, 47.00529, -1.05532, 47.00612,
      -1.05564, 47.00709, -1.05623, 47.00738, -1.05653, 47.00762, -1.05738,
      47.009, -1.05973, 47.00914, -1.06056, 47.00898, -1.06213, 47.00884,
      -1.06241, 47.00821, -1.06286, 47.0079, -1.06329, 47.00787, -1.06375,
      47.00801, -1.06407, 47.00842, -1.06427, 47.00875, -1.06429, 47.00897,
      -1.06379, 47.0093, -1.06375, 47.00951, -1.06384, 47.01063, -1.06481,
      47.01252, -1.06607, 47.01275, -1.06628, 47.01304, -1.06676, 47.01309,
      -1.0677, 47.01294, -1.06853, 47.01263, -1.06934, 47.01219, -1.07012,
      47.01178, -1.07052, 47.01107, -1.07151, 47.01059, -1.07171, 47.00999,
      -1.07166, 47.00968, -1.07174, 47.00909, -1.07225, 47.00863, -1.07292,
      47.00845, -1.07388, 47.00848, -1.07429, 47.00902, -1.07569, 47.00927,
      -1.07613, 47.01015, -1.07722, 47.01079, -1.07831, 47.01124, -1.07965,
      47.01146, -1.08005, 47.01329, -1.08149, 47.01339, -1.08175, 47.01341,
      -1.08208, 47.01332, -1.08271, 47.013, -1.08312, 47.01258, -1.08329,
      47.01212, -1.08362, 47.01193, -1.08392, 47.01176, -1.08463, 47.01183,
      -1.08524, 47.01261, -1.08686, 47.0136, -1.08813, 47.01411, -1.08859,
      47.01446, -1.08904, 47.01513, -1.09042, 47.01556, -1.09159, 47.01574,
      -1.09228, 47.01576, -1.09272, 47.01539, -1.09445, 47.01516, -1.09493,
      47.01453, -1.0954, 47.0141, -1.09594, 47.01369, -1.09664, 47.01353,
      -1.09736, 47.0136, -1.09755, 47.01437, -1.09848, 47.01482, -1.10039,
      47.01496, -1.10547, 47.015, -1.10558, 47.01527, -1.1057, 47.01563,
      -1.10744, 47.01575, -1.10855, 47.01567, -1.11027, 47.01581, -1.11102,
      47.0161, -1.11151, 47.01641, -1.1116, 47.01685, -1.11284, 47.01756,
      -1.11332, 47.01833, -1.11366, 47.01869, -1.114, 47.01902, -1.11462,
      47.01947, -1.1158, 47.02052, -1.11788, 47.02082, -1.11876, 47.02152,
      -1.12329, 47.02158, -1.12641, 47.02177, -1.12646, 47.02174, -1.12687,
      47.02144, -1.12762, 47.0214, -1.12813, 47.02175, -1.1296, 47.0226,
      -1.13158, 47.02273, -1.13311, 47.02328, -1.1341, 47.02372, -1.13463,
      47.02393, -1.13475, 47.02409, -1.13454, 47.02436, -1.13453, 47.02458,
      -1.13481, 47.02459, -1.13549, 47.025, -1.13681, 47.02509, -1.1374,
      47.02504, -1.13801, 47.02465, -1.13822, 47.02442, -1.13951, 47.0248,
      -1.14365, 47.02573, -1.14489, 47.02678, -1.14581, 47.02747, -1.1457,
      47.02784, -1.14593, 47.02802, -1.14645, 47.02835, -1.14704, 47.02945,
      -1.14837, 47.02959, -1.14793, 47.02964, -1.14673, 47.03043, -1.14504,
      47.03072, -1.14462, 47.03116, -1.14427, 47.03123, -1.14379, 47.03124,
      -1.14257, 47.03168, -1.14157, 47.03206, -1.14008, 47.03268, -1.13935,
      47.03308, -1.13862, 47.03319, -1.13809, 47.03363, -1.1375, 47.03415,
      -1.13699, 47.03479, -1.13668, 47.03496, -1.13641, 47.03502, -1.13579,
      47.0349, -1.13441, 47.03441, -1.13288, 47.03539, -1.13203, 47.03606,
      -1.13177, 47.03632, -1.13129, 47.03637, -1.13107, 47.03632, -1.1307,
      47.03598, -1.12992, 47.0358, -1.12981, 47.03568, -1.12948, 47.0358,
      -1.12895, 47.03605, -1.12846, 47.03608, -1.12673, 47.03619, -1.12648,
      47.03639, -1.12628, 47.03738, -1.1262, 47.03788, -1.12556, 47.03842,
      -1.12446, 47.03849, -1.12402, 47.03836, -1.12337, 47.03848, -1.12298,
      47.03834, -1.12262, 47.03832, -1.12223, 47.03822, -1.12202, 47.0383,
      -1.12187, 47.03825, -1.12159, 47.03865, -1.12057, 47.03901, -1.12039,
      47.03899, -1.11995, 47.03929, -1.11941, 47.0393, -1.11903, 47.03949,
      -1.11894, 47.03946, -1.11868, 47.03969, -1.11827, 47.03984, -1.11817,
      47.03991, -1.11789, 47.04008, -1.11778, 47.04005, -1.11763, 47.03989,
      -1.11755, 47.03995, -1.11736, 47.04128, -1.11707, 47.0414, -1.11698,
      47.04144, -1.11668, 47.04159, -1.1167, 47.04187, -1.117, 47.04235,
      -1.11712, 47.04316, -1.11703, 47.04448, -1.11727, 47.04482, -1.11742,
      47.04519, -1.11776, 47.04598, -1.11759, 47.04708, -1.11881, 47.04825,
      -1.1194, 47.05046, -1.11975, 47.05322, -1.12052, 47.05613, -1.12104,
      47.05686, -1.12096, 47.05727, -1.12065, 47.05774, -1.12002, 47.05848,
      -1.11803, 47.05978, -1.1168, 47.06006, -1.11633, 47.06134, -1.1149,
      47.06179, -1.11476, 47.06275, -1.11529, 47.06347, -1.11828, 47.064,
      -1.1185, 47.06419, -1.11872, 47.06441, -1.11939, 47.06443, -1.12073,
      47.06418, -1.12194, 47.06402, -1.12204, 47.06402, -1.12218, 47.06452,
      -1.12365, 47.06447, -1.12444, 47.0646, -1.12501, 47.06461, -1.12565,
      47.06438, -1.12642, 47.0641, -1.1269, 47.06377, -1.12775, 47.06381,
      -1.12903, 47.06403, -1.12953, 47.06408, -1.12993, 47.0643, -1.13039,
      47.06473, -1.13096, 47.06505, -1.13119, 47.06522, -1.13185, 47.06558,
      -1.13226, 47.06571, -1.13272, 47.06569, -1.1332, 47.06606, -1.13428,
      47.06666, -1.13566, 47.06715, -1.13615, 47.06761, -1.13797, 47.06815,
      -1.13932, 47.0679, -1.14033, 47.0678, -1.14132, 47.06789, -1.14184,
      47.06827, -1.1424, 47.06801, -1.14296, 47.06816, -1.14342, 47.06809,
      -1.1438, 47.06862, -1.14578, 47.069, -1.14685, 47.06933, -1.14722,
      47.06984, -1.14888, 47.06998, -1.15004, 47.06982, -1.15047, 47.07052,
      -1.15091, 47.07093, -1.1518, 47.07128, -1.15228, 47.07138, -1.15304,
      47.07148, -1.15337, 47.07173, -1.15371, 47.07174, -1.1541, 47.07205,
      -1.1546, 47.07236, -1.15484, 47.07251, -1.15533, 47.07335, -1.15594,
      47.07364, -1.15636, 47.07465, -1.15746, 47.07547, -1.15808, 47.07598,
      -1.15822, 47.07676, -1.15893, 47.07753, -1.15916, 47.07829, -1.15924,
      47.07851, -1.15917, 47.0788, -1.16139, 47.07925, -1.16145, 47.07953,
      -1.16202, 47.07975, -1.16223, 47.08004, -1.16219, 47.08033, -1.1623,
      47.08072, -1.16221, 47.08107, -1.16232, 47.08125, -1.16255, 47.08148,
      -1.16266, 47.0816, -1.16305, 47.08186, -1.16302, 47.08193, -1.16311,
      47.08194, -1.16352, 47.08206, -1.16362, 47.08263, -1.16339, 47.08306,
      -1.16338, 47.08401, -1.16399, 47.08454, -1.16395, 47.08495, -1.16407,
      47.08609, -1.16382, 47.08629, -1.16408, 47.08644, -1.16413, 47.08664,
      -1.16459, 47.0869, -1.16491, 47.08734, -1.16613, 47.08773, -1.16649,
      47.08775, -1.16682, 47.08807, -1.16719, 47.08842, -1.1674, 47.08891,
      -1.16813, 47.08954, -1.16858, 47.08976, -1.16841, 47.09033, -1.16835,
      47.09045, -1.16823, 47.09101, -1.16832, 47.09132, -1.16858, 47.09175,
      -1.16852, 47.09206, -1.16871, 47.09226, -1.16864, 47.09256, -1.16894,
      47.09275, -1.16896, 47.09278, -1.16909, 47.09294, -1.16913, 47.09331,
      -1.16965, 47.09327, -1.17043, 47.09304, -1.17064, 47.09299, -1.17086,
      47.09275, -1.17152, 47.09275, -1.17211, 47.09296, -1.17304, 47.09353,
      -1.17472, 47.09375, -1.17629, 47.09436, -1.17779, 47.09471, -1.17823,
      47.09546, -1.17885, 47.09551, -1.17911, 47.09543, -1.17948, 47.0949,
      -1.18032, 47.09477, -1.18097, 47.09481, -1.18139, 47.09531, -1.18295,
      47.09532, -1.18341, 47.09565, -1.18497, 47.09562, -1.1858, 47.09536,
      -1.18621, 47.09549, -1.18643, 47.09554, -1.18676, 47.09561, -1.18767,
      47.09558, -1.18828, 47.09531, -1.18962, 47.09522, -1.1898, 47.09511,
      -1.18983, 47.09505, -1.19268, 47.09527, -1.19357, 47.09495, -1.19444,
      47.0949, -1.19523, 47.09533, -1.19701, 47.09615, -1.19984, 47.09667,
      -1.2006, 47.0974, -1.2014, 47.09751, -1.20196, 47.09757, -1.20244,
      47.09751, -1.20321, 47.09705, -1.20526, 47.09692, -1.2063, 47.09695,
      -1.20754, 47.09712, -1.20944, 47.09743, -1.21159, 47.09782, -1.21309,
      47.09787, -1.21392, 47.09836, -1.21501, 47.09906, -1.21697, 47.09923,
      -1.21778, 47.09953, -1.21866, 47.09969, -1.21991, 47.09938, -1.22366,
      47.09944, -1.22452, 47.09941, -1.2255, 47.0997, -1.22645, 47.1, -1.22675,
      47.10032, -1.2281, 47.10065, -1.22891, 47.10057, -1.2297, 47.10024,
      -1.23104, 47.09964, -1.23265, 47.09935, -1.23283, 47.09884, -1.23264,
      47.09834, -1.23269, 47.09727, -1.23324, 47.09684, -1.23356, 47.09649,
      -1.23398, 47.09636, -1.23437, 47.0962, -1.23554, 47.09631, -1.23795,
      47.09615, -1.23908, 47.09586, -1.23945, 47.09564, -1.23957, 47.09471,
      -1.23973, 47.09443, -1.24015, 47.09418, -1.24081, 47.09434, -1.24246,
      47.09433, -1.24434, 47.09443, -1.24476, 47.09463, -1.24502, 47.09587,
      -1.24599, 47.09725, -1.24781, 47.0977, -1.24857, 47.09782, -1.24898,
      47.09779, -1.24936, 47.09858, -1.249, 47.09884, -1.24898, 47.09915,
      -1.24863, 47.09979, -1.24836, 47.10003, -1.24806, 47.10028, -1.24737,
      47.10063, -1.24676, 47.1008, -1.24617, 47.10098, -1.24441, 47.10119,
      -1.2437, 47.10133, -1.24254, 47.10142, -1.24231, 47.10209, -1.24148,
      47.10239, -1.24127, 47.10276, -1.24042, 47.10309, -1.24004, 47.10482,
      -1.23846, 47.10776, -1.23509, 47.10799, -1.23498, 47.10987, -1.23233,
      47.10997, -1.22964, 47.11081, -1.22907, 47.11162, -1.22877, 47.11328,
      -1.22781, 47.11353, -1.22775, 47.115, -1.22638, 47.11499, -1.22667,
      47.11524, -1.22855, 47.11528, -1.22958, 47.11675, -1.2275, 47.11693,
      -1.22697, 47.117, -1.22644, 47.11796, -1.22575, 47.11871, -1.22455,
      47.11889, -1.22462, 47.11999, -1.22707, 47.12029, -1.22742, 47.12158,
      -1.22797, 47.1221, -1.22846, 47.12228, -1.22874, 47.12268, -1.23005,
      47.12267, -1.23076, 47.12334, -1.23065, 47.12304, -1.23215, 47.123,
      -1.23292, 47.12314, -1.23296, 47.12327, -1.23245, 47.12375, -1.23271,
      47.12404, -1.23258, 47.12437, -1.23262, 47.12477, -1.23203, 47.12811,
      -1.23223, 47.13033, -1.23185, 47.13074, -1.23161, 47.1313, -1.23099,
      47.13138, -1.23146, 47.13164, -1.2318, 47.13244, -1.23353, 47.13248,
      -1.23406, 47.13243, -1.2344, 47.1323, -1.23459, 47.13186, -1.23456,
      47.13162, -1.23476, 47.13157, -1.23496, 47.13161, -1.23515, 47.13235,
      -1.23603, 47.13359, -1.23699, 47.134, -1.23751, 47.13467, -1.23776,
      47.13497, -1.23817, 47.13494, -1.23784, 47.13502, -1.23751, 47.13523,
      -1.2373, 47.13536, -1.2373, 47.13579, -1.23768, 47.13617, -1.23864,
      47.13689, -1.23902, 47.1372, -1.23888, 47.1373, -1.23862, 47.137,
      -1.23724, 47.13698, -1.23615, 47.13727, -1.23441, 47.13751, -1.23378,
      47.13772, -1.23357, 47.13789, -1.23354, 47.13815, -1.23383, 47.13849,
      -1.23479, 47.13888, -1.23521, 47.13955, -1.23531, 47.13981, -1.23508,
      47.14006, -1.2351, 47.14052, -1.23445, 47.14058, -1.23403, 47.14024,
      -1.23354, 47.14019, -1.23335, 47.14046, -1.23283, 47.14046, -1.23262,
      47.14011, -1.23219, 47.13989, -1.23212, 47.13958, -1.23174, 47.13929,
      -1.23159, 47.13868, -1.2308, 47.13817, -1.23042, 47.13816, -1.23015,
      47.13802, -1.22988, 47.13803, -1.22961, 47.13846, -1.22888, 47.13897,
      -1.22865, 47.1393, -1.2288, 47.13975, -1.22856, 47.14046, -1.22868,
      47.14079, -1.22849, 47.14097, -1.22874, 47.14146, -1.22855, 47.14202,
      -1.22862, 47.14215, -1.22851, 47.14229, -1.22807, 47.14224, -1.22764,
      47.14191, -1.22756, 47.14168, -1.2272, 47.14083, -1.22634, 47.14064,
      -1.22541, 47.14038, -1.22483, 47.14031, -1.22383, 47.14094, -1.22316,
      47.14158, -1.22214, 47.14216, -1.22173, 47.14235, -1.22109, 47.14226,
      -1.22026, 47.14246, -1.21982, 47.14277, -1.21943, 47.14354, -1.21898,
      47.14404, -1.21892, 47.14447, -1.21909, 47.145, -1.21962, 47.14551,
      -1.21971, 47.14561, -1.21963, 47.14561, -1.21943, 47.14578, -1.21897,
      47.1458, -1.21853, 47.14547, -1.2176, 47.14524, -1.21733, 47.14497,
      -1.21723, 47.14467, -1.21726, 47.14426, -1.21745, 47.1436, -1.21713,
      47.14339, -1.21685, 47.14329, -1.21632, 47.14299, -1.21575, 47.14294,
      -1.21537, 47.14303, -1.21481, 47.14335, -1.21466, 47.14377, -1.21406,
      47.14491, -1.21371, 47.14538, -1.2127, 47.14543, -1.21217, 47.14538,
      -1.21193, 47.14526, -1.21184, 47.14496, -1.21193, 47.14475, -1.21186,
      47.14467, -1.21171, 47.14467, -1.21124, 47.14417, -1.21054, 47.14394,
      -1.20985, 47.14335, -1.20977, 47.14314, -1.20957, 47.1431, -1.20939,
      47.1433, -1.20915, 47.14314, -1.2087, 47.14327, -1.20847, 47.14373,
      -1.20847, 47.14398, -1.20809, 47.14456, -1.2079, 47.14471, -1.20739,
      47.14469, -1.20714, 47.14441, -1.20725, 47.14408, -1.2069, 47.14491,
      -1.20482, 47.14553, -1.20396, 47.1462, -1.20397, 47.14655, -1.20431,
      47.14672, -1.20412, 47.14671, -1.20362, 47.14699, -1.20366, 47.1472,
      -1.20414, 47.14711, -1.20451, 47.1472, -1.20477, 47.14725, -1.20583,
      47.14765, -1.20574, 47.14771, -1.20589, 47.14791, -1.20597, 47.148,
      -1.20611, 47.148, -1.20632, 47.14793, -1.20641, 47.14772, -1.20632,
      47.14772, -1.20657, 47.14757, -1.20673, 47.14755, -1.20694, 47.14784,
      -1.207, 47.148, -1.20721, 47.14834, -1.20725, 47.14883, -1.2071, 47.14904,
      -1.20695, 47.14899, -1.20648, 47.14906, -1.20637, 47.14975, -1.20597,
      47.1502, -1.20599, 47.15037, -1.2051, 47.15028, -1.20505, 47.15006,
      -1.20518, 47.14967, -1.20416, 47.14968, -1.20399, 47.14991, -1.20401,
      47.14999, -1.20392, 47.14991, -1.20361, 47.1499, -1.20305, 47.14943,
      -1.20317, 47.14952, -1.20209, 47.14947, -1.20167, 47.14907, -1.20151,
      47.14898, -1.20132, 47.1488, -1.20119, 47.14858, -1.20065, 47.14863,
      -1.20043, 47.14857, -1.19999, 47.14867, -1.19969, 47.14898, -1.19969,
      47.14909, -1.19953, 47.14925, -1.1995, 47.14958, -1.19977, 47.14982,
      -1.19917, 47.1495, -1.19908, 47.14934, -1.1987, 47.14905, -1.19857,
      47.14899, -1.19833, 47.14907, -1.19825, 47.1491, -1.198, 47.14925,
      -1.19793, 47.14939, -1.19821, 47.14963, -1.19801, 47.14997, -1.19826,
      47.15012, -1.19826, 47.15027, -1.19794, 47.15029, -1.19725, 47.15068,
      -1.19721, 47.15118, -1.19649, 47.15144, -1.19663, 47.15268, -1.19824,
      47.15291, -1.19825, 47.15344, -1.1979, 47.15381, -1.19808, 47.15408,
      -1.198, 47.15414, -1.19727, 47.15453, -1.1969, 47.15453, -1.19624,
      47.15484, -1.1957, 47.15489, -1.19536, 47.15502, -1.19512, 47.155,
      -1.19473, 47.15519, -1.19453, 47.15534, -1.19389, 47.15527, -1.19348,
      47.1553, -1.19286, 47.15514, -1.19237, 47.1548, -1.19224, 47.15472,
      -1.19204, 47.15472, -1.19183, 47.1549, -1.19154, 47.1549, -1.1913,
      47.15479, -1.19117, 47.15453, -1.19115, 47.15454, -1.19094, 47.15488,
      -1.19065, 47.15468, -1.19024, 47.15468, -1.19001, 47.15486, -1.18997,
      47.15505, -1.18965, 47.15541, -1.18987, 47.15546, -1.18957, 47.1557,
      -1.18948, 47.15593, -1.18953, 47.156, -1.18968, 47.15613, -1.18972,
      47.15623, -1.18965, 47.15625, -1.18943, 47.15646, -1.18923, 47.15685,
      -1.18926, 47.15715, -1.18915, 47.15716, -1.18896, 47.15688, -1.18872,
      47.15686, -1.18841, 47.15697, -1.18827, 47.15728, -1.1884, 47.15761,
      -1.18825, 47.15796, -1.18835, 47.15837, -1.18815, 47.15856, -1.18803,
      47.15873, -1.18774, 47.1587, -1.18755, 47.15839, -1.18764, 47.1583,
      -1.18745, 47.15853, -1.18645, 47.15871, -1.18639, 47.15902, -1.18673,
      47.15929, -1.18648, 47.15955, -1.1859, 47.15951, -1.18552, 47.15927,
      -1.185, 47.15926, -1.18479, 47.15944, -1.1847, 47.1596, -1.18499,
      47.15974, -1.18506, 47.15992, -1.1849, 47.16005, -1.18453, 47.15998,
      -1.18426, 47.15919, -1.18378, 47.15942, -1.18337, 47.1593, -1.18299,
      47.15909, -1.18278, 47.15909, -1.1822, 47.15923, -1.18217, 47.15948,
      -1.18252, 47.15958, -1.1825, 47.15968, -1.18241, 47.15959, -1.18181,
      47.15991, -1.18163, 47.16019, -1.18217, 47.16044, -1.18219, 47.16059,
      -1.18184, 47.1604, -1.18146, 47.16043, -1.1812, 47.16052, -1.18118,
      47.16079, -1.18143, 47.1609, -1.18126, 47.16098, -1.18076, 47.16128,
      -1.18054, 47.16143, -1.18058, 47.16173, -1.18093, 47.16209, -1.18099,
      47.16238, -1.18116, 47.16259, -1.18114, 47.16277, -1.18128, 47.16303,
      -1.18105, 47.16312, -1.18133, 47.16335, -1.18138, 47.16336, -1.18184,
      47.16373, -1.18176, 47.16418, -1.18229, 47.1648, -1.18226, 47.1655,
      -1.18137, 47.16557, -1.18088, 47.16548, -1.18047, 47.16537, -1.18035,
      47.16504, -1.18027, 47.16495, -1.18006, 47.165, -1.1799, 47.16526,
      -1.17968, 47.16534, -1.17948, 47.16514, -1.17911, 47.16512, -1.17855,
      47.16481, -1.17865, 47.16461, -1.17835, 47.16443, -1.17843, 47.16421,
      -1.17837, 47.16397, -1.17864, 47.16379, -1.17862, 47.16369, -1.17852,
      47.16365, -1.17817, 47.1634, -1.17801, 47.1632, -1.17806, 47.16312,
      -1.17769, 47.16293, -1.17736, 47.16291, -1.17697, 47.16313, -1.17676,
      47.16323, -1.17589, 47.16344, -1.17534, 47.16356, -1.17525, 47.16369,
      -1.17538, 47.1636, -1.17589, 47.16369, -1.17607, 47.16396, -1.17599,
      47.16424, -1.1762, 47.16449, -1.17599, 47.16473, -1.17594, 47.16475,
      -1.17573, 47.16457, -1.1754, 47.16457, -1.17512, 47.16469, -1.17506,
      47.16484, -1.17514, 47.16539, -1.17482, 47.16537, -1.17465, 47.16517,
      -1.17461, 47.16517, -1.17434, 47.16545, -1.17401, 47.16577, -1.17336,
      47.16607, -1.17357, 47.1665, -1.1734, 47.16661, -1.17278, 47.16676,
      -1.17262, 47.167, -1.17258, 47.16717, -1.17228, 47.16718, -1.17211,
      47.167, -1.17173, 47.16716, -1.17131, 47.16729, -1.17176, 47.16747,
      -1.17171, 47.16773, -1.17146, 47.16793, -1.17146, 47.16788, -1.17186,
      47.16806, -1.17212, 47.16848, -1.17222, 47.16868, -1.17176, 47.16918,
      -1.17214, 47.16961, -1.17226, 47.16965, -1.17209, 47.16953, -1.17183,
      47.16964, -1.17166, 47.16978, -1.17085, 47.1699, -1.17071, 47.17012,
      -1.1708, 47.1702, -1.17109, 47.17056, -1.17073, 47.17057, -1.17052,
      47.17043, -1.17009, 47.17051, -1.16992, 47.1703, -1.16964, 47.17033,
      -1.16934, 47.17052, -1.16905, 47.17028, -1.16848, 47.17041, -1.16818,
      47.1703, -1.16799, 47.17041, -1.16784, 47.1703, -1.16754, 47.17052,
      -1.1672, 47.17051, -1.16709, 47.17039, -1.16679, 47.17013, -1.16647,
      47.17003, -1.16605, 47.17027, -1.16502, 47.17052, -1.16518, 47.17066,
      -1.16495, 47.17109, -1.16484, 47.17114, -1.16474, 47.17094, -1.16408,
      47.17109, -1.16355, 47.17113, -1.16303, 47.17122, -1.16279, 47.1714,
      -1.16266, 47.17139, -1.16237, 47.17151, -1.16223, 47.17153, -1.16184,
      47.17169, -1.16171, 47.17168, -1.16135, 47.17179, -1.16134, 47.17196,
      -1.16105, 47.17197, -1.16045, 47.17209, -1.16014, 47.1718, -1.15971,
      47.17177, -1.15936, 47.1714, -1.15897, 47.17135, -1.15846, 47.17143,
      -1.15793, 47.17132, -1.15767, 47.17107, -1.15735, 47.17083, -1.15731,
      47.17057, -1.15697, 47.17011, -1.15675, 47.1696, -1.155, 47.16754,
      -1.15092, 47.16686, -1.14911, 47.16638, -1.14742, 47.16648, -1.14697,
      47.16646, -1.14584, 47.16672, -1.14437, 47.16671, -1.14385, 47.16698,
      -1.14379, 47.16744, -1.14317, 47.16813, -1.14191, 47.16841, -1.14112,
      47.16849, -1.14059, 47.16859, -1.14052, 47.16875, -1.13966, 47.16872,
      -1.13899, 47.16771, -1.1366, 47.16667, -1.13517, 47.16601, -1.13344,
      47.16467, -1.13158, 47.16442, -1.13139, 47.16394, -1.13126, 47.16329,
      -1.13074, 47.163, -1.13092, 47.16251, -1.13064, 47.16184, -1.13044,
      47.16177, -1.12896, 47.16148, -1.12849, 47.1607, -1.12779, 47.16045,
      -1.12731, 47.16039, -1.12632, 47.15823, -1.12611, 47.15755, -1.12627,
      47.15613, -1.12716, 47.15538, -1.1281, 47.15481, -1.12733, 47.15442,
      -1.12704, 47.15056, -1.12488, 47.15167, -1.12336, 47.15239, -1.12155,
      47.15179, -1.11992, 47.15121, -1.11753, 47.15106, -1.11651, 47.14966,
      -1.11431, 47.14909, -1.11251, 47.14871, -1.11191, 47.14617, -1.11096,
      47.14742, -1.10893, 47.14805, -1.10769, 47.14697, -1.10677, 47.14816,
      -1.10452, 47.14944, -1.10571, 47.1537, -1.10112, 47.15422, -1.10074,
      47.15359, -1.09882, 47.1535, -1.09807, 47.15357, -1.09716, 47.15352,
      -1.09652, 47.15244, -1.09386, 47.15254, -1.09367, 47.1536, -1.09281,
      47.15474, -1.09222, 47.15644, -1.09206, 47.15718, -1.09156, 47.15641,
      -1.0916, 47.15535, -1.09125, 47.15533, -1.09096, 47.15621, -1.09095,
      47.15655, -1.09082, 47.15689, -1.09054, 47.1577, -1.09013, 47.15786,
      -1.08999, 47.15796, -1.08966, 47.158, -1.08849, 47.15782, -1.08767,
      47.15744, -1.08678, 47.15716, -1.08651, 47.1569, -1.08598, 47.15667,
      -1.08597, 47.15662, -1.08584, 47.15701, -1.08521, 47.15666, -1.08483,
      47.15608, -1.08239, 47.15359, -1.08308, 47.15208, -1.08302, 47.15131,
      -1.08328, 47.15039, -1.08316, 47.15037, -1.08055, 47.15029, -1.07967,
      47.15008, -1.07893, 47.15056, -1.07817, 47.15074, -1.07753, 47.15124,
      -1.07692, 47.15155, -1.07674, 47.15179, -1.07638, 47.15147, -1.07584,
      47.15032, -1.07488, 47.14751, -1.07374, 47.14572, -1.07357, 47.14464,
      -1.07374, 47.14432, -1.07355, 47.1435, -1.07401, 47.14302, -1.07448,
      47.14193, -1.07495, 47.14085, -1.07636, 47.13974, -1.07687, 47.13827,
      -1.07849, 47.13762, -1.07873, 47.13649, -1.07738, 47.13615, -1.07709,
      47.13215, -1.07581, 47.13091, -1.07491, 47.13188, -1.07326, 47.13201,
      -1.07275, 47.1321, -1.07183, 47.13272, -1.06964, 47.13315, -1.06902,
      47.13372, -1.06841, 47.13458, -1.06633, 47.13486, -1.06647, 47.1355,
      -1.06322, 47.13572, -1.06276, 47.13607, -1.0623, 47.1358, -1.06198,
      47.13598, -1.06139, 47.13637, -1.06061, 47.13653, -1.06051, 47.13732,
      -1.05928, 47.13736, -1.05893, 47.13763, -1.05825, 47.13778, -1.05794,
      47.138, -1.05782, 47.13804, -1.05728, 47.13837, -1.05683, 47.13854,
      -1.05623, 47.13879, -1.05582, 47.13879, -1.05553, 47.13922, -1.05304,
      47.13939, -1.05265, 47.13935, -1.05229, 47.13943, -1.05197, 47.13977,
      -1.05138, 47.13986, -1.05107, 47.1398, -1.05099, 47.13983, -1.05011,
      47.14002, -1.04977, 47.1403, -1.04988, 47.14097, -1.04934, 47.14157,
      -1.04865, 47.14207, -1.04777, 47.14225, -1.04762, 47.14242, -1.04717,
      47.14315, -1.0464, 47.14333, -1.04598, 47.14361, -1.04559, 47.14373,
      -1.04501, 47.144, -1.04462, 47.14454, -1.04435, 47.14519, -1.04364,
      47.14579, -1.04363, 47.14637, -1.04318, 47.14619, -1.04284, 47.14594,
      -1.04261, 47.14569, -1.04256, 47.14546, -1.04198, 47.14523, -1.04188,
      47.14474, -1.04135, 47.14442, -1.04067, 47.14427, -1.04015, 47.14424,
      -1.03981, 47.14433, -1.03929, 47.14448, -1.03908, 47.14503, -1.03869,
      47.14536, -1.03875, 47.1456, -1.03894, 47.14565, -1.03848, 47.14582,
      -1.0384, 47.14602, -1.03775, 47.14599, -1.03755, 47.14575, -1.03737,
      47.14584, -1.03688, 47.14571, -1.03672, 47.14554, -1.03609, 47.14568,
      -1.03567, 47.14531, -1.03484, 47.14521, -1.03402, 47.14525, -1.0337,
      47.14561, -1.0336, 47.14565, -1.0328, 47.14585, -1.03242, 47.14538,
      -1.03145, 47.14543, -1.03124, 47.14575, -1.03091, 47.14579, -1.03057,
      47.14526, -1.03003, 47.14499, -1.02947, 47.14474, -1.02915, 47.14486,
      -1.02849, 47.14508, -1.02831, 47.14523, -1.02735, 47.1455, -1.02729,
      47.14556, -1.02718, 47.14558, -1.02677, 47.14576, -1.02645, 47.14572,
      -1.02631, 47.1458, -1.02575, 47.14564, -1.02547, 47.14566, -1.0252,
      47.1454, -1.02486, 47.14544, -1.02462, 47.14515, -1.02424, 47.14502,
      -1.02426, 47.14493, -1.02453, 47.14464, -1.02468, 47.14449, -1.02464,
      47.14445, -1.02442, 47.14464, -1.02392, 47.1445, -1.02378, 47.14466,
      -1.02304, 47.14502, -1.02248, 47.14515, -1.02197, 47.14561, -1.02158,
      47.14578, -1.0212, 47.14593, -1.02056, 47.1462, -1.0201, 47.14625,
      -1.01976, 47.14661, -1.01902, 47.14689, -1.01815, 47.147, -1.01809,
      47.14732, -1.01722, 47.14759, -1.01682, 47.1474, -1.01635, 47.14827,
      -1.01487, 47.14741, -1.01337, 47.1471, -1.01346, 47.14527, -1.01354,
      47.1448, -1.01411, 47.14356, -1.01272, 47.14362, -1.01252, 47.14413,
      -1.01199, 47.14482, -1.01072, 47.14547, -1.00991, 47.14608, -1.00803,
      47.146, -1.00788, 47.14607, -1.00727, 47.14647, -1.00558, 47.14672,
      -1.00388, 47.14732, -1.00402, 47.1481, -1.00451, 47.14809, -1.00493,
      47.14862, -1.0058, 47.14845, -1.00647, 47.14905, -1.00756, 47.14896,
      -1.00776, 47.14902, -1.00794, 47.14951, -1.00873, 47.1492, -1.00894,
      47.14888, -1.00967, 47.14846, -1.01022, 47.14935, -1.01059, 47.14976,
      -1.00942, 47.15038, -1.01001, 47.15043, -1.00982, 47.1506, -1.00986,
      47.15115, -1.0088, 47.15186, -1.00801, 47.15244, -1.00653, 47.1527,
      -1.00495, 47.15282, -1.00479, 47.15206, -1.00291, 47.15167, -1.00168,
      47.15145, -1, 47.1516, -0.99906, 47.15266, -0.99753, 47.15333, -0.9956,
      47.15268, -0.99518, 47.15124, -0.995, 47.1504, -0.99525, 47.14968,
      -0.99524, 47.14956, -0.99447, 47.1493, -0.99409, 47.14951, -0.99368,
      47.14975, -0.99259, 47.15076, -0.99047, 47.15072, -0.98921, 47.15107,
      -0.98819, 47.14955, -0.98766, 47.14817, -0.98755, 47.14711, -0.98733,
      47.14661, -0.98601, 47.14634, -0.98446, 47.14622, -0.98423, 47.146,
      -0.98279, 47.14793, -0.97979, 47.14887, -0.97728, 47.14836, -0.97558,
      47.14813, -0.97538, 47.14804, -0.97508, 47.14808, -0.97388, 47.14796,
      -0.97307, 47.1482, -0.97273, 47.14829, -0.97237, 47.14827, -0.97056,
      47.14737, -0.96807, 47.14633, -0.9661, 47.14514, -0.96306, 47.14538,
      -0.96186, 47.14547, -0.96069, 47.14539, -0.96061, 47.14537, -0.96035,
      47.14515, -0.95972, 47.14501, -0.95785, 47.14472, -0.95716, 47.1447,
      -0.95687, 47.14479, -0.95646, 47.1442, -0.95559, 47.14419, -0.95504,
      47.14027, -0.95627, 47.13948, -0.95632, 47.13825, -0.95695, 47.13727,
      -0.95769, 47.13712, -0.95761, 47.13727, -0.95613, 47.13709, -0.95599,
      47.13699, -0.95649, 47.13667, -0.95645, 47.13531, -0.95511, 47.13453,
      -0.95383, 47.13438, -0.95339, 47.13442, -0.9533, 47.13416, -0.95245,
      47.13394, -0.95137, 47.13354, -0.95144, 47.13306, -0.94869, 47.13283,
      -0.94854, 47.13227, -0.94858, 47.13204, -0.94826, 47.13207, -0.94786,
      47.13271, -0.9467, 47.132, -0.94453, 47.13178, -0.94423, 47.13054,
      -0.94612, 47.13123, -0.94716, 47.13071, -0.94847, 47.12957, -0.95019,
      47.12879, -0.94887, 47.12745, -0.94698, 47.12586, -0.94898, 47.12563,
      -0.949, 47.1243, -0.94849, 47.12409, -0.94874, 47.12422, -0.94952,
      47.1242, -0.94979, 47.12413, -0.94999, 47.12402, -0.95005, 47.12379,
      -0.94991, 47.12332, -0.95153, 47.1227, -0.9515, 47.12219, -0.95169,
      47.12213, -0.95187, 47.12194, -0.95175, 47.12169, -0.95174, 47.12009,
      -0.95213, 47.12018, -0.94943, 47.12032, -0.94795, 47.12062, -0.94619,
      47.12015, -0.94625, 47.11993, -0.94604, 47.11876, -0.94821, 47.11764,
      -0.94988, 47.11657, -0.95062, 47.1155, -0.95094, 47.1152, -0.95144,
      47.1148, -0.95235, 47.1146, -0.95319, 47.11443, -0.95361, 47.11451,
      -0.95586, 47.11443, -0.95651, 47.11455, -0.95688, 47.11463, -0.95781,
      47.11471, -0.95813, 47.11539, -0.95852, 47.11565, -0.95889, 47.11614,
      -0.96157, 47.11625, -0.96369, 47.11621, -0.9645, 47.11577, -0.96526,
      47.11564, -0.96493, 47.11529, -0.96458, 47.11486, -0.96449, 47.11412,
      -0.96455, 47.11373, -0.96467, 47.11346, -0.96492, 47.11308, -0.96502,
      47.11264, -0.96545, 47.11225, -0.9657, 47.11104, -0.96627, 47.11061,
      -0.96625, 47.11008, -0.96676, 47.10987, -0.96725, 47.10887, -0.96705,
      47.10775, -0.96785, 47.10796, -0.96876, 47.1069, -0.97037, 47.1063,
      -0.97022, 47.10597, -0.96945, 47.10544, -0.96872, 47.10476, -0.96633,
      47.10165, -0.96933, 47.10129, -0.96911, 47.10116, -0.97081, 47.10076,
      -0.97365, 47.10053, -0.97406, 47.10041, -0.97483, 47.10007, -0.97844,
      47.09988, -0.97843, 47.09823, -0.97918, 47.09691, -0.9799, 47.09625,
      -0.9815, 47.09525, -0.98274, 47.09493, -0.98336, 47.09496, -0.98364,
      47.09509, -0.98371, 47.0951, -0.98399, 47.09484, -0.98478, 47.09265,
      -0.98739, 47.09176, -0.98876, 47.09109, -0.98941, 47.09079, -0.99009,
      47.09048, -0.99044, 47.0903, -0.99082, 47.09025, -0.99144, 47.09042,
      -0.99181, 47.09038, -0.9921, 47.08936, -0.99303, 47.08902, -0.99311,
      47.08906, -0.99328, 47.08918, -0.99336, 47.08957, -0.99337, 47.09084,
      -0.99789, 47.08862, -0.9985, 47.08739, -0.99777, 47.0867, -0.99751,
      47.08639, -0.99761, 47.08601, -0.99792, 47.08513, -0.99807, 47.08469,
      -0.99774, 47.08397, -0.99748, 47.08387, -0.99665, 47.08319, -0.99655,
      47.08305, -0.99697, 47.08301, -0.99824, 47.0827, -0.99965, 47.08259,
      -1.00113, 47.08248, -1.00164, 47.08185, -1.00326, 47.0805, -1.00428,
      47.0801, -1.00483, 47.07994, -1.00583, 47.07989, -1.00668, 47.08001,
      -1.00727, 47.08004, -1.00823, 47.07983, -1.00867, 47.07905, -1.00913,
      47.07853, -1.00974, 47.07829, -1.01031, 47.07827, -1.01094, 47.07765,
      -1.01207, 47.07685, -1.01248, 47.0766, -1.0129, 47.07653, -1.01317,
      47.07617, -1.01355, 47.07563, -1.01335, 47.07548, -1.01341, 47.07541,
      -1.01408, 47.07528, -1.01445, 47.07533, -1.01492, 47.0755, -1.01542,
      47.07529, -1.0159, 47.07502, -1.01626, 47.07497, -1.01672, 47.07476,
      -1.01743, 47.07521, -1.01928, 47.0758, -1.02078, 47.07675, -1.02259,
      47.07741, -1.0236, 47.07752, -1.02402, 47.07755, -1.02462, 47.07774,
      -1.02522, 47.07776, -1.02635, 47.07785, -1.02684, 47.07727, -1.02766,
      47.07683, -1.02807, 47.07481, -1.02924, 47.074, -1.03, 47.07287, -1.03064,
      47.07158, -1.0326, 47.071, -1.03277, 47.07011, -1.03286, 47.07002,
      -1.03406, 47.06967, -1.03396, 47.06948, -1.03428, 47.0689, -1.0358,
      47.06862, -1.0363, 47.06852, -1.03682, 47.06823, -1.03743, 47.0681,
      -1.03836, 47.06792, -1.03848, 47.06755, -1.03837, 47.06782, -1.03957,
      47.06868, -1.03882, 47.06922, -1.0382, 47.06938, -1.03796, 47.06957,
      -1.03735, 47.06977, -1.03698, 47.07046, -1.03595, 47.07064, -1.03579,
      47.07183, -1.037, 47.07315, -1.03929, 47.07318, -1.04101, 47.07305,
      -1.04141, 47.07271, -1.0419, 47.07142, -1.04285, 47.07092, -1.04344,
      47.07066, -1.04392, 47.06862, -1.04619, 47.06587, -1.0441, 47.06484,
      -1.04357, 47.06398, -1.04292, 47.06244, -1.04263, 47.05951, -1.04296,
      47.06045, -1.04106, 47.06139, -1.03872, 47.05995, -1.03635, 47.05893,
      -1.0351, 47.05807, -1.03379, 47.05681, -1.03256, 47.05597, -1.0316,
      47.05585, -1.03156, 47.05579, -1.03156, 47.05565, -1.03268, 47.05411,
      -1.03679, 47.05389, -1.03712, 47.05351, -1.03818, 47.05307, -1.03909,
      47.05254, -1.04008, 47.05204, -1.04074, 47.05273, -1.04248, 47.05236,
      -1.04386, 47.05178, -1.04329, 47.05136, -1.04239, 47.05102, -1.0419,
      47.05027, -1.04131, 47.04906, -1.04079, 47.0488, -1.04059, 47.04808,
      -1.04036, 47.04787, -1.04067, 47.04722, -1.041, 47.04699, -1.04026,
      47.04661, -1.03946, 47.04631, -1.03915, 47.04581, -1.039, 47.04541,
      -1.03803, 47.04498, -1.03665, 47.04487, -1.03583, 47.04443, -1.03392,
      47.04527, -1.03227, 47.04568, -1.03167, 47.04649, -1.02933, 47.04648,
      -1.02894, 47.04658, -1.02853, 47.04319, -1.02834, 47.04297, -1.02629,
      47.04257, -1.02385, 47.0443, -1.02373, 47.04451, -1.02262, 47.04372,
      -1.02199, 47.04225, -1.02008, 47.0395, -1.02071, 47.0389, -1.02034,
      47.03916, -1.01954, 47.03815, -1.01882, 47.0374, -1.02022, 47.03558,
      -1.01662, 47.03515, -1.01391, 47.03453, -1.01198, 47.03433, -1.01185,
      47.03489, -1.01112, 47.03652, -1.01248, 47.03691, -1.01232, 47.03721,
      -1.01206, 47.03728, -1.01185, 47.03709, -1.01087, 47.0364, -1.01051,
      47.03676, -1.01005, 47.03709, -1.00881, 47.03663, -1.00836, 47.0354,
      -1.00665, 47.03465, -1.00589, 47.03424, -1.00485, 47.03398, -1.00453,
      47.03416, -1.0035, 47.03401, -1.00341, 47.03381, -1.00355, 47.03363,
      -1.00349, 47.03307, -1.00387, 47.03269, -1.00376, 47.03228, -1.00327,
      47.03058, -1.00367, 47.03033, -1.00363, 47.03033, -1.00355, 47.0292,
      -1.00369, 47.0287, -1.00505, 47.02805, -1.00639, 47.02776, -1.00744,
      47.02761, -1.00857, 47.02709, -1.00587, 47.02556, -1.00376, 47.02593,
      -1.00301, 47.02645, -1.00239, 47.02771, -1.00176, 47.02784, -1.00185,
      47.02759, -1.00003, 47.0271, -0.99967, 47.02593, -0.9978, 47.02488,
      -0.99675, 47.02442, -0.99546, 47.02358, -0.99733, 47.02317, -0.99715,
      47.02298, -0.99742, 47.02279, -0.99829, 47.02292, -0.99924, 47.02261,
      -1.00045, 47.02366, -1.00024, 47.02514, -0.9994, 47.02528, -0.99966,
      47.02556, -1.00067, 47.02497, -1.00101, 47.02461, -1.00153, 47.0244,
      -1.00204, 47.02254, -1.00057, 47.02242, -1.0007, 47.022, -1.0019,
      47.02185, -1.0019, 47.02152, -1.00218, 47.02117, -1.00215, 47.0205,
      -1.00267, 47.02007, -1.00275, 47.01937, -1.00359, 47.0187, -1.00481,
      47.01786, -1.00577, 47.01763, -1.00621, 47.01747, -1.00633, 47.01717,
      -1.00726, 47.01686, -1.0073, 47.01652, -1.00758, 47.01604, -1.00768,
      47.01523, -1.00736, 47.01516, -1.00719, 47.01494, -1.00704, 47.01481,
      -1.00674, 47.01368, -1.00647, 47.01303, -1.00645, 47.01269, -1.00678,
      47.01225, -1.00745, 47.01205, -1.00815, 47.01184, -1.00856, 47.01102,
      -1.00944, 47.01062, -1.01008, 47.0104, -1.01067, 47.00995, -1.0111,
      47.00963, -1.01168, 47.00912, -1.01193, 47.00885, -1.0119, 47.00861,
      -1.01208, 47.00808, -1.01212, 47.00757, -1.01195, 47.00706, -1.01292,
      47.00647, -1.01345, 47.00628, -1.01351, 47.00615, -1.01392, 47.00587,
      -1.01429, 47.00541, -1.01458, 47.00517, -1.01452, 47.00422, -1.01548,
      47.00401, -1.01605, 47.00356, -1.0163
    ]
  ]
};

export const MORET_LOING_ET_ORVANNE: LocationState = {
  id: 'FR-77316',
  name: 'Moret-Loing-et-Orvanne',
  type: LocationSearchType.MUNICIPALITY,
  coordinates: [],
  geom: [
    [
      48.32123, 2.80842, 48.32081, 2.80805, 48.32074, 2.80816, 48.31753,
      2.80387, 48.31809, 2.80244, 48.31854, 2.80061, 48.31865, 2.79975,
      48.31915, 2.79904, 48.3195, 2.79816, 48.31973, 2.79699, 48.31982, 2.79586,
      48.31969, 2.79488, 48.31928, 2.79309, 48.31915, 2.79134, 48.31874,
      2.79125, 48.31789, 2.79079, 48.31865, 2.78882, 48.31942, 2.78773,
      48.31952, 2.78728, 48.31945, 2.78667, 48.3197, 2.78628, 48.31975, 2.78595,
      48.31996, 2.78583, 48.32018, 2.7853, 48.31979, 2.78498, 48.32, 2.78476,
      48.32024, 2.78418, 48.32037, 2.78368, 48.32026, 2.78347, 48.32045,
      2.78345, 48.32064, 2.78321, 48.32175, 2.78, 48.32286, 2.77724, 48.32294,
      2.77688, 48.32274, 2.77665, 48.32239, 2.77495, 48.32059, 2.77326,
      48.32066, 2.77309, 48.32097, 2.77288, 48.32309, 2.77342, 48.32455,
      2.77355, 48.32512, 2.77351, 48.32661, 2.77311, 48.32831, 2.77378,
      48.32858, 2.77427, 48.32895, 2.77423, 48.32954, 2.77343, 48.32976,
      2.77296, 48.33035, 2.77209, 48.33045, 2.77174, 48.33086, 2.77143, 48.3317,
      2.77005, 48.33314, 2.7688, 48.33351, 2.76741, 48.3348, 2.76672, 48.33478,
      2.76653, 48.33544, 2.76617, 48.3354, 2.76603, 48.33642, 2.76544, 48.33625,
      2.76477, 48.33755, 2.76457, 48.33832, 2.76815, 48.33898, 2.76919,
      48.33923, 2.76983, 48.3393, 2.77024, 48.33966, 2.77089, 48.34032, 2.7716,
      48.34095, 2.77263, 48.34121, 2.77374, 48.34119, 2.77425, 48.3409, 2.77627,
      48.34005, 2.77742, 48.33985, 2.77785, 48.33959, 2.77885, 48.33934,
      2.77928, 48.33792, 2.78064, 48.33721, 2.78123, 48.33658, 2.78146,
      48.33624, 2.78187, 48.33595, 2.78227, 48.33577, 2.78308, 48.3356, 2.78334,
      48.33544, 2.78404, 48.3358, 2.78515, 48.33763, 2.78851, 48.33887, 2.78958,
      48.33964, 2.78996, 48.34169, 2.7917, 48.34255, 2.79263, 48.34343, 2.79385,
      48.34436, 2.79236, 48.34484, 2.79137, 48.34566, 2.7924, 48.3465, 2.79304,
      48.34741, 2.79423, 48.34755, 2.79403, 48.35147, 2.79892, 48.35229,
      2.79964, 48.35364, 2.79921, 48.35558, 2.79939, 48.3576, 2.79945, 48.35791,
      2.79936, 48.35862, 2.80021, 48.35924, 2.80115, 48.35931, 2.80101, 48.3614,
      2.80214, 48.36462, 2.80178, 48.36622, 2.80143, 48.37059, 2.79676,
      48.37101, 2.79596, 48.37168, 2.79497, 48.37126, 2.79296, 48.37088, 2.7919,
      48.37085, 2.79147, 48.37094, 2.79128, 48.37336, 2.78768, 48.37348,
      2.78787, 48.37717, 2.78283, 48.37811, 2.78183, 48.37833, 2.78197,
      48.37965, 2.78019, 48.38032, 2.77957, 48.38275, 2.77841, 48.38386,
      2.77797, 48.38551, 2.77761, 48.38662, 2.77721, 48.38701, 2.77715,
      48.38734, 2.77722, 48.38805, 2.77752, 48.38872, 2.77804, 48.38967,
      2.77812, 48.39149, 2.78953, 48.39136, 2.78949, 48.39032, 2.78986,
      48.38895, 2.79052, 48.38835, 2.79119, 48.38797, 2.79219, 48.38787,
      2.79364, 48.38815, 2.79686, 48.38812, 2.79851, 48.38807, 2.79972,
      48.38764, 2.80228, 48.38701, 2.80233, 48.38629, 2.80273, 48.38593,
      2.80316, 48.38466, 2.80576, 48.38153, 2.81032, 48.3805, 2.81253, 48.37961,
      2.81471, 48.38079, 2.81605, 48.38104, 2.81645, 48.38115, 2.81686,
      48.38148, 2.81714, 48.38162, 2.8175, 48.38212, 2.81912, 48.38203, 2.81928,
      48.38259, 2.82107, 48.38266, 2.821, 48.38324, 2.82312, 48.38316, 2.82334,
      48.38287, 2.82343, 48.38301, 2.82557, 48.38291, 2.82633, 48.38296,
      2.82671, 48.38267, 2.82726, 48.38499, 2.83042, 48.38385, 2.83248,
      48.38218, 2.83431, 48.38319, 2.83574, 48.38363, 2.8351, 48.38414, 2.83586,
      48.38433, 2.83565, 48.38474, 2.83619, 48.38487, 2.83613, 48.38571,
      2.83876, 48.38481, 2.84024, 48.38092, 2.84482, 48.38072, 2.84481,
      48.38031, 2.84508, 48.37905, 2.84619, 48.37789, 2.84711, 48.37641,
      2.84898, 48.37496, 2.85169, 48.37378, 2.85458, 48.37309, 2.85369,
      48.37346, 2.85198, 48.37343, 2.85166, 48.37288, 2.8527, 48.372, 2.854,
      48.37032, 2.85584, 48.37022, 2.85612, 48.37001, 2.85631, 48.36969,
      2.85684, 48.36868, 2.85783, 48.36837, 2.85797, 48.36763, 2.85889,
      48.36723, 2.85822, 48.36731, 2.85812, 48.3646, 2.85557, 48.36447, 2.85689,
      48.36456, 2.85781, 48.36432, 2.85766, 48.36413, 2.8584, 48.36381, 2.85914,
      48.36308, 2.85846, 48.36191, 2.86315, 48.36144, 2.86546, 48.36121,
      2.86519, 48.3607, 2.86524, 48.3601, 2.86588, 48.35915, 2.86772, 48.35821,
      2.86993, 48.35686, 2.8718, 48.35602, 2.87379, 48.35523, 2.87478, 48.35492,
      2.87769, 48.35351, 2.87752, 48.35262, 2.87919, 48.35036, 2.88197,
      48.34875, 2.87876, 48.34847, 2.87844, 48.34857, 2.87015, 48.34412, 2.8709,
      48.34414, 2.87031, 48.34393, 2.87019, 48.34397, 2.86845, 48.3417, 2.86863,
      48.34167, 2.8682, 48.34086, 2.86828, 48.34051, 2.86513, 48.34005, 2.8622,
      48.34084, 2.86162, 48.34152, 2.86131, 48.34084, 2.85693, 48.34039,
      2.85691, 48.33989, 2.85419, 48.33972, 2.85262, 48.33978, 2.85095,
      48.34192, 2.84363, 48.34134, 2.84393, 48.34119, 2.84442, 48.34108,
      2.84433, 48.34084, 2.84263, 48.34038, 2.84084, 48.33851, 2.83606,
      48.33753, 2.83715, 48.33727, 2.83681, 48.33713, 2.83647, 48.33635,
      2.83369, 48.33623, 2.83203, 48.33587, 2.83101, 48.33364, 2.8266, 48.33356,
      2.82567, 48.33347, 2.82011, 48.33342, 2.81998, 48.33236, 2.81985,
      48.33178, 2.81582, 48.33064, 2.81602, 48.32971, 2.81474, 48.32562,
      2.81107, 48.32318, 2.81013, 48.32225, 2.80939, 48.32224, 2.80924,
      48.32123, 2.80842
    ]
  ]
};
