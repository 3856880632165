import { useRouter } from 'next/router';
import React, { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import Cookies from 'universal-cookie';

import { authSelector } from '@/components/state/AuthState';
import { COOKIE_KEY } from '@/constants/constants';
import { CUSTOMER_DASHBOARD_LINK, FRONT_URL } from '@/constants/global';
import { useUser } from '@/hooks/state';
import { decodeToken, expiredToken } from '@/utils/auth/auth';
import { Loader } from '@proprioo/salatim';

export const EXTERNAL_AUTH_URL = `${
  process.env.NEXT_PUBLIC_AUTH_CLIENT_AUTHORIZE
}?client_id=${
  process.env.NEXT_PUBLIC_AUTH_CLIENT_ID
}&redirect_uri=${`${FRONT_URL}/dashboard`}/callback&response_type=${
  process.env.NEXT_PUBLIC_AUTH_CLIENT_RESPONSE
}`;

type AuthProps = {
  cookieToken: string;
  listingId?: string;
  queryToken?: string;
};

const cookies = new Cookies();

const Auth: FC<PropsWithChildren<AuthProps>> = ({
  cookieToken,
  queryToken,
  listingId,
  children
}) => {
  const { t } = useTranslation();
  const { route, push, replace } = useRouter();
  const { id: storedUserId, listings } = useUser();
  const [{ userId }, setAuthSelector] = useRecoilState(authSelector);

  const token = queryToken || cookieToken;
  const user = decodeToken(token);

  useEffect(() => {
    if (user && !expiredToken(user.expiredAt)) {
      cookies.set(COOKIE_KEY, token, {
        path: '/',
        expires: new Date(user.expiredAt)
      });

      if (!userId) {
        setAuthSelector({ userId: user.uid, type: user.type });
      }

      if (
        (route === '/dashboard/[[...slug]]' ||
          route === '/dashboard/callback/listing/[listingId]') &&
        storedUserId
      ) {
        push(
          listings.length
            ? `/dashboard/listing/${listingId ? listingId : listings[0].id}`
            : CUSTOMER_DASHBOARD_LINK
        );
      }
    } else {
      replace(EXTERNAL_AUTH_URL);
    }
  }, [user, listings]);

  return userId && storedUserId ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Loader text={t('clientDashboardLoading')} />
  );
};

export default Auth;
