import styled, { css } from 'styled-components';

import { colors, convertPxToRem, paragraphs, radius } from '@proprioo/salatim';

import { ToastStatus } from './interfaces';

export const ToastWrapper = styled.div<{
  status: ToastStatus;
  isMobile: boolean;
}>`
  display: flex;
  align-items: center;
  border-left: ${convertPxToRem(4)} solid currentColor;
  border-radius: ${radius.small};
  padding: ${convertPxToRem(8)} ${convertPxToRem(16)};
  width: ${({ isMobile }) => (isMobile ? '100vw' : `${convertPxToRem(500)}`)};

  ${({ status }) =>
    status === ToastStatus.ERROR
      ? css`
          color: ${colors.red.base};
          background: ${colors.red.base10};
        `
      : status === ToastStatus.WARNING
      ? css`
          color: ${colors.terracota.base};
          background: ${colors.terracota.base10};
        `
      : css`
          color: ${colors.dark.base};
          background: ${colors.blue.base60};
        `}
`;

export const IconWrapper = styled.div`
  svg {
    width: ${convertPxToRem(32)};
    height: ${convertPxToRem(32)};
  }
`;

export const TextWrapper = styled.div`
  ${paragraphs.body2};
  margin: 0 ${convertPxToRem(8)};
`;

export const NotificationLayout = styled.div<{ isMobile: boolean }>`
  .Toastify__toast-container {
    ${({ isMobile }) =>
      isMobile
        ? css`
            width: 100vw;
            padding-top: ${convertPxToRem(56)};
          `
        : css`
            width: ${convertPxToRem(500)};
            padding-bottom: ${convertPxToRem(32)};
          `}
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast {
    background: transparent;
    padding: 0;
    min-height: ${convertPxToRem(52)};
  }
`;
