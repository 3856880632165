import '../../helpers/wdyr.js'; // Must be import first
import 'react-toastify/dist/ReactToastify.css';
import '@/components/common/carousel/slick-theme.css';
import '@/components/common/carousel/slick.css';
import '../css/fonts.css';
import '../css/nprogress.css';
import '../i18n';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '@/components/dashboard/planning/planningCalendar/PlanningCalendar.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { FC, Fragment, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';

import NotificationToast from '@/components/app/toast/NotificationToast';
import RecoilAuth from '@/components/state/RecoilAuth';
import { isProduction } from '@proprioo/hokkaido';

import { DATA_LAYER_NAME, GOOGLE_TAG_MANAGER } from '../utils/gtm';

NProgress.configure({ showSpinner: false, trickleSpeed: 100, minimum: 0.2 });

type RouterProperties = {
  shallow: boolean;
};

const CustomApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  const handleRouteChange = (_: string, { shallow }: RouterProperties) => {
    NProgress.done();

    if (!shallow) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (isProduction()) {
      TagManager.initialize({
        gtmId: GOOGLE_TAG_MANAGER,
        dataLayerName: DATA_LAYER_NAME
      });

      if (window[DATA_LAYER_NAME]) {
        TagManager.dataLayer({
          dataLayer: {
            originalLocation:
              document.location.protocol +
              '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
          },
          dataLayerName: DATA_LAYER_NAME
        });
      }
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeError', () => NProgress.start());
    router.events.on('routeChangeComplete', handleRouteChange);
  });

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          focusThrottleInterval: 60000,
          shouldRetryOnError: false,
          fetcher: url => fetch(url).then(res => res.json())
        }}
      >
        <RecoilRoot>
          <RecoilAuth>
            <NotificationToast />
            <Component {...pageProps} />
          </RecoilAuth>
        </RecoilRoot>
      </SWRConfig>
    </Fragment>
  );
};

export default CustomApp;
