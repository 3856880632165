import { nullOrNumber } from '@proprioo/hokkaido';

export enum Days {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday'
}

export type RangeProps = {
  start: string;
  end: string;
};

export type DateProps = {
  listingId: number;
  range: RangeProps;
  day: Days;
};

export type UniqueSlot = {
  range: RangeProps;
  date: string;
};

export type RecurrentDay = DateProps & {
  id: string;
  lastOccurenceDate?: string;
  duplicateIds?: string[];
  // hash is used for internal RecurrentDay created by client app.
  hash?: string;
};

export type RecurrentDayRequest = DateProps & {
  id: null;
};

export type Slot = DateProps & {
  date: string;
  id: nullOrNumber;
  listingRecurrent: nullOrNumber;
};

export type RecurrentDayDict = Record<string, RecurrentDay[]>;

export type GroupedDays = {
  deletedIds: string[];
  days: RecurrentDayDict;
};
