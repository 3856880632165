export enum LocationSearchType {
  COUNTY = 'county',
  IRIS = 'iris',
  MUNICIPALITY = 'municipality',
  NEIGHBORHOOD = 'neighborhood',
  REGION = 'region',
  SUBMUNICIPALITY = 'submunicipality'
}

export type GeoResponseAPI = {
  id: string;
  name: string;
  type: LocationSearchType;
  geom: string[][];
};
