export enum FilterTypes {
  TYPE = 'typeBien',
  PRICE = 'prix',
  SURFACE = 'surface',
  BEDROOMS = 'nbBedrooms',
  ROOMS = 'nbPieces',
  LOCATION = 'localisation',
  PAGE = 'page',
  STATUS = 'status',
  OTHER = 'other'
}
