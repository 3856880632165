import { atom, selector } from 'recoil';

import { User, UserType } from '../dashboard/auth/interfaces';

type UserAtomProps = User & {
  refreshUser(): Promise<{ user: User } | undefined>;
};

const DEFAULT_USER: User = {
  address: '',
  city: '',
  email: '',
  firstName: '',
  id: '',
  lastName: '',
  listings: [],
  phone: '',
  streetnumber: '',
  type: UserType.CLIENT,
  zipcode: ''
};

export const userAtom = atom<UserAtomProps>({
  key: 'userAtom',
  default: {
    ...DEFAULT_USER,
    refreshUser: () =>
      new Promise<{ user: User } | undefined>(() => DEFAULT_USER)
  }
});

export const userSelector = selector<UserAtomProps>({
  key: 'userSelector',
  get: ({ get }) => get(userAtom),
  set: ({ set }, newValues) => set(userAtom, newValues)
});
