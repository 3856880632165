import React from 'react';

if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  /**
   * By default, do not track anything
   * @see https://github.com/welldone-software/why-did-you-render#options
   */
  whyDidYouRender(React, { trackHooks: false });
}
