import { atom, selector } from 'recoil';

import { UserType } from '../dashboard/auth/interfaces';

type AuthProps = {
  userId?: string;
  type?: UserType;
};

export const authAtom = atom<AuthProps>({
  key: 'authAtom',
  default: {}
});

export const authSelector = selector<AuthProps>({
  key: 'authSelector',
  get: ({ get }) => get(authAtom),
  set: ({ set }, newValues) => set(authAtom, newValues)
});
