import React, { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useSWR from 'swr';
import Cookies from 'universal-cookie';

import { authSelector } from '@/components/state/AuthState';
import { userSelector } from '@/components/state/UserState';
import { COOKIE_KEY } from '@/constants/constants';

import { EXTERNAL_AUTH_URL } from '../dashboard/auth/Auth';
import { User } from '../dashboard/auth/interfaces';
import { filterLostListings } from '../dashboard/auth/utils';

const RecoilAuth: FC<PropsWithChildren> = ({ children }) => {
  const { userId, type: userType } = useRecoilValue(authSelector);
  const [storedUser, setStoredUser] = useRecoilState(userSelector);

  const hasToken = userId && userType;

  const {
    data,
    isValidating,
    mutate: refreshUser
  } = useSWR<{ user: User }>(
    hasToken ? `/api/dashboard/userById?id=${userId}` : null
  );

  if (!isValidating && data === null && hasToken) {
    const cookies = new Cookies();
    cookies.remove(COOKIE_KEY);
    window.location.replace(EXTERNAL_AUTH_URL);
  }

  useEffect(() => {
    if (hasToken && userType && data && !isValidating) {
      const user = {
        ...data.user,
        listings: filterLostListings(data.user.listings),
        refreshUser,
        type: userType
      };

      if (JSON.stringify(user) !== JSON.stringify(storedUser)) {
        setStoredUser(user);
      }
    }
  }, [data]);

  return <Fragment>{children}</Fragment>;
};

export default RecoilAuth;
