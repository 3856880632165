import TagManager from 'react-gtm-module';

import { isProduction } from '@proprioo/hokkaido';

export const GOOGLE_TAG_MANAGER = 'GTM-PSCPMXR';
export const DATA_LAYER_NAME = 'pageViewData';
export enum TITLE_DATALAYER {
  ABOUT_US = 'about-us',
  AGENT_PAGE = 'agent-profile-page',
  ALERT_FORM = 'alert-form',
  ALERT_FORM_UPDATE = 'alert-form-update',
  ALERT_FORM_MAP = 'alert-map',
  ALERT_CONFIRMATION = 'alert-confirmation',
  ALERT_EDIT_CONFIRMATION = 'alert-edit-confirmation',
  ALERT_UNSUBSCRIBE_CONFIRMATION = 'alert-unsubscribe-confirmation',
  ALERT_UNSUBSCRIBE_ALL_CONFIRMATION = 'alert-unsubscribe-all-confirmation',
  BOOKING_ENTRANCE = 'seller booking - funnel entrance',
  BOOKING_LEAD_INSIDE = 'seller booking - lead inside area',
  BOOKING_LEAD_OUTSIDE = 'seller booking - lead outside area',
  BOOKING_MEETING = 'seller booking - meeting confirmed',
  BOOKING_REDIRECT_LINK = 'seller booking - property - valuation redirection click',
  CONTACT = 'contact',
  CONTACT_SUCCESS = 'contact-success',
  DASHBOARD_CUSTOMER = 'dashboard-customer',
  DASHBOARD_LISTING = 'dashboard-listing',
  DASHBOARD_PLANNING = 'dashboard-planning',
  DASHBOARD_VISITS = 'dashboard-visits',
  EOL_ENTRANCE = 'EOL-funnel-entrance',
  EOL_TOO_MANY = 'EOL-too-many',
  EOL_CONFIRMATION_INSIDE = 'EOL-confirmation-seller-inside-area',
  EOL_CONFIRMATION_OUTSIDE = 'EOL-confirmation-seller-outside-area',
  EOL_CONFIRMATION_BUYER = 'EOL-confirmation-buyer',
  EOL_SUCCESS = 'EOL-success',
  GUIDE = 'guide',
  HOMEPAGE = 'home-page',
  HOMEPAGE_LYON = 'home-page-lyon',
  HOMEPAGE_NICE = 'home-page-nice',
  HOMEPAGE_NANTES = 'home-page-nantes',
  HOMEPAGE_AIX = 'home-page-aix',
  OFFER_ENTRANCE = 'offer',
  OFFER_CONFIRMATION = 'offer-confirmation',
  LEGAL = 'mentions-legales',
  LISTING = 'annonce',
  LISTINGS = 'nosannonces',
  LISTING_EXPIRED = 'annonce-expired',
  PRICING = 'services-tarifs',
  PRIVACY = 'charte-pour-la-protection-des-donnees',
  QUALIFICATION = 'qualification-acheteur',
  VIRTUAL_CARD = 'virtual-card',
  VISIT = 'reserver-visite',
  VISIT_CONFIRMATION = 'confirmation-visite'
}

export type DatalayerProps = Record<
  string,
  string | number | Record<string, unknown> | undefined
>;

export const sendDatalayer = (options: DatalayerProps) => {
  const { path, title, ...rest } = options;

  if (isProduction() && window[DATA_LAYER_NAME]) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: {
          ...(path && { path }),
          ...(title && { title })
        },
        ...rest
      },
      dataLayerName: DATA_LAYER_NAME
    });
  }
};
