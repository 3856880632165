import { jwtDecode } from 'jwt-decode';

import { UserType } from '@/components/dashboard/auth/interfaces';

export type TokenProps = {
  uid: string;
  type: UserType;
  expiredAt: number;
};

export const decodeToken = (token: string): TokenProps | null => {
  try {
    return jwtDecode(token) || null;
  } catch (error) {
    return null;
  }
};

export const expiredToken = (date: number) => date < Date.now();
