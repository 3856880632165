// Constants
export const COMPANY = 'Casavo';
export const OPINION_SYSTEM_KEY = '9943aa909377c697f867839f572c511b';
export const PHONE_NUMBER = '01 86 65 65 65';
export const PHONE_NUMBER_AIX = ' 04 11 92 32 73';
export const PHONE_NUMBER_AIX_BUYER = '04 84 80 05 42';
export const PHONE_NUMBER_BUYER = '01 83 81 11 11';
export const PHONE_NUMBER_LYON = '04 22 84 04 74';
export const PHONE_NUMBER_LYON_BUYER = '04 81 68 39 89';
export const PHONE_NUMBER_NANTES = '02 78 84 00 33';
export const PHONE_NUMBER_NANTES_BUYER = '02 52 86 00 95';
export const PHONE_NUMBER_NICE = '04 85 80 00 48';
export const PHONE_NUMBER_NICE_BUYER = '04 83 58 11 06';

// Date helpers
export const DATE_FORMAT = 'dd/MM/yyyy';
export const FULL_DATE_FORMAT = 'dd MMMM yyyy';

// Environment variables
export const API_URL = `${process.env.API_URL}`;
export const APP_SALES_URL = `${process.env.NEXT_PUBLIC_APP_SALES_URL}`;
export const BACKOFFICE_URL = `${process.env.NEXT_PUBLIC_BACKOFFICE_URL}`;
export const BUYER_URL = `${process.env.BUYER_URL}`;
export const CALENDAR_URL = `${process.env.CALENDAR_URL}`;
export const CASAVO_VALUATION_URL = `${process.env.CASAVO_VALUATION_URL}`;
export const FRONT_URL = `${process.env.NEXT_PUBLIC_FRONT_URL}`;
export const GEO_URL = `${process.env.GEO_URL}`;
export const GRAPH_TOKEN = `${process.env.GRAPH_TOKEN}`;
export const IMAGES_URL = `${process.env.NEXT_PUBLIC_IMAGES_URL}`;
export const LOCAL_API = `http://${process.env.HOSTNAME || 'localhost'}:3000`;
export const OAUTH_JWT_SIGNING_CERTIFICATE = `${process.env.OAUTH_JWT_SIGNING_CERTIFICATE}`;
export const PLANNER_URL = `${process.env.PLANNER_URL}`;
export const SSO_COOKIE_NAME = `${process.env.SSO_COOKIE_NAME}`;

// Feature flags
export const FEATURE_FLAG_PHONE = `${process.env.NEXT_PUBLIC_FEATURE_FLAG_PHONE}`;

// Links
export const CUSTOMER_DASHBOARD_LINK = '/dashboard/customer';
export const OPINION_LINK =
  'https://www.opinionsystem.fr/fr-fr/certificate/8132';
export const OPINION_LINK_LYON =
  'https://www.opinionsystem.fr/fr-fr/certificate/10811';
export const OPINION_LINK_NICE =
  'https://www.opinionsystem.fr/fr-fr/certificate/10812';
export const URL_API_OPINION_SYSTEM = 'https://new.api.opinionsystem.fr/v2';
export const URL_API_SMART_RECRUITERS =
  'https://api.smartrecruiters.com/v1/companies/proprioo/postings';
export const URL_BACKOFFICE_QUALIFICATION = `${BACKOFFICE_URL}/qualification/seller`;
export const URL_COMPANY = `${FRONT_URL}`;
export const URL_FACEBOOK = 'https://www.facebook.com/CasavoFrance';
export const URL_INSTAGRAM = 'https://www.instagram.com/casavo.fr/';
export const URL_JOB = 'https://jobs.smartrecruiters.com/Proprioo';
export const URL_LEGAL_MENTIONS = `${URL_COMPANY}/mentions-legales`;
export const URL_LINKEDIN = 'https://www.linkedin.com/company/casavo-group/';
export const URL_PARAGES = 'https://www.proprioo.fr/blog';
export const URL_PRIVACY_POLICY = `${URL_COMPANY}/charte-pour-la-protection-des-donnees`;
export const URL_RECRUITMENT_NEW_ZONES =
  'https://recrutement.proprioo.fr/nouvelles-zones/';
export const URL_SMART_RECRUITERS =
  'https://careers.smartrecruiters.com/Proprioo';
export const URL_SMART_RECRUITERS_APPLY =
  'https://jobs.smartrecruiters.com/oneclick-ui/company/Proprioo/job/1502190874';
export const URL_TWITTER = 'https://twitter.com/Casavo_it';

export type UrlProps = {
  label: string;
  href: string;
  as?: string;
  isInternal?: boolean;
};

export type UrlType = Record<string, UrlProps>;

export const URLS: UrlType = {
  ABOUT: {
    label: 'whoWeAre',
    href: 'https://casavo.com/fr/a-propos/',
    isInternal: false
  },
  AGENTS: {
    label: 'agents',
    href: '/nos-agents',
    isInternal: true
  },
  ALERT: {
    label: 'beAlerted',
    href: '/nosannonces/alerte',
    isInternal: true
  },
  ALERT_MAP: {
    label: 'beAlertedMap',
    href: '/nosannonces/alerte/carte',
    isInternal: true
  },
  ARTICLE: {
    label: 'guide',
    href: '/guide/[hub]/[chapter]/[article]',
    isInternal: true
  },
  BLOG: {
    href: 'https://casavo.com/fr/blog/',
    label: 'blog'
  },
  BOOKING: {
    label: 'sell',
    href: '/rendez-vous-estimation/',
    isInternal: true
  },
  CHAPTER: {
    label: 'guide',
    href: '/guide/[hub]/[chapter]',
    isInternal: true
  },
  COMMUNITY_AGENTS: {
    label: 'communityAgents',
    href: '/communaute-agents',
    isInternal: true
  },
  CONFIRMATION: {
    label: 'alert',
    href: '/nosannonces/alerte/confirmation',
    isInternal: true
  },
  CONFIRMATION_SELLER: {
    label: 'alert',
    href: '/nosannonces/alerte/confirmation/vendeur',
    isInternal: true
  },
  CONTACT: {
    label: 'contactUs',
    href: '/contact',
    isInternal: true
  },
  CONTACT_CONFIRMATION: {
    label: 'contactUs',
    href: '/contact/success',
    isInternal: true
  },
  DASHBOARD: {
    label: 'dashboard',
    href: '/dashboard',
    isInternal: true
  },
  ERROR: {
    label: 'error',
    href: '/erreur',
    isInternal: true
  },
  GUIDE: {
    label: 'guide',
    href: '/guide',
    isInternal: true
  },
  FAQ: {
    label: 'faqLabel',
    href: 'https://casavo.com/fr/faqs/',
    isInternal: false
  },
  FEES: {
    label: 'ourFees',
    href: 'https://info.casavo.com/hubfs/FR%20files/FR%20PDFs/Bar%C3%A8mes%20honoraires%20de%20vente%20%E2%80%93%20Casavo%20.pdf',
    isInternal: false
  },
  HOMEPAGE: {
    href: '/',
    label: 'home',
    isInternal: true
  },
  HOMEPAGE_IDF: {
    href: '/ville/immobilier-ile-de-france',
    label: 'homeIdf',
    isInternal: true
  },
  HOMEPAGE_AIX: {
    href: '/ville/immobilier-aix-en-provence',
    label: 'homeAix',
    isInternal: true
  },
  HOMEPAGE_LYON: {
    href: '/ville/immobilier-lyon',
    label: 'homeLyon',
    isInternal: true
  },
  HOMEPAGE_NICE: {
    href: '/ville/immobilier-nice',
    label: 'homeNice',
    isInternal: true
  },
  HOMEPAGE_NANTES: {
    href: '/ville/immobilier-nantes',
    label: 'homeNantes',
    isInternal: true
  },
  HUB: {
    label: 'guide',
    href: '/guide/[hub]',
    isInternal: true
  },
  JOIN_US: {
    href: 'https://careers.casavo.com/job-positions/',
    label: 'joinUs',
    isInternal: false
  },
  LEGAL: {
    label: 'legalMentions',
    href: '/mentions-legales',
    isInternal: true
  },
  LEGAL_COOKIES: {
    label: 'legalCookies',
    href: '/politique-cookies',
    isInternal: true
  },
  LISTING: {
    label: 'listing',
    href: '/nosannonces/annonce/[...id]',
    as: '/nosannonces/annonce',
    isInternal: true
  },
  LISTING_EXPIRED: {
    label: 'expiredListing',
    href: '/nosannonces/annonce-expiree',
    isInternal: true
  },
  MEDIA: {
    label: 'menumedia',
    href: 'https://casavo.com/fr/presse/',
    isInternal: false
  },
  OFFER: {
    label: 'createOffer',
    href: '/offre',
    isInternal: true
  },
  OFFER_CONFIRMATION: {
    label: 'ourOffer',
    href: '/offre/confirmation',
    isInternal: true
  },
  OUR_OFFER: {
    label: 'ourOffer',
    href: '/vendre',
    isInternal: true
  },
  PRICES: {
    label: 'services',
    href: '/services-et-tarifs',
    isInternal: true
  },
  PRIVACY: {
    label: 'privacyPolicy',
    href: '/charte-pour-la-protection-des-donnees',
    isInternal: true
  },
  QUALIFICATION: {
    label: 'qualification',
    href: '/qualification',
    isInternal: true
  },
  RECRUITMENT: {
    href: `/devenir-agent-immobilier`,
    label: 'beAgent',
    isInternal: true
  },
  RECRUITMENT_ALERT: {
    href: URL_RECRUITMENT_NEW_ZONES,
    label: 'ourJobOffersAlertCtaLabel',
    isInternal: false
  },
  SEARCH: {
    label: 'ads',
    href: '/nosannonces',
    isInternal: true
  },
  SELLER_GUIDE: {
    label: 'guide',
    href: 'https://casavo.com/fr/guides/comment-vendre-un-bien-immobilier/frais-agence-vente/',
    isInternal: false
  },
  SMART_RECRUITERS: {
    href: URL_SMART_RECRUITERS,
    label: 'ourJobOffersBecomeAgentCtaLabel',
    isInternal: false
  },
  SMART_RECRUITERS_APPLY: {
    href: URL_SMART_RECRUITERS_APPLY,
    label: 'ourJobOffersApplyLabel',
    isInternal: false
  },
  SUPPORT_AGENTS: {
    label: 'supportAgents',
    href: '/support-agents',
    isInternal: true
  },
  TOOLS_PROPRIOO: {
    href: '/app-proprioo',
    label: 'toolsProprioo',
    isInternal: true
  },
  VALUATION: {
    label: 'visitValuation',
    href: '/estimation-immobiliere',
    isInternal: true
  },
  VALUATION_CONFIRMATION: {
    label: 'visitValuation',
    href: '/estimation-immobiliere/confirmation',
    isInternal: true
  },
  VALUATION_SUCCESS: {
    label: 'visitValuation',
    href: '/estimation-immobiliere/success',
    isInternal: true
  },
  VISIT: {
    label: 'visitListing',
    href: '/visiter/[id]',
    as: '/visiter',
    isInternal: true
  }
};
