import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import { useMobileDevice } from '@proprioo/salatim';

import { NotificationLayout } from './Toast.styles';

const NotificationToast: FC = () => {
  const isMobile = useMobileDevice();

  return (
    <NotificationLayout isMobile={isMobile}>
      <ToastContainer
        autoClose={2000}
        closeButton={false}
        closeOnClick={true}
        hideProgressBar={true}
        icon={false}
        newestOnTop={true}
        position={isMobile ? 'top-center' : 'bottom-right'}
      />
    </NotificationLayout>
  );
};

export default NotificationToast;
