import { ListingAttributes } from '@/components/listing/listing/Listing.interfaces';

export enum UserType {
  AGENT = 'agent',
  CLIENT = 'client'
}

export type UserProps = {
  address: string;
  city: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phone: string;
  type: UserType;
  zipcode: string;
  streetnumber?: string;
};

export type User = UserProps & {
  listings: ListingAttributes[];
};

export type UserContextProps = User & {
  refreshUser(): Promise<{ user: User } | undefined>;
};
