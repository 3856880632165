import { resources } from 'i18n.resources';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE } from './constants/constants';

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  keySeparator: false,
  lng: LANGUAGE,
  resources,
  returnNull: false
});

export default i18n;
